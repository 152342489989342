import styled from "styled-components";

import { vars } from "../../../../common/styles";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 3rem 0;
`;

export const FormLinks = styled.div`
  margin-top: 3rem;
  font-size: 1.4rem;

  p {
    color: ${vars.colors.grey60};

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;
