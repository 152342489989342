import { useUserEventSocketConsumer } from "./useUserEventSocketConsumer";
import { RowType, UserTableRowItem } from "../../../../common/components/MasterTable/models/MasterTableModel";
import { useUsersTable } from "../../../../common/components/ShakeTable/modules/useUsersTable";
import { TicketSortOption, UserSortOption } from "../../../../common/components/ShakeTable/ui/SortHeaderCell";
import { Attribute, UseModuleAttributes } from "../../../../common/hooks/filtering/sharedTypes";
import { useAppBoundStorage } from "../../../../common/hooks/filtering/useAppBoundStorage";
import { prepareFiltersForRequest } from "../../../../common/hooks/filtering/utils";
import { useShakePagination } from "../../../../common/hooks/useShakePaginaton";
import { AppUser } from "../../../../common/models";
import { getTimeSinceLong } from "../../../../common/models/helpers/time/timeSince";
import { useAppContext } from "../../../context/App/App.context";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useUsersDeps } from "../Users";

export default function useUsersPageApiConsumer({ attributesProps }: { attributesProps: UseModuleAttributes }) {
  const { socket } = useAppContext();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const { usersService } = useUsersDeps();

  const { state: sortOption, setState: setSortOption } = useAppBoundStorage<TicketSortOption | UserSortOption>(
    "shakebugs.users_sort",
    "created",
  );

  /// Pagination logic
  const pagination = useShakePagination<AppUser, UserTableRowItem>({
    deps: [selectedWorkspace?.id, selectedApp?.id, attributesProps.validAttributes, sortOption.value],
    fetchFn: ({ deps, signal, pagination }) => {
      const workspaceID = deps[0] as string;
      const appID = deps[1] as string;
      const validAttributes = deps[2] as Array<Required<Attribute>>;
      const sort = deps[3] as UserSortOption;

      if (Boolean(validAttributes.length)) {
        const filters = prepareFiltersForRequest(validAttributes);
        return usersService
          .getUsersByFilters(
            workspaceID,
            appID,
            pagination.offset,
            pagination.limit,
            filters,
            sort as UserSortOption,
            signal,
          )
          .then(({ data }) => {
            return { total: data.total, items: data.items };
          });
      } else {
        return usersService
          .getAppUsers(workspaceID, appID, pagination.limit, pagination.offset, sort as UserSortOption, signal)
          .then(({ data }) => {
            return { total: data.total, items: data.items };
          });
      }
    },
    enabled: (deps) => deps.every((dep) => Boolean(dep)),
    select: {
      mapperDeps: [],
      mapper: (items) => {
        const mappedItems = [] as UserTableRowItem[];
        items.map((user) => {
          if (user) {
            mappedItems.push(mapUserToTableRowItem(user as AppUser));
          }
        });
        return mappedItems;
      },
    },
    queryKey: `users ${selectedApp?.id}`,
  });

  useUserEventSocketConsumer({ refetchData: pagination.refetchData, socket });

  /// Table props

  const userTableProps = useUsersTable({
    data: pagination.mappedData || [],
    hasMore: pagination.hasMore,
    isLoading: pagination.isLoading,
    isFetchingNext: pagination.isFetchingNextPage,
    loadMore: pagination.loadNext,
    selectable: false,
    onChangeSortOption: (newOption) => {
      if (newOption == sortOption.value) {
        pagination.refetchData();
        return;
      }
      setSortOption(newOption);
    },
    selectedSortOption: sortOption.value,
    total: pagination.total,
  });

  return {
    items: pagination.mappedData,
    moreItemsToLoad: pagination.hasMore,
    loading: pagination.isLoading,
    loadNext: pagination.loadNext,
    error: pagination.error,
    userTableProps,
    refetchData: pagination.refetchData,
  };
}

const mapUserToTableRowItem = (user: AppUser): UserTableRowItem => {
  return {
    id: user.id,
    firstName: user.metadata_?.first_name || "",
    lastName: user.metadata_?.last_name || "",
    endUserId: user.end_user_id,
    row_type: RowType.USER,
    banned: user.banned,
    city: user.city,
    country: user.country,
    signedUp: getTimeSinceLong(user.created),
    tickets_count: user.tickets_count,
  };
};
