import React from "react";

import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { hot } from "react-hot-loader";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import { AuthDependencyProvider } from "./App.dependencies";
import EnvironmentRibbon from "../common/components/EnvironmentRibbon/EnvironmentRibbon";
import Toast from "../common/components/Toast/Toast";
import localization from "../common/localization";
import { base, Theme, ThemeDark, typography } from "../common/styles";
import { AuthContextProvider } from "./context/Auth/Auth.context";
import Router from "./router/Router";

declare global {
  // eslint-disable-next-line
  const Intercom: any;

  // eslint-disable-next-line
  interface Window {
    // eslint-disable-next-line
    analytics: any;
    // eslint-disable-next-line
    gtag: any;
    // eslint-disable-next-line
    Shake: any;

    openInkeep: () => void;
    closeInkeep: () => void;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
    ${base}
    ${typography}
`;

function App() {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: localStoragePersister }}
    >
      <BrowserRouter>
        <ThemeProvider theme={ThemeDark}>
          <GlobalStyle />
          <EnvironmentRibbon />
          <Toast />
          <IntlProvider
            messages={localization["en"]}
            locale="en"
            defaultLocale="en"
          >
            <AuthDependencyProvider>
              <AuthContextProvider>
                <Router />
              </AuthContextProvider>
            </AuthDependencyProvider>
          </IntlProvider>
        </ThemeProvider>
      </BrowserRouter>
    </PersistQueryClientProvider>
  );
}

declare let module: Record<string, unknown>;

export default hot(module)(App);
