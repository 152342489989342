import { vars } from "@common/styles";
import styled from "styled-components";

export const TeamMemberName = styled.div<{ $isModal?: boolean }>`
  padding-right: 2.8rem;
  color: ${vars.colors.grey30};
`;
export const TeamMemberEmail = styled.div<{ $isModal?: boolean }>`
  padding-right: 3rem;
  color: ${vars.colors.grey60};
`;
