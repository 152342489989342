import React, { useEffect } from "react";

import { App } from "@common/models";
import { getRouteFromPath } from "@common/util/moduleRouteHelper";
import { setShakeSdkAppMetadata } from "@common/util/shakeSdkUtils";
import { useAppContext } from "@main/context/App/App.context";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { SubscriptionState, useSubscriptionContext } from "@main/context/Subscription/SubscriptionContext";
import { useWorkspaceAppParams } from "@main/pages/IntegrationActivation/helpers/useWorkspaceAppParams";
import { generatePath, Outlet, useLocation, useNavigate } from "react-router-dom";

import { PageNavigation } from "../components/PageNavigation/PageNavigation";

export function ModuleLayoutView() {
  const { apps, loading: appsLoading } = useAppContext();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useWorkspaceAppParams();
  const { state } = useSubscriptionContext();

  useEffect(() => {
    if (appsLoading || selectedApp || !selectedWorkspace || state !== SubscriptionState.good) return;
    const pickedApp = getSelectedApp(apps);

    const routePath = getRouteFromPath(location.pathname);
    pickedApp &&
      navigate(generatePath(routePath, { workspaceSlug: selectedWorkspace.slug, appKey: pickedApp.key }), {
        replace: true,
      });
  }, [appsLoading, selectedApp, selectedWorkspace, state, apps, params, location.pathname, navigate]);

  return (
    <PageNavigation>
      <Outlet />
    </PageNavigation>
  );
}

function getSelectedApp(availableApps: App[]) {
  let selectedApp: App | undefined;
  const activeApps = availableApps.filter((a) => a.active);
  if (!selectedApp) {
    const extensionApps = activeApps.filter((a) => a.is_extension);
    selectedApp = extensionApps.length > 0 ? extensionApps[0] : activeApps[0];
  }

  setShakeSdkAppMetadata(selectedApp);

  if (localStorage.getItem("localAppKey") && activeApps.find((a) => a.key === localStorage.getItem("localAppKey")))
    selectedApp = activeApps.find((a) => a.key === localStorage.getItem("localAppKey"));

  return selectedApp;
}
