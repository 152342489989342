import React from "react";

import * as Styled from "./WorkspaceCreateView.styles";
import { CenteredForm } from "../../../../common/components/CenteredForm/CenteredForm";
import { Spinner } from "../../../../common/components/Spinner/Spinner.styles";
import { useAppContext } from "../../../context/App/App.context";
import WorkspaceCreateElement from "../components/WorkspaceCreateElement/WorkspaceCreateElement";

export default function WorkspaceCreateView() {
  const { workspacesLoading } = useAppContext();

  if (workspacesLoading)
    return (
      <Styled.CenteredContainer>
        <Spinner />
      </Styled.CenteredContainer>
    );

  return (
    <CenteredForm>
      <WorkspaceCreateElement />
    </CenteredForm>
  );
}
