import Cookies from "js-cookie";

import { UserData, Workspace } from "../models";

export const setAnalyticsData = (workspaces?: Workspace[], user?: Partial<UserData>, currentWorkspace?: Workspace) => {
  let company = {} as Company;

  if (currentWorkspace || (workspaces && workspaces.length)) {
    company = {
      company_id: currentWorkspace ? currentWorkspace.id : workspaces ? workspaces[0].id : undefined,
      name: currentWorkspace ? currentWorkspace.name : workspaces ? workspaces[0].name : undefined,
    };
  }

  const userData: AnalyticsUser | undefined = user
    ? {
        user_id: user?.id,
        email: user?.email,
        name: user?.name,
        company: company,
      }
    : undefined;

  const analyticsData = getAnalyticsTrackingParams();

  const UserProperties = Object.assign({}, userData, analyticsData);

  setUserProperties(UserProperties);
};

const setUserProperties = (properties: UserProperties) => {
  try {
    if (window.analytics) {
      if (properties.user_id) {
        window.analytics.identify(properties.user_id, properties);
      } else {
        window.analytics.identify(properties);
      }
    }
  } catch (e) {
    if (!(e instanceof ReferenceError)) {
      throw e;
    }
  }
};

export const sendGtagEvent = (tagId: string) => {
  if (window.gtag) {
    window.gtag("event", "conversion", {
      send_to: tagId,
    });
  }
};

const getAnalyticsTrackingParams = () => {
  let googleAnalyticsId = "";
  const googleAnalyticsCookie = Cookies.get("_ga")?.split(".");
  if (googleAnalyticsCookie && googleAnalyticsCookie.length == 4)
    googleAnalyticsId = [googleAnalyticsCookie[2], googleAnalyticsCookie[3]].join(".");

  // map our custom cookies to intercom utm_params and our custom param
  const analyticsData: AnalyticsData = {
    shk_utm_source: Cookies.get("shk_utm_source"),
    shk_utm_medium: Cookies.get("shk_utm_medium"),
    shk_utm_campaign: Cookies.get("shk_utm_campaign"),
    shk_utm_content: Cookies.get("shk_utm_content"),
    shk_utm_term: Cookies.get("shk_utm_term"),
    shk_referral_url: Cookies.get("shk_referral_url"),
    shk_google_analytics_id: googleAnalyticsId,
  };

  // delete empty fields so that intercom dont overwrite with blank values
  if (!analyticsData.shk_utm_source) delete analyticsData.shk_utm_source;
  if (!analyticsData.shk_utm_medium) delete analyticsData.shk_utm_medium;
  if (!analyticsData.shk_utm_campaign) delete analyticsData.shk_utm_campaign;
  if (!analyticsData.shk_utm_content) delete analyticsData.shk_utm_content;
  if (!analyticsData.shk_utm_term) delete analyticsData.shk_utm_term;
  if (!analyticsData.shk_referral_url) delete analyticsData.shk_referral_url;
  if (!analyticsData.shk_google_analytics_id) delete analyticsData.shk_google_analytics_id;

  return analyticsData;
};

export const analyticsLogin = (user?: Partial<UserData>) => {
  if (!user || !window.analytics) return;
  window.analytics.track("User Login", { user_id: user.id, email: user.email });
};

export const analyticsChangedData = (user?: Partial<UserData>) => {
  if (!user || !window.analytics) return;
  window.analytics.track("User data changed", {
    user_id: user.id,
    email: user.email,
  });
};

export const analyticsTrack = (text: string) => {
  if (!window.analytics) return;
  window.analytics.track(text, {});
};

type AnalyticsData = {
  shk_utm_source?: string;
  shk_utm_medium?: string;
  shk_utm_campaign?: string;
  shk_utm_content?: string;
  shk_utm_term?: string;
  shk_referral_url?: string;
  shk_google_analytics_id?: string;
};

type AnalyticsUser = {
  user_id?: string;
  email?: string;
  name?: string;
  company?: Company;
};

type Company = {
  company_id?: string;
  name?: string;
};

type UserProperties = AnalyticsData & AnalyticsUser;
