import React from "react";

import Heading from "@common/components/Heading/Heading";
import LargeButton from "@common/components/LargeButton/LargeButton";
import { LargeButtonGroup } from "@common/components/LargeButton/LargeButtonGroup.styles";
import PageWrap from "@common/components/PageWrap/PageWrap";
import Paragraph from "@common/components/Paragraph/Paragraph";
import { Platform } from "@common/models";
import { getPlatformImage, getPlatformName } from "@common/util/contentResolvers";

import * as Styled from "./AddAppSelectPlatform.styles";

interface Props {
  platforms?: Platform[];
  onPlatformSelected: (platform: Platform) => void;
}

export function AddAppSelectPlatform({ platforms, onPlatformSelected }: Props) {
  return (
    <PageWrap style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <Styled.Container>
        <Heading
          className={"center"}
          heading1
          marginBottom={24}
        >
          {"Choose your platform"}
        </Heading>
        <Paragraph
          marginBottom={28}
          fontWeight={500}
        >
          {"So we can show you the exact installation instructions."}
        </Paragraph>
        <LargeButtonGroup>
          {platforms?.map((platform) => {
            return (
              <LargeButton
                small={true}
                key={platform.id}
                image={getPlatformImage(platform)}
                title={getPlatformName(platform)}
                onClick={() => {
                  onPlatformSelected(platform);
                }}
              />
            );
          })}
        </LargeButtonGroup>
      </Styled.Container>
    </PageWrap>
  );
}
