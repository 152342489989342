import { useState } from "react";

import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { useForgotPasswordConsumer } from "./useForgotPasswordConsumer";
import { useForm } from "../../../../common/hooks/useForm";
import { validateEmail } from "../../../../common/util/ValidatorFunctions";

export interface EmailPrefillState {
  prefillEmail: string;
}

interface ForgotPasswordFormState {
  email: string;
}

interface Params {
  setResetLinkState?: (state: { email: string; isLinkSent: boolean }) => void;
}

const validators = {
  email: validateEmail,
};

export const useForgotPasswordFormConsumer = (params?: Params) => {
  const intl = useIntl();

  const { resendResetLink } = useForgotPasswordConsumer();

  const { state } = useLocation();
  const { prefillEmail = "" }: EmailPrefillState = (state as EmailPrefillState) || {};

  const [loading, setLoading] = useState(false);

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<ForgotPasswordFormState>({
    initialState: { email: prefillEmail },
    onSubmit: handleSendEmailResetLink,
    validators,
  });

  async function handleSendEmailResetLink() {
    try {
      setLoading(true);

      await resendResetLink(formState.email, false);
      params?.setResetLinkState?.({ email: formState.email, isLinkSent: true });

      document.title = intl.formatMessage({ id: "email.check.title" });
    } catch (error) {
      setError({ email: new Error("There was an error while trying to reset your password") });
    } finally {
      setLoading(false);
    }
  }

  return { formState, handleFormChange, handleFormSubmit, formError, loading };
};
