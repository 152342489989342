import React from "react";

import { generatePath, Link } from "react-router-dom";

import * as Styled from "./SmartSuggestion.styles";
import { ReactComponent as CloseIcon } from "../../../../../common/assets/images/close-icon.svg";
import SparkleEmoji from "../../../../../common/assets/images/ticket-icons/sparkle-icon.png";
import Paragraph from "../../../../../common/components/Paragraph/Paragraph";
import Tooltip from "../../../../../common/components/Tooltip/Tooltip";
import { Issue } from "../../../../../common/models/issueTracking";
import { vars } from "../../../../../common/styles";
import { Flex } from "../../../../../common/styles/reusable/Flex/Flex.styles";
import { useAppSelectionContext } from "../../../../context/App/AppSelectionContext";
import { RoutePaths } from "../../../../router/config/routePaths";
import * as Icon from "../../../shared/icons/icons";

interface Props {
  onMerge?: () => void;
  onRemove?: (issueId: string) => void;
  suggestion: Issue;
}

export default function SmartSuggestion({ onMerge, onRemove, suggestion }: Props) {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  return (
    <Flex
      $alignItems="center"
      style={{ marginTop: "3rem", marginBottom: "1.2rem" }}
    >
      <Tooltip
        text="Shake AI"
        position="top"
        style={{ width: "unset", paddingRight: "0.4rem" }}
      >
        <img
          width={18}
          height={18}
          src={SparkleEmoji}
        />
      </Tooltip>
      <Styled.TruncatedParagraph
        color={vars.colors.white}
        fontSize={14}
      >
        {`Hmmm... Mark this ticket as a duplicate of `}
        <Link
          to={generatePath(RoutePaths.USER_FEEDBACK_DETAILS, {
            workspaceSlug: selectedWorkspace?.slug ?? null,
            appKey: selectedApp?.key ?? null,
            userFeedbackKey: suggestion.key.toLocaleString(),
          })}
        >
          {suggestion.pretty_title}?
        </Link>
      </Styled.TruncatedParagraph>

      <Styled.Buttons>
        <Icon.CheckmarkIcon onClick={onMerge} />
        <CloseIcon onClick={onRemove} />
      </Styled.Buttons>
    </Flex>
  );
}
