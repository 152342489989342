import React from "react";

import { useNavigate } from "react-router-dom";

import * as Styled from "./EmptyStateElement.styles";
import PageWrap from "../../PageWrap/PageWrap";
import Paragraph from "../../Paragraph/Paragraph";

interface EmptyStateElementProps {
  title: string;
  subtitle: string;
  imageSrc: string;
  button?: {
    url: string;
    title: string;
  };
}
export default function EmptyStateElement({ title, subtitle, imageSrc, button }: EmptyStateElementProps) {
  const navigate = useNavigate();

  return (
    <PageWrap>
      <Styled.Container>
        <Styled.LeftOuterContainer>
          <Styled.LeftContentContainer>
            <Styled.Image src={imageSrc} />
          </Styled.LeftContentContainer>
        </Styled.LeftOuterContainer>

        <Styled.CopyContainer>
          <Styled.Heading
            heading3
            fontWeight="bold"
          >
            {title}
          </Styled.Heading>
          <Paragraph fontWeight={500}>{subtitle}</Paragraph>
          {button && (
            <div>
              <Styled.Button
                onClick={() =>
                  button?.url.startsWith("/") ? navigate(button.url) : (window.location.href = button?.url)
                }
              >
                {button.title}
              </Styled.Button>
            </div>
          )}
        </Styled.CopyContainer>
      </Styled.Container>
    </PageWrap>
  );
}
