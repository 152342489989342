import React, { useCallback, useEffect } from "react";
import { ReactNode } from "react";

import { useAppSelectionContext } from "../../main/context/App/AppSelectionContext";
import { setUserTokenAction } from "../../main/context/Auth/Auth.actions";
import { useAuthDispatch } from "../../main/context/Auth/Auth.context";
import { useUpdateSelectedWorkspace } from "../../main/layouts/views/WorkspaceRootLayout";
import { UserToken } from "../models";
import ApiService from "../services/Api.service";

interface Props {
  children: ReactNode;
}

const apiService = ApiService.getInstance();

const apiProviderContext = React.createContext(apiService);

export const APIProvider = ({ children }: Props) => {
  const authDispatch = useAuthDispatch();
  const { selectedWorkspace } = useAppSelectionContext();
  const { updateSelectedWorkspace } = useUpdateSelectedWorkspace();

  const renewTokenHandler = useCallback(
    (userToken: UserToken) => {
      authDispatch(setUserTokenAction(userToken));
    },
    [authDispatch],
  );

  const handle402PaymentRequired = useCallback(async () => {
    try {
      if (!selectedWorkspace) return;
      await updateSelectedWorkspace(selectedWorkspace);
    } catch (error) {}
  }, [updateSelectedWorkspace, selectedWorkspace]);

  useEffect(() => {
    apiService.injectRenewTokenHandler(renewTokenHandler);
  }, [renewTokenHandler]);

  useEffect(() => {
    apiService.inject402ErrorHandler(handle402PaymentRequired);
  }, [handle402PaymentRequired]);

  return <apiProviderContext.Provider value={apiService}>{children}</apiProviderContext.Provider>;
};
