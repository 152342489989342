import { rgba } from "polished";
import styled, { css } from "styled-components";

import { vars } from "../../styles";

export const TooltipContainer = styled.div<{
  $disabled?: boolean;
}>`
  width: 100%;
  position: relative;
  display: inline-block;
  border: 0px solid transparent;

  ${(props) =>
    !props.$disabled &&
    css`
      &:hover {
        > span:first-child {
          visibility: visible;
        }
      }
    `};
`;

export const TooltipContent = styled.span<{
  $position: "top" | "right" | "bottom" | "left" | "top-right" | "top-left";
  $offset: number;
  $prewrap?: boolean;
}>`
  visibility: hidden;
  z-index: ${vars.zIndex.tooltip};
  pointer-events: none;
  position: absolute;
  color: ${vars.colors.grey30} !important;

  /* $position: top */
  ${(props) =>
    props.$position === "top" &&
    css`
      top: -${props.$offset}rem;
      left: 50%;
      transform: translate(-50%, -100%);
      white-space: pre;
    `};

  ${(props) =>
    props.$position === "top-left" &&
    css`
      top: -${props.$offset}rem;
      right: 0;
      transform: translate(0%, -100%);
      white-space: pre;
    `};

  ${(props) =>
    props.$position === "top-right" &&
    css`
      top: -${props.$offset}rem;
      left: 0;
      transform: translate(-25%, -100%);
      white-space: pre;
    `};

  /* $position: right */
  ${(props) =>
    props.$position === "right" &&
    css`
      top: 50%;
      right: -${props.$offset}rem;
      transform: translate(100%, -50%);
    `};

  /* $position: bottom */
  ${(props) =>
    props.$position === "bottom" &&
    css`
      bottom: -${props.$offset}rem;
      left: 50%;
      transform: translate(-50%, 100%);
    `};

  /* $position: left */
  ${(props) =>
    props.$position === "left" &&
    css`
      top: 50%;
      left: -${props.$offset}rem;
      transform: translate(-100%, -50%);
    `};

  padding: 0.5rem 1.5rem;
  border: 1px solid ${vars.colors.grey90};
  box-shadow: 0rem 0.2rem 1rem ${vars.colors.black};
  border-radius: 0.5rem;
  background-color: ${rgba(vars.colors.grey100, 0.8)};
  color: ${vars.colors.grey30};
  text-align: center;
  font-size: 1.4rem;
  white-space: nowrap;

  ${(props) =>
    props.$prewrap &&
    css`
      text-align: start;
      white-space: pre;
    `};
`;
