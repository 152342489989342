import React from "react";

import RedirectWithLoading from "./RedirectWithLoading";
import { useAppSelectionContext } from "../../context/App/AppSelectionContext";
import { RoutePaths } from "../config/routePaths";

export const CrashesRedirect = () => {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const dynamicValues = {
    workspaceSlug: selectedWorkspace?.slug,
    appKey: selectedApp?.key,
  };

  return (
    <RedirectWithLoading
      path={RoutePaths.CRASH_REPORTS}
      dynamicValues={dynamicValues}
    />
  );
};
