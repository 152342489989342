import { useCallback, useEffect, useRef, useState } from "react";

import { analyticsLogin, setAnalyticsData } from "@common/util/analyticsData";
import { rememberUrlSrcParam } from "@main/pages/shared/components/ChromeExtensionLoginDialog/ChromeExtensionLoginDialog";
import useAbortController from "common/hooks/useAbortController";
import { useForm } from "common/hooks/useForm";
import { UserToken } from "common/models";
import { validateEmail } from "common/util/ValidatorFunctions";
import { useAuthDeps } from "main/App.dependencies";
import { setUserTokenAction } from "main/context/Auth/Auth.actions";
import { useAuthDispatch } from "main/context/Auth/Auth.context";
import { USER_TOKEN_LOCAL_STORAGE_KEY } from "main/context/Auth/Auth.types";
import { ReturnUrlState } from "main/layouts/components/AuthProtected";
import { RoutePaths } from "main/router/config/routePaths";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

import { useSignInDeps } from "../SignIn";
import { parseGoogleCallback } from "../utils/parseGoogleCallback";
import { useGoogleSignIn } from "../utils/useGoogleSignIn";
import { validatePasswordSignInForm } from "../utils/validatorFunctions";

export interface SignInFormState {
  email: string;
  password: string;
}

interface EmailPrefillState {
  prefillEmail: string;
}

const validators = {
  email: validateEmail,
  password: validatePasswordSignInForm,
};

enum ErrorStatusCodes {
  WRONG_PASSWORD = 401,
  WRONG_EMAIL = 404,
}

export function useSignInFormConsumer() {
  const { signInService } = useSignInDeps();
  const { authService } = useAuthDeps();

  const { abortSignal } = useAbortController();

  const authDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [googleLoginLoading, setGoogleLoginLoading] = useState(false);

  const currentURL = new URL(window.location.href);
  const { redirectToGoogle } = useGoogleSignIn({ currentURL });
  const { code, error, restoreOriginalURL, redirectURL } = parseGoogleCallback({ currentURL });

  const { state } = useLocation();
  const { returnUrl = generatePath(RoutePaths.ROOT) } = (state as ReturnUrlState) || {};
  const { prefillEmail = "" }: EmailPrefillState = (state as EmailPrefillState) || {};

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<SignInFormState>({
    initialState: { email: prefillEmail, password: "" },
    onSubmit: handleEmailLogin,
    validators,
  });

  const [isErrorOnSubmit, setIsErrorOnSubmit] = useState<boolean>(false);

  async function handleEmailLogin() {
    try {
      setLoading(true);
      setIsErrorOnSubmit(false);

      const { data: userToken } = await authService.signIn(formState.email, formState.password);
      await handleUserToken(userToken);

      const userData = { email: formState.email };
      setAnalyticsData(undefined, userData);
      analyticsLogin(userData);

      rememberUrlSrcParam();
      navigate(returnUrl, { replace: true });
    } catch (error) {
      if (error.response.status === ErrorStatusCodes.WRONG_PASSWORD) {
        setError({ password: new Error("Hmm, that's not the right password.") });
        setIsErrorOnSubmit(true);
      }

      if (error.response.status === ErrorStatusCodes.WRONG_EMAIL) {
        setError({ email: new Error("The email you've entered doesn't match any account.") });
        setIsErrorOnSubmit(true);
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleGoogleLogin() {
    rememberUrlSrcParam();
    redirectToGoogle({});
  }

  async function handleDemoLogin() {
    try {
      setLoading(true);
      setIsErrorOnSubmit(false);

      const { data: userToken } = await authService.demoSignIn();
      await handleUserToken(userToken);

      const userData = { email: formState.email };
      setAnalyticsData(undefined, userData);
      analyticsLogin(userData);

      navigate(returnUrl, { replace: true });
    } catch (error) {
      if (error.response.status === ErrorStatusCodes.WRONG_PASSWORD) {
        setError({ password: new Error("Hmm, that's not the right password.") });
        setIsErrorOnSubmit(true);
      }

      if (error.response.status === ErrorStatusCodes.WRONG_EMAIL) {
        setError({ email: new Error("The email you've entered doesn't match any account.") });
        setIsErrorOnSubmit(true);
      }
    } finally {
      setLoading(false);
    }
  }

  const handleUserToken = useCallback(
    async (userToken: UserToken) => {
      localStorage.setItem(USER_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(userToken));
      authDispatch(setUserTokenAction(userToken));
    },
    [authDispatch],
  );

  const signInWithGoogleRef = useRef((googleCode: string, redirectUrl: URL) => {
    setGoogleLoginLoading(true);
    signInService
      .signInWithGoogleCode(googleCode, redirectUrl.toString(), undefined, abortSignal)
      .then(({ data: userToken }) => handleUserToken(userToken))
      .then(() => {
        navigate(returnUrl, { replace: true });
      })
      .catch(() => {
        setGoogleLoginLoading(false);
        restoreOriginalURL?.();
      })
      .finally(() => {
        setGoogleLoginLoading(false);
      });
  });

  useEffect(() => {
    if (code && redirectURL) {
      !loading && signInWithGoogleRef.current(code, redirectURL);
    }
    return () => {
      setLoading(false);
    };
  }, [code, redirectURL, loading]);

  useEffect(() => {
    error && restoreOriginalURL?.();
  }, [error, restoreOriginalURL]);

  useEffect(() => {
    setIsErrorOnSubmit(false);
  }, [formState]);

  return {
    handleFormChange,
    handleFormSubmit,
    formState,
    formError,
    loading,
    isErrorOnSubmit,
    googleLoginLoading,
    handleDemoLogin,
    handleGoogleLogin,
  };
}
