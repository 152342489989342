import React from "react";

import { FloatingOverlay, FloatingPortal } from "@floating-ui/react";

import * as Styled from "./AssigneeCell.styles";
import { useAppContext } from "../../../../main/context/App/App.context";
import { useAuthContext } from "../../../../main/context/Auth/Auth.context";
import { getRandomColor } from "../../../../main/pages/shared/helpers/getRandomColorHelper";
import NoAssignee from "../../../assets/images/unassigned-icon.png";
import { PopoverInput } from "../../../hooks/filtering/ui/Attributes.styles";
import { usePopover } from "../../../hooks/filtering/ui/SimplePopover";
import { getUserInitials } from "../../../models";
import { vars } from "../../../styles";
import { Flex } from "../../../styles/reusable/Flex/Flex.styles";
import identifiers from "../../../util/identifiers.json";
import * as StyledDropdown from "../../Dropdown/Dropdown.styles";
import RoundedCanvas from "../../RoundedCanvas/RoundedCanvas";
import Tooltip from "../../Tooltip/Tooltip";

type AssigneeCellProps = {
  assignee: { name: string; id: string | null; picture: string | null };
  onChangeAssigneeRequest: (assignee_id: string | null) => Promise<void>;
};

export function AssigneeCell({ onChangeAssigneeRequest, assignee }: AssigneeCellProps) {
  return (
    <AssigneeSelector
      assignee={assignee}
      onSelectAssignee={onChangeAssigneeRequest}
    />
  );
}

function AssigneeSelector({
  assignee,
  onSelectAssignee,
}: Pick<AssigneeCellProps, "assignee"> & { onSelectAssignee: (assignee_id: string | null) => void }) {
  const assigneePopover = usePopover({});

  const { wsTeamMembers } = useAppContext();
  const { userData } = useAuthContext();

  const sortedTeamMembers = () => {
    return (
      wsTeamMembers
        ?.filter((member) => member.user.id !== userData?.id)
        .sort((a, b) => a.user.name.localeCompare(b.user.name)) ?? []
    );
  };

  function onSelectAssigneeClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, assignee_id: string | null) {
    e.stopPropagation();
    e.preventDefault();
    assigneePopover.setOpen(false);
    onSelectAssignee(assignee_id);
  }

  return (
    <>
      <button
        ref={assigneePopover.reference}
        {...assigneePopover.getReferenceProps({
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        })}
        style={{
          width: "30px",
          minWidth: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          alignSelf: "stretch",
          border: "none",
          position: "relative",
          marginRight: "0.4rem",
        }}
      >
        <Tooltip
          style={{
            position: "absolute",
            height: "100%",
            zIndex: 5,
            width: "100%",
          }}
          text="Set assignee"
          position="top"
          offset={-3}
        />

        <RoundedCanvas
          letter={getUserInitials(assignee.name) ?? ""}
          size="xsmall"
          txtColor={
            assignee && assignee.id !== null && getRandomColor(assignee.id)
              ? getRandomColor(assignee.id)?.text
              : undefined
          }
          bgColor={
            assignee.id && assignee.id !== null && getRandomColor(assignee.id)
              ? getRandomColor(assignee.id)?.background
              : vars.colors.black
          }
          image={assignee && assignee.id ? (assignee.picture ? assignee.picture : undefined) : NoAssignee}
        />
      </button>

      {assigneePopover.open && (
        <FloatingPortal>
          <FloatingOverlay
            lockScroll
            style={{
              zIndex: vars.zIndex.modalOverlay,
            }}
          >
            <div
              ref={assigneePopover.floating}
              {...assigneePopover.getFloatingProps()}
              style={{
                position: assigneePopover.strategy,
                top: assigneePopover.y ?? 0,
                left: assigneePopover.x ?? 0,
                minHeight: "10rem",
                backgroundColor: vars.colors.grey100,
              }}
            >
              <PopoverInput $widthPx={250}>
                <p style={{ margin: "0 2rem", fontSize: "1.4rem", color: vars.colors.grey60, marginBottom: "1rem" }}>
                  Set assignee
                </p>
                <StyledDropdown.DropdownScroll cx={Styled.dropdownScroll}>
                  <Styled.DropdownItem
                    $clicked={assignee?.id === userData?.id}
                    onClick={(e) =>
                      onSelectAssigneeClick(
                        e,
                        wsTeamMembers?.find((member) => member.user.id === userData?.id)?.id ?? null,
                      )
                    }
                    testId={identifiers["central.column.assignee.dropdown.you"]}
                  >
                    <Flex>
                      <Styled.Checkmark>
                        {assignee.id === userData?.id && (
                          <Styled.CheckmarkIcon
                            width={16}
                            height={16}
                          />
                        )}
                      </Styled.Checkmark>
                      <p>You</p>
                    </Flex>
                  </Styled.DropdownItem>
                  <Styled.DropdownItem
                    onClick={(e) => onSelectAssigneeClick(e, null)}
                    testId={identifiers["central.column.assignee.dropdown.unassigned"]}
                  >
                    <Flex>
                      <Styled.Checkmark>
                        {(!assignee || !assignee.id) && (
                          <Styled.CheckmarkIcon
                            width={16}
                            height={16}
                          />
                        )}
                      </Styled.Checkmark>
                      <p>Unassigned</p>
                    </Flex>
                  </Styled.DropdownItem>
                  {sortedTeamMembers().map((member) => {
                    return (
                      <Styled.DropdownItem
                        $clicked={assignee?.id === member.id}
                        key={member.id}
                        onClick={(e) => onSelectAssigneeClick(e, member.id)}
                        testId={identifiers["central.column.assignee.dropdown.assigneeListing"]}
                      >
                        <Flex>
                          <Styled.Checkmark>
                            {assignee?.id === member.user.id && (
                              <Styled.CheckmarkIcon
                                width={16}
                                height={16}
                              />
                            )}
                          </Styled.Checkmark>
                          <p style={{ maxWidth: "18rem" }}>{member.user.name}</p>
                        </Flex>
                      </Styled.DropdownItem>
                    );
                  })}
                </StyledDropdown.DropdownScroll>
              </PopoverInput>
            </div>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
}
