import React from "react";

import ChromeImage from "@common/assets/images/chrome-extension-button.png";
import { SHAKE_EXTENSION_PAGE } from "@common/util/urls";
import { useLocation } from "react-router-dom";

import * as Styled from "./ChromeExtensionButton.styles";

export const ChromeExtensionButton = () => {
  const location = useLocation();

  const openChromeExtensionPage = () => {
    window.open(SHAKE_EXTENSION_PAGE, "_blank");
  };

  if (location.pathname.includes("activity-history")) {
    return null;
  }

  return (
    <Styled.ChromeExtensionButton onClick={openChromeExtensionPage}>
      <Styled.ChromeExtensionButtonIcon src={ChromeImage} />
      <Styled.ChromeExtensionButtonText>{"Install Shake Chrome extension"}</Styled.ChromeExtensionButtonText>
    </Styled.ChromeExtensionButton>
  );
};
