import { useCallback } from "react";

import { useAppDeps } from "../App.dependencies";
import { setWSTeamMembersDataAction } from "../context/App/App.actions";
import { useAppDispatch } from "../context/App/App.context";

export default function useTeamMembersApiConsumer() {
  const dispatch = useAppDispatch();
  const { appsService } = useAppDeps();

  const fetchCurrentTeamMembersAndDispatch = useCallback(
    async (workspaceId: string, abortSignal?: AbortSignal) => {
      dispatch(setWSTeamMembersDataAction(undefined));
      const { data } = await appsService.getMembers(workspaceId, abortSignal);
      if (abortSignal && abortSignal.aborted) return;

      dispatch(setWSTeamMembersDataAction(data));
      return data;
    },
    [dispatch, appsService],
  );

  return { fetchCurrentTeamMembersAndDispatch };
}
