import React, { Fragment, useState } from "react";

import { HelperText } from "@common/components/HelperText/HelperText";
import { helpCenterArticles, showHelpCenterArticle } from "@common/util/helpCenterArticles";

import { ApiKeyRow } from "./ApiKeyRow";
import { AdministrationCard } from "../../../../../common/components/AdministrationCard/AdministrationCard";
import Button from "../../../../../common/components/Button/Button";
import { GridTable } from "../../../../../common/components/GridTable/GridTable";
import { useModal } from "../../../../../common/components/Modal/Modal";
import { App, Workspace } from "../../../../../common/models";
import { ApiKey } from "../../../../../common/models/ApiKey.model";
import { isAdmin } from "../../../../../common/models/Member.model";
import { t } from "../../../../../common/util/translator";
import { useShakingAnimation } from "../../../../context/ShakingAnimation/ShakingAnimationContext";
import DeleteApiKeyModalBody from "../ModalBody/DeleteApiKeyModalBody";

interface ApiKeysCardProps {
  app: App;
  loading: boolean;
  addApiKey: () => Promise<void>;
  deleteApiKey: (id: string) => Promise<void>;
  selectedWorkspace?: Workspace;
}

export default function ApiKeysCard({
  loading,
  app,
  addApiKey,
  deleteApiKey,
  selectedWorkspace,
}: ApiKeysCardProps): JSX.Element {
  const { api_keys } = app;
  const { triggerShake } = useShakingAnimation();
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey>();
  const { Modal: DeleteApiKeyModal, toggle, modalProps } = useModal({ size: "narrow" });

  const handleApiKeyDelete = async () => {
    if (app.is_sample) {
      triggerShake("AddAppButton");
      return;
    }
    if (!selectedApiKey) return;
    await deleteApiKey(selectedApiKey.id);
    toggle();
  };

  const handleApiKeyCreate = async () => {
    if (app.is_sample) {
      triggerShake("AddAppButton");
      return;
    }
    await addApiKey();
  };

  const tooltipDisabled = app.active && isAdmin(selectedWorkspace?.role) && !app.is_sample;
  const tooltipText = app.is_sample
    ? "Sample app can't be modified"
    : !isAdmin(selectedWorkspace?.role)
    ? "Only admins can add API keys"
    : "API keys can be added on active apps only";

  return (
    <Fragment>
      <AdministrationCard
        title={t("administration.general.card.apiKeys.heading")}
        subtitle={t("administration.general.card.apiKeys.subtitle")}
        topLeftAccessory={
          <Button
            size="small"
            onClick={handleApiKeyCreate}
            disabled={loading || !app.active || !isAdmin(selectedWorkspace?.role)}
            loadingText={loading && "Adding..."}
            tooltip={{
              text: tooltipText,
              disabled: tooltipDisabled,
              position: "top",
            }}
          >
            Add new
          </Button>
        }
      >
        {api_keys && (
          <GridTable gridTemplateColumns="repeat(3, auto)">
            {api_keys.map((api_key) => {
              return (
                <ApiKeyRow
                  key={api_key.id}
                  setSelectedApiKey={setSelectedApiKey}
                  toggleDelete={toggle}
                  apiKey={api_key}
                  isAdmin={isAdmin(selectedWorkspace?.role)}
                />
              );
            })}
          </GridTable>
        )}
        <HelperText>
          {"Why API Keys? "}
          <a onClick={() => showHelpCenterArticle(helpCenterArticles.deprecatedClientKeys)}>{"Learn more here"}</a>
          {"."}
        </HelperText>
      </AdministrationCard>

      <DeleteApiKeyModalBody
        deleteApiKey={handleApiKeyDelete}
        loading={loading}
        Modal={DeleteApiKeyModal}
        internalProps={modalProps}
      />
    </Fragment>
  );
}
