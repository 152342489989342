import React from "react";

import * as Styled from "./NotificationsView.styles";
import Heading from "../../../../../common/components/Heading/Heading";
import PageTitle from "../../../../../common/components/PageTitle/PageTitle";
import PageWrap from "../../../../../common/components/PageWrap/PageWrap";
import identifiers from "../../../../../common/util/identifiers.json";
import NotifyMajorReleases from "../../components/NotifyMajorReleases/NotifyMajorReleases";
import NotifyMeAboutCard from "../../components/NotifyMeAboutCard/NotifyMeAboutCard";

export default function NotificationsView() {
  return (
    <>
      <PageTitle>
        <Heading
          as="h1"
          heading1
          testId={identifiers["myAccount.header.notifications"]}
        >
          Notifications
        </Heading>
        <p>Select where and when you&apos;ll be notified.</p>
      </PageTitle>
      <PageWrap>
        <Styled.NotificationsCardsContainer>
          <NotifyMeAboutCard />
          <NotifyMajorReleases />
        </Styled.NotificationsCardsContainer>
      </PageWrap>
    </>
  );
}
