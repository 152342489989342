import React from "react";

import Heading from "@common/components/Heading/Heading";
import Input from "@common/components/Input/Input";
import PageWrap from "@common/components/PageWrap/PageWrap";
import { Platform, PlatformName } from "@common/models";
import { useAddAppConsumer } from "@main/pages/AddApp/consumers/useAddAppConsumer";
import { InstallationType } from "@main/pages/AddApp/views/IntroPage/AddAppIntroPage";

import * as Styled from "./AddAppCreateApp.styles";
import AddAppLimitBanner from "./LimitBanner/AddAppLimitBanner";

interface Props {
  platform?: Platform;
  installationType?: InstallationType;
}

export function AddAppCreateApp({ platform, installationType }: Props) {
  const { formState, formError, handleFormChange, handleFormSubmit, loading } = useAddAppConsumer({
    platformId: platform?.id ?? "",
    installationType: installationType ?? InstallationType.MANUAL,
  });

  const { bundleId, appName } = formState;
  const { bundleId: bundleIdError } = formError;

  function handleChange(e: React.KeyboardEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isWeb = platform?.name === PlatformName.WEB;
  const isButtonDisabled = (!isWeb && !bundleId) || (isWeb && !appName);

  return (
    <PageWrap style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <Styled.Container>
        <Heading
          className={"center"}
          heading1
          marginBottom={24}
        >
          {isWeb ? "And lastly, name this app" : "Lastly, your bundle ID"}
        </Heading>
        <Styled.Subtitle
          marginBottom={28}
          fontWeight={500}
        >
          {isWeb
            ? "Name only visible to members of this workspace, and can be changed later."
            : "Shake servers will start listening for your app to ping them."}
        </Styled.Subtitle>
        {!isWeb ? <AddAppLimitBanner /> : null}
        <Styled.InputWrapper>
          <Input
            id="appName"
            name="appName"
            type="text"
            label={"App name"}
            value={appName ?? ""}
            placeholder="e.g. Uber, Pinterest, Yourapp"
            onChange={handleChange}
            helper={
              isWeb
                ? "Usually the name of the project you work on"
                : "Optional, but an app with a name looks nicer here on Dashboard"
            }
          />
        </Styled.InputWrapper>
        {!isWeb && (
          <Styled.InputWrapper>
            <Input
              id="bundleId"
              name="bundleId"
              type="text"
              label={isWeb ? "Domain" : "Bundle ID"}
              value={bundleId ? bundleId : ""}
              placeholder={isWeb ? "e.g. docs.yoursite.com" : "e.g. com.yourdomain.appname"}
              onChange={handleChange}
              error={!!bundleIdError}
              helper={
                bundleIdError
                  ? bundleIdError.message
                  : isWeb
                  ? "Add new website for every domain prefix: app., www., docs."
                  : undefined
              }
            />
          </Styled.InputWrapper>
        )}
        <Styled.CreateButton
          loadingText={loading && "Adding..."}
          disabled={isButtonDisabled}
          tooltip={{
            position: "top",
            text: `${isWeb ? "Please enter the website name" : "Please enter bundle ID first"}`,
            disabled: !isButtonDisabled,
          }}
          testId="add-app"
          onClick={handleFormSubmit}
        >
          {"Add an app"}
        </Styled.CreateButton>
      </Styled.Container>
    </PageWrap>
  );
}
