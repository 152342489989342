import styled from "styled-components";

import { Flex } from "../../../common/components/Accordion/Accordion.styles";

export const Container = styled(Flex)`
  height: 100%;
`;

export const InnerContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
`;
