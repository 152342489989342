import { App } from "../../../../common/models";
import { IntegrationType } from "../../../../common/models/integrations";

export interface PostIntegrationSearchParams {
  appId?: string;
  type?: IntegrationType;
  appKey?: string;
}

export function determineInitialSelectedAppId(
  globalSelectedApp: App | undefined,
  allApps: App[],
  locationSearch: string,
  appKey?: string,
) {
  const postIntegrationState = Object.fromEntries(
    new URLSearchParams(locationSearch),
  ) as Partial<PostIntegrationSearchParams>;

  if (postIntegrationState && postIntegrationState.appKey) {
    return allApps.find((a) => a.key === postIntegrationState.appKey)?.id;
  }

  if (postIntegrationState && postIntegrationState.appId) {
    return postIntegrationState.appId;
  }

  if (globalSelectedApp) return globalSelectedApp.id;

  const activeApps = allApps.filter((app) => app.active);

  if (appKey) return allApps.find((a) => a.key === appKey)?.id;
  if (activeApps.length) return activeApps[0]?.id;

  if (allApps.length) return allApps[0]?.id;
}
