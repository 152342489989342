import { useCallback, useEffect } from "react";

import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import displayToast from "../../../../common/components/Toast/displayToast";
import { setAnalyticsData } from "../../../../common/util/analyticsData";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useAuthContext } from "../../../context/Auth/Auth.context";
import { RoutePaths } from "../../../router/config/routePaths";
import { useChangeEmailConfirmationDeps } from "../ChangeEmailConfirmation";

const TOKEN_QUERY_PARAM_KEY = "code";

export default function useChangeEmailConfirmationApiConsumer() {
  const { selectedWorkspace } = useAppSelectionContext();
  const { userData } = useAuthContext();
  const { changeEmailConfirmationService } = useChangeEmailConfirmationDeps();
  const [searchParams] = useSearchParams();
  const emailConfirmationToken = searchParams.get(TOKEN_QUERY_PARAM_KEY);
  const navigate = useNavigate();

  const confirmNewEmailAndRedirect = useCallback(async () => {
    try {
      if (!emailConfirmationToken) {
        renderErrorToast();
        return;
      }

      await changeEmailConfirmationService.changeEmail(emailConfirmationToken);

      setAnalyticsData(undefined, userData, selectedWorkspace);

      if (window.analytics) {
        window.analytics.track("Email changed", {
          email: userData?.email,
        });
      }

      displayToast({
        title: "Fantastic!",
        content: "Email successfully changed",
      });
    } catch (error) {
      renderErrorToast();
    } finally {
      navigate(generatePath(RoutePaths.ROOT), {
        replace: true,
      });
    }
  }, [emailConfirmationToken, changeEmailConfirmationService, navigate, selectedWorkspace, userData]);

  useEffect(() => {
    confirmNewEmailAndRedirect();
  }, [confirmNewEmailAndRedirect]);
}

const renderErrorToast = () => {
  displayToast({
    title: "Something went wrong",
    content: "Email change confirmation token invalid or expired, please try again.",
  });
};
