import { useQuery } from "@tanstack/react-query";

import useAbortController from "../../../../common/hooks/useAbortController";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useUserFeedbackDeps } from "../UserFeedback";

export default function useSearchFieldsApiConsumer() {
  const { userFeedbackService } = useUserFeedbackDeps();

  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const { abortSignal } = useAbortController();

  const selectedAppId = selectedApp?.id;
  const selectedWorkspaceId = selectedWorkspace?.id;

  const getSearchMetadataFields = async () => {
    if (!selectedWorkspaceId || !selectedAppId) return undefined;

    try {
      return await userFeedbackService
        .getUserFeedbackSearchMetadataCustomFields(selectedWorkspaceId, selectedAppId, abortSignal)
        .then(({ data }) => {
          return data;
        });
    } catch (error) {
      if (abortSignal.aborted) return undefined;

      return undefined;
    }
  };

  const { data: issuesMetadataFields } = useQuery(
    [`issuesMetadataFields`, { selectedAppId }],
    getSearchMetadataFields,
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );

  return { customFields: issuesMetadataFields?.custom_fields, metadataFields: issuesMetadataFields?.metadata };
}
