import React, { useCallback, useState } from "react";

import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";

import { useCrashGroupAddedSocketConsumer } from "./useCrashGroupAddedSocketConsumer";
import { CrashReportTableRowItem, RowType } from "../../../../common/components/MasterTable/models/MasterTableModel";
import { useCrashesTable } from "../../../../common/components/ShakeTable/modules/useCrashesTable";
import { TicketSortOption, UserSortOption } from "../../../../common/components/ShakeTable/ui/SortHeaderCell";
import displayToast, { ToastContent, updateToast } from "../../../../common/components/Toast/displayToast";
import { Attribute, UseModuleAttributes } from "../../../../common/hooks/filtering/sharedTypes";
import { useAppBoundStorage } from "../../../../common/hooks/filtering/useAppBoundStorage";
import { prepareFiltersForRequest } from "../../../../common/hooks/filtering/utils";
import { Page, useShakePagination } from "../../../../common/hooks/useShakePaginaton";
import { CrashReport } from "../../../../common/models/crashReporting";
import { Member } from "../../../../common/models/Member.model";
import { TicketPriority } from "../../../../common/models/TicketPriority.model";
import { TicketStatus } from "../../../../common/models/TicketStatus.model";
import { useAppContext } from "../../../context/App/App.context";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useCrashesDeps } from "../Crashes";

// Used to store in memory ticket status to avoid re-fetching large data.
// Sockets with individual ticket updates not yet available
type MemoryStatus = Record<string, string>;
type MemoryPriority = Record<string, string>;
type MemoryAssignee = Record<string, string>;

const autoClose = 2000;

export default function useCrashReportsApiConsumer<TData>({
  attributesProps,
}: {
  attributesProps: UseModuleAttributes;
}) {
  const { crashesService } = useCrashesDeps();
  const queryClient = useQueryClient();
  const { socket } = useAppContext();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const { wsTeamMembers } = useAppContext();

  const { state: sortOption, setState: setSortOption } = useAppBoundStorage<TicketSortOption | UserSortOption>(
    "shakebugs.crashes_sort",
    "key",
  );

  const [memoryStatus, setMemoryStatus] = useState<MemoryStatus>({});
  const [memoryPriority, setMemoryPriority] = useState<MemoryPriority>({});
  const [memoryAssignee, setMemoryAssignee] = useState<MemoryAssignee>({});

  /// Pagination logic
  const { refetchData, ...pagination } = useShakePagination<CrashReport, CrashReportTableRowItem>({
    deps: [selectedWorkspace?.id, selectedApp?.id, attributesProps.validAttributes, sortOption.value],
    fetchFn: ({ deps, signal, pagination }) => {
      const workspaceID = deps[0] as string;
      const appID = deps[1] as string;
      const validAttributes = deps[2] as Array<Required<Attribute>>;
      const sort = deps[3] as TicketSortOption;

      if (Boolean(validAttributes.length)) {
        const filters = prepareFiltersForRequest(validAttributes);
        return crashesService
          .getCrashReportsByFilters(workspaceID, appID, pagination.offset, pagination.limit, filters, sort, signal)
          .then(({ data }) => {
            return { total: data.total, items: data.items };
          });
      } else {
        return crashesService
          .getCrashReports(workspaceID, appID, pagination.limit, pagination.offset, sort, signal)
          .then(({ data }) => {
            return { total: data.total, items: data.items };
          });
      }
    },
    enabled: (deps) => deps.every((dep) => Boolean(dep)),
    select: {
      mapperDeps: [memoryStatus, memoryPriority, memoryAssignee],
      mapper: (items, _, mapperDeps) => {
        const memoryStatus = mapperDeps[0] as MemoryStatus;
        const memoryPriority = mapperDeps[1] as MemoryPriority;
        const memoryAssignee = mapperDeps[2] as MemoryAssignee;

        const mappedItems = [] as CrashReportTableRowItem[];
        items.map((crashReport) => {
          if (crashReport) {
            mappedItems.push(
              mapCrashReportToTableRowItem(crashReport, memoryStatus, memoryPriority, memoryAssignee, wsTeamMembers),
            );
          }
        });
        return mappedItems;
      },
    },
    queryKey: `crashReports ${selectedApp?.id}`,
  });

  useCrashGroupAddedSocketConsumer({ socket, refetchData });

  const onDeleteRequest = async (items: Array<CrashReportTableRowItem>) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;
    return crashesService
      .bulkDeleteIssues(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
      )
      .then(() => {
        displayToast({ content: getDeletedToastMessage(items.length), duration: 3000 });
        refetchData();
        return items.map((item) => item.id);
      })
      .catch(() => {
        displayToast({ title: "Error", content: "Something went wrong. Please try again.", duration: 3000 });
        return [];
      });
  };

  const deps = [selectedWorkspace?.id, selectedApp?.id, attributesProps.validAttributes, sortOption.value];
  const queryKey = `crashReports ${selectedApp?.id}`;

  const { mutate: mutateBulkDelete } = useMutation({
    mutationFn: onDeleteRequest,
    onSuccess: (items) => {
      if (!items) return;

      //eslint-disable-next-line
      return queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
        ...oldData,
        pages: oldData.pages.map((page: Page<CrashReport>) => {
          const pageItems = page.items.filter((ticket) => !items.find((item) => item === ticket.id));
          return { ...page, items: pageItems };
        }),
      }));
    },
  });

  const onChangeStatus = useCallback(
    async (status: string, ticketID: string) => {
      if (!selectedApp || !selectedWorkspace || !status || !ticketID) return;
      try {
        const { data } = await crashesService.changeStatus(
          selectedWorkspace.id,
          selectedApp.id,
          ticketID,
          status as TicketStatus,
        ); // TODO fix typin
        setMemoryStatus((status) => {
          return {
            ...status,
            [ticketID]: data.status,
          };
        });

        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            let pageItems: CrashReport[] = [];

            const statusFilter = attributesProps.validAttributes.find((attr) => attr.field_name === "status");
            // We check if we are currently filtering by status
            if (statusFilter) {
              // If we are using 'is' ('filter') filter
              if (statusFilter.filter?.filter_type === "filter") {
                // We remove the ticket from the list if filter status is not the same as the one we are changing to
                pageItems = page.items.filter((ticket) =>
                  statusFilter.filter?.fieldValue !== status
                    ? !(ticketID === ticket.id)
                    : ticketID === ticket.id
                    ? {
                        ...ticket,
                        status: { status: status as TicketStatus },
                      }
                    : ticket,
                );
              }
              // Check if we are using 'is not' ('exclude') filter and remove the ticket from the list if filter status is the same as the one we are changing to
              else if (statusFilter.filter?.filter_type === "exclude") {
                pageItems = page.items.filter((ticket) =>
                  statusFilter.filter?.fieldValue === status
                    ? !(ticketID === ticket.id)
                    : ticketID === ticket.id
                    ? {
                        ...ticket,
                        status: { status: status as TicketStatus },
                      }
                    : ticket,
                );
              }
            } else {
              pageItems = page.items.map((ticket) =>
                ticketID === ticket.id
                  ? {
                      ...ticket,
                      status: { status: status as TicketStatus },
                    }
                  : ticket,
              );
            }
            return { ...page, items: pageItems };
          }),
        }));
      } catch (error) {
        // Display Error only in table flows
        displayToast({ title: "Something went wrong", content: "Please try again." });
      }
      return;
    },
    [selectedApp, selectedWorkspace, crashesService, queryClient, queryKey, deps, attributesProps.validAttributes],
  );

  const onChangePriority = async (priority: string, ticketID: string) => {
    if (!selectedApp || !selectedWorkspace || !priority || !ticketID) return;
    try {
      const { data } = await crashesService.changePriority(
        selectedWorkspace.id,
        selectedApp.id,
        ticketID,
        priority as TicketPriority,
      ); // type
      setMemoryPriority((priority) => {
        return {
          ...priority,
          [ticketID]: data.priority,
        };
      });

      // eslint-disable-next-line
      queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
        ...oldData,
        pages: oldData.pages.map((page: Page<CrashReport>) => {
          let pageItems: CrashReport[] = [];

          const priorityFilter = attributesProps.validAttributes.find((attr) => attr.field_name === "priority");
          // We check if we are currently filtering by priority
          if (priorityFilter) {
            // If we are using 'is' ('filter') filter
            if (priorityFilter.filter?.filter_type === "filter") {
              // We remove the ticket from the list if filter priority is not the same as the one we are changing to
              pageItems = page.items.filter((ticket) =>
                priorityFilter.filter?.fieldValue !== priority
                  ? !(ticketID === ticket.id)
                  : ticketID === ticket.id
                  ? {
                      ...ticket,
                      priority: { priority: priority as TicketPriority },
                    }
                  : ticket,
              );
            }
            // Check if we are using 'is not' ('exclude') filter and remove the ticket from the list if filter priority is the same as the one we are changing to
            else if (priorityFilter.filter?.filter_type === "exclude") {
              pageItems = page.items.filter((ticket) =>
                priorityFilter.filter?.fieldValue === priority
                  ? !(ticketID === ticket.id)
                  : ticketID === ticket.id
                  ? {
                      ...ticket,
                      priority: { priority: priority as TicketPriority },
                    }
                  : ticket,
              );
            }
          } else {
            pageItems = page.items.map((ticket) =>
              ticketID === ticket.id
                ? {
                    ...ticket,
                    priority: { priority: priority as TicketPriority },
                  }
                : ticket,
            );
          }
          return { ...page, items: pageItems };
        }),
      }));
    } catch (error) {
      displayToast({ title: "Something went", content: "Please try again." });
    }
    return;
  };

  const onChangeAssignee = useCallback(
    async (assignee: string | null, ticketID: string) => {
      if (!selectedApp || !selectedWorkspace || !ticketID) return;
      try {
        const { data } = await crashesService.changeAssignee(selectedWorkspace.id, selectedApp.id, ticketID, assignee); // TODO fix typin
        setMemoryAssignee((assignee) => {
          return {
            ...assignee,
            [ticketID]: data.assignee_id,
          };
        });

        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            let pageItems: CrashReport[] = [];

            const assigneeFilter = attributesProps.validAttributes.find((attr) => attr.field_name === "assignee_id");
            // We check if we are currently filtering by assignee
            if (assigneeFilter) {
              // If we are using 'is' ('filter') filter
              if (assigneeFilter.filter?.filter_type === "filter") {
                // We remove the ticket from the list if filter assignee is not the same as the one we are changing to
                pageItems = page.items.filter((ticket) =>
                  assigneeFilter.filter?.fieldValue !== assignee
                    ? !(ticketID === ticket.id)
                    : ticketID === ticket.id
                    ? {
                        ...ticket,
                        assignee: { assignee_id: assignee },
                      }
                    : ticket,
                );
              }
              // Check if we are using 'is not' ('exclude') filter and remove the ticket from the list if filter assignee is the same as the one we are changing to
              else if (assigneeFilter.filter?.filter_type === "exclude") {
                pageItems = page.items.filter((ticket) =>
                  assigneeFilter.filter?.fieldValue === assignee
                    ? !(ticketID === ticket.id)
                    : ticketID === ticket.id
                    ? {
                        ...ticket,
                        assignee: { assignee_id: assignee },
                      }
                    : ticket,
                );
              }
            } else {
              pageItems = page.items.map((ticket) =>
                ticketID === ticket.id
                  ? {
                      ...ticket,
                      assignee: { assignee_id: assignee },
                    }
                  : ticket,
              );
            }
            return { ...page, items: pageItems };
          }),
        }));
      } catch (error) {
        // Display Error only in table flows
        displayToast({ title: "Something went wrong", content: "Please try again." });
      }
      return;
    },
    [selectedApp, selectedWorkspace, crashesService, queryClient, queryKey, deps, attributesProps.validAttributes],
  );

  const onBulkChangePriority = async (
    items: Array<CrashReportTableRowItem>,
    priority: TicketPriority,
    resetSelection?: () => void,
  ) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;
    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return crashesService
      .bulkChangePriority(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        priority,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            let pageItems: CrashReport[] = [];

            const priorityFilter = attributesProps.validAttributes.find((attr) => attr.field_name === "priority");
            // We check if we are currently filtering by priority
            if (priorityFilter) {
              // If we are using 'is' ('filter') filter
              if (priorityFilter.filter?.filter_type === "filter") {
                // We remove the ticket from the list if filter priority is not the same as the one we are changing to
                pageItems = page.items.filter((ticket) =>
                  priorityFilter.filter?.fieldValue !== priority
                    ? !items.find((i) => i.id === ticket.id)
                    : items.find((i) => i.id === ticket.id)
                    ? {
                        ...ticket,
                        priority: { priority: priority },
                      }
                    : ticket,
                );
              }
              // Check if we are using 'is not' ('exclude') filter and remove the ticket from the list if filter priority is the same as the one we are changing to
              else if (priorityFilter.filter?.filter_type === "exclude") {
                pageItems = page.items.filter((ticket) =>
                  priorityFilter.filter?.fieldValue === priority
                    ? !items.find((i) => i.id === ticket.id)
                    : items.find((i) => i.id === ticket.id)
                    ? {
                        ...ticket,
                        priority: { priority: priority },
                      }
                    : ticket,
                );
              }
              resetSelection?.();
            } else {
              pageItems = page.items.map((ticket) =>
                items.find((item) => item.id === ticket.id)
                  ? {
                      ...ticket,
                      priority: { priority: priority },
                    }
                  : ticket,
              );
            }
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: (
              <ToastContent content={`Priority has been successfully changed to ${priority} on selected tickets`} />
            ),
          },
        });

        refetchData();
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content="Error happened while changing priority on selected tickets" />,
          },
        });
        return [];
      });
  };

  const onBulkChangeStatus = async (
    items: Array<CrashReportTableRowItem>,
    status: TicketStatus,
    resetSelection?: () => void,
  ) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return crashesService
      .bulkChangeStatus(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        status,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            let pageItems: CrashReport[] = [];

            const statusFilter = attributesProps.validAttributes.find((attr) => attr.field_name === "status");
            // We check if we are currently filtering by status
            if (statusFilter) {
              // If we are using 'is' ('filter') filter
              if (statusFilter.filter?.filter_type === "filter") {
                // We remove the ticket from the list if filter status is not the same as the one we are changing to
                pageItems = page.items.filter((ticket) =>
                  statusFilter.filter?.fieldValue !== status
                    ? !items.find((i) => i.id === ticket.id)
                    : items.find((i) => i.id === ticket.id)
                    ? {
                        ...ticket,
                        status: { status: status },
                      }
                    : ticket,
                );
              }
              // Check if we are using 'is not' ('exclude') filter and remove the ticket from the list if filter status is the same as the one we are changing to
              else if (statusFilter.filter?.filter_type === "exclude") {
                pageItems = page.items.filter((ticket) =>
                  statusFilter.filter?.fieldValue === status
                    ? !items.find((i) => i.id === ticket.id)
                    : items.find((i) => i.id === ticket.id)
                    ? {
                        ...ticket,
                        status: { status: status },
                      }
                    : ticket,
                );
              }
              resetSelection?.();
            } else {
              pageItems = page.items.map((ticket) =>
                items.find((item) => item.id === ticket.id)
                  ? {
                      ...ticket,
                      status: { status: status },
                    }
                  : ticket,
              );
            }
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content={`Status has been successfully changed to ${status} on selected tickets`} />,
          },
        });

        refetchData();
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content="Error happened while changing status on selected tickets" />,
          },
        });
        return [];
      });
  };

  const onBulkChangeAssignee = async (
    items: Array<CrashReportTableRowItem>,
    assigneeId: string | null,
    resetSelection?: () => void,
  ) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return crashesService
      .bulkChangeAssignee(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        assigneeId,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            let pageItems: CrashReport[] = [];

            const assigneeFilter = attributesProps.validAttributes.find((attr) => attr.field_name === "assignee_id");
            // We check if we are currently filtering by assignee_id
            if (assigneeFilter) {
              // If we are using 'is' ('filter') filter
              if (assigneeFilter.filter?.filter_type === "filter") {
                // We remove the ticket from the list if filter assignee is not the same as the one we are changing to
                pageItems = page.items.filter((ticket) =>
                  assigneeFilter.filter?.fieldValue !== assigneeId
                    ? !items.find((i) => i.id === ticket.id)
                    : items.find((i) => i.id === ticket.id)
                    ? {
                        ...ticket,
                        assignee: { assignee_id: assigneeId },
                      }
                    : ticket,
                );
              }
              // Check if we are using 'is not' ('exclude') filter and remove the ticket from the list if filter assignee is the same as the one we are changing to
              else if (assigneeFilter.filter?.filter_type === "exclude") {
                pageItems = page.items.filter((ticket) =>
                  assigneeFilter.filter?.fieldValue === assigneeId
                    ? !items.find((i) => i.id === ticket.id)
                    : items.find((i) => i.id === ticket.id)
                    ? {
                        ...ticket,
                        assignee: { assignee_id: assigneeId },
                      }
                    : ticket,
                );
              }
              resetSelection?.();
            } else {
              pageItems = page.items.map((ticket) =>
                items.find((item) => item.id === ticket.id)
                  ? {
                      ...ticket,
                      assignee: { assignee_id: assigneeId },
                    }
                  : ticket,
              );
            }
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content={`Assignee has been successfully changed on selected tickets`} />,
          },
        });

        refetchData();
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content="Error happened while changing assignee on selected tickets" />,
          },
        });
        return [];
      });
  };

  const onBulkAddTags = async (items: Array<CrashReportTableRowItem>, tags: string[]) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return crashesService
      .bulkAddTags(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        tags,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            const pageItems = page.items.map((ticket) =>
              items.find((item) => item.id === ticket.id)
                ? {
                    ...ticket,
                    tags: ticket.tags
                      .concat(
                        tags.map((tag) => {
                          return {
                            name: tag,
                          };
                        }),
                      )
                      .filter((value, index, self) => index === self.findIndex((t) => t.name === value.name)),
                  }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));
        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content={"Tags have been successfully added to the selected tickets"} />,
          },
        });

        refetchData();
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content={"Error happened while adding tags to the selected tickets"} />,
          },
        });
        return [];
      });
  };

  /// Table props
  const crashTableProps = useCrashesTable({
    data: pagination.mappedData || [],
    hasMore: pagination.hasMore,
    isLoading: pagination.isLoading,
    isFetchingNext: pagination.isFetchingNextPage,
    loadMore: pagination.loadNext,
    onDeleteRequest: mutateBulkDelete,
    onBulkChangePriority: onBulkChangePriority,
    onBulkChangeAssignee: onBulkChangeAssignee,
    onBulkChangeStatus: onBulkChangeStatus,
    onBulkAddTags: onBulkAddTags,
    onChangeSortOption: (newOption) => {
      if (newOption == sortOption.value) {
        refetchData();
        return;
      }
      setSortOption(newOption);
    },
    selectedSortOption: sortOption.value,
    onChangePriorityRequest: onChangePriority,
    onChangeStatusRequest: onChangeStatus,
    onChangeAssigneeRequest: onChangeAssignee,
    total: pagination.total,
  });

  return {
    items: pagination.mappedData,
    loading: pagination.isLoading,
    error: pagination.error,
    hasMore: pagination.hasMore,
    total: pagination.total,
    refetchData,
    crashTableProps,
  };
}

export const mapCrashReportToTableRowItem = (
  crashReport: CrashReport,
  memoryStatus?: MemoryStatus,
  memoryPriority?: MemoryPriority,
  memoryAssignee?: MemoryAssignee,
  members?: Member[],
): CrashReportTableRowItem => {
  const firstAndSecondColumn = crashReport.title?.split(":");
  return {
    id: crashReport.id,
    key: crashReport.key,
    name: firstAndSecondColumn?.[0],
    line: firstAndSecondColumn?.[1],
    method: crashReport.subtitle,
    tags: crashReport.tags?.length ? crashReport.tags?.sort((a, b) => a.name.localeCompare(b.name)) : [],
    highestVersion: 2.45,
    events: crashReport.crash_events_count,
    row_type: RowType.CRASH_REPORT,
    status: (memoryStatus?.[crashReport.id] ?? crashReport.status?.status) as Exclude<
      TicketStatus,
      TicketStatus.LOCKED
    >,
    priority: (memoryPriority?.[crashReport.id] ?? crashReport.priority?.priority) as TicketPriority,
    assignee: {
      name:
        memoryAssignee?.[crashReport.id] !== null
          ? getAssignee(
              memoryAssignee?.[crashReport.id] ? memoryAssignee?.[crashReport.id] : crashReport.assignee?.assignee_id,
              members,
            )?.user.name ?? ""
          : "",
      id:
        memoryAssignee?.[crashReport.id] !== null
          ? getAssignee(
              memoryAssignee?.[crashReport.id] ? memoryAssignee?.[crashReport.id] : crashReport.assignee?.assignee_id,
              members,
            )?.user.id ?? null
          : null,
      picture:
        memoryAssignee?.[crashReport.id] !== null
          ? getAssignee(
              memoryAssignee?.[crashReport.id] ? memoryAssignee[crashReport.id] : crashReport.assignee?.assignee_id,
              members,
            )?.user.picture ?? null
          : null,
    },
    linked_crash_groups_count: crashReport.linked_crash_groups_count,
  };
};

const getDeletedToastMessage = (issuesLength: number): string => {
  if (issuesLength === 1) {
    return `${issuesLength} crash report has been successfully deleted.`;
  }
  return `${issuesLength} crash reports have been successfully deleted.`;
};

const getAssignee = (assigneeId: string | null, members?: Member[]) => {
  if (!members) return;
  return members.find((member) => member.id === assigneeId);
};
