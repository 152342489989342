import React from "react";

import * as Styled from "./Card.styles";

export interface CardProps {
  noPadding?: boolean;
  scrollHeight?: number;
  className?: string;
}

const Card: React.FC<CardProps> = ({ children, scrollHeight, className, noPadding }) => {
  return (
    <Styled.CardWrap
      className={`card ${className}`}
      $noPadding={noPadding}
    >
      <Styled.CardScroll
        $height={scrollHeight}
        $noPadding={noPadding}
      >
        {children}
      </Styled.CardScroll>
    </Styled.CardWrap>
  );
};

export default Card;
