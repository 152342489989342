import React from "react";

import * as Styled from "./ChangeLog.styles";
import { ReactComponent as ArrowIcon } from "../../../../../common/assets/images/quick-links/caret-right-icon.svg";
import Paragraph from "../../../../../common/components/Paragraph/Paragraph";
import { Changelog, ChangelogLink } from "../../../../../common/models/Changelog.model";
import { vars } from "../../../../../common/styles";
import { Flex } from "../../../../../common/styles/reusable/Flex/Flex.styles";
import { NotificationIndicator } from "../../../../layouts/components/Sidebar/Sidebar.styles";

interface ChangeLogProps {
  changeLog: Changelog;
  isUnread?: boolean;
  markAsRead?: (logId: string) => void;
}

export default function ChangeLog({ changeLog, isUnread, markAsRead }: ChangeLogProps) {
  return (
    <Flex $gap={1.6}>
      {isUnread && (
        <NotificationIndicator
          style={{ width: "0.9rem", height: "0.9rem", marginTop: "0.8rem", marginLeft: "-2.4rem" }}
        />
      )}
      <Flex $gap={1.2}>
        {changeLog.image_url ? (
          <img
            src={changeLog?.image_url ?? undefined}
            height={24}
            width={24}
          />
        ) : (
          <div style={{ width: "2.4rem", height: "2.4rem" }}></div>
        )}
        <div>
          <Flex>
            <Paragraph
              color={vars.colors.grey30}
              style={{ paddingRight: "2rem" }}
            >
              {changeLog?.title}
            </Paragraph>
            {changeLog?.links?.map((link, index) => {
              return (
                <LinkElement
                  key={index}
                  link={link}
                />
              );
            })}
          </Flex>
          <Paragraph style={{ paddingTop: "0.4rem" }}>{changeLog?.description}</Paragraph>
          {isUnread && <Styled.Button onClick={() => markAsRead?.(changeLog.id)}>Mark as read</Styled.Button>}
        </div>
      </Flex>
    </Flex>
  );
}

interface Props {
  link: ChangelogLink;
}

function LinkElement({ link }: Props) {
  return (
    <Styled.Link
      style={{ paddingRight: "1.2rem", lineHeight: "1.6rem" }}
      key={link.id}
      href={link.url}
      target="_blank"
      rel="noreferrer"
    >
      {link.title}
      <ArrowIcon />
    </Styled.Link>
  );
}
