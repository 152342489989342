import { Environment } from "../Environment.model";

export const production: Environment = {
  production: true,
  name: "production",
  apiHost: "https://dashboard-api.shakebugs.com/api/1.0/",
  publicApiHost: "https://dashboard-api.shakebugs.com/api/public",
  socketioHost: "https://ws.shakebugs.com",
  shakeSDKApiKey: "pgJOrIGcjU7pgV6Vg1lSjZdmaggfjQ31BRL0yLfUYGc70L9YQEXYmxx",
  oAuthClientId: "shake_dashboard",
  oAuthClientSecret: "",
  oAuthDemoClientId: "shake_demo",
  oAuthDemoClientSecret: "h6zw6bP4orsqws5J8gqP8tvcfbRf4lxiLIT49stcDDKxMv4JVMumQSi",
  oAuthHost: "https://dashboard-api.shakebugs.com/auth/oauth2/",
  segmentWriteKey: "vpQAXLVX5o9F3gP9oM6mSN8mbDzHI2iy",
  stripeKey: "pk_live_65xGiItJTET0yLWzG9VgUTMx",
  sentryDsn: "https://107ce352dff84220bd0ff18a652089dd@o257007.ingest.sentry.io/5312361",
  appURL: "https://app.shakebugs.com",
  wwwURL: "https://www.shakebugs.com",
  documentationUrl: "https://www.shakebugs.com/docs",
  shareDomain: "shk.sh",
  issuesLimit: 50,
  googleAuthEnabled: true,
  youTrackEnabled: false,
  azureEnabled: true,
  zapierEnabled: true,
  trelloEnabled: true,
  trelloAppKey: "1c2affc5f6df8f2b8e567309114f52f5",
  asanaEnabled: true,
  asanaClientId: "1185706593431935",
  githubEnabled: true,
  githubClientId: "f2a47603d56f4e3527f5",
  clickupEnabled: true,
  clickupClientId: "WSXVXWRI7ERLLY5WLPNLIB9KFDEZTMSN",
  cannyUrl: "https://feedback.shakebugs.com",
  logsEnabled: true,
  logsCustomLogsEnabled: true,
  logsNotificationsTrackingEnabled: true,
  blackboxEnabled: true,
  notificationsEnabled: true,
  unreadNotificationsEmailEnabled: true,
  tagsEnabled: true,
  deepSearchEnabled: true,
  billingEnabled: true,
  billingInvoicesEnabled: true,
  crashReportingEnabled: false,
  googleClientId: "846486073736-ss5r3c6tb91b1pdccdh2en1nl76pdv6a.apps.googleusercontent.com",
  jiraCloudClientId: "SDueYKkDUcLU8SFlVFh0APEm30xIbD3d",
  linearClientId: "2b8038a74b9e80ffc1ca9bf66d8f73a4",
  sentryEnabled: true,
  sentryClientId: "c6122fa45de8f10151f0d6d7b461349dc98470e604cd87d4554df01a38f7842f",
};
