import React, { Fragment, useState } from "react";

import { usePlatformsConsumer } from "main/pages/AddApp/consumers/usePlatformsConsumer";

import * as Styled from "./AddAppPage.styles";
import { AddAppCreateApp } from "./views/CreateApp/AddAppCreateApp";
import { AddAppIntroPage, InstallationType } from "./views/IntroPage/AddAppIntroPage";
import { AddAppSelectPlatform } from "./views/SelectPlatform/AddAppSelectPlatform";
import Button from "../../../common/components/Button/Button";
import Header from "../../../common/components/Header/Header";
import Icon from "../../../common/components/Icon/Icon";
import Pager from "../../../common/components/Pager/Pager";
import PageWrap from "../../../common/components/PageWrap/PageWrap";
import { Platform, PlatformName } from "../../../common/models";

enum Pages {
  INTRO,
  PLATFORM,
  APP_NAME,
}

export function AddAppPage() {
  const { platforms } = usePlatformsConsumer();

  const [selectedPage, setSelectedPage] = useState<number>(Pages.INTRO);
  const [installationType, setInstallationType] = useState<InstallationType | undefined>();
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | undefined>();

  const onInstallationTypeSelected = (newInstallationType: InstallationType) => {
    setInstallationType(newInstallationType);

    if (newInstallationType === InstallationType.MANUAL) {
      nextPage();
    }
    if (newInstallationType === InstallationType.EXTENSION) {
      const webPlatform: Platform | undefined = platforms?.find((p) => p.name === PlatformName.WEB);
      setSelectedPlatform(webPlatform);
      setSelectedPage(Pages.APP_NAME);
    }
  };

  const onPlatformSelected = (platform: Platform) => {
    setSelectedPlatform(platform);
    nextPage();
  };

  const nextPage = () => {
    if (selectedPage >= Object.keys(Pages).length / 2) return;
    setSelectedPage(selectedPage + 1);
  };

  const previousPage = () => {
    if (selectedPage <= 0) return;
    setSelectedPage(selectedPage - 1);
  };

  return (
    <Fragment>
      <Header>
        <Styled.HeaderWrap>
          {selectedPage > 0 && (
            <Button
              className={"align-left"}
              styling={"flat"}
              onClick={() => {
                if (installationType === InstallationType.EXTENSION) setSelectedPage(Pages.INTRO);
                if (installationType === InstallationType.MANUAL) previousPage();
              }}
            >
              <Icon
                icon="caret-left"
                size={10}
              />
              {"Back"}
            </Button>
          )}
          <Pager
            pagesNumber={Object.keys(Pages).length / 2}
            selectedPage={selectedPage}
          />
        </Styled.HeaderWrap>
      </Header>
      <PageWrap
        style={{
          overflow: "scroll",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Styled.Container style={{ width: selectedPage === Pages.PLATFORM ? "82.5rem" : "73.6rem" }}>
          {(() => {
            switch (selectedPage) {
              case Pages.INTRO:
                return <AddAppIntroPage onInstallationTypeSelected={onInstallationTypeSelected} />;
              case Pages.PLATFORM:
                return (
                  <AddAppSelectPlatform
                    platforms={platforms}
                    onPlatformSelected={onPlatformSelected}
                  />
                );
              case Pages.APP_NAME:
                return (
                  <AddAppCreateApp
                    installationType={installationType}
                    platform={selectedPlatform}
                  />
                );
            }
            return undefined;
          })()}
        </Styled.Container>
      </PageWrap>
    </Fragment>
  );
}
