import { AuthReducer } from "./Auth.reducer";
import { AuthState, USER_DATA_LOCAL_STORAGE_KEY, USER_TOKEN_LOCAL_STORAGE_KEY } from "./Auth.types";
import { getAndParseItemFromLocalStorage } from "../../../common/util/localStorage";
import makeContextStore from "../../../common/util/makeContextStore";

const initialState: AuthState = {
  userData: getAndParseItemFromLocalStorage(USER_DATA_LOCAL_STORAGE_KEY),
  userToken: getAndParseItemFromLocalStorage(USER_TOKEN_LOCAL_STORAGE_KEY),
};

const [AuthContextProvider, useAuthContext, useAuthDispatch] = makeContextStore(AuthReducer, initialState);

export { AuthContextProvider, useAuthContext, useAuthDispatch };
