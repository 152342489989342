import React, { useEffect } from "react";

import { Outlet } from "react-router";

import { useWorkspacesAPI } from "../consumers/useWorkspacesAPI";

export function RootLayoutView() {
  const { fetchWorkspacesAndDispatch } = useWorkspacesAPI();

  useEffect(() => {
    fetchWorkspacesAndDispatch();
  }, [fetchWorkspacesAndDispatch]);

  return <Outlet />;
}
