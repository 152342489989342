import React from "react";

import { Row } from "@tanstack/react-table";

import * as Styled from "./ModalBody.styles";
import * as Icon from "../../../../../../main/pages/shared/icons/icons";
import { TicketPriority } from "../../../../../models/TicketPriority.model";
import { TicketStatus } from "../../../../../models/TicketStatus.model";
import { Flex } from "../../../../../styles/reusable/Flex/Flex.styles";
import { ModalHeader } from "../../../../Modal/ModalHeader/ModalHeader";

export function SetStatusPriorityModalBody<TData>({
  title,
  changeStatus,
  changePriority,
  priority,
  selectedRows,
  close,
  resetSelection,
}: {
  title: string;
  changeStatus?: (rows: TData[], value: TicketStatus, resetSelection?: () => void) => void;
  changePriority?: (rows: TData[], value: TicketPriority, resetSelection?: () => void) => void;
  priority?: boolean;
  selectedRows: Row<TData>[];
  close: () => void;
  resetSelection?: () => void;
}) {
  return (
    <Flex $column>
      <Styled.HeaderContainer>
        <ModalHeader
          heading={title}
          noContent
        />
      </Styled.HeaderContainer>
      <Styled.Container>
        {priority && changePriority ? (
          <Priorities
            selectedRows={selectedRows}
            confirmAction={changePriority}
            close={close}
            resetSelection={resetSelection}
          />
        ) : changeStatus ? (
          <Statuses
            selectedRows={selectedRows}
            confirmAction={changeStatus}
            close={close}
            resetSelection={resetSelection}
          />
        ) : undefined}
      </Styled.Container>
    </Flex>
  );
}

function Priorities<TData>({
  confirmAction,
  selectedRows,
  close,
  resetSelection,
}: {
  confirmAction: (rows: TData[], value: TicketPriority, resetSelection?: () => void) => void;
  selectedRows: Row<TData>[];
  close: () => void;
  resetSelection?: () => void;
}) {
  return (
    <>
      <Element
        Icon={<Icon.PriorityHighIcon />}
        value={TicketPriority.HIGH}
        confirmAction={() => {
          confirmAction(
            selectedRows.map((row) => row.original),
            TicketPriority.HIGH,
            resetSelection,
          );
          close();
        }}
      />
      <Element
        Icon={<Icon.PriorityMediumIcon />}
        value={TicketPriority.MEDIUM}
        confirmAction={() => {
          confirmAction(
            selectedRows.map((row) => row.original),
            TicketPriority.MEDIUM,
            resetSelection,
          );
          close();
        }}
      />
      <Element
        Icon={<Icon.PriorityLowIcon />}
        value={TicketPriority.LOW}
        confirmAction={() => {
          confirmAction(
            selectedRows.map((row) => row.original),
            TicketPriority.LOW,
            resetSelection,
          );
          close();
        }}
      />
    </>
  );
}

function Statuses<TData>({
  confirmAction,
  selectedRows,
  close,
  resetSelection,
}: {
  confirmAction: (rows: TData[], value: TicketStatus, resetSelection?: () => void) => void;
  selectedRows: Row<TData>[];
  close: () => void;
  resetSelection?: () => void;
}) {
  return (
    <>
      <Element
        Icon={<Icon.StatusNewIcon />}
        value={TicketStatus.NEW}
        confirmAction={() => {
          confirmAction(
            selectedRows.map((row) => row.original),
            TicketStatus.NEW,
            resetSelection,
          );
          close();
        }}
      />
      <Element
        Icon={<Icon.InProgressIcon />}
        value={TicketStatus.IN_PROGRESS}
        confirmAction={() => {
          confirmAction(
            selectedRows.map((row) => row.original),
            TicketStatus.IN_PROGRESS,
            resetSelection,
          );
          close();
        }}
      />
      <Element
        Icon={<Icon.StatusDoneIcon />}
        value={TicketStatus.CLOSED}
        confirmAction={() => {
          confirmAction(
            selectedRows.map((row) => row.original),
            TicketStatus.CLOSED,
            resetSelection,
          );
          close();
        }}
      />
    </>
  );
}

function Element({
  confirmAction,
  Icon,
  value,
}: {
  Icon: JSX.Element;
  confirmAction: () => void;
  value: TicketStatus | TicketPriority;
}) {
  return (
    <Styled.Button onClick={confirmAction}>
      {Icon}

      <p>{value}</p>
    </Styled.Button>
  );
}
