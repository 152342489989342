import { AxiosResponse } from "axios";

import { UserToken } from "../../../../common/models";
import ApiService from "../../../../common/services/Api.service";
import { UTMTags } from "../../../../common/util/parseUTMTags";

export class SignInService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  signInWithGoogleCode(
    googleCode: string,
    redirect_uri: string,
    utmTags?: UTMTags,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<UserToken>> {
    return this.apiService.post<UserToken>({
      resource: "accounts/google_auth",
      data: {
        client_id: "shake_dashboard",
        token: googleCode,
        redirect_uri,
        ...utmTags,
      },
      config: {
        signal: abortSignal,
      },
    });
  }
}
