import { Environment } from "../Environment.model";

export const staging: Environment = {
  production: true,
  name: "staging",
  apiHost: "https://api-dashboard.stage.staging5h4k3.com/api/1.0/",
  publicApiHost: "https://api-dashboard.stage.staging5h4k3.com/api/public",
  socketioHost: "https://ws.stage.staging5h4k3.com",
  shakeSDKApiKey: "Ui1KNgyX16Aib7HtKiazH9oMp7sRGhh3Tgg7eFGVu7Mn2GIeiGUByiV",
  oAuthClientId: "shake_dashboard",
  oAuthClientSecret: "",
  oAuthDemoClientId: "shake_demo",
  oAuthDemoClientSecret: "gAi0TAgxSov4WKePDfLrqJQjZgktDGoaJAxXuDZj5DPLoBend4BX9cl",
  oAuthHost: "https://api-dashboard.stage.staging5h4k3.com/auth/oauth2/",
  segmentWriteKey: "T1WcTo0VWBnxCNYuGx1kxy2zLAXboegS",
  stripeKey: "pk_test_bAJo7XJEDqZja9yvrV74rtM300LMp9WPMi",
  sentryDsn: "https://107ce352dff84220bd0ff18a652089dd@o257007.ingest.sentry.io/5312361",
  appURL: "https://app.stage.staging5h4k3.com",
  wwwURL: "https://www.stage.staging5h4k3.com",
  documentationUrl: "https://stage.staging5h4k3.com/docs",
  shareDomain: "staging.shk.sh",
  issuesLimit: 20,
  googleAuthEnabled: true,
  youTrackEnabled: false,
  azureEnabled: true,
  zapierEnabled: true,
  trelloEnabled: true,
  trelloAppKey: "1c2affc5f6df8f2b8e567309114f52f5",
  asanaEnabled: true,
  asanaClientId: "1185708886795986",
  githubEnabled: true,
  githubClientId: "9e2922381db40458d698",
  clickupEnabled: true,
  clickupClientId: "7SC6ZATG1M5BUU8XF8G8BSEI1JFGS6YO",
  cannyUrl: "https://feedback.shakebugs.com",
  logsEnabled: true,
  logsCustomLogsEnabled: true,
  logsNotificationsTrackingEnabled: true,
  blackboxEnabled: true,
  notificationsEnabled: true,
  unreadNotificationsEmailEnabled: true,
  tagsEnabled: true,
  deepSearchEnabled: true,
  billingEnabled: true,
  billingInvoicesEnabled: true,
  crashReportingEnabled: true,
  googleClientId: "846486073736-ss5r3c6tb91b1pdccdh2en1nl76pdv6a.apps.googleusercontent.com",
  jiraCloudClientId: "ENq40IJUEmeBgOnVmrcr3mEQnHhwqDKR",
  linearClientId: "2b8038a74b9e80ffc1ca9bf66d8f73a4",
  sentryEnabled: true,
  sentryClientId: "c6122fa45de8f10151f0d6d7b461349dc98470e604cd87d4554df01a38f7842f",
};
