import styled from "styled-components";

import { vars } from "../../../../common/styles";
import { responsive } from "../../../../common/styles/abstracts/utils";

export const Details = styled.div`
  position: relative;
  padding-top: 10rem;
  padding-left: 6.4rem;

  div.main-container {
    display: flex;
    flex-wrap: wrap;
  }

  .master-view {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 40%;

    > * {
      max-width: 54rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  /*  .sidebar-container {
    display: grid;
    grid-template-rows: auto 1fr auto;
    width: 100%;
    height: calc(100vh - 13.2rem);
  }

  .sidebar-container .content {
    overflow-y: auto;
  }

  .sidebar .sidebar-container {
    margin-bottom: -6.4rem;
    max-height: calc(100vh - 10rem);
  } */

  div.main-container {
    margin-bottom: -6.4rem;
    margin-left: -6.4rem;
  }

  div.main-container > * {
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
  }

  div.master-view > * + * {
    margin-top: 3.2rem;
  }
`;

export const DetailsTitle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: default;
  p {
    padding-left: 0.8rem;
    font-weight: normal;
    max-width: 20rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${vars.colors.grey10};
  }
`;

export const Sidebar = styled.div`
  flex-grow: 1;
  flex-basis: 34%;
  align-self: start;
  position: sticky;
  max-width: 48rem;

  position: relative;
  border-radius: 1rem;
  background-color: ${vars.colors.grey100};

  padding: 4rem 2.4rem;
  overflow-y: auto;

  height: calc(100vh - 13.2rem);

  ${responsive.tablet_portrait} {
    flex-basis: 50%;
  }

  ${responsive.phone} {
    flex-basis: 100%;
  }

  scrollbar-width: thin;
  scrollbar-color: ${vars.colors.grey90} transparent;

  margin-right: 0.8rem;
  &::-webkit-scrollbar {
    width: 0.8rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${vars.colors.grey90};
    border-radius: 0.8rem;
  }
`;

export const DropdownContainer = styled.div<{ $isError?: boolean }>`
  > div:first-child {
    > div:first-child {
      padding: 0.4rem 1.2rem;
      align-items: flex-start;
    }
  }
  div:last-child {
    width: auto;
  }

  width: 100%;

  svg {
    path {
      stroke: ${vars.colors.grey50};
    }
    width: 2rem;
    height: 2rem;
    margin-right: 1.2rem;
    flex-shrink: 0;
    display: inline-block;
  }

  span {
    color: ${vars.colors.grey30};

    &:hover: {
      opacity: 1;
      color: ${vars.colors.grey30};
    }
  }
`;
