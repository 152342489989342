import React from "react";

import { IntegrationActivationService } from "./services/IntegrationActivationService";
import { IntegrationActivationView } from "./views/IntegrationActivationView";
import dependencyInjection from "../../../common/util/dependencyInjection";

interface Service {
  service: IntegrationActivationService;
}

const service = new IntegrationActivationService();

const [IntegrationActivationDepProvider, useIntegrationActivationDeps] = dependencyInjection<Service>({
  services: { service },
});

const IntegrationActivation = () => (
  <IntegrationActivationDepProvider>
    <IntegrationActivationView />
  </IntegrationActivationDepProvider>
);

export { IntegrationActivation, useIntegrationActivationDeps };
