import React, { PropsWithChildren } from "react";

import * as Styled from "./AdministrationCard.styles";
import { Flex } from "../../styles/reusable/Flex/Flex.styles";
import Heading from "../Heading/Heading";

interface Props {
  title: string;
  subtitle?: string;
  topLeftAccessory?: React.ReactNode;
  testId?: string;
  isEmpty?: boolean;
  danger?: boolean;
}

export const AdministrationCard = ({
  children,
  title,
  subtitle,
  topLeftAccessory,
  testId,
  isEmpty,
  danger,
}: PropsWithChildren<Props>) => {
  return (
    <Styled.AdministrationCardWrap $danger={danger}>
      <HeadingSection
        title={title}
        subtitle={subtitle}
        testId={testId}
        isEmpty={isEmpty}
      >
        {topLeftAccessory && <Styled.TopLeftAccessoryWrap>{topLeftAccessory}</Styled.TopLeftAccessoryWrap>}
      </HeadingSection>
      {children}
    </Styled.AdministrationCardWrap>
  );
};

interface HeadingSectionProps {
  title: string;
  subtitle?: string;
  testId?: string;
  isEmpty?: boolean;
}

const HeadingSection = ({ title, subtitle, children, testId, isEmpty }: PropsWithChildren<HeadingSectionProps>) => {
  return (
    <Flex $justifyContent="space-between">
      <div>
        <Styled.HeadingWrap>
          <Heading
            as="h3"
            heading3
            marginBottom={16}
            testId={testId}
          >
            {title}
          </Heading>
        </Styled.HeadingWrap>
        {subtitle && <Styled.SubtitleWrap $isEmpty={isEmpty}>{subtitle}</Styled.SubtitleWrap>}
      </div>
      {children}
    </Flex>
  );
};
