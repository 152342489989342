import { InviteActivationRes } from "../../../../common/models/InviteActivation.model";
import { TeamInfo, TeamInvitationHackRes, TeamInvitationRes } from "../../../../common/models/TeamInvitation.model";
import ApiService from "../../../../common/services/Api.service";

export class WorkspaceJoinInvitationService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  checkInvitationCode(code: string) {
    return this.apiService
      .post<TeamInfo | TeamInvitationHackRes>({
        resource: "accounts/teams/check_invite_code",
        data: {
          code,
        },
      })
      .then(({ data }): TeamInvitationRes => {
        /// Re-map backend hacky return
        const teamAndTokenResponse = data as TeamInvitationHackRes;
        const team_info = data as TeamInfo;
        if (teamAndTokenResponse.token) {
          return {
            ...teamAndTokenResponse,
          };
        } else {
          return {
            team_info,
          };
        }
      });
  }

  inviteActivation(code: string, password: string) {
    return this.apiService.post<InviteActivationRes>({
      resource: "accounts/teams/invite_activation",
      data: {
        code,
        password,
      },
    });
  }
}
