import { AxiosResponse } from "axios";

import { TicketSortOption } from "../../../../common/components/ShakeTable/ui/SortHeaderCell";
import { FilteringBody } from "../../../../common/hooks/filtering/sharedTypes";
import { FetchFNResult } from "../../../../common/hooks/filtering/useFilterHitsQuery";
import { Tag } from "../../../../common/models";
import { Blackbox } from "../../../../common/models/blackbox";
import { Log } from "../../../../common/models/eventTracking";
import { SentryIntegrationInfo, SentryIntegrationIssue } from "../../../../common/models/integrations";
import {
  Issue,
  IssueChatRequest,
  IssueMessageResponse,
  IssueServiceHookPermalink,
  TicketsIssueResponse,
} from "../../../../common/models/issueTracking";
import { TicketPriority } from "../../../../common/models/TicketPriority.model";
import { TicketStatus } from "../../../../common/models/TicketStatus.model";
import ApiService from "../../../../common/services/Api.service";

export default class UserFeedbackService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  getUserFeedbackTickets(
    workspaceId: string,
    appId: string,
    offset = 0,
    limit = 21,
    sort: TicketSortOption = "key",
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<TicketsIssueResponse>> {
    return this.apiService.get({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues?limit=${limit}&offset=${offset}&sort=${sort}`,
      config: { signal: abortSignal },
    });
  }

  getUserFeedbackTicketsByFilters(
    workspaceId: string,
    appId: string,
    offset = 0,
    limit = 21,
    filters: FilteringBody[],
    sort: TicketSortOption = "key",
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<TicketsIssueResponse>> {
    return this.apiService.post({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/filter`,
      config: { signal: abortSignal },
      data: { filters: filters, limit, offset, sort },
    });
  }

  getUserFeedbackSearchMetadataCustomFields(
    workspaceId: string,
    appId: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<{ custom_fields: { [key: string]: string }[]; metadata: string[] }>> {
    return this.apiService.get({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/searchable`,
      config: { signal: abortSignal },
    });
  }

  getUserFeedbackFiltersHits(
    workspaceId: string,
    appId: string,
    keyword: string,
    filterField: string,
    abortSignal?: AbortSignal,
    limit?: number,
  ): Promise<AxiosResponse<FetchFNResult>> {
    return this.apiService.get({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/filter?search_field=${filterField}&search_text=${encodeURI(
        keyword,
      )}${limit ? `&limit=${limit}` : ""}`,
      config: { signal: abortSignal },
    });
  }

  getUserFeedback(
    workspaceId: string,
    appId: string,
    issueKey: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<Issue>> {
    return this.apiService.get({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueKey}`,
      config: { signal: abortSignal },
    });
  }

  getUserFeedbackSuggestion(
    workspaceId: string,
    appId: string,
    issueId: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<Issue>> {
    return this.apiService.get({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueId}/more_like_this`,
      config: { signal: abortSignal },
    });
  }

  getUserFeedbackDetailsNums(
    workspaceId: string,
    appId: string,
    issueId: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<{ aggs: { [key: string]: number }; total: number }>> {
    return this.apiService.get({
      resource: `analytics/${workspaceId}/apps/${appId}/issues/${issueId}`,
      config: { signal: abortSignal },
    });
  }

  getUserFeedbackMessages(
    workspaceId: string,
    appId: string,
    issueId: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<IssueMessageResponse[]>> {
    return this.apiService.get({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueId}/chat`,
      config: { signal: abortSignal },
    });
  }

  updateUserFeedback(
    workspaceId: string,
    appId: string,
    issueKey: string,
    updateValue: Partial<Issue>,
  ): Promise<AxiosResponse<Issue>> {
    return this.apiService.patch({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueKey}`,
      data: updateValue,
    });
  }

  updateUserFeedbackPublic(
    workspaceId: string,
    appId: string,
    issueKey: string,
    isPublic: boolean,
  ): Promise<AxiosResponse<Issue>> {
    return this.apiService.patch({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueKey}`,
      data: {
        is_public: isPublic,
      },
    });
  }
  deleteUserFeedback(workspaceId: string, appId: string, issueKey: string): Promise<AxiosResponse<void>> {
    return this.apiService.delete({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueKey}`,
    });
  }

  sendUserFeedbackMessage(
    workspaceId: string,
    appId: string,
    issueId: string,
    message: IssueChatRequest,
  ): Promise<AxiosResponse<IssueMessageResponse[]>> {
    return this.apiService.post({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueId}/chat`,
      data: message,
    });
  }

  bulkDeleteIssues(workspaceId: string, appId: string, issues: string[]) {
    return this.apiService.post({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/bulk_delete`,
      data: { issues: issues },
    });
  }

  // the response contains all issue tags, not just the one created
  createIssueTag(workspaceId: string, issueId: string, tagName: string): Promise<AxiosResponse<Tag[]>> {
    return this.apiService.post({
      resource: `issue_tracking/${workspaceId}/issues/${issueId}/tag/${tagName}`,
    });
  }

  deleteIssueTag(workspaceId: string, issueId: string, tagName: string): Promise<AxiosResponse<Tag[]>> {
    return this.apiService.delete({
      resource: `issue_tracking/${workspaceId}/issues/${issueId}/tag/${tagName}`,
    });
  }

  getActivityHistory(
    workspaceId: string,
    appId: string,
    issueKey: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<Log>> {
    return this.apiService.get({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueKey}/log`,
      config: { signal: abortSignal },
    });
  }

  downloadActivityHistory(workspaceId: string, appId: string, issueKey: string) {
    return this.apiService.get<BlobPart>({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueKey}/log/download`,
      config: {
        responseType: "blob",
      },
    });
  }

  deleteIssueNote(
    workspaceId: string,
    appId: string,
    messageId: string,
  ): Promise<AxiosResponse<IssueMessageResponse[]>> {
    return this.apiService.delete({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues_chat/${messageId}`,
    });
  }

  editIssueNote(
    workspaceId: string,
    appId: string,
    messageId: string,
    newValue: string,
  ): Promise<AxiosResponse<IssueMessageResponse[]>> {
    return this.apiService.patch({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues_chat/${messageId}`,
      data: {
        message: newValue,
      },
    });
  }

  getServiceHooks(workspaceId: string, appId: string, issueId: string) {
    return this.apiService.get<[IssueServiceHookPermalink]>({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueId}/service_hooks`,
    });
  }

  getBlackbox(workspaceId: string, appId: string, issueId: string) {
    return this.apiService.get<Blackbox>({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueId}/blackbox`,
    });
  }

  sendIssue(workspaceId: string, appId: string, issueId: string, serviceHookId: string) {
    return this.apiService.post<[IssueServiceHookPermalink]>({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueId}/service_hooks/${serviceHookId}/send`,
    });
  }

  getSentryInfo(workspaceId: string) {
    return this.apiService.get<SentryIntegrationInfo[]>({
      resource: `service_hooks/${workspaceId}/sentry/projects`,
    });
  }

  getSentryIssues(workspaceId: string, appId: string, issueId: string, projectSlug: string, environment: string) {
    return this.apiService.get<SentryIntegrationIssue[]>({
      resource: `service_hooks/${workspaceId}/apps/${appId}/issues/${issueId}/sentry/issues?project_slug=${projectSlug}&environment=${environment}`,
    });
  }

  changeStatus(workspaceId: string, appId: string, issueId: string, status: TicketStatus) {
    return this.apiService.post<{ status: string }>({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueId}/status`,
      data: { status },
    });
  }

  changePriority(workspaceId: string, appId: string, issueId: string, priority: TicketPriority) {
    return this.apiService.post<{ priority: string }>({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueId}/priority`,
      data: { priority },
    });
  }

  changeAssignee(workspaceId: string, appId: string, issueId: string, assigneeId: string | null) {
    return this.apiService.post<{ assignee_id: string }>({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueId}/assignee`,
      data: { assignee_id: assigneeId },
    });
  }

  bulkChangePriority(workspaceId: string, appId: string, issues: string[], priority: TicketPriority) {
    return this.apiService.post({
      resource: `/issue_tracking/${workspaceId}/apps/${appId}/issues/priority`,
      data: { issues, priority },
    });
  }

  bulkChangeStatus(workspaceId: string, appId: string, issues: string[], status: TicketStatus) {
    return this.apiService.post({
      resource: `/issue_tracking/${workspaceId}/apps/${appId}/issues/status`,
      data: { issues, status },
    });
  }

  bulkChangeAssignee(workspaceId: string, appId: string, issues: string[], assigneeId: string | null) {
    return this.apiService.post({
      resource: `/issue_tracking/${workspaceId}/apps/${appId}/issues/assignee`,
      data: { issues, assignee_id: assigneeId },
    });
  }

  bulkSendChatNote(workspaceId: string, appId: string, issues: string[], message: string, isNote?: boolean) {
    return this.apiService.post({
      resource: `/issue_tracking/${workspaceId}/apps/${appId}/issues/chat`,
      data: { issues, message, is_note: isNote ? true : false },
    });
  }

  bulkMarkAsRead(workspaceId: string, appId: string, issues: string[]) {
    return this.apiService.post({
      resource: `/accounts/${workspaceId}/apps/${appId}/issue_notification/mark`,
      data: { issues, read: true },
    });
  }

  bulkAddTags(workspaceId: string, appId: string, issues: string[], tags: string[]) {
    return this.apiService.post({
      resource: `/issue_tracking/${workspaceId}/apps/${appId}/issues/tag`,
      data: { issues, tags },
    });
  }

  bulkMergeInto(workspaceId: string, appId: string, issues: string[], issue: string) {
    return this.apiService.post({
      resource: `/issue_tracking/${workspaceId}/apps/${appId}/issues/${issue}/link`,
      data: { issues },
    });
  }

  unMergeTicket(workspaceId: string, appId: string, issues: string[], issue: string) {
    return this.apiService.delete({
      resource: `/issue_tracking/${workspaceId}/apps/${appId}/issues/${issue}/link`,
      config: { data: { issues } },
    });
  }

  getLinkedIssues(workspaceId: string, appId: string, issueId: string) {
    return this.apiService.get<Issue[]>({
      resource: `issue_tracking/${workspaceId}/apps/${appId}/issues/${issueId}/link`,
    });
  }
}
