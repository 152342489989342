import { AxiosResponse } from "axios";

import { ResetPasswordResponse } from "../../../../common/models";
import ApiService from "../../../../common/services/Api.service";

export class ResetPasswordService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  resetPassword(code: string, password: string): Promise<AxiosResponse<ResetPasswordResponse>> {
    return this.apiService.post<ResetPasswordResponse>({
      resource: "accounts/users/reset_password",
      data: { code, password },
    });
  }

  checkPasswordCode(code: string): Promise<AxiosResponse> {
    return this.apiService.post({
      resource: "accounts/users/check_password_code",
      data: { code },
    });
  }
}
