import React, { PropsWithChildren } from "react";

import * as Styled from "./HelperText.styles";
import { ReactComponent as Sparkle } from "../../assets/images/sparkle-emoji.svg";

type Props = {
  className?: string;
};

export const HelperText: React.FC<PropsWithChildren<Props>> = ({ children, className }): JSX.Element => {
  return (
    <Styled.HelperText className={className}>
      <div style={{ width: "2rem" }}>
        <Sparkle />
      </div>
      <p>{children}</p>
    </Styled.HelperText>
  );
};
