import React from "react";

import * as Styled from "./FormTitle.styles";
import { ReactComponent as ShakeIcon } from "../../../../../../common/assets/icons/shake_icon.svg";
import Heading from "../../../../../../common/components/Heading/Heading";
import Paragraph from "../../../../../../common/components/Paragraph/Paragraph";

interface Props {
  title: string;
  subtitle?: string | JSX.Element;
  titleTestId?: string;
  subtitleTestId?: string;
}

export default function FormTitle({ title, subtitle, titleTestId, subtitleTestId }: Props) {
  return (
    <Styled.FormTitle>
      <ShakeIcon width={40} />
      <Heading
        as="h1"
        heading1
        testId={titleTestId}
      >
        {title}
      </Heading>
      {subtitle && (
        <Paragraph testId={subtitleTestId}>
          {/*TODO remove strong element once e2e tests are fixed*/}
          <strong>{subtitle}</strong>
        </Paragraph>
      )}
    </Styled.FormTitle>
  );
}
