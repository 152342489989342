import React, { Fragment } from "react";

import ReactPlayer from "react-player";
import { CSSProperties } from "styled-components";

import * as Styled from "./VideoAttachment.styles";
import { BaseDialog, useBaseDialog } from "../../../../../../common/components/Dialog/BaseDialog";
import Tooltip from "../../../../../../common/components/Tooltip/Tooltip";

interface Props {
  url?: string;
  width?: number;
  height?: number;
  style?: CSSProperties;
}

export default function VideoAttachment({ url, width, height, style }: Props) {
  const modal = useBaseDialog({});
  const showModal = () => {
    modal.setOpen(true);
  };

  if (!url) return <Fragment />;

  return (
    <Fragment>
      <Tooltip
        text="Play video"
        position="top"
        style={{ height: "100%" }}
      >
        <Styled.VideoContainer
          onClick={() => showModal()}
          style={style}
        >
          <Styled.Play />
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <ReactPlayer
              url={url}
              style={{ cursor: "pointer", borderRadius: "1rem", objectFit: "cover" }}
              width={width ?? "100%"}
              height={height ?? "100%"}
              playing={false}
              type="video/mp4"
              controls={false}
              showInfo={false}
            />
          }
        </Styled.VideoContainer>
      </Tooltip>

      <BaseDialog
        {...modal}
        noBackground
        fullHeight
        size={"wide"}
        noPadding
      >
        <Styled.Flex
          $alignItems="center"
          $justifyContent="center"
        >
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <ReactPlayer
              url={url}
              controls
              playing
              type="video/mp4"
              autoPlay
              style={{ minHeight: "90vh", maxWidth: "90vw" }}
            />
          }
        </Styled.Flex>
      </BaseDialog>
    </Fragment>
  );
}
