import { useCallback } from "react";

import displayToast from "@common/components/Toast/displayToast";
import { registerShakeSdkUser } from "@common/util/shakeSdkUtils";
import { useAuthDeps } from "@main/App.dependencies";
import { setJoinableWorkspaces, setWorkspacesAction, setWorkspacesLoading } from "@main/context/App/App.actions";
import { useAppDispatch } from "@main/context/App/App.context";
import { setUserDataAction } from "@main/context/Auth/Auth.actions";
import { useAuthDispatch } from "@main/context/Auth/Auth.context";

export const useWorkspacesAPI = () => {
  const { authService } = useAuthDeps();
  const authDispatch = useAuthDispatch();
  const appDispatch = useAppDispatch();

  const fetchWorkspacesAndDispatch = useCallback(async () => {
    try {
      appDispatch(setWorkspacesLoading(true));
      const { data } = await authService.getMe();

      const { teams: workspaces, joinable_teams: joinableWorkspaces, ...userData } = data;
      authDispatch(setUserDataAction(userData));
      appDispatch(setJoinableWorkspaces(joinableWorkspaces));
      appDispatch(setWorkspacesAction(workspaces));

      registerShakeSdkUser(data);

      return { userData, workspaces };
    } catch (error) {
      window.location.href = "https://status.shakebugs.com/";
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
      throw error;
    } finally {
      appDispatch(setWorkspacesLoading(false));
    }
  }, [authService, authDispatch, appDispatch]);

  return { fetchWorkspacesAndDispatch };
};
