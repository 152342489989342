import React from "react";

import { generatePath, Link } from "react-router-dom";

import * as Styled from "./ResetPasswordForm.styles";
import Button from "../../../../common/components/Button/Button";
import Input from "../../../../common/components/Input/Input";
import { t } from "../../../../common/util/translator";
import { RoutePaths } from "../../../router/config/routePaths";
import FormTitle from "../../shared/components/Forms/FormTitle/FormTitle";
import { useResetPasswordFormConsumer } from "../consumers/useResetPasswordFormConsumer";

export default function ResetPasswordForm({
  resetCode,
  shouldAllowSubmit,
}: {
  resetCode: string | null;
  shouldAllowSubmit: () => boolean;
}) {
  const { formState, formError, handleFormChange, handleFormSubmit, loading } = useResetPasswordFormConsumer({
    resetCode,
  });

  const { password } = formState;
  const { password: passwordError } = formError;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  };

  const handleSubmit = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    const shouldAllow = shouldAllowSubmit();
    shouldAllow && handleFormSubmit(e);
  };

  const isButtonDisabled = loading || !password;

  return (
    <>
      <FormTitle
        title="Reset password"
        subtitle="Enter a new password for your Shake account"
      />

      <Styled.ResetPasswordForm
        onSubmit={handleSubmit}
        noValidate
      >
        <Input
          disabled={loading}
          name="password"
          type="password"
          label={t("password")}
          value={password}
          error={!!passwordError}
          helper={passwordError && passwordError.message}
          onChange={handleChange}
          autoFocus
        />
        <Button
          disabled={isButtonDisabled}
          size="full"
          type="submit"
          tooltip={{
            position: "top",
            text: "Please enter your password first",
            disabled: Boolean(password),
          }}
          loadingText={loading && "Saving..."}
        >
          {t("resetPassword.form.button.change")}
        </Button>
      </Styled.ResetPasswordForm>

      <Styled.ResetPasswordFormLinks>
        <p>
          Here&apos;s a quick link to&nbsp;
          <Link to={generatePath(RoutePaths.SIGNIN)}>get back to sign in</Link>.
        </p>
      </Styled.ResetPasswordFormLinks>
    </>
  );
}
