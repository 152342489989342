import styled from "styled-components";

export const QuickActionsContainer = styled.div`
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

export const QuickActionsButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(91.04deg, #4fcf70 2.28%, #fad648 26.49%, #a767e5 50.69%, #12bcfe 74.9%, #44ce7b 99.11%);
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 10rem;
  cursor: pointer;
  align-self: end;
  box-shadow: 0 2px 10px 0 #1c1c1e;
  border: none;
  padding: 0.2rem;
  margin: 0;

  :hover {
    opacity: 0.7;
  }
`;

export const QuickActionsButtonInner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  width: 100%;
  height: 100%;
`;

export const QuickActions = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(91.04deg, #4fcf70 2.28%, #fad648 26.49%, #a767e5 50.69%, #12bcfe 74.9%, #44ce7b 99.11%);
  border-radius: 1rem;
  width: 18.4rem;
  padding: 2px;
  margin-bottom: 0.8rem;
  box-shadow: 0 2px 10px 0 #1c1c1e;
`;

export const QuickActionsInner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  border-radius: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  height: 100%;
`;

export const QuickActionsAction = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0.6rem 1.6rem;
  margin: 0;

  :hover {
    opacity: 0.7;
  }
`;

export const QuickActionsActionIcon = styled.img`
  margin-right: 0.8rem;
  width: 2.4rem;
  height: 2.4rem;
`;
