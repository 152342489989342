import React, { ReactNode } from "react";

import * as Styled from "./TicketDetailsContainer.styles";
import { ScrollContainer } from "../../../../../common/components/ScrollContainer/ScrollContainer";

export const TicketDetailsContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Styled.TicketDetailsPane>
      <Styled.DetailsPaneWrap>
        <ScrollContainer>
          <div style={{ paddingRight: "0.8rem" }}>{children}</div>
        </ScrollContainer>
      </Styled.DetailsPaneWrap>
    </Styled.TicketDetailsPane>
  );
};
