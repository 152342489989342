import React, { Fragment, useEffect, useState } from "react";

import { FloatingOverlay } from "@floating-ui/react";

import Icon from "../../../components/Icon/Icon";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { vars } from "../../../styles";
import { useAttributesContext } from "../context/AttributesProvider";
import { attributeLabelFor } from "../utils";
import { AddAttributePill } from "./AddAttributePill";
import * as Styled from "./Attributes.styles";
import { FilterSelectorPopover, useFilterSelectorPopover } from "./FilterSelectorPopover";
import { usePopover } from "./SimplePopover";

type Props = {
  identifier: number;
  attributeName: string;
  isLastAttribute: boolean;
  isFirstAttribute: boolean;
  tooltipPosition?: "top" | "bottom" | "right" | "left";
  label?: string;
  hideOperator?: boolean;
  searchField?: string;
  enableKeyboardShortcut: boolean;
};

export function AttributePill({
  isLastAttribute,
  isFirstAttribute,
  attributeName,
  identifier,
  tooltipPosition,
  label,
  hideOperator,
  searchField,
  enableKeyboardShortcut,
}: Props) {
  const { filterSelectorInitialOpen, isFilterUXValid, remove, getSelectedUXOperator, availableAttributes } =
    useAttributesContext();
  const attributeUXValid = isFilterUXValid(identifier);

  const [removeHover, setRemoveHover] = useState(false);
  const { reference, floating, getFloatingProps, getReferenceProps, open, strategy, x, y, context, setOpen } =
    usePopover({
      initialOpen: filterSelectorInitialOpen(identifier),
    });

  const filterPopoverProps = useFilterSelectorPopover({
    identifier,
    attributeName,
    context,
    setOpen,
  });

  useEffect(() => {
    if (open && removeHover) setRemoveHover(false);
  }, [open, removeHover]);

  return (
    <Styled.AttributePill>
      <Styled.PillContainer
        onMouseEnter={() => setRemoveHover(true)}
        onMouseLeave={() => setRemoveHover(false)}
        onMouseOver={() => setRemoveHover(true)}
        $borderRightSolidNoRadius={
          isLastAttribute &&
          Boolean(!availableAttributes[0]?.valuesWithId || availableAttributes[0]?.valuesWithId?.length)
        }
        $borderLeftNone={!isFirstAttribute}
        $borderRightNone={
          !(
            isLastAttribute &&
            !Boolean(!availableAttributes[0]?.valuesWithId || availableAttributes[0]?.valuesWithId?.length)
          )
        }
      >
        {!isFirstAttribute && (
          <Styled.BorderConnection>
            <Styled.PillConnection $reversed={true} />
          </Styled.BorderConnection>
        )}
        <Styled.AttributeFlex>
          <Styled.AttributeEditButton
            $noBorderLeft={isFirstAttribute}
            ref={reference}
            {...getReferenceProps()}
          >
            <AttributeMainBody
              attributeName={attributeName}
              identifier={identifier}
              selectedOperatorLabel={getSelectedUXOperator(identifier)?.label ?? ""}
              hideOperator={hideOperator}
            />
          </Styled.AttributeEditButton>
          <Styled.RemoveButton
            $hover={removeHover}
            onClick={() => remove(identifier)}
          >
            <Tooltip
              text={`Remove this ${label ? label : "filter"}`}
              position={tooltipPosition ?? "bottom"}
              offset={2}
            >
              <Icon
                icon="close"
                size={8}
              />
            </Tooltip>
          </Styled.RemoveButton>

          <Styled.RemoveBackground
            $removeHover={removeHover}
            $right={5}
          />
        </Styled.AttributeFlex>

        {!isLastAttribute && (
          <Styled.BorderConnection>
            <Styled.PillConnection />
          </Styled.BorderConnection>
        )}
      </Styled.PillContainer>

      {isLastAttribute && (!availableAttributes[0]?.valuesWithId || availableAttributes[0]?.valuesWithId?.length) ? (
        <AddAttributePill
          isTrailing={true}
          disabled={!attributeUXValid || !Boolean(availableAttributes.length)}
          disabledTooltip={
            !attributeUXValid
              ? `Finish editing ${label ? label : "filter"} before adding another.`
              : "Only one log type and description are allowed."
          }
          tooltipPosition={tooltipPosition ?? "bottom"}
          searchField={searchField}
          enableKeyboardShortcut={enableKeyboardShortcut}
        />
      ) : (
        <Fragment />
      )}
      {open && (
        <FloatingOverlay
          style={{
            zIndex: vars.zIndex.modalOverlay,
            width: "100vw",
            overflow: "inherit",
          }}
        >
          <div
            ref={floating}
            {...getFloatingProps()}
            style={{
              top: y ?? 0,
              left: x ?? 0,
              width: "25rem",
              position: strategy,
              zIndex: vars.zIndex.modalContent,
              overflow: "inherit",
            }}
          >
            <FilterSelectorPopover {...filterPopoverProps} />
          </div>
        </FloatingOverlay>
      )}
    </Styled.AttributePill>
  );
}

function AttributeMainBody({
  attributeName,
  identifier,
  selectedOperatorLabel,
  hideOperator,
}: {
  attributeName: string;
  identifier: number;
  selectedOperatorLabel: string;
  hideOperator?: boolean;
}) {
  const { isFilterUXValid, attributeHasDefinedFilter, getAttributeIcon, getAttributeValuePresentation } =
    useAttributesContext();
  const attributeUXValid = isFilterUXValid(identifier);

  return (
    <>
      <Styled.IconContainer $isError={!attributeUXValid}>{getAttributeIcon(attributeName)}</Styled.IconContainer>
      <Styled.AttributeTextFlex>
        <Styled.AttributeParagraph $isError={!attributeUXValid}>
          {attributeLabelFor(attributeName)}
        </Styled.AttributeParagraph>
        {attributeHasDefinedFilter(identifier) && (
          <>
            {attributeUXValid && !hideOperator && (
              <Styled.AttributeParagraph $isError={!attributeUXValid}>
                {selectedOperatorLabel}
              </Styled.AttributeParagraph>
            )}
            <Styled.AttributeParagraph $isError={!attributeUXValid}>
              {getAttributeValuePresentation(identifier)}
            </Styled.AttributeParagraph>
          </>
        )}
      </Styled.AttributeTextFlex>
    </>
  );
}
