import { useCallback, useEffect, useRef, useState } from "react";

import { AxiosError } from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import displayToast from "../../../../common/components/Toast/displayToast";
import { RoutePaths } from "../../../router/config/routePaths";
import { useResetPasswordDeps } from "../ResetPassword";

enum ErrorStatusCodes {
  CODE_EXPIRED = 404,
}

export const useResetPasswordApiConsumer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetCode = useRef(searchParams.get("code"));

  const { forgotPasswordService, resetPasswordService } = useResetPasswordDeps();

  const [email, setEmail] = useState<string>("");
  const [linkExpired, setLinkExpired] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const validateResetPasswordCode = useCallback(async () => {
    try {
      if (!resetCode.current) {
        navigate(RoutePaths.FORGOT_PASSWORD);
        return;
      }

      await resetPasswordService.checkPasswordCode(resetCode.current);
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<{ [key: string]: string | undefined }, unknown>;

        if (axiosError.response?.status === ErrorStatusCodes.CODE_EXPIRED && axiosError.response.data.message) {
          const userEmail = JSON.parse(axiosError.response.data.message).email;

          userEmail && setEmail(userEmail);

          userEmail && setLinkExpired(true);

          return;
        }

        displayToast({
          title: "Something went wrong",
          content: "Failed to validate reset code from email",
        });
      }
    }
  }, [resetPasswordService, navigate]);

  useEffect(() => {
    validateResetPasswordCode();
  }, [validateResetPasswordCode]);

  const requestPasswordResetLink = useCallback(
    async (isResend: boolean) => {
      if (!email) return;
      try {
        await forgotPasswordService.forgotPassword(email);

        setLinkSent(true);

        isResend &&
          displayToast({
            title: "Check your email",
            content: `Email successfully re-sent to ${email}.`,
          });
      } catch (error) {
        displayToast({ title: "Something went wrong", content: "Failed to send you reset link" });
      }
    },
    [email, forgotPasswordService],
  );

  return { requestPasswordResetLink, resetCode: resetCode.current, linkExpired, linkSent, email };
};
