import React, { useMemo } from "react";

import { generatePath, useParams } from "react-router-dom";

import ChooseSubscriptionImage from "../../../common/assets/images/FTUX/billing-choose-subscription.png";
import EmptyStateElement from "../../../common/components/EmptyStateElement/components/EmptyStateElement";
import { useNoSubscriptionConsumer } from "../../../common/components/EmptyStateElement/consumers/useNoSubscriptionConsumer";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import { App, PlatformName, WorkspaceData } from "../../../common/models";
import { Plan, PlanProductName } from "../../../common/models/billing";
import { getBillableApps } from "../../../common/util/appsUtil";
import { useAppContext } from "../../context/App/App.context";
import { useAppSelectionContext } from "../../context/App/AppSelectionContext";
import { RoutePaths } from "../../router/config/routePaths";

export default function NoSubscriptionPage() {
  const { selectedWorkspace } = useAppSelectionContext();
  const { workspaceSlug } = useParams();
  const { apps } = useAppContext();
  const { teamData, loading } = useNoSubscriptionConsumer();

  const buttonUrl = generatePath(RoutePaths.ADMINISTRATION_BILLING, { workspaceSlug: workspaceSlug ?? null });

  const activeApps = useMemo(() => getBillableApps(apps), [apps]);

  if (!selectedWorkspace || loading || !teamData) {
    return <Spinner />;
  }

  if (didWSReachLimit(selectedWorkspace.subscription_plans, activeApps, teamData)) {
    return (
      <EmptyStateElement
        title="Which plan & add-on to choose"
        subtitle="Your usage of Shake exceeds your current workspace subscription. You can always check your workspace numbers and pick the appropriate subscription."
        imageSrc={ChooseSubscriptionImage}
        button={{ url: buttonUrl, title: "See subscription options" }}
      />
    );
  }

  return <div />;
}

const didWSReachLimit = (subscriptionPlans: Plan[], activeApps: App[], teamData: WorkspaceData) => {
  const currentPlan = subscriptionPlans.find((item) => item.type === "plan");
  if (
    (!currentPlan || (currentPlan && currentPlan.product_name === PlanProductName.PREMIUM)) &&
    activeApps.filter((a) => a.platform.name !== PlatformName.WEB).length > 4
  ) {
    return true;
  }

  return didWSReachDevicesLimit(subscriptionPlans, teamData);
};

const didWSReachDevicesLimit = (subscriptionPlans: Plan[], teamData: WorkspaceData) => {
  const currentAddOn = subscriptionPlans.find((item) => item.type === "devices");
  if (!currentAddOn && teamData?.unique_devices > 10000) {
    return true;
  }
  if (currentAddOn && currentAddOn.nickname.includes("100K") && teamData?.unique_devices > 100000) {
    return true;
  }
  if (currentAddOn && currentAddOn.nickname.includes("1M") && teamData?.unique_devices > 1000000) {
    return true;
  }
  if (currentAddOn && currentAddOn.nickname.includes("10M") && teamData?.unique_devices > 10000000) {
    return true;
  }
  if (currentAddOn && currentAddOn.nickname.includes("50M") && teamData?.unique_devices > 50000000) {
    return true;
  }
  return false;
};
