import React from "react";

import * as Styled from "./PersonalInfoView.styles";
import Heading from "../../../../../common/components/Heading/Heading";
import PageTitle from "../../../../../common/components/PageTitle/PageTitle";
import PageWrap from "../../../../../common/components/PageWrap/PageWrap";
import identifiers from "../../../../../common/util/identifiers.json";
import BasicsCard from "../../components/BasicsCard/BasicsCard";
import { SecurityCard } from "../../components/SecurityCard/SecurityCard";

export default function PersonalInfoView() {
  return (
    <>
      <PageTitle>
        <Heading
          as="h1"
          heading1
          testId={identifiers["myAccount.header.info"]}
        >
          Personal info
        </Heading>
        <p>Everything about you and only you</p>
      </PageTitle>
      <PageWrap>
        <Styled.PersonalInfoViewCardsContainer>
          <BasicsCard />
          <SecurityCard />
        </Styled.PersonalInfoViewCardsContainer>
      </PageWrap>
    </>
  );
}
