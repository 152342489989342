import { useCallback } from "react";

import { App, Workspace } from "@common/models";
import { getRouteFromPath } from "@common/util/moduleRouteHelper";
import { removeAttributesFromLocalStorage } from "@common/util/removeAttributesFromLocalStorage";
import { setShakeSdkAppMetadata, setShakeSdkWorkspaceMetadata } from "@common/util/shakeSdkUtils";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

import { useAppSelectionContext } from "../context/App/AppSelectionContext";
import { RoutePaths } from "../router/config/routePaths";

export default function useWorkspaceAndAppChange() {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedWorkspace } = useAppSelectionContext();

  const handleWorkspaceChange = useCallback(
    async (workspace: Workspace) => {
      removeAttributesFromLocalStorage();
      localStorage.setItem("lastWorkspaceVisited", workspace.id);
      setShakeSdkWorkspaceMetadata(workspace);
      navigate(generatePath(RoutePaths.USER_FEEDBACK_MODULE_ROOT, { workspaceSlug: workspace.slug }));
    },
    [navigate],
  );

  const handleAppChange = useCallback(
    (app: App) => {
      if (!selectedWorkspace) return;
      // removeAttributesFromLocalStorage();
      setShakeSdkAppMetadata(app);
      const moduleAppRoute = getRouteFromPath(location.pathname);
      navigate(generatePath(moduleAppRoute, { workspaceSlug: selectedWorkspace.slug, appKey: app.key }));
    },
    [navigate, selectedWorkspace, location.pathname],
  );

  return { handleWorkspaceChange, handleAppChange };
}
