import React, { Fragment, useState } from "react";

import Dropdown, { DropdownItem } from "@common/components/Dropdown/Dropdown";
import { CellEnd, CenteredCell } from "@common/components/GridTable/GridTable";
import Paragraph from "@common/components/Paragraph/Paragraph";
import displayToast from "@common/components/Toast/displayToast";
import { Domain } from "@common/models/Domain.model";
import { vars } from "@common/styles";
import identifiers from "@common/util/identifiers.json";

import * as Styled from "../../ApprovedDomainsCard/ApprovedDomainsCard.styles";

interface Props {
  setSelectedDomain: (domain: Domain) => void;
  toggleDelete: () => void;
  domain: Domain;
  isAdmin: boolean;
}

export const DomainRow = ({ setSelectedDomain, toggleDelete, domain, isAdmin }: Props) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

  const handleClick = () => {
    if (!domain) return;
    navigator.clipboard.writeText(domain.domain);
    displayToast({
      content: "Domain copied to clipboard",
    });
    setIsDropdownOpened(false);
  };

  return (
    <Fragment>
      <CenteredCell
        minWidth="400"
        maxWidth="400"
      >
        <Styled.Globe />
        <span onDoubleClick={handleClick}>
          <Paragraph color={vars.colors.grey30}>{domain.domain}</Paragraph>
        </span>
      </CenteredCell>
      <CellEnd>
        <Paragraph color={vars.colors.grey60}>{domain.verified ? "Verified" : "Not verified"}</Paragraph>
      </CellEnd>
      <CellEnd>
        <Dropdown
          isOpened={isDropdownOpened}
          setIsOpened={setIsDropdownOpened}
        >
          <DropdownItem
            onClick={handleClick}
            testId={identifiers["general.dropdownButton.copy"]}
          >
            Copy
          </DropdownItem>
          {isAdmin && (
            <DropdownItem
              onClick={() => {
                setSelectedDomain(domain);
                setIsDropdownOpened(false);
                toggleDelete();
              }}
              error
            >
              Delete
            </DropdownItem>
          )}
        </Dropdown>
      </CellEnd>
    </Fragment>
  );
};
