import React, { useEffect, useRef, useState } from "react";

import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { omitObjectKeys } from "../../../util/omitProps";
import Input, { InputElementType, TextareaProps } from "../Input";

interface Props extends TextareaProps {
  defaultValue?: string;
  onEditConfirm?: (value: string) => void;
  testId?: string;
}

export default function EditableTitle(props: Props) {
  const [value, setValue] = useState(props.value?.toString() ?? props.defaultValue);
  const inputWrapperRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setValue(props.value?.toString());
  }, [props.value]);

  useOnClickOutside(inputWrapperRef, handleConfirmEvent);

  const handleKeydownEvent = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.stopPropagation();
      inputWrapperRef?.current?.blur();
      handleConfirmEvent();
    }
  };

  function handleConfirmEvent() {
    if (!value && props.defaultValue) {
      setValue(props.defaultValue);
      props.onEditConfirm?.(props.defaultValue);
      return;
    }

    value && props.value !== value && props.onEditConfirm?.(value);
  }

  const handleChangeEvent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <Input
      {...omitObjectKeys(props, ["defaultValue", "onEditConfirm"])}
      elementType={InputElementType.TEXTAREA}
      value={value}
      innerRef={inputWrapperRef}
      onKeyDown={handleKeydownEvent}
      onChange={handleChangeEvent}
      testId={props.testId}
      editableTitle
    />
  );
}
