import { useCallback, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Attribute } from "./sharedTypes";
import { useAppSelectionContext } from "../../../main/context/App/AppSelectionContext";

type Props = {
  storageKeyPrefix: string;
  defaultAttributes?: Attribute[];
  withoutSearchParams?: boolean;
};

export function useAppAttributes({ storageKeyPrefix, defaultAttributes = [], withoutSearchParams = true }: Props) {
  const { selectedApp } = useAppSelectionContext();
  const storageKey = selectedApp ? `${storageKeyPrefix}_${selectedApp?.id}` : null;
  const [searchParams, setSearchParams] = useSearchParams();
  const [attributes, setAttributes] = useState<Attribute[]>(() => {
    if (!storageKey) return;
    const urlSearch = searchParams.get("search");
    if (urlSearch && !withoutSearchParams) {
      try {
        const decodedAttributes = JSON.parse(decodeURIComponent(atob(urlSearch)));
        localStorage.setItem(storageKey, JSON.stringify({ attributes: decodedAttributes }));
        return decodedAttributes;
      } catch (error) {
        console.error("Error parsing URL search parameter:", error);
        return defaultAttributes;
      }
    }
    return defaultAttributes;
  });

  useEffect(() => {
    if (attributes && !withoutSearchParams) {
      const encodedAttributes = btoa(encodeURIComponent(JSON.stringify(attributes)));
      setSearchParams({ search: encodedAttributes });
    }
  }, [attributes, setSearchParams, withoutSearchParams]);

  useEffect(() => {
    if (!storageKey) return;
    const storedState = localStorage.getItem(storageKey);
    if (!storedState) {
      localStorage.setItem(storageKey, JSON.stringify({ attributes: defaultAttributes }));
      setAttributes(defaultAttributes);
      return;
    }
    setAttributes(JSON.parse(storedState).attributes);
    // eslint-disable-next-line
  }, [selectedApp]);

  const addAttribute = useCallback(
    (attribute: Attribute) => {
      if (!storageKey) return;
      const storedState = localStorage.getItem(storageKey);
      if (!storedState) {
        const newAttributes = [attribute];
        localStorage.setItem(storageKey, JSON.stringify({ attributes: newAttributes }));
        setAttributes(newAttributes);
        return;
      }
      const newAttributes = [...JSON.parse(storedState).attributes, attribute];
      localStorage.setItem(storageKey, JSON.stringify({ attributes: newAttributes }));
      setAttributes(newAttributes);
    },
    [storageKey],
  );

  const removeAttribute = useCallback(
    (atIndex: number) => {
      if (!storageKey) return;
      const storedState = localStorage.getItem(storageKey);
      if (!storedState) return;

      let newAttributes = [...JSON.parse(storedState).attributes];
      newAttributes.splice(atIndex, 1);
      newAttributes = newAttributes.map((attribute, index) => {
        if (index == 0) return { ...attribute, attributeOperator: undefined };
        return attribute;
      });

      localStorage.setItem(storageKey, JSON.stringify({ attributes: newAttributes }));
      setAttributes(newAttributes);
    },
    [storageKey],
  );

  const updateAttribute = useCallback(
    (atIndex: number, updated: Attribute) => {
      if (!storageKey) return;
      const storedState = localStorage.getItem(storageKey);
      if (!storedState) return;
      const newAttributes = [...JSON.parse(storedState).attributes];
      newAttributes.splice(atIndex, 1, updated);
      localStorage.setItem(storageKey, JSON.stringify({ attributes: newAttributes }));
      setAttributes(newAttributes);
    },
    [storageKey],
  );

  return {
    attributes: attributes || [],
    addAttribute,
    removeAttribute,
    updateAttribute,
  };
}
