import React, { PropsWithChildren } from "react";

import { useNavigate } from "react-router-dom";

import * as Styled from "./CenteredMessage.styles";
import ShakeIcon from "../../assets/icons/shake_icon.svg";
import { Flex } from "../../styles/reusable/Flex/Flex.styles";
import PageWrap from "../PageWrap/PageWrap";
import Paragraph from "../Paragraph/Paragraph";

export default function CenteredMessage({
  children,
  title,
  message,
  paragraph,
  isError,
}: PropsWithChildren<{
  title: string;
  message?: string;
  paragraph?: string;
  isError?: boolean;
}>) {
  const navigate = useNavigate();
  return (
    <PageWrap detailsView>
      <Styled.FullCenter>
        <Flex
          $column
          $justifyContent="start"
        >
          <Styled.ShakeImage
            src={ShakeIcon}
            alt="Shake Icon"
            width={40}
          />
          {title && (
            <Styled.Heading
              as="h2"
              $noMargin={Boolean(paragraph)}
            >
              {title}
            </Styled.Heading>
          )}
          {message && <Styled.Heading heading3>{message}</Styled.Heading>}
          {paragraph && (
            <Styled.Paragraph
              fontWeight={500}
              $noMargin={Boolean(children)}
            >
              {paragraph}
            </Styled.Paragraph>
          )}
          {children}
          {isError && <Styled.Button onClick={() => navigate("/")}>Return to dashboard</Styled.Button>}
        </Flex>
      </Styled.FullCenter>
    </PageWrap>
  );
}
