import React from "react";

import NotificationConfirmationView from "@main/pages/Account/views/NotificationConfirmationView/NotificationConfirmationView";

import { AccountService } from "./services/Account.service";
import NotificationsView from "./views/Notifications/NotificationsView";
import PersonalInfoView from "./views/PersonalInfo/PersonalInfoView";
import dependencyInjection from "../../../common/util/dependencyInjection";

interface Services {
  accountService: AccountService;
}

const accountService = new AccountService();

const [AccountDependencyProvider, useAccountDeps] = dependencyInjection<Services>({
  services: { accountService },
});

export const AccountInfoPage = () => {
  return (
    <AccountDependencyProvider>
      <PersonalInfoView />
    </AccountDependencyProvider>
  );
};

export const NotificationsPage = () => {
  return (
    <AccountDependencyProvider>
      <NotificationsView />
    </AccountDependencyProvider>
  );
};

export const NotificationsConfirmationPage = () => {
  return (
    <AccountDependencyProvider>
      <NotificationConfirmationView />
    </AccountDependencyProvider>
  );
};

export { useAccountDeps };
