import styled, { css } from "styled-components";

import { vars } from "../../../../../common/styles";
import { Form, FormLinks } from "../../../shared/styles/Form.styles";

export const WorkspaceCreateInfoText = styled.div`
  > * {
    color: ${vars.colors.grey60};
    margin-bottom: 0.5rem;
  }

  svg {
    width: 2rem;
  }
`;

export const WorkspaceCreateForm = styled(Form)<{ $isModal?: boolean }>`
  button {
    ${(props) =>
      props.$isModal &&
      css`
        width: 9rem;
      `}
  }
  ${(props) =>
    props.$isModal &&
    css`
      margin-top: 0;
    `}
`;
export const WorkspaceCreateFormLinks = styled(FormLinks)`
  margin-top: 2.4rem;
`;
