import { useCallback, useState } from "react";

import {
  CrashReportTableRowItem,
  IssueTableRowItem,
  RowType,
} from "../../common/components/MasterTable/models/MasterTableModel";
import displayToast from "../../common/components/Toast/displayToast";
import { FilteringBody } from "../../common/hooks/filtering/sharedTypes";
import { useAppSelectionContext } from "../context/App/AppSelectionContext";
import { mapCrashReportToTableRowItem } from "../pages/Crashes/consumers/useCrashReportsApiConsumer";
import { useCrashesDeps } from "../pages/Crashes/Crashes";
import { mapIssueToTableRowItem } from "../pages/UserFeedback/consumers/useUserFeedbackApiConsumer";
import { useUserFeedbackDeps } from "../pages/UserFeedback/UserFeedback";

export default function useFilterByTitleConsumer({
  row_type,
  selectedIds,
}: {
  row_type: RowType;
  selectedIds: string[];
}) {
  const { userFeedbackService } = useUserFeedbackDeps();
  const { crashesService } = useCrashesDeps();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const [mergeFilteredTickets, setMergeFilteredTickets] = useState<IssueTableRowItem[] | CrashReportTableRowItem[]>([]);
  const [loading, setLoading] = useState(false);

  const filterTicketsByTitle = useCallback(
    async (searchValue: string) => {
      setLoading(true);
      if (!selectedApp || !selectedWorkspace) return;
      try {
        const filterBody: FilteringBody[] = [
          {
            filter_type: "query",
            filter_value: "prefix",
            field_name: "title",
            field_value: searchValue,
          },
        ];

        if (row_type === RowType.USER_FEEDBACK) {
          const { data } = await userFeedbackService.getUserFeedbackTicketsByFilters(
            selectedWorkspace.id,
            selectedApp.id,
            0,
            100,
            filterBody,
          );
          const mappedIssues = data.items.map((i) => {
            return mapIssueToTableRowItem(i, selectedApp.id);
          });

          setMergeFilteredTickets(mappedIssues.filter((item) => !selectedIds.includes(item.id)));

          return;
        }
        const { data } = await crashesService.getCrashReportsByFilters(
          selectedWorkspace.id,
          selectedApp.id,
          0,
          100,
          filterBody,
        );
        const mapped = data.items.map((i) => {
          return mapCrashReportToTableRowItem(i);
        });
        setMergeFilteredTickets(mapped.filter((item) => !selectedIds.includes(item.id)));
      } catch (error) {
        // Display Error only in table flows
        displayToast({ title: "Something went wrong", content: "Please try again." });
      } finally {
        setLoading(false);
      }
      return;
    },
    [selectedApp, selectedWorkspace, userFeedbackService, crashesService, row_type, selectedIds],
  );

  return {
    filter: filterTicketsByTitle,
    filteredData: mergeFilteredTickets,
    setFilteredData: setMergeFilteredTickets,
    loading,
  };
}
