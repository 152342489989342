import React from "react";

import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { format, isSameDay } from "date-fns";
import { Line } from "react-chartjs-2";

import Paragraph from "../../../../../common/components/Paragraph/Paragraph";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import { Analytics, TimeRange } from "../../../../../common/models/Analytics.model";
import { vars } from "../../../../../common/styles";
import { Flex } from "../../../../../common/styles/reusable/Flex/Flex.styles";
import {
  formatLabels,
  formatTooltipDateLabels,
  getMaxValue,
  getMinValue,
  nFormatter,
} from "../../helpers/analyticsHelper";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface LineChartProps {
  data: Analytics[];
  loading: boolean;
  time: TimeRange;
}

export default function LineChart({ data, loading, time }: LineChartProps) {
  const max = getMaxValue(data, true) && getMaxValue(data, true) !== 0 ? getMaxValue(data, true) : 10;
  const min = getMinValue(data, true) ?? 0;
  const stepSize = max ? Math.round(max / 2) : 0;

  const data1: ChartData<"line"> = {
    labels: formatLabels(
      data.map((rec) => rec.time),
      time,
      true,
    ),
    datasets: [
      {
        label: "Dataset 1",
        data: getData(data),
        fill: "start",
        pointRadius: 0,
        pointHoverBackgroundColor: vars.colors.mint,
        pointHoverBorderColor: vars.colors.grey100,
        pointHoverBorderWidth: 1,
        borderColor: vars.colors.mint,
        pointBackgroundColor: vars.colors.mint,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "rgba(97, 240, 182, 1)");
          gradient.addColorStop(1, "rgba(97, 240, 182, 0)");
          return gradient;
        },
        tension: 0.6,
        pointStyle: "circle",
        pointHitRadius: 2,
        borderCapStyle: "round",
      },
    ],
  };

  if (loading)
    return (
      <div style={{ height: "19.8rem" }}>
        <Spinner />
      </div>
    );

  if (!Boolean(data.length)) {
    return (
      <Flex
        style={{ height: "24.6rem" }}
        $justifyContent="center"
        $alignItems="center"
      >
        <Paragraph>No data available for this period</Paragraph>
      </Flex>
    );
  }

  return (
    <Line
      options={getChartOptions(min, max, stepSize)}
      data={data1}
    />
  );
}

const getChartOptions = (min: number, max: number, stepSize: number) => {
  return {
    aspectRatio: 1.85,
    responsive: true,
    interaction: {
      intersect: false,
      mode: "index", //ovo maknuti ako treba samo na hover tocke
    },
    clip: false,
    indexAxis: "x",
    scales: {
      y: {
        afterDataLimits: (scale) => {
          scale.max = max;
          scale.min = min;
        },
        display: true,
        ticks: {
          color: vars.colors.grey60,
          stepSize: stepSize, //calculate
          font: {
            family: vars.fonts.fontFamily,
            size: 12,
            weight: 500,
          },
          callback: function (label) {
            return nFormatter(Number(label));
          },
        },
        position: "right" as const,
        border: { dash: [4, 4], display: false }, // for the grid lines
        grid: {
          color: vars.colors.grey90, // for the grid lines
          tickColor: "transparent", // for the tick mark
          tickBorderDash: [0, 1],
          tickLength: 10,
          tickWidth: 1,
          drawTicks: true,
          drawOnChartArea: true,
        },
      },
      x: {
        ticks: {
          align: "inner",
          autoSkip: false,
          color: vars.colors.grey60,
          font: {
            family: vars.fonts.fontFamily,
            size: 12,
            weight: 500,
          },
          callback: function (label, index, labels) {
            const labelOfTick = this.getLabelForValue(label as number);
            if (index === 0) return labelOfTick;
            if (index === labels.length - 1) return format(new Date(), "LLL d, yyyy");
          },
        },
        border: { display: true, color: vars.colors.grey80 },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        backgroundColor: "rgba(44, 44, 46, 0.9)",
        borderColor: vars.colors.grey90,
        borderWidth: 1,
        boxPadding: 4,
        boxWidth: 6,
        boxHeight: 6,
        titleColor: vars.colors.white,
        titleMarginBottom: 4,
        titleFont: {
          family: vars.fonts.fontFamily,
          size: 14,
          weight: 500,
        },
        bodyColor: vars.colors.grey40,
        bodyFont: {
          family: vars.fonts.fontFamily,
          size: 14,
          weight: 500,
        },
        callbacks: {
          title: (xDatapoint: TooltipItem<"line">[]) => {
            return formatTooltipDateLabels(xDatapoint[0].label);
          },
          label: (yDatapoint: TooltipItem<"line">) => {
            return nFormatter(Number(yDatapoint.raw)) !== 1
              ? `${nFormatter(Number(yDatapoint.raw))} app users`
              : "1 app user";
          },
        },
      },
    },
  } as ChartOptions<"line">;
};

const getData = (data: Analytics[]) => {
  const newData: number[] = [];
  if (data && data.length) {
    data.map((data: Analytics) => {
      newData.push(data.cum_value);
    });
    if (!isSameDay(new Date(data[data.length - 1].time), new Date())) newData.push(data[data.length - 1].cum_value);
  }

  return newData;
};
