import { AppReducer } from "./App.reducer";
import { AppState } from "./App.types";
import makeContextStore from "../../../common/util/makeContextStore";

export const initialAppContextState: AppState = {
  workspaces: [],
  wsTeamMembers: undefined,
  joinableWorkspaces: [],
  apps: [],
  loading: true,
  updatingSelectedWorkspace: false,
  socket: undefined,
  workspacesLoading: true,
  unreadChangeLogNotifications: [],
};

const [AppContextProvider, useAppContext, useAppDispatch] = makeContextStore(AppReducer, initialAppContextState);

export { AppContextProvider, useAppContext, useAppDispatch };
