import { useCallback, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import displayToast from "../../../../common/components/Toast/displayToast";
import { RoutePaths } from "../../../router/config/routePaths";
import { useCannyAuthDeps } from "../CannyAuth";

interface CannyAuthApiConsumer {
  cannyRedirectUrl: string;
}

export default function useCannyAuthApiConsumer({ cannyRedirectUrl }: CannyAuthApiConsumer) {
  const navigate = useNavigate();
  const { cannyAuthService } = useCannyAuthDeps();

  const tryToCannyAuthAndRedirect = useCallback(() => {
    if (!cannyRedirectUrl) {
      return navigate(RoutePaths.ROOT, { replace: true });
    }

    return cannyAuthService
      .authenticateCanny()
      .then(({ data }) => {
        const cannyWithSso = new URL(cannyRedirectUrl);
        cannyWithSso.searchParams.append("ssoToken", data.token);
        window.location.replace(cannyWithSso.toString());
      })
      .catch(() => {
        displayToast({ id: "canny-auh", title: "Authentication failed", content: "Failed to signin to Canny." });
        return navigate(RoutePaths.ROOT, { replace: true });
      });
  }, [cannyRedirectUrl, cannyAuthService, navigate]);

  useEffect(() => {
    (() => tryToCannyAuthAndRedirect())();
  }, [tryToCannyAuthAndRedirect]);
}
