import React, { Fragment, useEffect } from "react";

import background from "@common/assets/images/empty-state/users-module-empty-heading.png";
import HouseIcon from "@common/assets/images/FTUX/house-icon.svg";
import CodeBlock, { Tab } from "@common/components/CodeBlock/CodeBlock";
import {
  TabController,
  useAndroidTabController,
  useFlutterTabController,
  useIosTabController,
  useReactNativeTabController,
  useWebTabController,
} from "@common/components/CodeBlock/useCodeTabsController";
import { HelperText } from "@common/components/HelperText/HelperText";
import Paragraph from "@common/components/Paragraph/Paragraph";
import displayToast from "@common/components/Toast/displayToast";
import Toast from "@common/components/Toast/Toast";
import { PlatformName } from "@common/models";
import { docLink } from "@common/util/docs";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { UsersModuleDescriptionBox } from "@main/pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";
import {
  androidUsersSetupSteps,
  flutterUsersSetupSteps,
  iosUsersSetupSteps,
  rnUsersSetupSteps,
  webUsersSetupSteps,
} from "@main/pages/shared/components/ModuleEmptyState/helpers/usersSetupSteps";
import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath, useNavigate } from "react-router-dom";

import * as Styled from "./ModuleEmptyState.styles";
import { SetupStep } from "../SetupStep/SetupStep";

const USERS_SOMETHING_NOT_WORKING_TOAST = "users-something-not-working";

export const UsersModuleEmptyState = () => {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedWorkspace) return;
    displayToast({
      icon: HouseIcon,
      containerId: USERS_SOMETHING_NOT_WORKING_TOAST,
      title: "Something not working?",
      content: (
        <Fragment>
          Whenever you need help, visit{" "}
          {
            <Styled.StyledLink
              onClick={() => navigate(generatePath(RoutePaths.INFO_ROOT, { workspaceSlug: selectedWorkspace.slug }))}
            >
              Info
            </Styled.StyledLink>
          }{" "}
          where you will find links to help center, our Slack community and to private chat with our support engineers.
        </Fragment>
      ),
    });
  }, [navigate, selectedWorkspace]);

  const androidTabController = useAndroidTabController();
  const iosTabController = useIosTabController();
  const reactNativeTabController = useReactNativeTabController();
  const flutterTabController = useFlutterTabController();
  const webTabController = useWebTabController();

  switch (selectedApp?.platform?.name) {
    case PlatformName.ANDROID:
      return (
        <EmptyUserModuleView
          codeBlockTabs={androidUsersSetupSteps.registerUserCode}
          tabController={androidTabController}
        />
      );
    case PlatformName.IOS:
      return (
        <EmptyUserModuleView
          codeBlockTabs={iosUsersSetupSteps.registerUserCode}
          tabController={iosTabController}
        />
      );
    case PlatformName.REACT_NATIVE:
      return (
        <EmptyUserModuleView
          codeBlockTabs={rnUsersSetupSteps.registerUserCode}
          tabController={reactNativeTabController}
        />
      );
    case PlatformName.FLUTTER:
      return (
        <EmptyUserModuleView
          codeBlockTabs={flutterUsersSetupSteps.registerUserCode}
          tabController={flutterTabController}
        />
      );
    case PlatformName.WEB:
      if (selectedApp?.is_extension) return <EmptyUserModuleExtensionView />;
      return (
        <EmptyUserModuleView
          codeBlockTabs={webUsersSetupSteps.registerUserCode}
          tabController={webTabController}
        />
      );
    default:
      return null;
  }
};

export interface Props {
  codeBlockTabs: Tab[];
  tabController: TabController;
}

const EmptyUserModuleView = ({ codeBlockTabs, tabController }: Props) => {
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <UsersModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={"Register app users"}
        />
        <Styled.StepContent>
          <CodeBlock
            tabController={tabController}
            tabs={codeBlockTabs}
          />
          <Paragraph
            lineHeight={26}
            marginTop={12}
            marginBottom={60}
          >
            {"And that’s it — your app users will start appearing here as soon as they sign into your app."}
          </Paragraph>
        </Styled.StepContent>
      </Styled.Container>
      <Toast
        containerId={USERS_SOMETHING_NOT_WORKING_TOAST}
        autoClose={false}
      />
    </Styled.BackgroundContainer>
  );
};

const EmptyUserModuleExtensionView = () => {
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <UsersModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <Styled.StepContent>
          <HelperText>
            {"This module is not available through the Chrome extension."}
            <br />
            {"If you want to use App users, "}
            {<a href={docLink(PlatformName.WEB).installation}>add the Shake SDK</a>}
            {" to your codebase."}
          </HelperText>
        </Styled.StepContent>
      </Styled.Container>
      <Toast
        containerId={USERS_SOMETHING_NOT_WORKING_TOAST}
        autoClose={false}
      />
    </Styled.BackgroundContainer>
  );
};
