import styled from "styled-components";

import { vars } from "../../styles";
import { Flex as BaseFlex } from "../../styles/reusable/Flex/Flex.styles";
import BaseTooltip from "../Tooltip/Tooltip";

export const Tooltip = styled(BaseTooltip)`
  width: auto;
`;

export const Flex = styled(BaseFlex)`
  width: 100%;
`;

export const Banner = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 0.8rem;
  padding: 2px 6px;
  border-radius: 1.6rem;
  background-color: ${vars.colors.red};
  color: ${vars.colors.black};
  font-size: 1.2rem;
`;
