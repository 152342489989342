import styled from "styled-components";

import ButtonComponent from "../../../../../common/components/Button/Button";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionsContainer = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  padding: 1rem;
  margin: 1rem 0 1rem;

  > * {
    margin-right: 2.4rem;
  }

  > *:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

export const Button = styled(ButtonComponent)`
  align-self: flex-end;
`;
