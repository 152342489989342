import React, { Fragment } from "react";

import PageWrap from "../../../../../common/components/PageWrap/PageWrap";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import { isNativePlatform } from "../../../../../common/models";
import { useAppContext } from "../../../../context/App/App.context";
import { useAppSelectionContext } from "../../../../context/App/AppSelectionContext";
import MappingFilesCard from "../../components/AppsCards/MappingFilesCard";
import NoAppsView from "../../components/NoAppsView";
import useAppsCrashesViewConsumer from "../../consumers/useAppsCrashesViewConsumer";

export default function AppsCrashesView(): JSX.Element {
  const { loading: appsLoading } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();

  const { allApps, localSelectedApp, mappingFiles, refetchMissingDysms, deleteMappingFile, loading } =
    useAppsCrashesViewConsumer();

  if (!appsLoading && allApps.length === 0) {
    <NoAppsView selectedWorkspace={selectedWorkspace} />;
  }

  if (!localSelectedApp) {
    return <Spinner />;
  }

  const localLoading = appsLoading || loading;

  return (
    <PageWrap style={{ marginTop: "4rem" }}>
      {isNativePlatform(localSelectedApp) && (
        <Fragment>
          <MappingFilesCard
            mappingFiles={mappingFiles}
            getMissingDsyms={refetchMissingDysms}
            deleteMappingFile={deleteMappingFile}
            selectedDropdownApp={localSelectedApp}
            disabled={localLoading}
            isSample={localSelectedApp.is_sample}
          />
        </Fragment>
      )}
    </PageWrap>
  );
}
