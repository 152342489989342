import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  flex: 1;
  margin-top: 4rem;
`;
