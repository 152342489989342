import styled from "styled-components";

import { vars } from "../../../../../common/styles";

export const BackgroundContainer = styled.div`
  //height: 100%;
  display: flex;
  flex: 1;
  cursor: default;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100rem auto;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 62rem;
  cursor: default;
`;

export const HeadingBoxWrapper = styled.div`
  margin-left: 8.2rem;
  margin-bottom: 5rem;
  margin-top: 9.5rem;
`;

export const StepContent = styled.div`
  margin-left: 8.2rem;
  margin-bottom: 3.8rem;

  a {
    text-decoration: underline;
    color: ${vars.colors.grey60};
  }
`;

export const StepReference = styled.span`
  position: relative;
  bottom: 1ex;
  font-size: 70%;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${vars.colors.grey60}; !important;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  color: ${vars.colors.grey70} !important;
`;

export const Image = styled.img`
  margin-top: 3rem;
  max-width: 40rem;
`;

export const Link = styled.a`
  color: ${vars.colors.grey40};
  text-decoration: none !important;
  cursor: pointer;
`;

export const StyledLink = styled.span`
  color: ${vars.colors.grey30};
  text-decoration: underline;
  cursor: pointer;
`;
