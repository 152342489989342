import { format, isValid, parseISO, subMilliseconds, subSeconds } from "date-fns";

import { DataType } from "./networkChartHelper";
import { BlackboxChartType, BlackboxData } from "../../../models/blackbox";
import { resolveBlackboxChartTime } from "../../../models/helpers/time/creationTime";
import { vars } from "../../../styles";

const getValue = (name: BlackboxChartType, value: string, divider?: number) => {
  if (value === "0" || !value) return "Unknown";
  switch (name) {
    case BlackboxChartType.CPU:
      return value + "%";
    case BlackboxChartType.MEMORY:
      if (!divider) return;
      const newValue = value.replaceAll(",", "");
      return `${((parseFloat(newValue) / divider) * 100).toFixed(0)}% • ${parseFloat(
        newValue,
      )} out of ${divider.toFixed(0)} MB`;
    case BlackboxChartType.APP_MEMORY:
      return parseFloat(value).toFixed(0) + "MB";
    case BlackboxChartType.STORAGE:
      if (!divider) return;
      const storageValue = value.replaceAll(",", "");
      return `${((parseFloat(storageValue) / divider) * 100).toFixed(2)}%`;
    case BlackboxChartType.BATTERY:
      return `${(parseFloat(value) * 100).toFixed(0)}%`;
    case BlackboxChartType.NETWORK:
      return value;
    case BlackboxChartType.ORIENTATION:
      return value;
  }
};

// eslint-disable-next-line
const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.color = "white";
    tooltipEl.style.zIndex = 999;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(0, -120%)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.padding = "8px 6px";
    tooltipEl.style.borderRadius = "6px";
    tooltipEl.style.border = `1px solid ${vars.colors.grey90}`;
    tooltipEl.style.backgroundColor = "rgba(44, 44, 46, 0.90)";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

// eslint-disable-next-line
export const externalTooltipHandler = (context: any, name: BlackboxChartType, keyValue?: number) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    // eslint-disable-next-line
    const bodyLines = tooltip.body.map((b: any) => b.lines);

    const tableHead = document.createElement("thead");

    // eslint-disable-next-line
    titleLines.forEach((title: any) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = "0px";

      const th = document.createElement("th");
      th.style.borderWidth = "0px";
      th.style.fontSize = "14px";
      th.style.fontWeight = "normal";
      th.style.fontFamily = vars.fonts.fontFamily;
      th.style.marginBottom = "4px";
      th.style.display = "flex";
      th.style.justifyContent = "flex-start";
      th.style.color = vars.colors.white;
      const text = document.createTextNode(resolveBlackboxChartTime(title));

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    // eslint-disable-next-line
    bodyLines.forEach((body: any, i: any) => {
      const colors = tooltip.labelColors[i];

      const value =
        name === BlackboxChartType.ORIENTATION
          ? tooltip.dataPoints[0]?.raw.myProperty
          : name === BlackboxChartType.BATTERY
          ? tooltip.dataPoints[0]?.raw.myProperty
          : name === BlackboxChartType.NETWORK
          ? tooltip.dataPoints[0]?.raw.myProperty
          : body[0];

      const text = document.createTextNode(`${name} ${getValue(name, value, keyValue)} `);

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = "0px";

      const td = document.createElement("td");
      td.style.borderWidth = "0px";

      td.style.fontSize = "14px";
      td.style.fontWeight = "normal";
      td.style.alignItems = "center";
      td.style.fontFamily = vars.fonts.fontFamily;
      td.style.color = vars.colors.grey40;
      td.style.wordBreak = "break-word";

      if (getValue(name, value, keyValue) !== "Unknown") {
        const span = document.createElement("span");
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = "2px";
        span.style.marginRight = "4px";
        span.style.width = "6px";
        span.style.height = "6px";
        span.style.borderRadius = "50%";
        span.style.display = "inline-block";

        td.appendChild(span);
      }

      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY, width } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.zIndex = 999;
  tooltipEl.style.minWidth = "100px";
  tooltipEl.style.left =
    positionX + tooltip.caretX < width / 2 - 100 ? positionX + tooltip.caretX + "px" : width / 2 - 100 + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
};

export const getBlackBoxData = (name: BlackboxChartType, data: BlackboxData[]) => {
  const chartHistory: BlackboxData[] = [];

  if (
    (name === BlackboxChartType.CPU ||
      name === BlackboxChartType.MEMORY ||
      name === BlackboxChartType.APP_MEMORY ||
      name === BlackboxChartType.STORAGE) &&
    data.length !== 120
  ) {
    for (let i = 0; i < 120 - data.length; i++) {
      const lastObject = chartHistory[i - 1] ? chartHistory[i - 1] : data[0];

      const newObject = { ...lastObject };
      if (!isValid(new Date(lastObject.time_occurred))) return;

      // Subtract 500 milliseconds from the time_occurred value
      const lastTime = format(parseISO(lastObject.time_occurred), "yyyy-MM-dd'T'HH:mm:ss.SSSSSS");

      newObject.time_occurred = format(subMilliseconds(new Date(lastTime), 500), "yyyy-MM-dd'T'HH:mm:ss.SSS");
      newObject.value = null;

      chartHistory.push(newObject);
    }

    // Append the known objects to the end of the array
    return chartHistory.reverse().concat(data);
  }

  if ((name === BlackboxChartType.NETWORK || name === BlackboxChartType.ORIENTATION) && data.length !== 30) {
    for (let i = 0; i < 30 - data.length; i++) {
      const lastObject = chartHistory[i - 1] ? chartHistory[i - 1] : data[0];

      const newObject = { ...lastObject };

      // Subtract 2 seconds from the time_occurred value
      const lastTime = new Date(lastObject.time_occurred);
      const newTime = subSeconds(lastTime, 2);
      newObject.time_occurred = format(newTime, "yyyy-MM-dd'T'HH:mm:ss.MS").toString();
      newObject.value = null;

      chartHistory.push(newObject);
    }

    // Append the known objects to the end of the array
    return chartHistory.reverse().concat(data);
  }

  if (name === BlackboxChartType.BATTERY && data.length !== 30) {
    for (let i = 0; i < 30 - data.length; i++) {
      const lastObject = chartHistory[i - 1] ? chartHistory[i - 1] : data[0];

      const newObject = { ...lastObject };

      // Subtract 2 seconds from the time_occurred value
      const lastTime = new Date(lastObject.time_occurred);
      const newTime = subSeconds(lastTime, 2);
      newObject.time_occurred = format(newTime, "yyyy-MM-dd'T'HH:mm:ss.MS").toString();
      newObject.battery_level = null;

      chartHistory.push(newObject);
    }

    // Append the known objects to the end of the array
    return chartHistory.reverse().concat(data);
  }

  return data;
};

export const getZeroData = (name: BlackboxChartType, data: BlackboxData[]) => {
  const chartHistory: BlackboxData[] = [];

  if (
    name === BlackboxChartType.CPU ||
    name === BlackboxChartType.MEMORY ||
    name === BlackboxChartType.APP_MEMORY ||
    name === BlackboxChartType.STORAGE ||
    name === BlackboxChartType.ORIENTATION
  ) {
    data.map((element) => {
      if (element.value === null) chartHistory.push({ value: "0", time_occurred: element.time_occurred });
    });

    return chartHistory;
  }

  if (name === BlackboxChartType.BATTERY) {
    data.map((element) => {
      if (element.battery_level === null)
        chartHistory.push({ battery_level: "0", value: "0", time_occurred: element.time_occurred });
    });

    return chartHistory;
  }
  return data;
};

export const divideByConsecutiveValues = (data: DataType[]) => {
  const result: DataType[][] = [];
  let currentGroup: DataType[] = [];

  for (let i = 0; i < data.length; i++) {
    if (i === 0 || (data[i] && data[i - 1] && data[i].myProperty === data[i - 1].myProperty)) {
      currentGroup.push(data[i]);
    } else {
      result.push(currentGroup);
      currentGroup = [data[i]];
    }
  }

  result.push(currentGroup);

  return result;
};
