import React, { ReactElement, useEffect, useRef } from "react";

import { useAuthContext } from "main/context/Auth/Auth.context";
import { rememberUrlSrcParam } from "main/pages/shared/components/ChromeExtensionLoginDialog/ChromeExtensionLoginDialog";
import { RoutePaths } from "main/router/config/routePaths";
import { useNavigate } from "react-router-dom";

interface Props {
  children: ReactElement;
}

export default function RedirectIfAuthenticated({ children }: Props) {
  const { userToken } = useAuthContext();
  const navigate = useNavigate();

  const navigateToRootRef = useRef(() => {
    if (userToken) {
      rememberUrlSrcParam();
      navigate(RoutePaths.ROOT, { replace: true });
    }
  });

  useEffect(() => {
    navigateToRootRef.current();
  }, []);

  return <>{children}</>;
}
