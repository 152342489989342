import { useEffect, useState } from "react";

import displayToast from "../../../../common/components/Toast/displayToast";
import { useForm } from "../../../../common/hooks/useForm";
import { Customer } from "../../../../common/models/billing";
import { validateEmail } from "../../../../common/util/ValidatorFunctions";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useAdministrationDeps } from "../Administration";

export interface CustomizeBillingFormState {
  billingEmail: string;
  extraInfo: string;
}

const CustomizeBillingInitialState: CustomizeBillingFormState = {
  billingEmail: "",
  extraInfo: "",
};

const validators = {
  billingEmail: validateEmail,
};

interface Props {
  customer?: Customer;
  toggle: () => void;
}

export const useCustomizeBillingFormConsumer = ({ customer, toggle }: Props) => {
  const { selectedWorkspace } = useAppSelectionContext();
  const { administrationService } = useAdministrationDeps();
  const [loading, setLoading] = useState(false);

  const customizeBilling = async () => {
    if (!selectedWorkspace) return;

    setLoading(true);
    try {
      const partialCustomer = { email: formState.billingEmail, extra_info: formState.extraInfo };
      await administrationService.changeCustomerInfo(selectedWorkspace.id, partialCustomer);

      displayToast({
        title: "Billing details updated",
        content: "All the changes have been saved and you're good to go.",
      });
      toggle();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toggle();

      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "There was an error while updating billing details",
      });
    }
  };

  const { handleFormChange, formError, formState, handleFormSubmit } = useForm<CustomizeBillingFormState>({
    initialState: CustomizeBillingInitialState,
    onSubmit: customizeBilling,
    validators: validators,
  });

  useEffect(() => {
    if (customer) {
      setLoading(true);

      handleFormChange("billingEmail", customer.email);
      handleFormChange("extraInfo", customer.extra_info ?? "");

      setLoading(false);
    }
    return () => {
      setLoading(false);
    };
  }, [customer, handleFormChange]);

  return { formState, formError, handleFormChange, handleFormSubmit, loading };
};
