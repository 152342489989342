import React from "react";

import ShakeIcon from "@common/assets/icons/shake_icon.svg";
import Button from "@common/components/Button/Button";
import { CenteredForm } from "@common/components/CenteredForm/CenteredForm";
import { t } from "@common/util/translator";
import useNotificationsConfirmationConsumer from "@main/pages/Account/consumers/useNotificationsConfirmationConsumer";
import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath, useNavigate } from "react-router-dom";

import * as Styled from "./NotificationConfirmationView.styles";
import FormTitle from "../../../shared/components/Forms/FormTitle/FormTitle";

export default function NotificationConfirmationView() {
  const navigate = useNavigate();
  const { member } = useNotificationsConfirmationConsumer();

  if (!member) {
    return (
      <CenteredForm>
        <Styled.NotificationConfirmationViewBoxWrap>
          <img
            src={ShakeIcon}
            alt="Shake Logo"
          />
          <h1>{t("administration.members.approvedDomain.activation.error_title")}</h1>
        </Styled.NotificationConfirmationViewBoxWrap>
      </CenteredForm>
    );
  }

  return (
    <CenteredForm>
      <Styled.NotificationConfirmationViewBoxWrap>
        <FormTitle
          title="Good to go!"
          subtitle={"You will continue to receive email notifications"}
        />
        <Button
          size="full"
          onClick={() => {
            navigate(generatePath(RoutePaths.ROOT));
          }}
        >
          {t("administration.members.approvedDomain.activation.button")}
        </Button>
      </Styled.NotificationConfirmationViewBoxWrap>
    </CenteredForm>
  );
}
