import React, { Fragment, useState } from "react";

import { helpCenterArticles, showHelpCenterArticle } from "@common/util/helpCenterArticles";
import { generatePath, useParams } from "react-router-dom";

import { Helper, ModeIcon, Subtitle, Tooltip, UserModeDropdown } from "./UserDetails.styles";
import { DetailsPane } from "../../../../common/components/DetailsPane/DetailsPane";
import { getDropdown, GroupedInfoElement, readMetadata } from "../../../../common/components/DetailsPane/helper";
import { UserIcon } from "../../../../common/components/DetailsPane/icons";
import { DropdownItem } from "../../../../common/components/Dropdown/Dropdown";
import Heading from "../../../../common/components/Heading/Heading";
import PageWrap from "../../../../common/components/PageWrap/PageWrap";
import { Spinner } from "../../../../common/components/Spinner/Spinner";
import { useUsersAttributes } from "../../../../common/hooks/filtering/modules/useUsersAttributes";
import { useCustomElementInjection } from "../../../../common/hooks/useCustomElementInjection";
import { AppUser, getUserName, PlatformName } from "../../../../common/models";
import { Flex } from "../../../../common/styles/reusable/Flex/Flex.styles";
import { docLink } from "../../../../common/util/docs";
import identifiers from "../../../../common/util/identifiers.json";
import useNotificationsApiConsumer from "../../../consumers/useNotificationsApiConsumer";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import NavigationCustomElement from "../../../layouts/components/PageNavigation/NavigationCustomElement/NavigationCustomElement";
import { ELEMENT_CONTAINER_ID, NAV_LIST } from "../../../layouts/components/PageNavigation/PageNavigation";
import { RoutePaths } from "../../../router/config/routePaths";
import { TicketDetailsContainer } from "../../shared/components/TicketDetails/TicketDetailsContainer";
import { TicketContainer } from "../../shared/components/TicketDetails/TicketDetailsContainer.styles";
import { TicketInfoContainer } from "../../shared/components/TicketDetails/TicketInfoContainer";
import * as Icon from "../../shared/icons/icons";
import * as Styled from "../../shared/styles/DetailsWrapper.styles";
import UserTicketCards from "../components/UserTicketCards";
import useUserDetailsPageApiConsumer from "../consumers/useUserDetailsPageApiConsumer";

export default function UserDetailsView() {
  const params = useParams<{ userId: string }>();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const consumer = useUserDetailsPageApiConsumer({ userId: params.userId });
  const { unreadNotifications } = useNotificationsApiConsumer();
  const [detailDropdownOpened, setDetailDropdownOpened] = useState<string | undefined>(undefined);
  const [modeDropdownOpened, setModeDropdownOpened] = useState<boolean>(false);

  const { addExternal } = useUsersAttributes();

  const CustomElement = (
    <NavigationCustomElement>
      <Styled.DetailsTitle data-testid={identifiers["navbar.detailsTitle"]}>
        {consumer.user && <p>{`${getUserName(consumer.user)}`}</p>}
      </Styled.DetailsTitle>
    </NavigationCustomElement>
  );

  useCustomElementInjection({
    parentElementId: NAV_LIST,
    element: CustomElement,
    containerId: ELEMENT_CONTAINER_ID,
    tagName: "li",
  });

  if (!selectedWorkspace || !selectedApp || !consumer.user) {
    return <Spinner />;
  }

  const handleChangeUserMode = (isBanned?: boolean) => {
    consumer.changeUserMode(isBanned);
    setModeDropdownOpened(false);
  };

  const addToFilterObject = {
    addToFilter: addExternal,
    redirectURL: generatePath(RoutePaths.USERS, {
      workspaceSlug: selectedWorkspace?.slug,
      appKey: selectedApp?.key,
    }),
  };

  const userName = getUserName(consumer.user);

  return (
    <PageWrap>
      <TicketContainer>
        <TicketInfoContainer>
          <Heading
            as="h2"
            heading2
            testId={identifiers["central.column.users.header"]}
            marginBottom={0}
          >
            {userName}
          </Heading>
          <Flex
            $gap={1}
            $alignItems="center"
            style={{ paddingTop: "2.8rem" }}
          >
            <Tooltip
              position="top"
              text="Set user mode"
            >
              <UserModeDropdown
                label={userModes.find((mode) => mode.value === consumer.user?.banned)?.label}
                iconComponent={getModeIcon(consumer.user.banned)}
                buttonElement={<Fragment />}
                position="left"
                isOpened={modeDropdownOpened}
                setIsOpened={setModeDropdownOpened}
              >
                <Subtitle fontSize={12}>Set user mode</Subtitle>
                {userModes.map((mode) => {
                  return (
                    <DropdownItem
                      key={mode.label}
                      onClick={() => handleChangeUserMode(mode.value)}
                    >
                      {getModeIcon(mode.value)}
                      {mode.label}
                    </DropdownItem>
                  );
                })}
              </UserModeDropdown>
            </Tooltip>
            {!consumer.user.banned && (
              <Helper>
                {"Learn more about"}&nbsp;
                <a onClick={() => showHelpCenterArticle(helpCenterArticles.blockUsers)}>{"blocking spammy users"}</a>
                {"."}
              </Helper>
            )}
          </Flex>
          <UserTicketCards
            userTickets={consumer.user.tickets}
            unreadNotifications={unreadNotifications}
          />
        </TicketInfoContainer>

        <TicketDetailsContainer>
          <DetailsPane
            info={getUserDetails(
              consumer.user,
              detailDropdownOpened,
              setDetailDropdownOpened,
              selectedApp?.platform.name,
              addToFilterObject,
            )}
            headerTestId={identifiers["users.details.pane.header"]}
          />
        </TicketDetailsContainer>
      </TicketContainer>
    </PageWrap>
  );
}

function getUserDetails(
  user?: AppUser | null,
  detailDropdownOpened?: string,
  setDetailDropdownOpened?: (opened?: string) => void,
  platformName?: PlatformName,
  addToFilterObject?: {
    addToFilter: (attributeName: string, filterValue: string) => void;
    redirectURL: string;
  },
): GroupedInfoElement[] {
  if (!user) return [];

  const keysToOmit = ["first_name", "last_name"];

  return [
    {
      title: "App user details",
      infoElements: [
        {
          infoElements: [
            {
              isError: false,
              Dropdown: getDropdown(
                user.end_user_id,
                "App user ID",
                <Icon.UserID style={{ width: "20px", height: "20px" }} />,
                detailDropdownOpened,
                setDetailDropdownOpened,
                undefined,
                "User ID",
                addToFilterObject,
                user.end_user_id,
              ),
              fullwidth: true,
            },
          ],
        },
      ],
    },
    {
      title: "App user metadata",
      infoElements: [
        { infoElements: readMetadata(user.metadata_, keysToOmit, detailDropdownOpened, setDetailDropdownOpened) },
      ],
      link: {
        tooltipText: "Set user metadata",
        link: docLink(platformName).addUserMetadata,
        isExternal: true,
      },
    },
  ];
}

const getModeIcon = (isBlocked?: boolean) => {
  if (isBlocked)
    return (
      <ModeIcon>
        <Icon.BlockedIcon />
      </ModeIcon>
    );
  return (
    <ModeIcon>
      <UserIcon />
    </ModeIcon>
  );
};

export const userModes = [
  { label: "Active", value: false },
  { label: "Blocked", value: true },
];
