import styled, { css } from "styled-components";

import { vars } from "../../../../../common/styles";

export const AnalyticsCard = styled.div`
  width: 52.1rem;
  height: 40.2rem;

  border-radius: 2rem;
  background-color: ${vars.colors.grey100};

  padding: 3.2rem 3.2rem 2.4rem;
`;

export const Container = styled.div<{ $noPaddingBottom?: boolean }>`
  padding: 2rem 0rem;

  ${(props) =>
    props.$noPaddingBottom &&
    css`
      padding-bottom: 0rem;
    `}
`;
