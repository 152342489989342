import React from "react";

import { WorkspaceJoinInvitationService } from "./service/WorkspaceJoinInvitationService";
import { WorkspaceJoinInvitationView } from "./views/WorkspaceJoinInvitationView";
import dependencyInjection from "../../../common/util/dependencyInjection";
import RedirectIfAuthenticated from "../shared/components/RedirectIfAuthenticated";

interface Services {
  invitationService: WorkspaceJoinInvitationService;
}

const invitationService = new WorkspaceJoinInvitationService();

const [WorkspaceJoinInvitationDependencyProvider, useWorkspaceJoinInvitationDeps] = dependencyInjection<Services>({
  services: { invitationService },
});

const WorkspaceJoinInvitationPage = () => {
  return (
    <RedirectIfAuthenticated>
      <WorkspaceJoinInvitationDependencyProvider>
        <WorkspaceJoinInvitationView />
      </WorkspaceJoinInvitationDependencyProvider>
    </RedirectIfAuthenticated>
  );
};

export { useWorkspaceJoinInvitationDeps, WorkspaceJoinInvitationPage };
