import React from "react";

import ShakeIcon from "../../../../common/assets/icons/shake_icon.svg";
import PageWrap from "../../../../common/components/PageWrap/PageWrap";
import { Centered } from "../../../../common/styles/reusable/Centered/Centered.styles";
import { useConfirmSignUpConsumer } from "../consumers/useConfirmSignUpConsumer";

export const ConfirmSignUpView = () => {
  useConfirmSignUpConsumer();

  return (
    <PageWrap>
      <Centered>
        <img
          src={ShakeIcon}
          alt="Shake Logo"
          height="100"
        />
      </Centered>
    </PageWrap>
  );
};
