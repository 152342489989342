import { IntegrationType } from "../../../../../common/models/integrations";
import { AsanaIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/AsanaIntegrationModalBody";
import { AzureIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/AzureIntegrationModalBody";
import { ClickupIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/ClickupIntegrationModalBody";
import { GithubIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/GithubIntegrationModalBody";
import { JiraIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/JiraIntegrationModal/JiraIntegrationModalBody";
import { JiraServerIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/JiraIntegrationModal/JiraServerIntegrationModalBody";
import { LinearIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/LinearIntegrationModalBody";
import { SlackIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/SlackIntegrationModalBody";
import { TrelloIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/TrelloIntegrationModalBody";
import { WebhookIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/WebhookIntegrationModalBody";
import { ZapierIntegrationModalBody } from "../ModalBody/UserFeedbackIntegrations/ZapierIntegrationModalBody";

export const integrationModalBodyMap = (type: IntegrationType) => {
  switch (type) {
    case IntegrationType.GITHUB:
      return {
        component: GithubIntegrationModalBody,
        type,
      };
    case IntegrationType.AZURE_DEVOPS:
      return {
        component: AzureIntegrationModalBody,
        type,
      };
    case IntegrationType.ASANA:
      return {
        component: AsanaIntegrationModalBody,
        type,
      };
    case IntegrationType.CLICKUP:
      return {
        component: ClickupIntegrationModalBody,
        type,
      };
    case IntegrationType.JIRA_CLOUD:
    case IntegrationType.JIRA:
      return {
        component: JiraIntegrationModalBody,
        type,
      };
    case IntegrationType.JIRA_SERVER:
      return {
        component: JiraServerIntegrationModalBody,
        type,
      };
    case IntegrationType.ZAPIER:
      return {
        component: ZapierIntegrationModalBody,
        type,
      };
    case IntegrationType.TRELLO:
      return {
        component: TrelloIntegrationModalBody,
        type,
      };
    case IntegrationType.SLACK:
      return {
        component: SlackIntegrationModalBody,
        type,
      };
    case IntegrationType.LINEAR:
      return {
        component: LinearIntegrationModalBody,
        type,
      };
    case IntegrationType.SENTRY:
      return {
        component: () => {
          return null;
        },
        type,
      };
    case IntegrationType.WEBHOOK:
      return {
        component: WebhookIntegrationModalBody,
        type,
      };
  }
};
