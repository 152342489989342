import React, { Fragment, useEffect } from "react";

import background from "@common/assets/images/empty-state/crashes-module-empty-heading.png";
import HouseIcon from "@common/assets/images/FTUX/house-icon.svg";
import CodeBlock, { Tab } from "@common/components/CodeBlock/CodeBlock";
import {
  TabController,
  useAndroidTabController,
  useIosTabController,
} from "@common/components/CodeBlock/useCodeTabsController";
import { HelperText } from "@common/components/HelperText/HelperText";
import Paragraph from "@common/components/Paragraph/Paragraph";
import displayToast from "@common/components/Toast/displayToast";
import Toast from "@common/components/Toast/Toast";
import { PlatformName } from "@common/models";
import { docLink } from "@common/util/docs";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { CrashesModuleDescriptionBox } from "@main/pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";
import {
  androidCrashesSetup,
  iosCrashesSetup,
} from "@main/pages/shared/components/ModuleEmptyState/helpers/crashesSetupSteps";
import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath, useNavigate } from "react-router-dom";

import * as Styled from "./ModuleEmptyState.styles";
import { SetupStep } from "../SetupStep/SetupStep";

export const CrashesModuleEmptyState = () => {
  const androidTabController = useAndroidTabController();
  const iosTabController = useIosTabController();

  const { selectedApp, selectedWorkspace } = useAppSelectionContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedWorkspace) return;
    displayToast({
      icon: HouseIcon,
      containerId: CRASHES_SOMETHING_NOT_WORKING_TOAST,
      title: "Something not working?",
      content: (
        <Fragment>
          Whenever you need help, visit{" "}
          {
            <Styled.StyledLink
              onClick={() => navigate(generatePath(RoutePaths.INFO_ROOT, { workspaceSlug: selectedWorkspace.slug }))}
            >
              Info
            </Styled.StyledLink>
          }{" "}
          where you will find links to help center, our Slack community and to private chat with our support engineers.
        </Fragment>
      ),
    });
  }, [navigate, selectedWorkspace]);

  if (selectedApp?.platform?.name === PlatformName.ANDROID)
    return (
      <EmptyCrashesModuleView
        codeBlock1Tabs={androidCrashesSetup.enableCrashesCode}
        codeBlock2Tabs={androidCrashesSetup.testCrashesCode}
        tabController={androidTabController}
      />
    );
  if (selectedApp?.platform?.name === PlatformName.IOS)
    return (
      <EmptyCrashesModuleView
        codeBlock1Tabs={iosCrashesSetup.enableCrashesCode}
        codeBlock2Tabs={iosCrashesSetup.testCrashesCode}
        tabController={iosTabController}
      />
    );
  if (selectedApp?.platform?.name === PlatformName.WEB) return <EmptyCrashesModuleWebView />;

  return null;
};

export interface Props {
  codeBlock1Tabs: Tab[];
  codeBlock2Tabs: Tab[];
  tabController: TabController;
}

const CRASHES_SOMETHING_NOT_WORKING_TOAST = "crashes-something-not-working";

const EmptyCrashesModuleView = ({ codeBlock1Tabs, codeBlock2Tabs, tabController }: Props) => {
  const { selectedApp } = useAppSelectionContext();
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <CrashesModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={"Enable crash reports"}
        />
        <Styled.StepContent>
          <CodeBlock
            tabController={tabController}
            tabs={codeBlock1Tabs}
          />
        </Styled.StepContent>
        <SetupStep
          number={2}
          title={"Add deobfuscation files"}
        />
        <Styled.StepContent>
          <Paragraph lineHeight={26}>
            To ensure that crash reports are readable, you should upload the necessary symbolication or mapping files to
            Shake. Please refer to the documentation for{" "}
            {<a href={docLink(selectedApp?.platform.name).deobfuscation}>instructions on how to upload these files</a>}.
          </Paragraph>
        </Styled.StepContent>
        <SetupStep
          number={3}
          title={"Test it"}
        />
        <Styled.StepContent>
          <Paragraph
            lineHeight={26}
            marginBottom={12}
          >
            {"Now crash your app to see what the crash report looks like on your Shake dashboard."}
          </Paragraph>
          <CodeBlock
            tabController={tabController}
            tabs={codeBlock2Tabs}
          />
          <Paragraph
            lineHeight={26}
            marginTop={12}
            marginBottom={60}
          >
            {"Reopen your app and the crash report will be sent to you instantly and silently."}
          </Paragraph>
        </Styled.StepContent>
      </Styled.Container>
      <Toast
        containerId={CRASHES_SOMETHING_NOT_WORKING_TOAST}
        autoClose={false}
      />
    </Styled.BackgroundContainer>
  );
};

const EmptyCrashesModuleWebView = () => {
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <CrashesModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <Styled.StepContent>
          <HelperText>{"This module is available only for native iOS and Android apps."}</HelperText>
        </Styled.StepContent>
      </Styled.Container>
      <Toast
        containerId={CRASHES_SOMETHING_NOT_WORKING_TOAST}
        autoClose={false}
      />
    </Styled.BackgroundContainer>
  );
};
