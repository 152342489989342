import { useCallback } from "react";

import displayToast from "../../common/components/Toast/displayToast";
import { registerShakeSdkUser } from "../../common/util/shakeSdkUtils";
import { useAuthDeps } from "../App.dependencies";
import { setJoinableWorkspaces, setWorkspacesAction } from "../context/App/App.actions";
import { useAppDispatch } from "../context/App/App.context";
import { setUserDataAction } from "../context/Auth/Auth.actions";
import { useAuthDispatch } from "../context/Auth/Auth.context";
import { USER_DATA_LOCAL_STORAGE_KEY } from "../context/Auth/Auth.types";

export default function useUserDataApiConsumer() {
  const { authService } = useAuthDeps();

  const authDispatch = useAuthDispatch();
  const appDispatch = useAppDispatch();

  const fetchUserDataAndDispatch = useCallback(async () => {
    try {
      const { data } = await authService.getMe();
      const { teams: workspaces, joinable_teams: joinableWorkspaces, ...userData } = data;

      localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(userData));
      authDispatch(setUserDataAction(userData));
      appDispatch(setWorkspacesAction(workspaces));
      appDispatch(setJoinableWorkspaces(joinableWorkspaces));

      registerShakeSdkUser(data);

      return { userData, workspaces };
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });

      throw error;
    }
  }, [authService, authDispatch, appDispatch]);

  return { fetchUserDataAndDispatch };
}
