import { AuthContextActionType, SetUserData, SetUserToken } from "./Auth.types";
import { UserData, UserToken } from "../../../common/models";

export const setUserDataAction = (payload?: UserData): SetUserData => ({
  type: AuthContextActionType.SET_USER_DATA,
  payload,
});

export const setUserTokenAction = (payload?: UserToken): SetUserToken => ({
  type: AuthContextActionType.SET_USER_TOKEN,
  payload,
});
