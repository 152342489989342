import { ReactNode } from "react";
import React from "react";

import * as Styled from "./TicketDetailsContainer.styles";
import PageContent from "../../../../../common/components/PageContent/PageContent";
import { ScrollContainer } from "../../../../../common/components/ScrollContainer/ScrollContainer";

export const TicketInfoContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Styled.TicketInfoWrap>
      <ScrollContainer>
        <PageContent width={640}>{children}</PageContent>
      </ScrollContainer>
    </Styled.TicketInfoWrap>
  );
};
