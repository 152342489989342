import { RoutePaths } from "./routePaths";
import identifiers from "../../../common/util/identifiers.json";
import {
  AccountLayout,
  AdministrationLayout,
  EmptyOutletLayout,
  InfoLayout,
  MainLayout,
  RootLayout,
} from "../../layouts/Layouts";
import { AppLevelLayout } from "../../layouts/views/AppLevelLayout";
import { ModuleLayoutView } from "../../layouts/views/ModuleLayoutView";
import { WorkspaceRootLayout } from "../../layouts/views/WorkspaceRootLayout";
import { AccountInfoPage, NotificationsConfirmationPage, NotificationsPage } from "../../pages/Account/Account";
import { AddAppPage } from "../../pages/AddApp/AddAppPage";
import {
  AdministrationAppDomainVerificationPage,
  AdministrationBillingPage,
  AdministrationCommonCommentsPage,
  AdministrationDomainVerificationPage,
  AdministrationExtensionsPage,
  AdministrationGeneralPage,
  AdministrationMembersPage,
} from "../../pages/Administration/Administration";
import AppsCrashesView from "../../pages/Administration/views/AppsView/AppsCrashesView";
import AppsFeedbackView from "../../pages/Administration/views/AppsView/AppsFeedbackView";
import AppsGeneralView from "../../pages/Administration/views/AppsView/AppsGeneralView";
import { AppsViewModule } from "../../pages/Administration/views/AppsView/AppsModuleLayout";
import AppsRedirect from "../../pages/Administration/views/AppsView/AppsRedirect";
import AppsUsersView from "../../pages/Administration/views/AppsView/AppsUsersView";
import { AnalyticsPage } from "../../pages/Analytics/Analytics";
import { AnalyticsModulePage } from "../../pages/Analytics/views/AnalyticsModulePage";
import { CannyAuthPage } from "../../pages/Canny/CannyAuth";
import { ChangeEmailConfirmationPage } from "../../pages/ChangeEmailConfirmation/ChangeEmailConfirmation";
import { ConfirmSignUpPage } from "../../pages/ConfirmSignUp/ConfirmSignUp";
import {
  CrashesPage,
  CrashEventActivityHistoryPage,
  CrashEventPage,
  CrashOverviewPage,
} from "../../pages/Crashes/Crashes";
import { CrashesModulePage } from "../../pages/Crashes/views/CrashesModulePage";
import { ForgotPasswordPage } from "../../pages/ForgotPassword/ForgotPassword";
import { AppDevelopmentModule } from "../../pages/Info/views/AppDevelopment/AppDevelopmentModule";
import { FeaturesLatestPage, FeaturesPopularPage } from "../../pages/Info/views/Features/Features";
import { FeaturesModule } from "../../pages/Info/views/Features/FeaturesModule";
import HowToAll from "../../pages/Info/views/HowTo/HowToAll";
import HowToCustomerSupport from "../../pages/Info/views/HowTo/HowToCustomerSupport";
import HowToDevelopers from "../../pages/Info/views/HowTo/HowToDevelopers";
import HowToManagers from "../../pages/Info/views/HowTo/HowToManagers";
import HowToTesters from "../../pages/Info/views/HowTo/HowToTesters";
import { InfoHowToModule } from "../../pages/Info/views/HowTo/InfoHowToModule";
import UsefulLinks from "../../pages/Info/views/UsefulLinks/UsefulLinks";
import { IntegrationActivation } from "../../pages/IntegrationActivation/IntegrationActivation";
import NoSubscriptionPage from "../../pages/NoSubscription/NoSubscription";
import NotFoundPage from "../../pages/NotFound/NotFoundPage";
import { ResetPasswordPage } from "../../pages/ResetPassword/ResetPassword";
import { SignInPage } from "../../pages/SignIn/SignIn";
import { SignUpPage } from "../../pages/SignUp/SignUp";
import {
  UserFeedbackActivityHistoryPage,
  UserFeedbackDetailsPage,
  UserFeedbackListPage,
} from "../../pages/UserFeedback/UserFeedback";
import { UserFeedbackModulePage } from "../../pages/UserFeedback/views/UserFeedbackModulePage";
import { UsersDetailPage, UsersPage } from "../../pages/Users/Users";
import { UsersModulePage } from "../../pages/Users/views/UsersModulePage";
import { WorkspaceCreatePage } from "../../pages/WorkspaceCreate/WorkspaceCreate";
import { WorkspaceJoinPage } from "../../pages/WorkspaceJoin/WorkspaceJoin";
import { WorkspaceJoinInvitationPage } from "../../pages/WorkspaceJoinInvitation/WorkspaceJoinInvitation";
import AccountRedirect from "../redirect/AccountRedirect";
import { AdministrationRedirect } from "../redirect/AdministrationRedirect";
import { CrashesRedirect } from "../redirect/CrashesRedirect";
import { FeaturesRedirect } from "../redirect/Info/FeaturesRedirect";
import { HowToRedirect } from "../redirect/Info/HowToRedirect";
import { InfoRedirect } from "../redirect/Info/InfoRedirect";

export enum RouteConfigType {
  PATH,
  INDEX,
  LAYOUT,
}

export interface PathRouteConfig {
  name: string;
  type: RouteConfigType.PATH;
  path: RoutePaths;
  element?: () => JSX.Element;
  subRoutes?: RouteConfig[];
  testId?: string;
  protected?: boolean;
}

export interface IndexRouteConfig {
  type: RouteConfigType.INDEX;
  index: true;
  element?: () => JSX.Element;
}

export interface LayoutRouteConfig {
  type: RouteConfigType.LAYOUT;
  element: () => JSX.Element;
  subRoutes: RouteConfig[];
}

export type RouteConfig = PathRouteConfig | IndexRouteConfig | LayoutRouteConfig;

export const userFeedbackRouteConfigs: RouteConfig[] = [
  {
    name: "User feedback",
    type: RouteConfigType.PATH,
    path: RoutePaths.USER_FEEDBACK,
    element: UserFeedbackListPage,
    testId: identifiers["user.feedback.header"],
  },
  {
    name: "User feedback",
    type: RouteConfigType.PATH,
    path: RoutePaths.USER_FEEDBACK_DETAILS,
    element: UserFeedbackDetailsPage,
    testId: identifiers["user.feedback.header"],
  },
  {
    name: "User feedback",
    type: RouteConfigType.PATH,
    path: RoutePaths.USER_FEEDBACK_LOGS,
    element: UserFeedbackActivityHistoryPage,
  },
];

export const crashReportingRouteConfigs: RouteConfig[] = [
  {
    name: "Crash reports",
    type: RouteConfigType.PATH,
    path: RoutePaths.CRASH_REPORTS,
    element: CrashesPage,
    testId: identifiers["crash.reports.header"],
  },
  {
    name: "Crash report root",
    type: RouteConfigType.PATH,
    path: RoutePaths.CRASH_REPORT_ROOT,
    element: EmptyOutletLayout,
    testId: identifiers["crash.reports.header"],
    subRoutes: [
      {
        name: "Crash reports",
        type: RouteConfigType.PATH,
        path: RoutePaths.CRASH_REPORT_OVERVIEW,
        element: CrashOverviewPage,
        testId: identifiers["crash.reports.header"],
      },
      {
        name: "Crash reports",
        type: RouteConfigType.PATH,
        path: RoutePaths.CRASH_REPORT_EVENTS,
        element: EmptyOutletLayout,
        testId: identifiers["crash.reports.header"],
        subRoutes: [
          {
            name: "Crash reports",
            type: RouteConfigType.PATH,
            path: RoutePaths.CRASH_REPORT_EVENT,
            element: CrashEventPage,
            testId: identifiers["crash.reports.header"],
          },
          {
            name: "Crash reports",
            type: RouteConfigType.PATH,
            path: RoutePaths.CRASH_REPORT_LOGS,
            element: CrashEventActivityHistoryPage,
            testId: identifiers["crash.reports.header"],
          },
          {
            type: RouteConfigType.INDEX,
            index: true,
            element: CrashesRedirect,
          },
        ],
      },
      {
        type: RouteConfigType.INDEX,
        index: true,
        element: CrashesRedirect,
      },
    ],
  },
];

export const usersRouteConfigs: RouteConfig[] = [
  {
    name: "Users",
    type: RouteConfigType.PATH,
    path: RoutePaths.USERS,
    element: UsersPage,
    testId: identifiers["users.header"],
  },
  {
    name: "Users",
    type: RouteConfigType.PATH,
    path: RoutePaths.USER_DETAILS,
    element: UsersDetailPage,
    testId: identifiers["users.header"],
  },
];

export const analyticsRouteConfigs: RouteConfig[] = [
  {
    name: "Stats",
    type: RouteConfigType.PATH,
    path: RoutePaths.STATS,
    element: AnalyticsPage,
  },
];

export const accountRouteConfigs: RouteConfig[] = [
  {
    name: "Personal info",
    type: RouteConfigType.PATH,
    path: RoutePaths.ACCOUNT_PERSONAL_INFO,
    element: AccountInfoPage,
    testId: identifiers["myAccount.button.info"],
  },
  {
    name: "Notifications",
    type: RouteConfigType.PATH,
    path: RoutePaths.ACCOUNT_NOTIFICATIONS,
    element: NotificationsPage,
    testId: identifiers["myAccount.button.notifications"],
  },
  {
    name: "Notifications confirmation",
    type: RouteConfigType.PATH,
    path: RoutePaths.ACCOUNT_NOTIFICATIONS_CONFIRMATION,
    element: NotificationsConfirmationPage,
  },
  {
    type: RouteConfigType.INDEX,
    index: true,
    element: AccountRedirect,
  },
];

export const appsRouteConfigsWithKey: RouteConfig[] = [
  {
    name: "General",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_APPS_GENERAL_KEY,
    element: AppsGeneralView,
  },
  {
    name: "User feedback",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_APPS_USER_FEEDBACK_KEY,
    element: AppsFeedbackView,
  },
  {
    name: "Crash reports",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_APPS_CRASHES_KEY,
    element: AppsCrashesView,
  },
  {
    name: "App users and live chat",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_APPS_USERS_KEY,
    element: AppsUsersView,
  },
];

export const appsRouteConfigs: RouteConfig[] = [
  {
    name: "General",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_APPS_GENERAL,
    element: AppsGeneralView,
  },
  {
    name: "User feedback",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_APPS_USER_FEEDBACK,
    element: AppsFeedbackView,
  },
  {
    name: "Crash reports",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_APPS_CRASHES,
    element: AppsCrashesView,
  },
  {
    name: "App users and live chat",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_APPS_USERS,
    element: AppsUsersView,
  },
];

export const joinedAppRouteConfigs: RouteConfig[] = appsRouteConfigsWithKey.concat(appsRouteConfigs).concat([
  {
    type: RouteConfigType.INDEX,
    index: true,
    element: AppsRedirect,
  },
]);

export const administrationRouteConfigs: RouteConfig[] = [
  {
    name: "General",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_GENERAL,
    element: AdministrationGeneralPage,
    testId: identifiers["workspaceAdministration.button.general"],
  },
  {
    name: "Extensions",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_EXTENSIONS,
    element: AdministrationExtensionsPage,
    testId: identifiers["workspaceAdministration.button.extensions"],
  },
  {
    name: "Members",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_MEMBERS,
    element: AdministrationMembersPage,
    testId: identifiers["workspaceAdministration.button.members"],
  },
  {
    name: "Apps",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_APPS,
    element: AppsViewModule,
    testId: identifiers["workspaceAdministration.button.apps"],
    subRoutes: joinedAppRouteConfigs,
  },
  {
    name: "Common comments",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_COMMON_COMMENTS,
    element: AdministrationCommonCommentsPage,
  },

  {
    name: "Billing",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_BILLING,
    element: AdministrationBillingPage,
    testId: identifiers["workspace.administration.button.billing"],
  },
  {
    type: RouteConfigType.INDEX,
    index: true,
    element: AdministrationRedirect,
  },
];

export const infoHowToRouteConfigs: RouteConfig[] = [
  {
    name: "Product managers",
    type: RouteConfigType.PATH,
    path: RoutePaths.INFO_PRODUCT_MANAGERS,
    element: HowToManagers,
  },
  {
    name: "App developers",
    type: RouteConfigType.PATH,
    path: RoutePaths.INFO_APP_DEVELOPERS,
    element: HowToDevelopers,
  },
  {
    name: "App testers",
    type: RouteConfigType.PATH,
    path: RoutePaths.INFO_APP_TESTERS,
    element: HowToTesters,
  },
  {
    name: "Customer support",
    type: RouteConfigType.PATH,
    path: RoutePaths.INFO_CUSTOMER_SUPORT,
    element: HowToCustomerSupport,
  },
  {
    name: "All",
    type: RouteConfigType.PATH,
    path: RoutePaths.INFO_ALL,
    element: HowToAll,
  },
  {
    type: RouteConfigType.INDEX,
    index: true,
    element: HowToRedirect,
  },
];

export const infoFeaturesRouteConfigs: RouteConfig[] = [
  {
    name: "Latest",
    type: RouteConfigType.PATH,
    path: RoutePaths.FEATURES_LATEST,
    element: FeaturesLatestPage,
  },
  {
    name: "Popular",
    type: RouteConfigType.PATH,
    path: RoutePaths.FEATURES_POPULAR,
    element: FeaturesPopularPage,
  },
  {
    type: RouteConfigType.INDEX,
    index: true,
    element: FeaturesRedirect,
  },
];

export const infoRouteConfigs: RouteConfig[] = [
  {
    name: "Features",
    type: RouteConfigType.PATH,
    path: RoutePaths.FEATURES_ROOT,
    element: FeaturesModule,
    subRoutes: infoFeaturesRouteConfigs,
  },
  {
    name: "Videos",
    type: RouteConfigType.PATH,
    path: RoutePaths.VIDEOS_ROOT,
    element: InfoHowToModule,
    subRoutes: infoHowToRouteConfigs,
  },
  {
    name: "Quick links",
    type: RouteConfigType.PATH,
    path: RoutePaths.QUICK_LINKS,
    element: UsefulLinks,
  },
  {
    name: "App development",
    type: RouteConfigType.PATH,
    path: RoutePaths.APP_DEVELOPMENT,
    element: AppDevelopmentModule,
  },
  {
    type: RouteConfigType.INDEX,
    index: true,
    element: InfoRedirect,
  },
];

export const workspaceRouteConfigs: RouteConfig[] = [
  {
    type: RouteConfigType.LAYOUT,
    element: ModuleLayoutView,
    subRoutes: [
      {
        name: "User feedback",
        type: RouteConfigType.PATH,
        path: RoutePaths.USER_FEEDBACK_MODULE_ROOT,
        element: UserFeedbackModulePage,
        subRoutes: userFeedbackRouteConfigs,
      },
      {
        name: "Crash reports",
        type: RouteConfigType.PATH,
        path: RoutePaths.CRASH_MODULE_ROOT,
        element: CrashesModulePage,
        subRoutes: crashReportingRouteConfigs,
      },
      {
        name: "Users",
        type: RouteConfigType.PATH,
        path: RoutePaths.USERS_MODULE_ROOT,
        element: UsersModulePage,
        subRoutes: usersRouteConfigs,
      },
      {
        name: "Stats",
        type: RouteConfigType.PATH,
        path: RoutePaths.STATS_MODULE_ROOT,
        element: AnalyticsModulePage,
        subRoutes: analyticsRouteConfigs,
      },
    ],
  },
  {
    name: "Administration root",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_ROOT,
    element: AdministrationLayout,
    subRoutes: administrationRouteConfigs,
  },
  {
    name: "Info root",
    type: RouteConfigType.PATH,
    path: RoutePaths.INFO_ROOT,
    element: InfoLayout,
    subRoutes: infoRouteConfigs,
  },
  {
    name: "Account root",
    type: RouteConfigType.PATH,
    path: RoutePaths.ACCOUNT_ROOT,
    element: AccountLayout,
    subRoutes: accountRouteConfigs,
  },
  {
    name: "No subscription",
    type: RouteConfigType.PATH,
    path: RoutePaths.NO_SUBSCRIPTION,
    element: NoSubscriptionPage,
  },
  {
    name: "Add App",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADD_APP,
    element: AddAppPage,
  },
  {
    type: RouteConfigType.INDEX,
    index: true,
    element: InfoRedirect,
  },
];

export const internalRoutesConfig: RouteConfig[] = [
  {
    type: RouteConfigType.LAYOUT,
    element: RootLayout,
    subRoutes: [
      {
        name: "Main",
        type: RouteConfigType.PATH,
        path: RoutePaths.ROOT,
        element: MainLayout,
        subRoutes: [
          {
            name: "Workspace root",
            type: RouteConfigType.PATH,
            path: RoutePaths.WORKSPACE_ROOT,
            element: WorkspaceRootLayout,
            subRoutes: workspaceRouteConfigs,
          },
        ],
      },
      {
        name: "Join workspace",
        type: RouteConfigType.PATH,
        path: RoutePaths.JOIN_WORKSPACE,
        element: WorkspaceJoinPage,
      },
      {
        name: "Create workspace",
        type: RouteConfigType.PATH,
        path: RoutePaths.CREATE_WORKSPACE,
        element: WorkspaceCreatePage,
      },

      {
        name: "Canny Auth",
        type: RouteConfigType.PATH,
        path: RoutePaths.CANNY_AUTH,
        element: CannyAuthPage,
      },
      {
        name: "Integration activation",
        type: RouteConfigType.PATH,
        path: RoutePaths.INTEGRATION_ACTIVATION,
        element: IntegrationActivation,
      },
      {
        name: "Change email confirmation",
        type: RouteConfigType.PATH,
        path: RoutePaths.CHANGE_EMAIL_CONFIRMATION,
        element: ChangeEmailConfirmationPage,
      },
    ],
  },
  {
    name: "Sign in",
    type: RouteConfigType.PATH,
    path: RoutePaths.SIGNIN,
    element: SignInPage,
  },
  {
    name: "Sign out",
    type: RouteConfigType.PATH,
    path: RoutePaths.SIGNUP,
    element: SignUpPage,
  },
  {
    name: "Confirm sign up",
    type: RouteConfigType.PATH,
    path: RoutePaths.CONFIRM_SIGNUP,
    element: ConfirmSignUpPage,
  },
  {
    name: "Forgot password",
    type: RouteConfigType.PATH,
    path: RoutePaths.FORGOT_PASSWORD,
    element: ForgotPasswordPage,
  },
  {
    name: "Reset password",
    type: RouteConfigType.PATH,
    path: RoutePaths.RESET_PASSWORD,
    element: ResetPasswordPage,
  },
  {
    name: "Accept Invitation",
    type: RouteConfigType.PATH,
    path: RoutePaths.WORKSPACE_JOIN_INVITATION,
    element: WorkspaceJoinInvitationPage,
  },
  {
    name: "Approved domain email verification",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_DOMAIN_VERIFICATION,
    element: AdministrationDomainVerificationPage,
  },
  {
    name: "App domain email verification",
    type: RouteConfigType.PATH,
    path: RoutePaths.ADMINISTRATION_APP_DOMAIN_VERIFICATION,
    element: AdministrationAppDomainVerificationPage,
  },
  {
    name: "Not found",
    type: RouteConfigType.PATH,
    path: RoutePaths.NOT_FOUND,
    element: NotFoundPage,
  },
];

export const routesConfig: RouteConfig[] = [
  { type: RouteConfigType.LAYOUT, element: AppLevelLayout, subRoutes: internalRoutesConfig },
];
