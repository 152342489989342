import styled from "styled-components";

import { vars } from "../../../../../common/styles";

export const CrashesNavigationWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    max-width: 21rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    padding-left: 0.8rem;
    padding-bottom: 0.1rem;
    color: ${vars.colors.grey10};
    font-weight: 500;
    cursor: default;
  }

  > *:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const CrashNavigationLinksWrapper = styled.div`
  a {
    margin: 0 1rem;
  }
`;
