import React, { useEffect, useMemo, useState } from "react";

import { FloatingContext, ReferenceType } from "@floating-ui/react";

import { useAppSelectionContext } from "../../../../main/context/App/AppSelectionContext";
import { DropdownItem } from "../../../components/Dropdown/Dropdown";
import { getTagColor } from "../../../components/Input/InputWithTags/InputWithTags";
import { Spinner } from "../../../components/Spinner/Spinner";
import { vars } from "../../../styles";
import { useAttributesContext } from "../context/AttributesProvider";
import { UXOperatorDisplay } from "../sharedTypes";
import { useFilterHitsQuery } from "../useFilterHitsQuery";
import * as Styled from "./Attributes.styles";
import { usePopover } from "./SimplePopover";

type Props = {
  attributeName: string;
  identifier: number;
  context: FloatingContext<ReferenceType>;
  setOpen: (open: boolean) => void;
};

export function useFilterSelectorPopover({ identifier, attributeName, context, setOpen }: Props) {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const {
    getPreSelectedUXOperator,
    fetchHitsFN,
    getAttributeValue,
    update,
    filterHitMapper,
    showsHitsResults,
    getPredefinedValuesForAttribute,
  } = useAttributesContext();
  const attributeValue = getAttributeValue(identifier);

  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  const [selectedUXOperator, setSelectedUXOperator] = useState(getPreSelectedUXOperator(identifier)!); // TODO unwrap
  const [query, setQuery] = useState<string>("");

  const { availableFilterHits, loading, error } = useFilterHitsQuery({
    query,
    deps: [selectedWorkspace?.id, selectedApp?.id],
    attributeName,
    fetchFN: fetchHitsFN,
  });

  function onDidPickFilterValue(value: string | undefined | null, operator: UXOperatorDisplay) {
    update(identifier, value, operator.operator);
    setOpen(false);
  }

  useEffect(() => {
    function handler() {
      if (attributeValue || !selectedUXOperator) return;
      onDidPickFilterValue(undefined, selectedUXOperator);
    }

    context.events.on("didClose", handler);
    return () => {
      context.events.off("didClose", handler);
    };
  }, [context.events, attributeValue, selectedUXOperator, onDidPickFilterValue]);

  return {
    query,
    setQuery,
    error,
    loading,
    mappedFilterHits: useMemo(
      () =>
        filterHitMapper?.(
          attributeName,
          Boolean(showsHitsResults(attributeName))
            ? availableFilterHits
            : getPredefinedValuesForAttribute(identifier, query),
          attributeValue,
        ),
      [availableFilterHits, attributeValue, filterHitMapper],
    ),
    onDidPickFilterValue,
    selectedUXOperator,
    setSelectedUXOperator,
    identifier,
    attributeName,
  };
}

export function FilterSelectorPopover({
  query,
  setQuery,
  error,
  loading,
  mappedFilterHits,
  selectedUXOperator,
  setSelectedUXOperator,
  onDidPickFilterValue,
  attributeName,
  identifier,
}: ReturnType<typeof useFilterSelectorPopover>) {
  const {
    searchBoxInitialOpen,
    shouldOpenSearchBox,
    getAvailableUXOperators,
    showsHitsResults,
    isEnterKeyEnabled,
    showSearch,
    addButton,
  } = useAttributesContext();

  const { reference, floating, getFloatingProps, getReferenceProps, strategy, open, x, setOpen } = usePopover({
    placement: "right-start",
    useClickInteraction: false,
    initialOpen: searchBoxInitialOpen(attributeName, selectedUXOperator.uxOperator),
  });

  function onOperatorClick(uxOperatorDisplay: UXOperatorDisplay) {
    setSelectedUXOperator(uxOperatorDisplay);

    if (!shouldOpenSearchBox(uxOperatorDisplay.uxOperator)) {
      onDidPickFilterValue(null, uxOperatorDisplay);
      return;
    }

    setOpen(true);
  }

  const handleKeydownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isEnterKeyEnabled(attributeName)) {
      event.stopPropagation();
      onDidPickFilterValue(event.currentTarget.value, selectedUXOperator);
    }
  };

  return (
    <Styled.PopoverInput
      ref={reference}
      {...getReferenceProps()}
    >
      {getAvailableUXOperators(identifier).map((uxOperatorInfo, index) => {
        return (
          <DropdownItem
            key={index}
            onClick={() => onOperatorClick(uxOperatorInfo)}
          >
            <Styled.Flex>
              <div style={{ width: "20px", height: "20px", marginRight: "8px", display: "flex", alignItems: "center" }}>
                {selectedUXOperator.uxOperator === uxOperatorInfo.uxOperator && (
                  <Styled.CheckmarkIcon
                    width={16}
                    height={16}
                  />
                )}
              </div>
              <div>
                <span>{uxOperatorInfo.uxLabel}</span>
              </div>
            </Styled.Flex>
          </DropdownItem>
        );
      })}
      {open && (
        <Styled.PopoverInput
          ref={floating}
          {...getFloatingProps()}
          style={{
            top: 0,
            left: x ?? 0,
            width: "25rem",
            minHeight: "10rem",
            position: strategy,
            zIndex: vars.zIndex.modalContent,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
            {showSearch(attributeName) && (
              <Styled.Input
                autoFocus
                placeholder="Search"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  setQuery(e.currentTarget.value);
                }}
                value={query}
                type="text"
                onKeyDown={handleKeydownEvent}
              />
            )}
            {isEnterKeyEnabled(attributeName) && <Styled.Helper> Press enter to apply changes</Styled.Helper>}
            {!showsHitsResults(attributeName) && (
              <Styled.HitsContainer>
                {mappedFilterHits.map((filter, index) => {
                  return (
                    <DropdownItem
                      onClick={() => onDidPickFilterValue(filter.value, selectedUXOperator)}
                      key={index}
                    >
                      <Styled.Flex>
                        <Styled.Checkmark>
                          {filter.isChecked && (
                            <Styled.CheckmarkIcon
                              width={16}
                              height={16}
                            />
                          )}
                        </Styled.Checkmark>
                        {filter.label}
                      </Styled.Flex>
                    </DropdownItem>
                  );
                })}
              </Styled.HitsContainer>
            )}
            {showsHitsResults(attributeName) && (
              <Styled.HitsContainer>
                {loading && (
                  <div style={{ margin: "1.4rem 0rem" }}>
                    <Spinner style={{ width: "2.2rem", height: "2.2rem" }} />
                  </div>
                )}
                {error && <Styled.HitsText $isError={error}>Error loading results</Styled.HitsText>}
                {!loading &&
                  !error &&
                  mappedFilterHits.length == 0 &&
                  (!addButton || (addButton && !addButton(attributeName))) && (
                    <Styled.HitsText>No results found</Styled.HitsText>
                  )}
                {!loading && !error && !Boolean(mappedFilterHits.length) && addButton && addButton(attributeName) && (
                  <DropdownItem onClick={() => onDidPickFilterValue(query, selectedUXOperator)}>
                    <Styled.ColorDot style={{ backgroundColor: getTagColor(query) }} />
                    {`Add new tag ${query}`}
                  </DropdownItem>
                )}
                {!loading &&
                  !error &&
                  mappedFilterHits.length > 0 &&
                  mappedFilterHits.map((filter, index) => {
                    return (
                      <DropdownItem
                        onClick={() => onDidPickFilterValue(filter.value, selectedUXOperator)}
                        key={index}
                      >
                        <Styled.Flex>
                          <Styled.Checkmark>
                            {filter.isChecked && (
                              <Styled.CheckmarkIcon
                                width={16}
                                height={16}
                              />
                            )}
                          </Styled.Checkmark>
                          <Styled.Label>{filter.label}</Styled.Label>
                        </Styled.Flex>
                      </DropdownItem>
                    );
                  })}
              </Styled.HitsContainer>
            )}
          </div>
        </Styled.PopoverInput>
      )}
    </Styled.PopoverInput>
  );
}
