import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { CenteredCell } from "../../../../../common/components/GridTable/GridTable";
import { vars } from "../../../../../common/styles";
import { Flex } from "../../../../../common/styles/reusable/Flex/Flex.styles";

export const Grid = styled.div<{ $gridTemplateColumns?: string }>`
  display: grid;
  grid-template-rows: min-content;
  align-items: center;

  ${(props) =>
    props.$gridTemplateColumns &&
    css`
      grid-template-columns: ${props.$gridTemplateColumns};
    `}

  > * {
    height: 6rem;
  }

  row-gap: 0.1rem;
  background-color: ${vars.colors.grey90};
`;

export const Cell = styled(CenteredCell)<{
  $title?: boolean;
  $borderTopRight?: boolean;
  $borderTopLeft?: boolean;
  $borderBottomRight?: boolean;
  $borderBottomLeft?: boolean;
  $leftPadding?: boolean;
  $lastRow?: boolean;
}>`
  background-color: ${vars.colors.black};
  height: 3.5rem;
  border-bottom: 1px solid ${vars.colors.grey100};
  font-size: 1.4rem;
  color: ${vars.colors.grey60};

  p {
    background-color: ${vars.colors.black};
  }

  ${(props) =>
    props.$leftPadding &&
    css`
      padding-left: 1.6rem;
    `}
  ${(props) =>
    props.$title &&
    css`
      height: 5.4rem;
      font-size: 1.6rem;
      color: ${vars.colors.grey30};
    `}
    ${(props) =>
    props.$borderTopRight &&
    css`
      border-top-right-radius: 1rem;
    `}
    ${(props) =>
    props.$borderTopLeft &&
    css`
      border-top-left-radius: 1rem;
    `};
  ${(props) =>
    props.$borderBottomRight &&
    css`
      border-bottom-right-radius: 1rem;
    `}
  ${(props) =>
    props.$borderBottomLeft &&
    css`
      border-bottom-left-radius: 1rem;
    `};
  ${(props) =>
    props.$lastRow &&
    css`
      border-bottom: 0px;
    `}
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  background-color: ${vars.colors.black};
  color: ${vars.colors.grey30};

  font-weight: 500;
`;

export const LinkContainer = styled(Link)`
  display: contents;
  &:hover {
    & > * {
      background-color: ${vars.colors.grey100};
      background: ${vars.colors.grey100};

      p {
        background-color: ${vars.colors.grey100};
      }
      div {
        background-color: ${vars.colors.grey100};
      }
    }
    div {
      /* background: ${vars.colors.grey100}; */
    }
  }
`;

export const BaseFlex = styled(Flex)`
  width: 100%;
  > div {
    overflow: visible;
  }

  overflow: hidden;
  position: relative;
  max-width: 12rem;
  display: flex;
`;

export const Indicator = styled.div`
  display: inline-block;

  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  background-color: ${vars.colors.red};
  margin-right: 0.5rem;
`;
