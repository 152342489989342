import { ScriptableContext } from "chart.js";
import { format, subSeconds } from "date-fns";

import { BlackboxChartType, BlackboxNetworkData } from "../../../models/blackbox";
import { vars } from "../../../styles";

export const getNetworkBlackBoxData = (data: BlackboxNetworkData[]) => {
  const chartHistory: BlackboxNetworkData[] = [];

  if (data.length !== 30) {
    for (let i = 0; i < 30 - data.length; i++) {
      const lastObject = chartHistory[i - 1] ? chartHistory[i - 1] : data[0];

      const newObject = { ...lastObject };

      // Subtract 2 seconds from the time_occurred value
      const lastTime = new Date(lastObject.time_occurred);
      const newTime = subSeconds(lastTime, 2);
      newObject.time_occurred = format(newTime, "yyyy-MM-dd'T'HH:mm:ss.MS").toString();
      newObject.prettified_type = null;
      newObject.prettified_network = null;

      chartHistory.push(newObject);
    }

    // Append the known objects to the end of the array
    return chartHistory.reverse().concat(data);
  }

  return data;
};

export const getZeroData = (name: BlackboxChartType, data: BlackboxNetworkData[]) => {
  const chartHistory: BlackboxNetworkData[] = [];

  data.map((element) => {
    if (element.prettified_type === null)
      chartHistory.push({ prettified_type: "0", time_occurred: element.time_occurred });
  });

  return chartHistory;
};

export type DataType = {
  y: string;
  myProperty: string;
  x: string;
};

export const getOptions = (dividedData: Array<DataType[]>) => {
  //eslint-disable-next-line
  const options: any[] = [];
  dividedData.map((data) => {
    options.push({
      data: data,
      fill: "start",
      pointRadius: function (context: ScriptableContext<"line">) {
        if (context.dataIndex === context.dataset.data.length - 1) {
          return 3;
        } else {
          return 0;
        }
      },
      pointBackgroundColor: data[0].myProperty === "Offline" ? vars.colors.grey80 : vars.colors.lavender,
      pointBorderWidth: 0,
      pointHoverBackgroundColor: data[0].myProperty === "Offline" ? vars.colors.grey80 : vars.colors.lavender,
      pointHoverBorderColor: vars.colors.grey100,
      pointHoverBorderWidth: 1,
      borderColor: (context: ScriptableContext<"line">) => {
        if (data[0].myProperty === "Offline") return vars.colors.grey90;
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(200, 0, 0, 0);
        gradient.addColorStop(0, "#B77DFF");
        gradient.addColorStop(1, "#E7A5FF");
        return gradient;
      },
      backgroundColor: "transparent",
      tension: 0.6,
      pointStyle: "circle",
      pointHitRadius: 2,
      borderCapStyle: "round",
    });
  });
  return options;
};
