import { App } from "./App.model";

export interface Platform {
  created: string;
  updated: string;
  id: string;
  name: PlatformName;
  os: PlatformOs;
  latest_version: string;
  active: boolean;
  sort_num?: number;
}

export enum PlatformName {
  ANDROID = "Android",
  IOS = "iOS",
  FLUTTER = "Flutter",
  REACT_NATIVE = "ReactNative",
  WEB = "Web",
}

export enum PlatformOs {
  ANDROID = "Android",
  IOS = "iOS",
  WEB = "Web",
}

export const isMobileOS = (app?: App): boolean => {
  return app?.platform.os === PlatformOs.ANDROID || app?.platform.os === PlatformOs.IOS;
};

export const isNativePlatform = (app?: App): boolean => {
  return app?.platform.name === PlatformName.ANDROID || app?.platform.name === PlatformName.IOS;
};

export const isCrossPlatform = (app?: App): boolean => {
  return app?.platform.name === PlatformName.REACT_NATIVE || app?.platform.name === PlatformName.FLUTTER;
};

export const isWebOs = (app?: App): boolean => {
  return app?.platform.os === PlatformOs.WEB;
};
