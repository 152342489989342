import React, { useEffect } from "react";

import { generatePath, Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { Spinner } from "../../../common/components/Spinner/Spinner";
import { Workspace } from "../../../common/models";
import { analyticsLogin, setAnalyticsData } from "../../../common/util/analyticsData";
import {
  getModuleRootRouteFromPath,
  isAccountRouteLocation,
  isAdministrationRouteLocation,
  isInfoRouteLocation,
} from "../../../common/util/moduleRouteHelper";
import { useAppContext } from "../../context/App/App.context";
import { useAppSelectionContext } from "../../context/App/AppSelectionContext";
import { useAuthContext } from "../../context/Auth/Auth.context";
import { RoutePaths } from "../../router/config/routePaths";

export default function MainLayoutView() {
  const { workspaces, joinableWorkspaces, workspacesLoading } = useAppContext();
  const { userData } = useAuthContext();
  const { selectedWorkspace } = useAppSelectionContext();
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (workspacesLoading || selectedWorkspace || !workspaces.length) return;

    const pickedWorkspace = getAvailableWorkspace(workspaces);

    if (isInfoRouteLocation(location.pathname)) {
      return navigate(generatePath(getInfoRedirectRoute(), { workspaceSlug: pickedWorkspace.slug }), {
        replace: true,
        state: location.state,
      });
    }

    if (isAdministrationRouteLocation(location.pathname)) {
      return navigate(generatePath(getRedirectRoute(), { workspaceSlug: pickedWorkspace.slug }), {
        replace: true,
        state: location.state,
      });
    }
    if (isAccountRouteLocation(location.pathname)) {
      return navigate(generatePath(getAccountRedirectRoute(), { workspaceSlug: pickedWorkspace.slug }), {
        replace: true,
        state: location.state,
      });
    }

    const routePath = getModuleRootRouteFromPath(location.pathname);

    navigate(generatePath(routePath || RoutePaths.USER_FEEDBACK_MODULE_ROOT, { workspaceSlug: pickedWorkspace.slug }), {
      replace: true,
      state: location.state,
    });
  }, [workspaces, workspacesLoading, selectedWorkspace, location.pathname, navigate, userData]);

  useEffect(() => {
    if (selectedWorkspace && !workspacesLoading) {
      setAnalyticsData(workspaces, userData, selectedWorkspace);
    }

    analyticsLogin(userData);
  }, [selectedWorkspace, workspaces, workspacesLoading, userData]);

  if (workspacesLoading) return <Spinner />;

  if (!workspaces.length && joinableWorkspaces.length) {
    return (
      <Navigate
        to={generatePath(RoutePaths.JOIN_WORKSPACE)}
        state={location.state}
      />
    );
  }

  if (!workspaces.length) {
    return (
      <Navigate
        to={generatePath(RoutePaths.CREATE_WORKSPACE)}
        state={location.state}
      />
    );
  }

  if (!selectedWorkspace) return <Spinner />;

  return <Outlet />;
}

function getAvailableWorkspace(availableWorkspaces: Workspace[]) {
  const lastWorkspaceVisited = localStorage.getItem("lastWorkspaceVisited");
  if (lastWorkspaceVisited) {
    const workspace = availableWorkspaces.find((w) => w.id === lastWorkspaceVisited);
    if (workspace) return workspace;
  }
  return availableWorkspaces[0];
}

const getRedirectRoute = () => {
  if (location.pathname.includes("members")) {
    return RoutePaths.ADMINISTRATION_MEMBERS;
  }
  if (location.pathname.includes("apps")) {
    return getAppsRedirectRoute();
  }
  if (location.pathname.includes("billing")) {
    return RoutePaths.ADMINISTRATION_BILLING;
  }
  if (location.pathname.includes("subscription")) {
    return RoutePaths.ADMINISTRATION_BILLING;
  }
  if (location.pathname.includes("white-labeling")) {
    return RoutePaths.ADMINISTRATION_WHITE_LABELING;
  }
  return RoutePaths.ADMINISTRATION_GENERAL;
};

const getAccountRedirectRoute = () => {
  if (location.pathname.includes("notifications")) {
    return RoutePaths.ACCOUNT_NOTIFICATIONS;
  }
  return RoutePaths.ACCOUNT_ROOT;
};

const getInfoRedirectRoute = () => {
  if (location.pathname.includes("product-managers")) {
    return RoutePaths.INFO_PRODUCT_MANAGERS;
  }
  if (location.pathname.includes("app-developers")) {
    return RoutePaths.INFO_APP_DEVELOPERS;
  }
  if (location.pathname.includes("testers")) {
    return RoutePaths.INFO_APP_TESTERS;
  }
  if (location.pathname.includes("customer-support")) {
    return RoutePaths.INFO_CUSTOMER_SUPORT;
  }
  if (location.pathname.includes("videos/all")) {
    return RoutePaths.INFO_ALL;
  }
  if (location.pathname.includes("features/latest")) {
    return RoutePaths.FEATURES_LATEST;
  }
  if (location.pathname.includes("features/popular")) {
    return RoutePaths.FEATURES_POPULAR;
  }
  if (location.pathname.includes("features")) {
    return RoutePaths.FEATURES_ROOT;
  }
  if (location.pathname.includes("quick-links")) {
    return RoutePaths.QUICK_LINKS;
  }
  return RoutePaths.INFO_ROOT;
};

const getAppsRedirectRoute = () => {
  if (location.pathname.includes("crashes")) return RoutePaths.ADMINISTRATION_APPS_CRASHES;
  if (location.pathname.includes("users")) return RoutePaths.ADMINISTRATION_APPS_USERS;
  if (location.pathname.includes("general")) return RoutePaths.ADMINISTRATION_APPS_GENERAL;
  if (location.pathname.includes("feedback")) return RoutePaths.ADMINISTRATION_APPS_USER_FEEDBACK;

  return RoutePaths.ADMINISTRATION_APPS;
};
