import React, { Fragment } from "react";

import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import ReactCountryFlag from "react-country-flag";
import CountUp from "react-countup";
import { generatePath } from "react-router-dom";

import { useAppSelectionContext } from "../../../../main/context/App/AppSelectionContext";
import { BlockedIcon } from "../../../../main/pages/shared/icons/icons";
import { RoutePaths } from "../../../../main/router/config/routePaths";
import { Flex } from "../../../styles/reusable/Flex/Flex.styles";
import { UserIcon } from "../../DetailsPane/icons";
import { UserTableRowItem } from "../../MasterTable/models/MasterTableModel";
import { BaseCell } from "../ui/BaseCell";
import { HeaderCell } from "../ui/BaseTableStyles";
import { SortHeaderCell, TicketSortOption, UserSortOption } from "../ui/SortHeaderCell";
import { useBaseTable } from "../useBaseTable";

type TableProps = {
  selectable?: boolean;
  data: UserTableRowItem[];
  onChangeSortOption: (sort: TicketSortOption | UserSortOption) => void;
  hasMore: boolean;
  isLoading: boolean;
  isFetchingNext: boolean;
  loadMore: () => void;
  selectedSortOption: TicketSortOption | UserSortOption;
  total: number;
};

export function useUsersTable({
  selectable,
  data,
  hasMore,
  isLoading,
  loadMore,
  selectedSortOption,
  onChangeSortOption,
  isFetchingNext,
  total,
}: TableProps) {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();

  const baseTableProps = useBaseTable({
    data,
    total,
    selectedSortOption: selectedSortOption,
    columns: defaultUsersColumns({
      selectedSortOption,
      onChangeSortOption,
      total,
    }) as unknown as ColumnDef<UserTableRowItem>[], // TODO FIX TYPING
    selectable,
    hasMore,
    isLoading,
    isFetchingNext,
    loadMore,
    renderRowLinkPath: (item) => {
      return generatePath(RoutePaths.USER_DETAILS, {
        workspaceSlug: selectedWorkspace?.slug ?? null,
        appKey: selectedApp?.key ?? null,
        userId: item.id.toString(),
      });
    },
    onPreviewRequest: (data) => {
      // Ignore
    },
    onDeleteRequest: () => {
      return;
    },
  });

  return { ...baseTableProps };
}

const columnHelper = createColumnHelper<UserTableRowItem>();

export type UserTableRowItemRes = {
  id: string;
  firstName: string;
  lastName: string;
  endUserId: string;
  banned: string;
  city: string;
  country: string;
  signedUp: string;
  tickets_count: number;
};

const sortOptions = [
  { value: "created" as TicketSortOption, label: "Registered" },
  { value: "tickets" as TicketSortOption, label: "Tickets" },
];

const defaultUsersColumns = ({
  selectedSortOption,
  onChangeSortOption,
  total,
}: Pick<TableProps, "selectedSortOption" | "onChangeSortOption" | "total">) => [
  columnHelper.accessor("endUserId", {
    cell: (info) => (
      <BaseCell>
        <Flex>
          <div style={{ marginRight: "0.8rem", display: "flex", alignItems: "center" }}>
            {info.row.original.banned ? <BlockedIcon /> : <UserIcon />}
          </div>
          {info.getValue()}
        </Flex>
      </BaseCell>
    ),
    header: () => (
      <HeaderCell>
        <CountUp end={total} /> {total === 1 ? "result" : "results"}
      </HeaderCell>
    ),
    size: 3,
  }),
  columnHelper.accessor("firstName", {
    cell: (info) => <BaseCell>{info.getValue()}</BaseCell>,
    header: () => <HeaderCell>First Name</HeaderCell>,
  }),
  columnHelper.accessor("lastName", {
    cell: (info) => <BaseCell>{info.getValue()}</BaseCell>,
    header: () => <HeaderCell>Last Name</HeaderCell>,
  }),
  columnHelper.accessor("city", {
    cell: (info) => <BaseCell>{info.getValue()}</BaseCell>,
    header: () => <HeaderCell>City</HeaderCell>,
  }),
  columnHelper.accessor("country", {
    cell: (info) => (
      <BaseCell>{<Flex $alignItems="center">{info.getValue() ? getCountryCell(info.getValue()) : ""}</Flex>}</BaseCell>
    ),
    header: () => <HeaderCell>Country</HeaderCell>,
  }),
  columnHelper.accessor("signedUp", {
    cell: (info) => <BaseCell>{info.getValue()}</BaseCell>,
    header: () => <HeaderCell>Signed up</HeaderCell>,
  }),
  columnHelper.accessor("tickets_count", {
    cell: (info) => <BaseCell>{info.getValue()}</BaseCell>,
    header: () => (
      <SortHeaderCell
        onSortRequest={onChangeSortOption}
        selectedSortOption={selectedSortOption}
        options={sortOptions}
        title="Tickets"
        type="Users"
      />
    ),
    size: 0.5,
  }),
];

const getCountryCell = (country: string) => {
  countries.registerLocale(enLocale);
  const code = countries.getAlpha2Code(country, "en");

  return (
    <Fragment>
      {code && (
        <ReactCountryFlag
          countryCode={code}
          svg
          style={{ height: "1.6rem", width: "1.6rem", marginRight: "0.8rem" }}
        />
      )}
      {country}
    </Fragment>
  );
};
