import React from "react";

import AttachmentsContainer from "../../../../main/pages/shared/components/Attachments/AttachmentsContainer";
import { getCustomFieldIcon } from "../../../../main/pages/shared/components/CustomFields/CustomFields";
import { getOSIcon } from "../../../../main/pages/shared/icons/getIconsHelper";
import { getUserName, SupportedFileTypes } from "../../../models";
import { resolveIssueCreatedTime } from "../../../models/helpers/time/creationTime";
import { Issue } from "../../../models/issueTracking";
import { vars } from "../../../styles";
import { Flex } from "../../../styles/reusable/Flex/Flex.styles";
import { resolveFileType } from "../../../util/files";
import { t } from "../../../util/translator";
import { resolveAttachmentUrls } from "../../DetailsPane/helper";
import * as Icon from "../../DetailsPane/icons";
import { IssueTableRowItem } from "../../MasterTable/models/MasterTableModel";
import Paragraph from "../../Paragraph/Paragraph";
import * as Styled from "../BaseDialog.styles";
import { useSpaceToPreviewDialogContext } from "../SpaceToPreviewDialog";

export function FeedbackPreviewDialogBody() {
  const { previewItem } = useSpaceToPreviewDialogContext<IssueTableRowItem | undefined>();

  if (!previewItem) return <></>;
  const { original: issue } = previewItem;

  return (
    <Styled.PreviewFlexWrap $wide={Boolean(getFirstAttachment(issue).length)}>
      <Styled.DialogTitle>{issue?.title || "/"}</Styled.DialogTitle>
      <Flex $gap={2.8}>
        {Boolean(getFirstAttachment(issue).length) ? (
          <AttachmentsContainer
            attachments={getFirstAttachment(issue)}
            style={{ width: "22.8rem", height: "52.8rem", marginBottom: "0rem" }}
            height={528}
            width={228}
          />
        ) : (
          <Flex
            $justifyContent="center"
            $alignItems="center"
            style={{
              width: "22.8rem",
              height: "52.8rem",
              borderRadius: "1rem",
              border: `1px dashed ${vars.colors.grey90}`,
            }}
          >
            <Paragraph fontSize={14}>Nothing to preview</Paragraph>
          </Flex>
        )}
        <div style={{ width: "24rem" }}>
          <Styled.EssentialsGrid
            $gap={1}
            $column
            style={{
              paddingBottom: issue?.app_user ? "2rem" : undefined,
              borderBottom: issue?.app_user ? `1px solid ${vars.colors.grey90}` : undefined,
              marginBottom: issue?.app_user ? "2rem" : undefined,
            }}
          >
            {issue.app_user && (
              <>
                <Styled.EssentialItemWrap>
                  <Icon.UserIcon />
                  <p>{getUserName(issue.app_user) || "Not available"}</p>
                </Styled.EssentialItemWrap>

                <Styled.EssentialItemWrap>
                  <Icon.UserIdIcon />
                  <p>{issue.app_user.end_user_id || "Not available"}</p>
                </Styled.EssentialItemWrap>
              </>
            )}
          </Styled.EssentialsGrid>

          <Styled.EssentialsGrid
            $gap={1}
            $column
            style={{
              paddingBottom: Boolean(issue.custom_fields?.length) ? "2.2rem" : undefined,
              borderBottom: Boolean(issue.custom_fields?.length) ? `1px solid ${vars.colors.grey90}` : undefined,
              marginBottom: "2rem",
            }}
          >
            {issueToEssentialsModel(issue).map((model, i) => {
              return (
                <Styled.EssentialItemWrap key={i}>
                  {model.icon}
                  <p>{model.value || "Not available"}</p>
                </Styled.EssentialItemWrap>
              );
            })}
          </Styled.EssentialsGrid>

          {issue.custom_fields?.length ? (
            <Styled.EssentialsGrid
              $column
              $gap={1}
            >
              {issue.custom_fields?.slice(0, 3).map((model, i) => {
                return (
                  <div key={i}>
                    <Styled.EssentialItemWrap>
                      {getCustomFieldIcon(model.type)}
                      <p
                        style={{
                          maxWidth: "20.8rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {model.label || "Not available"}
                      </p>
                    </Styled.EssentialItemWrap>
                    <Paragraph
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        marginTop: "0.4rem",
                      }}
                      color={vars.colors.grey30}
                      fontSize={14}
                    >
                      {Boolean(model.value) ? model.value : "/"}
                    </Paragraph>
                  </div>
                );
              })}
            </Styled.EssentialsGrid>
          ) : undefined}
        </div>
      </Flex>
    </Styled.PreviewFlexWrap>
  );
}

function getFirstAttachment(issue: Issue) {
  const allAttachments = resolveAttachmentUrls(issue);
  const first = allAttachments.shift();
  if (
    first &&
    (resolveFileType(first) === SupportedFileTypes.IMAGE || resolveFileType(first) === SupportedFileTypes.VIDEO)
  ) {
    return [{ url: first, num: 1 }];
  }
  return [];
}

function issueToEssentialsModel(issue: Issue) {
  return Object.entries(issue)
    .map(([key, value]) => {
      const issueKey = key as keyof Issue;
      const label = labelForEssentialProperty(issueKey);
      return { label, key: issueKey, value: valueForEssentialProperty(value, issueKey) };
    })
    .filter(({ label }) => !!label)
    .filter(({ key }) => displayedProperties.find((p) => p.property === key))
    .sort(
      (a, b) =>
        // eslint-disable-next-line
        displayedProperties.find((p) => p.property === a.key)!.id -
        // eslint-disable-next-line
        displayedProperties.find((p) => p.property === b.key)!.id,
    )
    .map(({ label, key, value }) => {
      return {
        label: label as string,
        icon: iconForEssentialProperty(key, value),
        value,
      };
    });
}

const displayedProperties: Array<{ property: keyof Issue; id: number }> = [
  { property: "issue_reported_time", id: 1 },
  { property: "current_view", id: 2 },
  { property: "pretty_app_version", id: 3 },
  { property: "pretty_device_name", id: 4 },
  { property: "pretty_os", id: 5 },
  { property: "pretty_tester_email", id: 6 },
];

function iconForEssentialProperty(value: keyof Issue, os?: string) {
  switch (value) {
    case "pretty_tester_email":
      return <Icon.EmailDeprecatedIcon />;
    case "issue_reported_time":
      return <Icon.ClockIcon />;
    case "pretty_locale":
      return <Icon.LanguageIcon />;
    case "pretty_os":
      return (
        <img
          src={os ? getOSIcon(os) : undefined}
          width={20}
          height={20}
        />
      );
    case "pretty_timezone":
      return <Icon.TimezoneIcon />;
    case "pretty_app_version":
      return <Icon.AppVersionIcon />;
    case "pretty_device_orientation":
      return <Icon.OrientationIcon />;
    case "pretty_network_status":
      return <Icon.NetworkIcon />;
    case "pretty_resolution":
      return <Icon.ResolutionIcon />;
    case "current_view":
      return <Icon.CurrentViewIcon />;
    case "sdk_version":
      return <Icon.IDEIcon />;
    case "pretty_device_name":
      return <Icon.DeviceIcon />;
    default:
      return <></>;
  }
}

function labelForEssentialProperty(value: keyof Issue) {
  switch (value) {
    case "pretty_tester_email":
      return "Email";
    case "issue_reported_time":
      return t("detailsPane.tooltip.time");
    case "pretty_locale":
      return t("detailsPane.tooltip.locale");
    case "pretty_os":
      return t("detailsPane.tooltip.os");
    case "pretty_timezone":
      return t("detailsPane.tooltip.timezone");
    case "pretty_app_version":
      return t("detailsPane.tooltip.appVersion");
    case "pretty_device_orientation":
      return t("detailsPane.tooltip.orientation");
    case "pretty_network_status":
      return t("detailsPane.tooltip.network");
    case "pretty_resolution":
      return t("detailsPane.tooltip.resolution");
    case "current_view":
      return t("detailsPane.tooltip.currentView");
    case "sdk_version":
      return t("detailsPane.tooltip.shakeSDK");
    case "pretty_device_name":
      return "Device";
    default:
      return null;
  }
}

function valueForEssentialProperty(value: string, key: keyof Issue) {
  switch (key) {
    case "issue_reported_time":
      return resolveIssueCreatedTime(value);
    default:
      return value;
  }
}
