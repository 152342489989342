import React from "react";

import { generatePath, Outlet, useParams } from "react-router-dom";

import ChooseSubscriptionImage from "../../../../common/assets/images/FTUX/billing-choose-subscription.png";
import EmptyStateElement from "../../../../common/components/EmptyStateElement/components/EmptyStateElement";
import { Spinner } from "../../../../common/components/Spinner/Spinner";
import { SubscriptionState, useSubscriptionContext } from "../../../context/Subscription/SubscriptionContext";
import { RoutePaths } from "../../../router/config/routePaths";
import { ZeroAppsFeedbackView } from "../../shared/components/ZeroAppsView/ZeroAppsView";

export function UserFeedbackModulePage() {
  const { workspaceSlug } = useParams();
  const { state } = useSubscriptionContext();
  const buttonUrl = generatePath(RoutePaths.ADMINISTRATION_BILLING, { workspaceSlug: workspaceSlug ?? null });

  if (!state) return <Spinner />;

  switch (state) {
    case SubscriptionState.noApps:
      return <ZeroAppsFeedbackView />;
    case SubscriptionState.usageExceedsCurrentSubscription:
      return (
        <EmptyStateElement
          title="Which plan & add-on to choose"
          subtitle="Your usage of Shake exceeds your current workspace subscription. You can always check your workspace numbers and pick the appropriate subscription."
          imageSrc={ChooseSubscriptionImage}
          button={{ url: buttonUrl, title: "See subscription options" }}
        />
      );
    case SubscriptionState.good:
      return <Outlet />;
  }
}
