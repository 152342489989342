import { GrantedPermission } from "@common/components/DetailsPane/icons";
import Paragraph from "@common/components/Paragraph/Paragraph";
import { vars } from "@common/styles";
import { MoreIcon, PlusIcon } from "@main/pages/shared/icons/icons";
import { rgba } from "polished";
import styled, { css } from "styled-components";

export const AppListDropdown = styled.div`
  user-select: none;

  .dropdown-menu {
    top: 4rem;
    z-index: ${vars.zIndex.modalContent};

    width: 30rem;
  }
`;

export const SelectedAppContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SelectedAppWithOptions = styled.div`
  max-width: 36rem;
  display: flex !important;
  justify-content: space-between;
  z-index: 45;
  padding-right: 0.4rem;
`;

export const SelectedApp = styled.div`
  padding-left: 1rem;
  max-width: 28rem;
  display: flex;
  align-items: center;

  p {
    color: ${vars.colors.white};
    cursor: pointer;
  }
`;

export const IconButton = styled.div<{
  $active: boolean;
  $addApp?: boolean;
}>`
  color: ${vars.colors.grey50};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;

  ${(props) =>
    props.$active &&
    css`
      color: ${vars.colors.grey30};
    `}
  ${(props) =>
    props.$addApp &&
    css`
      margin-left: 1rem;
    `}
`;

export const dropdownScroll = `max-height: calc(100vh - 10rem);`;

export const More = styled(MoreIcon)`
  margin-left: 1rem;
  color: ${vars.colors.grey50};
`;

export const Plus = styled(PlusIcon)`
  color: ${vars.colors.grey50};
  margin: 0 auto;
`;

export const Overlay = styled.div<{ active?: boolean }>`
  background-color: ${rgba("#000", 0)};
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: ${vars.transitions.hover};
  opacity: 0;
  pointer-events: none;
  z-index: -1;

  /* active */
  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      pointer-events: auto;
      cursor: default;
      z-index: ${vars.zIndex.modalOverlay};
    `}
`;

export const AppNameParagraph = styled(Paragraph)<{ $wide?: boolean }>`
  max-width: 24rem;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  margin-right: 1rem;
`;

export const Name = styled.span<{ $wide?: boolean }>`
  max-width: 16rem;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const NotificationIndicator = styled.div`
  margin-left: 0.8rem;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: ${vars.colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2rem;
`;

export const PermissionIcon = styled(GrantedPermission)`
  width: 2.4rem;
  height: 2.4rem;
`;

export const AddAppIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
`;
