import React from "react";

import * as Styled from "./Header.styles";

interface Props {
  bottomBorder?: boolean;
  styles?: React.CSSProperties;
}
const Header: React.FC<Props> = ({ children, bottomBorder = true, styles }) => {
  return (
    <Styled.Header
      $bottomBorder={bottomBorder}
      className="header"
      style={styles}
    >
      {children}
    </Styled.Header>
  );
};

export default Header;
