import React from "react";

import { generatePath, useNavigate } from "react-router-dom";

import * as Styled from "./DomainVerificationView.styles";
import ShakeIcon from "../../../../../common/assets/icons/shake_icon.svg";
import Button from "../../../../../common/components/Button/Button";
import { CenteredForm } from "../../../../../common/components/CenteredForm/CenteredForm";
import { t } from "../../../../../common/util/translator";
import { RoutePaths } from "../../../../router/config/routePaths";
import FormTitle from "../../../shared/components/Forms/FormTitle/FormTitle";
import useDomainVerificationApiConsumer from "../../consumers/useDomainVerificationConsumer";

export default function DomainVerificationView() {
  const navigate = useNavigate();

  const { approvedDomain } = useDomainVerificationApiConsumer();

  if (!approvedDomain) {
    return (
      <CenteredForm>
        <Styled.DomainVerificationViewBoxWrap>
          <img
            src={ShakeIcon}
            alt="Shake Logo"
          />
          <h1>{t("administration.members.approvedDomain.activation.error_title")}</h1>
        </Styled.DomainVerificationViewBoxWrap>
      </CenteredForm>
    );
  }

  return (
    <CenteredForm>
      <Styled.DomainVerificationViewBoxWrap>
        <FormTitle
          title={t("administration.members.approvedDomain.activation.title", {
            domain: approvedDomain.name,
          })}
          subtitle={t("administration.members.approvedDomain.activation.description", {
            domain: approvedDomain.name,
            team: approvedDomain.team.name,
          })}
        />
        <Button
          size="full"
          onClick={() => navigate(generatePath(RoutePaths.ROOT))}
        >
          {t("administration.members.approvedDomain.activation.button")}
        </Button>
      </Styled.DomainVerificationViewBoxWrap>
    </CenteredForm>
  );
}
