import React, { Fragment, useState } from "react";

import Linkify from "linkify-react";
import { generatePath } from "react-router-dom";

import ChatMessage from "./ChatMessage";
import * as Styled from "./MessageContainer.styles";
import { ReactComponent as DescriptionIcon } from "../../../../../common/assets/images/filter-icons/description-icon.svg";
import { ReactComponent as JiraIcon } from "../../../../../common/assets/images/integrations/jira-icon.svg";
import { ReactComponent as LinearIcon } from "../../../../../common/assets/images/integrations/linear-icon.svg";
import { ReactComponent as NoteIcon } from "../../../../../common/assets/images/note-write-icon.svg";
import MessageInput from "../../../../../common/components/Input/MessageInput/MessageInput";
import Paragraph from "../../../../../common/components/Paragraph/Paragraph";
import { ToggleButton } from "../../../../../common/components/ToggleButton/ToggleButton";
import { AppUserShared, getUserInitials, Workspace } from "../../../../../common/models";
import { CrashActivity, CrashActivityType } from "../../../../../common/models/crashReporting";
import { resolveCreationTimeString } from "../../../../../common/models/helpers/time/creationTime";
import {
  ActivityType,
  IssueChat,
  IssueChatRequest,
  IssueMessage,
  IssueMessageType,
} from "../../../../../common/models/issueTracking";
import { TicketPriority } from "../../../../../common/models/TicketPriority.model";
import { TicketStatus } from "../../../../../common/models/TicketStatus.model";
import { vars } from "../../../../../common/styles";
import { docLink } from "../../../../../common/util/docs";
import identifiers from "../../../../../common/util/identifiers.json";
import { useAppSelectionContext } from "../../../../context/App/AppSelectionContext";
import { useAuthContext } from "../../../../context/Auth/Auth.context";
import { RoutePaths } from "../../../../router/config/routePaths";
import * as Icon from "../../icons/icons";

interface Props {
  messages?: IssueMessage[];
  activity?: CrashActivity[];
  onMessageSend?: (message: IssueChatRequest) => void;
  onMessageDelete?: (message: IssueMessage) => void;
  onMessageEdit?: (message: IssueChat) => void;
  appUser?: AppUserShared;
}

export default function MessageContainer({
  messages,
  activity,
  onMessageSend,
  onMessageDelete,
  onMessageEdit,
  appUser,
}: Props) {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const [sendToUser, setSendToUser] = useState(false);
  const [editing, setEditing] = useState<IssueChat>();
  const { userData } = useAuthContext();

  const toggleSendToUser = () => setSendToUser(!sendToUser);

  const inputPlaceholder = sendToUser
    ? "Reply to the app user"
    : `Leave an internal note — such as "Is this ready for the 2.1.5 release?" or "Check with the backend team first" — visible only to you and your teammates`;

  const sendMessage = (message: string) => {
    if (message.trim().length === 0) return;

    onMessageSend?.({ message: message.trim(), is_note: !sendToUser });
  };

  const editMessage = (newValue: string) => {
    if (!editing) return;
    if (newValue.trim().length === 0) {
      onMessageDelete?.(editing);
    } else {
      onMessageEdit?.({ ...editing, message: newValue });
    }
    setEditing(undefined);
  };

  const messageEditable = (senderId: string) => {
    if (!userData) return false;
    return senderId === userData.id;
  };

  return (
    <Styled.MessageContainerWrap>
      {activity &&
        activity.map((ac) => {
          return (
            <Styled.SystemMessage key={ac.id}>
              {getActivityIcon(ac.activity_type, ac.activity_description.replace(/"|'/g, ""))}
              {getActivityText(
                ac.activity_type,
                ac.activity_description.replace(/"|'/g, ""),
                ac.created,
                ac.activity_url ?? undefined,
              )}
            </Styled.SystemMessage>
          );
        })}

      {messages && (
        <Fragment>
          {!messages.find((a) => a.type === IssueMessageType.ACTIVITY && a.activity_type === "Rule") &&
            noRulesTriggered(selectedWorkspace)}

          {messages.map((message) => {
            switch (message.type) {
              case IssueMessageType.CHAT:
                if (message.is_note && message.user.name !== "Shake AI") {
                  return (
                    <Styled.SystemMessage
                      key={message.id}
                      style={{ alignItems: "flex-start" }}
                    >
                      <div
                        style={{
                          width: "2.4rem",
                          height: "2.4rem",
                          marginRight: "1rem",
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <NoteIcon />
                      </div>
                      <Paragraph
                        color={vars.colors.grey50}
                        fontSize={14}
                      >
                        {message.user.name} left an internal note{" "}
                        <Linkify options={{ target: "_blank" }}>
                          <b>{message.message}</b>
                        </Linkify>
                        &nbsp;&nbsp;&nbsp;
                        <Styled.Date>{resolveCreationTimeString(message.created)}</Styled.Date>
                        {messageEditable(message.user.id) && (
                          <>
                            <Styled.ActionButton onClick={() => setEditing(message)}>Edit</Styled.ActionButton>
                            <Styled.ActionButton onClick={() => onMessageDelete?.(message)}>Delete</Styled.ActionButton>
                          </>
                        )}
                      </Paragraph>
                    </Styled.SystemMessage>
                  );
                }
                return (
                  message.user.name !== "Shake AI" && (
                    <ChatMessage
                      key={message.id}
                      user={message.user}
                      isRead={message.is_read}
                      messageContent={message.message}
                      messageTimestamp={resolveCreationTimeString(message.created)}
                      appUser={appUser}
                    />
                  )
                );
              case IssueMessageType.ACTIVITY:
                return (
                  <Styled.SystemMessage key={message.id}>
                    <div style={{ width: "2.4rem", height: "2.4rem", marginRight: "1rem" }}>
                      {getActivityIcon(message.activity_type, message.activity_description.replace(/"|'/g, ""))}
                    </div>
                    {getActivityText(
                      message.activity_type,
                      message.activity_description.replace(/"|'/g, ""),
                      message.created,
                      message.activity_url,
                    )}
                  </Styled.SystemMessage>
                );
            }
          })}
          <Styled.MessageInputWrap>
            <MessageInput
              autoFocus={!!editing}
              key={editing ? editing.id : undefined}
              initialValue={editing ? editing.message : undefined}
              placeholder={editing ? "Edit message" : inputPlaceholder}
              onMessageSend={editing ? editMessage : sendMessage}
              testId={identifiers["central.column.comment.input"]}
              icontestid={identifiers["central.column.comment.icon.send"]}
              sendButtonText={sendToUser ? "Send" : "Add note"}
              sendToUserButton={
                <Styled.ToggleWrap>
                  <Styled.ToggleLabel>Send to app user</Styled.ToggleLabel>
                  <Styled.Tooltip
                    text="Register your app users to chat with them"
                    disabled={!!appUser}
                    position="top"
                  >
                    <ToggleButton
                      checked={sendToUser}
                      onChange={toggleSendToUser}
                      testId={identifiers["central.column.comment.toggle.sendToUser"]}
                      disabled={!appUser}
                    />
                  </Styled.Tooltip>
                </Styled.ToggleWrap>
              }
            />
          </Styled.MessageInputWrap>

          {!appUser && (
            <Styled.HelperText>
              <a
                target="_blank"
                rel="noreferrer"
                href={docLink(selectedApp?.platform.name).registerAppUser}
              >
                Register your app users
              </a>{" "}
              to enable live chat.
            </Styled.HelperText>
          )}
        </Fragment>
      )}
    </Styled.MessageContainerWrap>
  );
}

const getActivityIcon = (activityType: ActivityType | CrashActivityType, message?: string) => {
  if (activityType === ActivityType.RULE) {
    return <Styled.RuleIcon />;
  }
  if (activityType === ActivityType.TITLE) {
    return <DescriptionIcon />;
  }
  if (activityType === ActivityType.TAG) return <Icon.TagsIcon />;
  if (activityType === ActivityType.JIRA) return <JiraIcon />;
  if (activityType === ActivityType.LINEAR) return <LinearIcon />;

  if (!message) return;
  if (activityType === ActivityType.ASSIGNEE) {
    const userNameArray = message.trimStart().trimEnd().split(" ");
    const userName = `${
      userNameArray[userNameArray.length - 2] !== "to" ? userNameArray[userNameArray.length - 2] : ""
    } ${userNameArray[userNameArray.length - 1]}`;
    return (
      <div style={{ marginRight: "0.8rem" }}>
        <Styled.RoundedCanvas
          size="xsmall"
          letter={!userName.includes("Unassigned") ? getUserInitials(userName) : undefined}
          bgColor={userName.includes("Unassigned") ? vars.colors.grey100 : vars.colors.lavender}
        />
      </div>
    );
  }
  if (activityType === ActivityType.STATUS) {
    if (message.endsWith(TicketStatus.IN_PROGRESS)) return <Icon.InProgressIcon />;
    if (message.endsWith(TicketStatus.NEW)) return <Icon.StatusNewIcon />;
    if (message.endsWith(TicketStatus.CLOSED)) return <Icon.StatusDoneIcon />;
    if (message.endsWith(TicketStatus.LOCKED)) return <Icon.LockedIcon />;
  }
  if (activityType === ActivityType.PRIORITY) {
    if (message.endsWith(TicketPriority.HIGH)) return <Icon.PriorityHighIcon />;
    if (message.endsWith(TicketPriority.LOW)) return <Icon.PriorityLowIcon />;
    if (message.endsWith(TicketPriority.MEDIUM)) return <Icon.PriorityMediumIcon />;
  }
};

const getActivityText = (
  activityType: ActivityType | CrashActivityType,
  message: string,
  created: string,
  url?: string,
) => {
  if (activityType === ActivityType.RULE && url) {
    // eslint-disable-next-line
    const [first, ...rest] = message.split(" ");
    const name = rest.join(" ").split(" triggered");

    return (
      <Paragraph
        fontSize={14}
        color={vars.colors.grey50}
      >
        Rule{" "}
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>{" "}
        was triggered.&nbsp;&nbsp;&nbsp;
        <Styled.Date>{resolveCreationTimeString(created)}</Styled.Date>
      </Paragraph>
    );
  }
  return (
    <Fragment>
      <Paragraph
        color={vars.colors.grey50}
        fontSize={14}
      >
        {message}.&nbsp;&nbsp;&nbsp;
        <Styled.Date>{resolveCreationTimeString(created)}</Styled.Date>
      </Paragraph>
    </Fragment>
  );
};

const noRulesTriggered = (selectedWorkspace?: Workspace) => {
  if (!selectedWorkspace) return;
  return (
    <Styled.SystemMessage>
      {getActivityIcon(ActivityType.RULE)}
      <Paragraph
        fontSize={14}
        color={vars.colors.grey50}
      >
        No{" "}
        <a
          href={generatePath(RoutePaths.ADMINISTRATION_APPS_GENERAL, {
            workspaceSlug: selectedWorkspace?.slug,
          })}
          target="_blank"
          rel="noreferrer"
        >
          Rules
        </a>{" "}
        were triggered.
      </Paragraph>
    </Styled.SystemMessage>
  );
};
