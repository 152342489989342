import React, { PropsWithChildren } from "react";

import { CSSProperties } from "styled-components";

import { BaseCell as StyledBaseCell } from "./BaseTableStyles";
import { RemoveBackground } from "../../../hooks/filtering/ui/Attributes.styles";
import { vars } from "../../../styles";

interface Props {
  style?: CSSProperties;
  fadeGradientReach?: number;
  noYPadding?: boolean;
  noLeftPadding?: boolean;
  noGradient?: boolean;
  centered?: boolean;
  withBorder?: boolean;
}
export const BaseCell = ({
  style,
  children,
  fadeGradientReach,
  noYPadding,
  noLeftPadding,
  noGradient,
  centered,
  withBorder,
}: PropsWithChildren<Props>) => {
  return (
    <StyledBaseCell
      style={style}
      $noYPadding={noYPadding}
      $noLeftPadding={noLeftPadding}
      centered={centered}
      $withBorder={withBorder}
    >
      {children}
      {!noGradient && (
        <RemoveBackground
          className="__tagsFade"
          $removeHover={true}
          $gradientColor={vars.colors.black}
          $heightPercent={90}
          $gradientReachPercent={fadeGradientReach ?? 120}
          $noZIndex
        />
      )}
    </StyledBaseCell>
  );
};
