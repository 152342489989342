import { motion } from "framer-motion";
import { rgba } from "polished";
import styled, { css } from "styled-components";

import { vars } from "../../styles";

export const Modal = styled(motion.div)<{
  $active?: boolean;
  $size?: "wide" | "narrow" | "full" | "fuller";
  $withTabs?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: default;

  .modal-inner {
    width: 56rem;
  }

  /* Full */
  ${(props) =>
    props.$size === "full" &&
    css`
      .modal-inner {
        width: 66rem;
      }
    `}
  ${(props) =>
    props.$size === "fuller" &&
    css`
      .modal-inner {
        width: 76rem;
      }
    `}
  /* active */
     ${(props) =>
    props.$active &&
    css`
      .modal-inner {
        pointer-events: auto;
        z-index: ${vars.zIndex.modalContent};
      }
    `} /* wide */ ${(props) =>
    props.$size == "wide" &&
    css`
      .modal-inner {
        width: 90rem;
      }
    `} 
    /* Narrow */ ${(props) =>
    props.$size == "narrow" &&
    css`
      .modal-inner {
        width: 46rem;
      }
    `} /* With Tabs */ ${(props) =>
    props.$withTabs &&
    css`
      .modal-inner {
        > .card {
          padding: 0;
          background-color: ${vars.colors.black};
        }
      }

      .modal-tabs {
        display: flex;
        align-items: stretch;
      }

      .modal-tabs_nav {
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding-top: 3.2rem;
        margin-left: 2rem;

        .subnavigation {
          height: auto;
        }

        > .heading {
          padding: 0 1.6rem;
        }
      }

      .modal-tabs_content {
        background-color: ${vars.colors.grey100};
        padding: 3.2rem;
        border-radius: 2rem;
        min-height: 40rem;
        margin-left: 2rem;
      }
    `}
`;

export const ModalOverlay = styled(motion.div)<{ active?: boolean }>`
  background-color: ${rgba("#000", 0.7)};
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  /* active */
  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      pointer-events: auto;
      cursor: default;
      z-index: ${vars.zIndex.modalOverlay};
    `}
`;

export const ModalInner = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: -1;
`;

export const ModalScroll = styled.div`
  overflow-x: hidden;
  margin-right: -2.4rem;
  padding-right: 2.4rem;
  display: block;

  z-index: -1;
  overflow-y: auto;
  max-height: 60vh;

  &::-webkit-scrollbar {
    width: 0.8rem;
  }
`;

export const ModalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};

export const ContainerVariant = {
  initial: {
    transform: "scale(0.85)",
    top: "50%",
    transition: { type: "opacity .25s cubic-bezier(0,1,.4,1),transform .25s cubic-bezier(.18,1.25,.4,1)" },
  },
  isOpen: { top: "50%", transform: "scale(1)" },
  exit: {
    top: "50%",
    transform: "scale(0)",
    transition: { type: "opacity .25s cubic-bezier(0,1,.4,1),transform .25s cubic-bezier(.18,1.25,.4,1)" },
  },
};
