import React, { Fragment } from "react";

import Button from "../../../../../../common/components/Button/Button";
import { ModalFooter } from "../../../../../../common/components/Modal/ModalFooter/ModalFooter";
import SelectDropdown, { Option } from "../../../../../../common/components/SelectDropdown/SelectDropdown";
import { Workspace } from "../../../../../../common/models";
import { Azure, forwardingOptions } from "../../../../../../common/models/integrations";
import { Form } from "../ModalBody.styles";
import { AuthorizeAzureBody } from "./AzureAuthorizationModalBody";
import { AzureOption, useAzureIntegrationFormConsumer } from "./consumers/useAzureIntegrationFormConsumer";

export interface BaseIntegrationModalProps {
  onFinish: () => void;
}

interface Props extends BaseIntegrationModalProps {
  integration?: Azure;
  selectedAppId: string;
  workspace: Workspace;
  onAuthSuccess: () => void;
}

export function AzureIntegrationModalBody({ integration, selectedAppId, onFinish, workspace, onAuthSuccess }: Props) {
  const { forwardingOption, projectOption, projectOptions, handleFormChange, handleFormSubmit, disabled, loading } =
    useAzureIntegrationFormConsumer({ selectedAppId, onFinish, integration });

  const optionDidChange = (option: Option, name: string | undefined) => {
    name && handleFormChange(name, option);
  };

  const isButtonDisabled = checkIsButtonDisabled(disabled, projectOption, forwardingOption, integration);

  if (!integration) {
    return (
      <AuthorizeAzureBody
        workspaceId={workspace.id}
        selectedAppId={selectedAppId}
        onFinish={onAuthSuccess}
        toggle={onFinish}
      />
    );
  }

  return (
    <Fragment>
      <Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <SelectDropdown
          name="forwardingOption"
          value={forwardingOption}
          options={forwardingOptions}
          placeholder="Please choose"
          label="Forward tickets"
          onChange={optionDidChange}
          isSearchable={false}
          disabled={disabled || loading}
          hasSubtitles
        />
        <SelectDropdown
          name="projectOption"
          value={projectOption}
          options={projectOptions}
          placeholder="Please choose"
          label="Project"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || loading}
        />
      </Form>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: "No changes have been made",
              disabled: !isButtonDisabled || disabled,
            }}
            disabled={isButtonDisabled || loading}
            loadingText={loading && "Saving..."}
            onClick={handleFormSubmit}
          >
            Save changes
          </Button>
        }
      />
    </Fragment>
  );
}

const checkIsButtonDisabled = (
  disabled: boolean,
  projectOption: Option,
  forwardingOption: Option,
  integration?: Azure,
) => {
  if (disabled) return true;
  if (!projectOption) return true;
  if (projectOption.value === "to_azure") return false;
  if (!integration) return false;

  const parsedProjectOption = JSON.parse(projectOption.value) as AzureOption;

  if (
    parsedProjectOption.projectName &&
    (parsedProjectOption.projectName !== integration?.project_name ||
      !!forwardingOption.value !== integration?.automatic_forward)
  ) {
    return false;
  }
  return true;
};
