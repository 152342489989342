import { UserData, Workspace } from "../../../../common/models";
import { UserNotificationsRequest, UserReleasesNotificationsRequest } from "../../../../common/models/notifications";

type NotifyMeAboutSettings = Pick<
  UserNotificationsRequest,
  "notify_new_bug" | "notify_new_chat_message" | "notify_new_app"
>;

type NotifyReleasesSettings = Pick<UserReleasesNotificationsRequest, "notify_major_releases">;

type HowToNotifyYouSettings = Pick<UserNotificationsRequest, "notify_unread_activity" | "notify_desktop">;

export const buildFinalNotificationsRequestBody = (
  selectedWorkspace: Workspace,
  updateRequest: UserNotificationsRequest,
): UserNotificationsRequest => {
  const notifyMeAboutSettings = resolveNotifyMeAboutSettings(selectedWorkspace, updateRequest);
  const allNotifyMeAboutSettingsDisabled = Object.values(notifyMeAboutSettings).every((value) => !value);

  if (allNotifyMeAboutSettingsDisabled) {
    return {
      ...notifyMeAboutSettings,
      notify_unread_activity: false,
      notify_desktop: false,
      notify_new_app: false,
    };
  }

  const howToNotifyYouSettings = resolveHowToNotifyYouSettings(selectedWorkspace, updateRequest);

  return { ...notifyMeAboutSettings, ...howToNotifyYouSettings };
};

export const buildReleasesRequestBody = (
  userData: UserData,
  updateRequest: UserReleasesNotificationsRequest,
): UserReleasesNotificationsRequest => {
  const notifyMeAboutSettings = resolveReleasesSettings(updateRequest, userData);

  return { ...notifyMeAboutSettings };
};

export const resolveNotifyMeAboutSettings = (
  selectedWorkspace: Workspace,
  updateRequest: UserNotificationsRequest,
): NotifyMeAboutSettings => {
  return {
    notify_new_bug: isUndefined(updateRequest.notify_new_bug)
      ? !!selectedWorkspace?.notify_new_bug
      : updateRequest.notify_new_bug,
    notify_new_chat_message: isUndefined(updateRequest.notify_new_chat_message)
      ? !!selectedWorkspace?.notify_new_chat_message
      : updateRequest.notify_new_chat_message,
    notify_new_app: isUndefined(updateRequest.notify_new_app)
      ? !!selectedWorkspace?.notify_new_app
      : updateRequest.notify_new_app,
  };
};

export const resolveReleasesSettings = (
  updateRequest: UserReleasesNotificationsRequest,
  userData: UserData,
): NotifyReleasesSettings => {
  return {
    notify_major_releases: isUndefined(updateRequest.notify_major_releases)
      ? !!userData?.notify_major_releases
      : updateRequest.notify_major_releases,
  };
};

export const resolveHowToNotifyYouSettings = (
  selectedWorkspace: Workspace,
  updateRequest: UserNotificationsRequest,
): HowToNotifyYouSettings => {
  return {
    notify_unread_activity: isUndefined(updateRequest.notify_unread_activity)
      ? !!selectedWorkspace?.notify_unread_activity
      : updateRequest.notify_unread_activity,
    notify_desktop: isUndefined(updateRequest.notify_desktop)
      ? !!selectedWorkspace?.notify_desktop
      : updateRequest.notify_desktop,
  };
};

export const buildHowToNotifyYouSettingsFromRequest = (
  updateRequest: UserNotificationsRequest,
): HowToNotifyYouSettings => {
  return {
    notify_unread_activity: updateRequest.notify_unread_activity,
    notify_desktop: updateRequest.notify_desktop,
  };
};

const isUndefined = (value: unknown) => {
  return value === undefined;
};
