import React, { Fragment, useEffect } from "react";

import background from "@common/assets/images/empty-state/analytics-module-empty-heading.png";
import HouseIcon from "@common/assets/images/FTUX/house-icon.svg";
import Paragraph from "@common/components/Paragraph/Paragraph";
import displayToast from "@common/components/Toast/displayToast";
import Toast from "@common/components/Toast/Toast";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { AnalyticsModuleDescriptionBox } from "@main/pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";
import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath, useNavigate } from "react-router-dom";

import * as Styled from "./ModuleEmptyState.styles";
import { SetupStep } from "../SetupStep/SetupStep";

const STATS_SOMETHING_NOT_WORKING_TOAST = "stats-something-not-working";

export const AnalyticsModuleEmptyState = () => {
  const { selectedWorkspace } = useAppSelectionContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedWorkspace) return;
    displayToast({
      icon: HouseIcon,
      containerId: STATS_SOMETHING_NOT_WORKING_TOAST,
      title: "Something not working?",
      content: (
        <Fragment>
          Whenever you need help, visit{" "}
          {
            <Styled.StyledLink
              onClick={() => navigate(generatePath(RoutePaths.INFO_ROOT, { workspaceSlug: selectedWorkspace.slug }))}
            >
              Info
            </Styled.StyledLink>
          }{" "}
          where you will find links to help center, our Slack community and to private chat with our support engineers.
        </Fragment>
      ),
    });
  }, [navigate, selectedWorkspace]);

  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <AnalyticsModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={"Report feedback, crashes or register users"}
        />
        <Styled.StepContent>
          <Paragraph
            lineHeight={26}
            marginBottom={60}
          >
            {
              "Currently, there is no data to display in the stats section. Stats data will become available once feedback, crashes, or app user are registered within the app."
            }
          </Paragraph>
        </Styled.StepContent>
      </Styled.Container>
      <Toast
        containerId={STATS_SOMETHING_NOT_WORKING_TOAST}
        autoClose={false}
      />
    </Styled.BackgroundContainer>
  );
};
