import React from "react";

import * as Styled from "./EmptyTableView.styles";
import emptyStateIcon from "../../../../../common/assets/images/FTUX/empty-state-ball.svg";
import Button from "../../../../../common/components/Button/Button";

export interface EmptyStateViewConf {
  title: string;
  firstParagraph: string;
  secondParagraph?: string;
  paragraphLink?: {
    text: string;
    link: string;
  };
  infoBlockContent?: {
    textBeforeLink: string;
    linkText: string;
    link: string;
    textAfterLink: string;
    sameTab?: boolean;
  };
  button?: {
    onClick: () => void;
    text: string;
  };
  withFilters?: boolean;
}

export const EmptyTableView = ({
  title,
  firstParagraph,
  secondParagraph,
  paragraphLink,
  infoBlockContent,
  button,
  withFilters,
}: EmptyStateViewConf) => {
  return (
    <>
      <Styled.Container style={{ flex: 1, height: "100%" }}>
        <div style={{ flex: withFilters ? "none" : 1 }}></div>
        <Styled.CopyContainer>
          <Styled.Icon src={emptyStateIcon} />
          <Styled.Heading
            heading3
            fontWeight="bold"
            marginBottom={0}
          >
            {title}
          </Styled.Heading>

          <Styled.DescriptionContainer>
            <Styled.Paragraph fontWeight={500}>{firstParagraph}</Styled.Paragraph>
            {secondParagraph && (
              <Styled.Paragraph>
                {paragraphLink && (
                  <Styled.Link
                    href={paragraphLink.link}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {paragraphLink.text}
                  </Styled.Link>
                )}
                {secondParagraph}
              </Styled.Paragraph>
            )}
          </Styled.DescriptionContainer>

          {infoBlockContent && (
            <Styled.InfoBlock>
              {infoBlockContent.textBeforeLink}{" "}
              {
                <a
                  href={infoBlockContent.link}
                  target={infoBlockContent.sameTab ? undefined : "_blank"}
                  rel="noreferrer noopener"
                >
                  {infoBlockContent.linkText}
                </a>
              }{" "}
              {infoBlockContent.textAfterLink}
            </Styled.InfoBlock>
          )}
          {button && <Button onClick={button.onClick}>{button.text}</Button>}
        </Styled.CopyContainer>
        <div style={{ flex: withFilters ? "none" : 1 }}></div>
      </Styled.Container>
    </>
  );
};
