import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

import {
  CrashesAnalyticsPayload,
  CrashesFilters,
  FeedbackFilters,
  FeedbacksAnalyticsPayload,
  TimeGroup,
  TimeRange,
  UsersAnalyticsPayload,
  UsersFilters,
} from "../../../../common/models/Analytics.model";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useCrashesDeps } from "../../Crashes/Crashes";
import { useUserFeedbackDeps } from "../../UserFeedback/UserFeedback";
import { useAnalyticsDeps } from "../Analytics";
import { getStartDate } from "../helpers/analyticsHelper";

const defaultUsersFilters: UsersFilters = {
  time: TimeRange.NINETY,
  group: TimeGroup.WEEK,
};

const defaultCrashesFilters: CrashesFilters = {
  time: TimeRange.NINETY,
  group: TimeGroup.WEEK,
  app_version: "",
};

const defaultFeedbackFilters: FeedbackFilters = {
  time: TimeRange.NINETY,
  group: TimeGroup.WEEK,
  app_version: "",
  tag: "",
};

export default function useAnalyticsConsumer({ isNative }: { isNative: boolean }) {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const { analyticsService } = useAnalyticsDeps();

  const [usersFilters, setUsersFilters] = useState<UsersFilters>(defaultUsersFilters);
  const [feedbackFilters, setFeedbackFilters] = useState<FeedbackFilters>(defaultFeedbackFilters);
  const [crashesFilters, setCrashesFilters] = useState<CrashesFilters>(defaultCrashesFilters);

  const { userFeedbackService } = useUserFeedbackDeps();
  const { crashesService } = useCrashesDeps();

  async function checkAnalyticsEmpty() {
    if (!selectedWorkspace || !selectedApp) return [];

    const requests = [];

    const payload = {
      start_date: format(new Date(1420066800000), "yyyy-MM-dd"), // 2015/1/1
      end_date: format(new Date(), "yyyy-MM-dd"),
      group: "year",
    };
    const feedbackRequest = analyticsService.getUserFeedbackAnalytics(
      selectedWorkspace.id,
      selectedApp?.id,
      payload as FeedbacksAnalyticsPayload,
    );
    const crashesRequest = analyticsService.getCrashAnalytics(
      selectedWorkspace.id,
      selectedApp?.id,
      payload as CrashesAnalyticsPayload,
    );
    const usersRequest = analyticsService.getUsersAnalytics(
      selectedWorkspace.id,
      selectedApp?.id,
      payload as UsersAnalyticsPayload,
    );

    requests.push(feedbackRequest);
    if (isNative) requests.push(crashesRequest);
    requests.push(usersRequest);

    const isEmptyArray: boolean[] = [];

    const results = await Promise.all(requests);
    results.forEach((result) => {
      const data = result?.data;
      const isEmpty = !data?.data || (data?.data?.at(-1)?.cum_value ?? 0) == 0;
      isEmptyArray.push(isEmpty);
    });

    return isEmptyArray.every((isEmpty) => isEmpty);
  }

  async function getCrashesAnalytics() {
    if (!selectedWorkspace || !selectedApp || !isNative) return [];

    const payload: CrashesAnalyticsPayload = {
      start_date: format(getStartDate(crashesFilters.time), "yyyy-MM-dd"),
      end_date: format(new Date(), "yyyy-MM-dd"),
      group: crashesFilters.group.toLocaleLowerCase(),
      app_version: crashesFilters.app_version ? crashesFilters.app_version : undefined,
    };

    const { data } = await analyticsService.getCrashAnalytics(selectedWorkspace.id, selectedApp?.id, payload);

    return data?.data ?? [];
  }

  async function getUserFeedbackAnalytics() {
    if (!selectedWorkspace || !selectedApp) return [];

    const payload: FeedbacksAnalyticsPayload = {
      start_date: format(getStartDate(feedbackFilters.time), "yyyy-MM-dd"),
      end_date: format(new Date(), "yyyy-MM-dd"),
      group: feedbackFilters.group.toLocaleLowerCase(),
      tag: feedbackFilters.tag ? feedbackFilters.tag : undefined,
      app_version: feedbackFilters.app_version ? feedbackFilters.app_version : undefined,
    };

    const { data } = await analyticsService.getUserFeedbackAnalytics(selectedWorkspace.id, selectedApp?.id, payload);
    return data?.data ?? [];
  }

  async function getUsersAnalytics() {
    if (!selectedWorkspace || !selectedApp) return [];

    const payload: UsersAnalyticsPayload = {
      start_date: format(getStartDate(usersFilters.time), "yyyy-MM-dd"),
      end_date: format(new Date(), "yyyy-MM-dd"),
      group: usersFilters.group.toLocaleLowerCase(),
    };

    const { data } = await analyticsService.getUsersAnalytics(selectedWorkspace.id, selectedApp?.id, payload);

    return data?.data ?? [];
  }

  async function getTags() {
    if (!selectedWorkspace || !selectedApp) return [];

    const tagsFilters = await userFeedbackService
      .getUserFeedbackFiltersHits(selectedWorkspace.id, selectedApp.id, "", "tags", undefined, 100)
      .then(({ data }) => data);

    return tagsFilters.map((a) => a.value);
  }

  async function getFeedbackAppVersions() {
    if (!selectedWorkspace || !selectedApp) return [];

    const appVers = await userFeedbackService
      .getUserFeedbackFiltersHits(selectedWorkspace.id, selectedApp.id, "", "app_version.full_version", undefined, 100)
      .then(({ data }) => data);

    return appVers.map((a) => a.value);
  }

  async function getCrashesAppVersions() {
    if (!selectedWorkspace || !selectedApp || !isNative) return [];

    const appVers = await crashesService
      .getCrashReportsFiltersHits(selectedWorkspace.id, selectedApp.id, "", "app_version.full_version", undefined, 100)
      .then(({ data }) => data);

    return appVers.map((a) => a.value);
  }

  const {
    data: isAnalyticsEmpty,
    error: checkEmptyError,
    isLoading: isEmptyLoading,
  } = useQuery(["analyticsEmpty", { selectedApp }], checkAnalyticsEmpty, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  const {
    data: crashData,
    error: crashError,
    isLoading: crashesLoading,
  } = useQuery(["crashAnalytics", { selectedApp, crashesFilters }], getCrashesAnalytics, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  const {
    data: userFeedbackData,
    error: feedbackError,
    isLoading: feedbackLoading,
  } = useQuery(["feedbackAnalytics", { selectedApp, feedbackFilters }], getUserFeedbackAnalytics, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useQuery(["usersAnalytics", { selectedApp, usersFilters }], getUsersAnalytics, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  const {
    data: tags,
    error: tagsError,
    isLoading: tagsLoading,
  } = useQuery(["tags", { selectedApp }], getTags, { refetchOnWindowFocus: false, cacheTime: Infinity });

  const {
    data: feedbackAppVersions,
    error: feedbackAppVersionsError,
    isLoading: feedbackAppVersionsLoading,
  } = useQuery(["appVersions", { selectedApp }], getFeedbackAppVersions, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  const {
    data: crashesAppVersions,
    error: crashesAppVersionsError,
    isLoading: crashesAppVersionsLoading,
  } = useQuery(["appVersions", { selectedApp }], getCrashesAppVersions, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  const error =
    (checkEmptyError && (checkEmptyError as Error).constructor.name !== "TypeError") ||
    (crashError && (crashError as Error).constructor.name !== "TypeError") ||
    (usersError && (usersError as Error).constructor.name !== "TypeError") ||
    (feedbackError && (feedbackError as Error).constructor.name !== "TypeError") ||
    (tagsError && (tagsError as Error).constructor.name !== "TypeError") ||
    (feedbackAppVersionsError && (feedbackAppVersionsError as Error).constructor.name !== "TypeError") ||
    (crashesAppVersionsError && (crashesAppVersionsError as Error).constructor.name !== "TypeError");

  return {
    isAnalyticsEmpty,
    isEmptyLoading,
    crashData: crashData ?? [],
    crashesLoading,
    feedbackData: userFeedbackData ?? [],
    feedbackLoading,
    usersData: usersData ?? [],
    usersLoading,
    error: error,
    usersFilters,
    setUsersFilters,
    crashesFilters,
    setCrashesFilters,
    feedbackFilters,
    setFeedbackFilters,
    tags,
    feedbackAppVersions,
    crashesAppVersions,
    infoLoading: feedbackAppVersionsLoading || crashesAppVersionsLoading || tagsLoading,
  };
}
