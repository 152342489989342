import { useCallback, useEffect, useState } from "react";

import { Option } from "../../../../../../../common/components/SelectDropdown/SelectDropdown";
import displayToast from "../../../../../../../common/components/Toast/displayToast";
import { useForm } from "../../../../../../../common/hooks/useForm";
import {
  automaticForwardOption,
  IntegrationType,
  manualForwardOption,
} from "../../../../../../../common/models/integrations";
import {
  Linear,
  LinearIntegrationInfo,
  LinearIntegrationUpdatePayload,
} from "../../../../../../../common/models/integrations/Linear.model";
import { useIntegrationFormConsumer } from "../../../../consumers/useIntegrationFormConsumer";

interface Props {
  integration?: Linear;
  selectedAppId: string;
  onFinish: () => void;
}

export interface TeamOption {
  name: string;
  teamId: string;
  integrationId: string;
}

interface FormState {
  teamOption: Option;
  projectOption: Option;
  forwardingOption: Option;
  webhook: boolean;
}

const initialFormState = {
  teamOption: { value: "{}", label: "Fetching... Please wait, large projects may take a few minutes." },
  forwardingOption: automaticForwardOption,
  projectOption: { value: "{}", label: "Fetching... Please wait, large projects may take a few minutes." },
  webhook: false,
};

export const useLinearIntegrationFormConsumer = ({ selectedAppId, integration, onFinish }: Props) => {
  const { integrationInfo, integrate, updateIntegration, fetchIntegrationInfo } =
    useIntegrationFormConsumer<LinearIntegrationInfo>({
      selectedAppId,
      type: IntegrationType.LINEAR,
      toggle: onFinish,
    });

  const [loading, setLoading] = useState(false);
  const [teamsLoading, setTeamsLoading] = useState(false);

  const [teamOptions, setTeamOptions] = useState<Option[]>([]);
  const [projectOptions, setProjectOptions] = useState<Option[]>([]);

  const { formState, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: initialFormState,
    onSubmit: handleConnect,
    validators: {},
  });

  const { teamOption, forwardingOption, projectOption, webhook } = formState;

  async function handleConnect() {
    setLoading(true);
    try {
      if (!integrationInfo) throw new Error();

      const LinearTeamInfo = JSON.parse(teamOption.value) as TeamOption;

      const payload: LinearIntegrationUpdatePayload = projectOption
        ? {
            automatic_forward: !!forwardingOption.value,
            linear_team_id: LinearTeamInfo.teamId,
            linear_project_id: projectOption.value !== "{}" ? projectOption.value : false,
            webhook: webhook,
          }
        : {
            automatic_forward: !!forwardingOption.value,
            linear_team_id: LinearTeamInfo.teamId,
            webhook: webhook,
          };

      const endpoint = !!integration ? updateIntegration : integrate;

      endpoint<Linear>(payload);
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      setLoading(false);
      onFinish();
    }
  }

  const loadOptions = useCallback(async () => {
    if (teamsLoading) return;

    setTeamsLoading(true);

    await fetchIntegrationInfo(integrationInfo?.next_page ?? undefined);
    setTeamsLoading(false);

    return {
      options: teamOptions,
      hasMore: Boolean(integrationInfo?.next_page !== null),
    };
  }, [fetchIntegrationInfo, integrationInfo, teamOptions, teamsLoading]);

  useEffect(() => {
    if (!integrationInfo) return;

    if (!integrationInfo) {
      setTeamOptions([]);
      setProjectOptions([]);
      handleFormChange("teamOption", null);
      handleFormChange("projectOption", { value: "", label: "/" });
      return;
    }

    const newTeamOptions: Option[] = integrationInfo.teams.map((teamInfo) => {
      const teamOption: TeamOption = {
        name: teamInfo.name,
        teamId: teamInfo.id,
        integrationId: integrationInfo.integration_id,
      };
      return { value: JSON.stringify(teamOption), label: teamInfo.name };
    });

    setTeamOptions([...newTeamOptions]);

    if (integration && !integration?.linear_project_id) {
      handleFormChange("projectOption", { value: "{}", label: "None" });
    }

    const preSelectedTeam = integration
      ? newTeamOptions.find((option) => {
          const teamOption = JSON.parse(option.value) as TeamOption;
          return teamOption.teamId === integration.linear_team_id;
        }) || null
      : null;

    handleFormChange("teamOption", preSelectedTeam);

    handleFormChange("webhook", integration ? integration.is_webhook_enabled : false);

    const preselectedForwardingOption = integration
      ? integration.automatic_forward
        ? automaticForwardOption
        : manualForwardOption
      : automaticForwardOption;

    handleFormChange("forwardingOption", preselectedForwardingOption);
  }, [integrationInfo, handleFormChange, integration]);

  useEffect(() => {
    if (!integrationInfo) return;

    if (teamOption === null) {
      handleFormChange("projectOption", null);
      return;
    }

    const selectedTeamOption = JSON.parse(teamOption.value) as TeamOption;

    const listsForSelectedTeamOption: Option[] | undefined = [];

    if (integrationInfo.teams.find((team) => team.id === selectedTeamOption.teamId)?.projects.length) {
      listsForSelectedTeamOption.push({ value: "{}", label: "None" });
    }

    integrationInfo.teams
      .find((team) => team.id === selectedTeamOption.teamId)
      ?.projects.map(({ id, name }) => {
        listsForSelectedTeamOption.push({ value: id, label: name });
      });

    if (!listsForSelectedTeamOption.length) handleFormChange("projectOption", { value: "", label: "/" });

    if (integration && integration?.linear_project_id && listsForSelectedTeamOption.length) {
      const preselectedProject = listsForSelectedTeamOption.find(
        (project) => project.value === integration.linear_project_id,
      );
      if (preselectedProject) {
        handleFormChange("projectOption", { value: preselectedProject.value, label: preselectedProject.label });
      } else {
        handleFormChange("projectOption", { value: "{}", label: "None" });
      }
    }

    listsForSelectedTeamOption && setProjectOptions(listsForSelectedTeamOption);
  }, [teamOption, integrationInfo, handleFormChange, integration]);

  const disabled = !integrationInfo;

  return {
    disabled,
    loading,
    teamOptions,
    teamOption,
    projectOption,
    projectOptions,
    forwardingOption,
    handleFormChange,
    handleFormSubmit,
    webhook,
    loadOptions,
  };
};
