import styled from "styled-components";

import Input from "../../../../../../common/components/Input/Input";
import BaseSelectDropdown from "../../../../../../common/components/SelectDropdown/SelectDropdown";
import { Attributes as BaseAttributes } from "../../../../../../common/hooks/filtering/ui/Attributes";
import { Flex as BaseFlex } from "../../../../../../common/styles/reusable/Flex/Flex.styles";

export const Toggle = styled(BaseFlex)`
  width: 10rem;
  margin-right: 0.4rem;
`;

export const RuleName = styled(Input)`
  width: 56rem;
`;

export const RulesBlock = styled(BaseFlex)`
  margin: 1.6rem 0rem;
  width: 100%;
  z-index: 0;

  > svg {
    width: 7rem;
    height: 4rem;
  }
`;

export const SelectDropdown = styled(BaseSelectDropdown)`
  user-select: none;
  width: 59.6rem;
`;

export const Attributes = styled(BaseAttributes)<{ $top: boolean }>`
  margin-bottom: 0rem;
  max-width: 59.6rem;
`;

export const AttributesContainer = styled.div`
  margin-left: 2.2rem;
`;
