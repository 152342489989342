import { useMemo } from "react";

import { useAppContext } from "../../../../main/context/App/App.context";
import { useAppSelectionContext } from "../../../../main/context/App/AppSelectionContext";
import { useCrashesDeps } from "../../../../main/pages/Crashes/Crashes";
import {
  AllFieldNameUXOperators,
  Attribute,
  AttributeOperator,
  Filter,
  FilterHit,
  FilterHitDisplay,
  UseModuleAttributes,
} from "../sharedTypes";
import { useAppAttributes } from "../useAppAttributes";
import {
  attributeIconFor,
  findAssigneeFilterHits,
  getFieldValue,
  getMappedAssignees,
  getUXOperatorForFilter,
  getUXOperators,
  isRangeFilter,
  partialFilterFor,
} from "../utils";

const availableCrashFieldNames = ["assignee_id", "priority", "status", "tags"];
const availableCrashEventFieldNames = [
  "os_version.full_version",
  "device",
  "app_version.full_version",
  "current_view",
  "city",
  "country",
  "device_orientation",
  "locale",
  "authentication",
  "low_power_mode",
  "sdk_version.full_version",
  "tester_email",
];

const availableFieldNames = availableCrashFieldNames.concat(availableCrashEventFieldNames);

const groupedAvailableFieldNames = [
  {
    title: "Crash data",
    values: availableCrashFieldNames,
  },
  {
    title: "Crash event data",
    values: availableCrashEventFieldNames,
  },
] as const;

export type CrashesFieldName = (typeof availableFieldNames)[number];

const availableOperators = ["is", "is_not", "contains", "greater_than", "less_than"] as const;

export function useCrashesAttributes(withoutSearchParams?: boolean): UseModuleAttributes {
  const { wsTeamMembers } = useAppContext();
  // const { selectedApp } = useAppSelectionContext();
  const { crashesService } = useCrashesDeps();

  const { addAttribute, removeAttribute, updateAttribute, attributes } = useAppAttributes({
    storageKeyPrefix: "shakebugs.crashes_attributes",
    withoutSearchParams: withoutSearchParams,
    defaultAttributes: [],
    // selectedApp?.is_sample || !selectedApp?.is_approved || !selectedApp?.has_crash
    //   ? []
    //   : [
    //       {
    //         field_name: "status",
    //         filter: {
    //           filter_type: "exclude",
    //           filter_value: "term",
    //           fieldValue: "Closed",
    //         },
    //       },
    //     ],
  });

  const validAttributesJSONString = useMemo(
    () => JSON.stringify(attributes.filter((attr) => isValidAttribute(attr))),
    [attributes],
  );

  return {
    attributes: attributes.map((attr) => {
      return {
        name: attr.field_name,
      };
    }),
    availableAttributes: useMemo(() => getAvailableAttributes(attributes), [attributes]),
    validAttributes: useMemo(() => JSON.parse(validAttributesJSONString), [validAttributesJSONString]),
    isFilterUXValid: (atIndex) => isFilterUXValid(atIndex, attributes),
    searchBoxInitialOpen,
    shouldOpenSearchBox,
    showsHitsResults,
    showSearch: () => {
      return true;
    },
    fetchHitsFN: ({ deps, signal, attributeName, query }) => {
      const workspaceID = deps[0];
      const appID = deps[1];
      if (attributeName !== "assignee_id") {
        return crashesService
          .getCrashReportsFiltersHits(workspaceID, appID, query, attributeName, signal)
          .then(({ data }) => data);
      }
      return crashesService
        .getCrashReportsFiltersHits(workspaceID, appID, "", attributeName, signal)
        .then(({ data }) => findAssigneeFilterHits(query, getMappedAssignees(data, attributeName, wsTeamMembers)));
    },
    add: (attributeName: string) => {
      addAttribute({
        field_name: attributeName,
        filter: undefined,
        attributeOperator: attributes.length === 0 ? undefined : "and",
      });
    },
    addExternal(attributeName: string, filterValue: string, attributeOperator?: AttributeOperator) {
      addAttribute({
        field_name: attributeName,
        filter: { ...partialFilterFor("is"), fieldValue: filterValue },
        attributeOperator: attributeOperator ? attributeOperator : attributes.length === 0 ? undefined : "and",
      });
    },
    update: (identifier: number, value: string | undefined | null, selectedOperator: string) => {
      const current = attributes.find((_, index) => index === identifier);
      if (!current) return;

      const filter: Filter = { ...partialFilterFor(selectedOperator), fieldValue: value };

      const attr: Attribute = {
        attributeOperator: identifier == 0 ? undefined : "and",
        field_name: current.field_name,
        filter,
      };

      updateAttribute(identifier, attr);
    },
    remove: (atIndex: number) => {
      removeAttribute(atIndex);
    },
    filterSelectorInitialOpen: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return false;

      return (
        !attribute.filter?.fieldValue &&
        attribute.filter?.fieldValue !== null &&
        isFilterUXValid(identifier, attributes) &&
        identifier === attributes.length - 1
      );
    },
    getAttributeValuePresentation: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return "";
      return (
        (attribute.filter && getFieldValue(attribute.field_name, wsTeamMembers, attribute.filter.fieldValue)) ||
        " is missing value"
      );
    },
    getAttributeIcon: (attributeName: string) => {
      return attributeIconFor(attributeName);
    },
    getAttributeValue: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.filter?.fieldValue;
    },
    getAvailableUXOperators: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return [];
      return getUXOperators(getAvailableFieldNameUXOperators(attribute.field_name));
    },
    getSelectedUXOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute || !attribute.filter) return;
      return getUXOperatorForFilter(attribute.filter, attribute.field_name);
    },
    getPreSelectedUXOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.filter
        ? getUXOperatorForFilter(attribute.filter, attribute.field_name)
        : getUXOperators(getAvailableFieldNameUXOperators(attribute.field_name))[0];
    },
    getPredefinedValuesForAttribute: () => {
      return [];
    },
    getAttributeOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.attributeOperator;
    },
    attributeHasDefinedFilter: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return false;
      return !!attribute.filter;
    },
    filterHitMapper,
    isEnterKeyEnabled: () => {
      return false;
    },
  };
}

//eslint-disable-next-line
function getAvailableAttributes(current: Array<Attribute>) {
  return groupedAvailableFieldNames.filter((field) => {
    // return current.map((e) => e.field_name).includes(name);
    return field;
  });
}

function getAvailableFieldNameUXOperators(fieldName: string) {
  const rangeOperators: Array<AllFieldNameUXOperators> = ["greater_than", "less_than"];
  const assigneeOperators: Array<AllFieldNameUXOperators> = ["is", "is_unknown"];
  // const priorityStatusOperators: Array<AllFieldNameUXOperators> = ["is"];
  const includeExcludeOperators: Array<AllFieldNameUXOperators> = ["is", "is_not"];

  switch (fieldName as CrashesFieldName) {
    case "app_version.full_version":
    case "os_version.full_version":
    case "sdk_version.full_version":
      return Array.from(availableOperators.filter((op) => op !== "contains"));
    case "assignee_id":
      return Array.from(assigneeOperators);
    case "priority":
    case "status":
    case "low_power_mode":
    case "authentication":
    case "current_view":
    case "tags":
    case "device":
    case "tester_email":
    case "locale":
    case "city":
    case "country":
    case "device_orientation":
      return Array.from(includeExcludeOperators);
    default:
      return Array.from(availableOperators.filter((op) => !rangeOperators.includes(op)));
  }
}

function isValidAttribute(attribute: Attribute) {
  if (!attribute.filter) return false;

  if (isRangeFilter(attribute.filter)) {
    return attribute.filter.fieldValue !== undefined;
  }

  const fieldName = attribute.field_name as CrashesFieldName;

  if (fieldName == "assignee_id" && attribute.filter.fieldValue === null) return true;

  if (!attribute.filter.fieldValue) return false;

  return attribute.filter.fieldValue.length > 0;
}

function isFilterUXValid(identifier: number, attributes: Attribute[]) {
  const attribute = attributes.find((_, index) => index === identifier);
  if (!attribute) return false;

  if (!attribute.filter) return true;
  return isValidAttribute(attribute);
}

function searchBoxInitialOpen(attributeName: string, selectedOperator: string) {
  const fieldName = attributeName as CrashesFieldName;
  return fieldName !== "assignee_id" || (fieldName === "assignee_id" && selectedOperator !== "is_unknown");
}

function shouldOpenSearchBox(selectedUXOperator: string) {
  if ((selectedUXOperator as AllFieldNameUXOperators) === "is_unknown") return false;
  return true;
}

function showsHitsResults(attributeName: string) {
  if ((attributeName as CrashesFieldName) === "title") return false;
  return true;
}

export function filterHitMapper(fieldName: string, issueFilters: FilterHit[], filterValue?: string | null | undefined) {
  const displayFilters = [] as FilterHitDisplay[];
  if (fieldName === "assignee_id")
    issueFilters.map((filter) => {
      displayFilters.push({
        value: filter.value,
        name: filter.name,
        label: filter.name,
        isChecked: filter.value === filterValue,
      });
    });
  else {
    issueFilters.map((filter) => {
      displayFilters.push({
        value: filter.value,
        name: filter.name,
        label: filter.value,
        isChecked: filter.value === filterValue,
      });
    });
  }
  return displayFilters;
}

export function getAvailableFieldNamesByString(text: string) {
  switch (text) {
    case "App version":
      return "app_version.full_version";
    case "Current view":
      return "current_view";
    default:
      return "";
  }
}
