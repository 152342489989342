import React from "react";

import * as Styled from "./WorkspaceJoinView.styles";
import { CenteredForm } from "../../../../common/components/CenteredForm/CenteredForm";
import { Spinner } from "../../../../common/components/Spinner/Spinner";
import { useAppContext } from "../../../context/App/App.context";
import WorkspaceJoinElement from "../components/WorkspaceJoinElement/WorkspaceJoinElement";

export default function WorkspaceJoinView() {
  const { workspacesLoading } = useAppContext();

  if (workspacesLoading) {
    return (
      <Styled.CenteredContainer>
        <Spinner />
      </Styled.CenteredContainer>
    );
  }

  return (
    <CenteredForm>
      <WorkspaceJoinElement />
    </CenteredForm>
  );
}
