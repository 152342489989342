import React, { useMemo } from "react";

import { generatePath, Link } from "react-router-dom";

import * as Styled from "./AddAppLimitBanner.styles";
import { HelperText } from "../../../../../../common/components/HelperText/HelperText";
import Paragraph from "../../../../../../common/components/Paragraph/Paragraph";
import { App, PlatformName, Workspace } from "../../../../../../common/models";
import { Plan } from "../../../../../../common/models/billing";
import { getBillableApps } from "../../../../../../common/util/appsUtil";
import { useAppContext } from "../../../../../context/App/App.context";
import { useAppSelectionContext } from "../../../../../context/App/AppSelectionContext";
import { RoutePaths } from "../../../../../router/config/routePaths";

interface Props {
  limitNumbers: LimitNumbers;
  selectedWorkspace: Workspace;
}

export default function AddAppLimitBanner() {
  const { apps } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();
  const activeApps = useMemo(() => getBillableApps(apps), [apps]);
  const limitNumbers = getLimitNumbers(activeApps, selectedWorkspace?.subscription_plans ?? []);

  if (!limitNumbers || !selectedWorkspace) return null;

  if (limitNumbers.isBelowLimit) {
    return (
      <BelowLimitInfo
        limitNumbers={limitNumbers}
        selectedWorkspace={selectedWorkspace}
      />
    );
  }
  return (
    <LimitInfo
      limitNumbers={limitNumbers}
      selectedWorkspace={selectedWorkspace}
    />
  );
}

const BelowLimitInfo = ({ limitNumbers, selectedWorkspace }: Props) => {
  return (
    <Styled.Container>
      <HelperText>
        <Paragraph
          p2
          fontWeight={500}
        >
          {`Your current plan includes ${limitNumbers.currentNum} active apps and you are about to add the ${limitNumbers.nextNum} one. This is just a heads up, there’s
      nothing you need to change now.\n`}
        </Paragraph>
        <Paragraph
          p2
          fontWeight={500}
        >
          {`You can always visit`}{" "}
          <Link
            to={generatePath(RoutePaths.ADMINISTRATION_BILLING, {
              workspaceSlug: selectedWorkspace?.slug,
            })}
          >
            Billing
          </Link>{" "}
          to learn about plans and pricing, and deactivate{" "}
          <Link
            to={generatePath(RoutePaths.ADMINISTRATION_APPS, {
              workspaceSlug: selectedWorkspace?.slug,
            })}
          >
            Apps
          </Link>{" "}
          that you’re not using.
        </Paragraph>
      </HelperText>
    </Styled.Container>
  );
};

const LimitInfo = ({ limitNumbers, selectedWorkspace }: Props) => {
  return (
    <Styled.Container>
      <HelperText>
        <Paragraph
          p2
          fontWeight={500}
        >
          {`Your current plan includes ${limitNumbers.currentNum} active apps and you are about to add the ${limitNumbers.nextNum} one.`}
        </Paragraph>
        <Paragraph
          p2
          fontWeight={500}
        >
          Visit&nbsp;
          <Link
            to={generatePath(RoutePaths.ADMINISTRATION_BILLING, {
              workspaceSlug: selectedWorkspace?.slug,
            })}
          >
            Billing
          </Link>
          &nbsp;to upgrade to the appropriate plan first, or deactivate&nbsp;
          <Link
            to={generatePath(RoutePaths.ADMINISTRATION_APPS, {
              workspaceSlug: selectedWorkspace?.slug,
            })}
          >
            Apps
          </Link>{" "}
          that you’re not using.
        </Paragraph>
      </HelperText>
    </Styled.Container>
  );
};

interface LimitNumbers {
  isBelowLimit: boolean;
  currentNum: number;
  nextNum: string;
}

const getLimitNumbers = (activeApps: App[], subscriptionPlans: Plan[]): LimitNumbers | undefined => {
  const currentPlan = subscriptionPlans.find((item) => item.type === "plan");
  if (!currentPlan || currentPlan.product_name === "Premium") {
    const activeAppsCount = activeApps.filter((a) => a.platform.name !== PlatformName.WEB).length;
    if (activeAppsCount === 3) {
      return { isBelowLimit: true, currentNum: 4, nextNum: "fourth" };
    }
    if (activeAppsCount === 4) {
      return { isBelowLimit: false, currentNum: 4, nextNum: "fifth" };
    }
  }
  return undefined;
};
