import React from "react";

import analyticsBackground from "@common/assets/images/empty-state/analytics-module-empty-heading.png";
import crashesBackground from "@common/assets/images/empty-state/crashes-module-empty-heading.png";
import feedbackBackground from "@common/assets/images/empty-state/feedback-module-empty-heading.png";
import usersBackground from "@common/assets/images/empty-state/users-module-empty-heading.png";
import PageWrap from "@common/components/PageWrap/PageWrap";
import {
  AnalyticsModuleDescriptionBox,
  CrashesModuleDescriptionBox,
  FeedbackModuleDescriptionBox,
  UsersModuleDescriptionBox,
} from "@main/pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";

import * as Styled from "./ZeroAppsView.styles";
import {
  AnalyticsModuleAddAppFooter,
  CrashesModuleAddAppFooter,
  FeedbackModuleAddAppFooter,
  UsersModuleAddAppFooter,
} from "../AppFooter/AddAppFooter/AddAppFooter";

interface Props {
  heading: React.ReactNode;
  footer: React.ReactNode;
  background: string;
}

const ZeroAppsView = ({ heading, footer, background }: Props) => {
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>{heading}</Styled.HeadingBoxWrapper>
        {footer}
      </Styled.Container>
    </Styled.BackgroundContainer>
  );
};

export function ZeroAppsFeedbackView() {
  return (
    <PageWrap>
      <ZeroAppsView
        heading={<FeedbackModuleDescriptionBox />}
        footer={<FeedbackModuleAddAppFooter />}
        background={feedbackBackground}
      />
    </PageWrap>
  );
}

export function ZeroAppsCrashesView() {
  return (
    <PageWrap>
      <ZeroAppsView
        heading={<CrashesModuleDescriptionBox />}
        footer={<CrashesModuleAddAppFooter />}
        background={crashesBackground}
      />
    </PageWrap>
  );
}

export function ZeroAppsUsersView() {
  return (
    <PageWrap>
      <ZeroAppsView
        heading={<UsersModuleDescriptionBox />}
        footer={<UsersModuleAddAppFooter />}
        background={usersBackground}
      />
    </PageWrap>
  );
}

export function ZeroAppsAnalyticsView() {
  return (
    <PageWrap>
      <ZeroAppsView
        heading={<AnalyticsModuleDescriptionBox />}
        footer={<AnalyticsModuleAddAppFooter />}
        background={analyticsBackground}
      />
    </PageWrap>
  );
}
