import styled from "styled-components";

export const NotificationConfirmationViewFlexWrap = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const NotificationConfirmationViewBoxWrap = styled.div`
  width: 42rem;

  img {
    height: 4rem;
  }

  h1 {
    padding-top: 1.6rem;
    padding-bottom: 0.8rem;
  }

  p {
    font-weight: normal;
    margin-bottom: 2.4rem;
  }
`;
