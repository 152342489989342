import React from "react";

import { Row } from "@tanstack/react-table";

import * as Styled from "./ModalBody.styles";
import { useAppContext } from "../../../../../../main/context/App/App.context";
import { useAuthContext } from "../../../../../../main/context/Auth/Auth.context";
import { getRandomColor } from "../../../../../../main/pages/shared/helpers/getRandomColorHelper";
import NoAssignee from "../../../../../assets/images/unassigned-icon.png";
import { getUserInitials, UserData } from "../../../../../models";
import { Member } from "../../../../../models/Member.model";
import { vars } from "../../../../../styles";
import { Flex } from "../../../../../styles/reusable/Flex/Flex.styles";
import { ModalHeader } from "../../../../Modal/ModalHeader/ModalHeader";
import RoundedCanvas from "../../../../RoundedCanvas/RoundedCanvas";

export function SetAssigneeModalBody<TData>({
  title,
  confirmAction,
  close,
  selectedRows,
  resetSelection,
}: {
  title: string;
  confirmAction: (rows: TData[], assigneeId: string | null, resetSelection?: () => void) => void;
  close: () => void;
  selectedRows: Row<TData>[];
  resetSelection?: () => void;
}) {
  const { wsTeamMembers } = useAppContext();
  const { userData } = useAuthContext();

  const sortedTeamMembers = () => {
    return (
      wsTeamMembers
        ?.filter((member) => member.user.id !== userData?.id)
        .sort((a, b) => a.user.name.localeCompare(b.user.name)) ?? []
    );
  };

  return (
    <Flex $column>
      <Styled.HeaderContainer>
        <ModalHeader
          heading={title}
          noContent
        />
      </Styled.HeaderContainer>
      <Styled.Container>
        <Styled.ScrollContainer>
          <Element
            confirmAction={() => {
              confirmAction(
                selectedRows.map((row) => row.original),
                wsTeamMembers?.find((member) => member.user.id === userData?.id)?.id ?? null,
                resetSelection,
              );
              close();
            }}
            member={wsTeamMembers?.find((member) => member.user.id === userData?.id)}
            userData={userData}
          />
          <Element
            confirmAction={() => {
              confirmAction(
                selectedRows.map((row) => row.original),
                null,
                resetSelection,
              );
              close();
            }}
            userData={userData}
          />
          {sortedTeamMembers().map((member) => {
            return (
              <Element
                key={member.id}
                confirmAction={() => {
                  confirmAction(
                    selectedRows.map((row) => row.original),
                    member.id,
                    resetSelection,
                  );
                  close();
                }}
                member={member}
              />
            );
          })}
        </Styled.ScrollContainer>
      </Styled.Container>
    </Flex>
  );
}

function Element({
  confirmAction,
  member,
  userData,
}: {
  confirmAction: () => void;
  member?: Member;
  userData?: UserData;
}) {
  return (
    <Styled.Button onClick={confirmAction}>
      {!member ? (
        <img
          src={NoAssignee}
          width={24}
          height={24}
        />
      ) : (
        <RoundedCanvas
          letter={getUserInitials(member?.user.name) ?? ""}
          size="xsmall"
          image={member?.user.picture ? member.user.picture : undefined}
          txtColor={
            member && member.user.id !== null && getRandomColor(member.user.id)
              ? getRandomColor(member.user.id)?.text
              : undefined
          }
          bgColor={
            member && member?.user.id !== null && getRandomColor(member.user.id)
              ? getRandomColor(member.user.id)?.background
              : vars.colors.grey100
          }
        />
      )}
      {!member ? <p>Unassigned</p> : member.user.id === userData?.id ? <p>You</p> : <p>{member.user.name}</p>}
    </Styled.Button>
  );
}
