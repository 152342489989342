import styled, { css } from "styled-components";

import BaseOption from "../../../../../common/components/Option/Option";
import { Form, FormLinks } from "../../../shared/styles/Form.styles";

export const WorkspaceJoinListWrap = styled.div<{ $isModal?: boolean }>`
  ${(props) =>
    !props.$isModal &&
    css`
      overflow-y: auto;
      max-height: 25rem;
    `}

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Option = styled(BaseOption)<{ $isModal?: boolean }>`
  ${(props) =>
    props.$isModal &&
    css`
      padding: 0.5rem 0rem;
    `}

  label {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: normal;
    }
  }
`;

export const WorkspaceJoinForm = styled(Form)<{ $isModal?: boolean }>`
  button {
    ${(props) =>
      props.$isModal &&
      css`
        width: 9rem;
      `}
  }
  ${(props) =>
    props.$isModal &&
    css`
      margin-top: 0;
    `}
`;
export const WorkspaceJoinFormLinks = styled(FormLinks)`
  p {
    font-size: 1.6rem;
  }
`;

export const Link = styled.a`
  font-size: 1.6rem;
`;
