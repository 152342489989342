import React from "react";

import { Spinner } from "@common/components/Spinner/Spinner";
import { Changelog } from "@common/models/Changelog.model";
import { Flex } from "@common/styles/reusable/Flex/Flex.styles";
import { useAppContext } from "@main/context/App/App.context";
import ChangeLog from "@main/pages/Info/components/ChangeLog/ChangeLog";
import useFeaturesConsumer from "@main/pages/Info/consumers/useFeaturesConsumer";

export default function FeaturesPopular() {
  const { popularChangeLogs, popularLoading, onMarkAsReadRequest } = useFeaturesConsumer({ latest: false });
  const { unreadChangeLogNotifications } = useAppContext();

  if (popularLoading || !popularChangeLogs) return <Spinner />;

  return (
    <div style={{ justifyContent: "flex-start", marginTop: "2.8rem", maxWidth: "88rem", width: "100%" }}>
      <Flex
        $column
        $gap={4}
        style={{ marginTop: "4rem" }}
      >
        {popularChangeLogs?.map((log: Changelog, index: number) => {
          return (
            <ChangeLog
              key={index}
              changeLog={log}
              isUnread={Boolean(unreadChangeLogNotifications.find((no) => no.changelog_id === log.id))}
              markAsRead={onMarkAsReadRequest}
            />
          );
        })}
      </Flex>
    </div>
  );
}
