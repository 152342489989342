import styled from "styled-components";

import { vars } from "../../../styles";
import BaseButton from "../../Button/Button";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${vars.colors.grey90};
  padding-top: 2.4rem;
`;

export const Button = styled(BaseButton)`
  margin-right: 1.6rem;
`;
