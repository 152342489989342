import { useState } from "react";

import displayToast from "../../../../common/components/Toast/displayToast";
import { useForm } from "../../../../common/hooks/useForm";
import { App } from "../../../../common/models";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useAdministrationDeps } from "../Administration";
import { validateVersionCode, validateVersionName } from "../util/Validator";

export interface AddMappingFileFormState {
  versionName: string;
  versionCode: string;
  file: File | null;
  platform: string;
}

const AddMappingFileInitialState: AddMappingFileFormState = {
  versionName: "",
  versionCode: "",
  platform: "",
  file: null,
};

const validators = {
  versionName: validateVersionName,
  versionCode: validateVersionCode,
};

export const useAddMappingFileFormConsumer = (selectedApp: App, closeModal?: () => void) => {
  const { selectedWorkspace } = useAppSelectionContext();
  const { administrationService } = useAdministrationDeps();
  const [loading, setLoading] = useState(false);

  const handleAddMappingFile = async () => {
    if (!selectedWorkspace || !selectedApp) return;
    setLoading(true);

    try {
      await administrationService.addMappingFile(
        selectedWorkspace.id,
        selectedApp.id,
        formState.versionName,
        formState.versionCode,
        selectedApp.platform.name,
        selectedApp.platform.os,
        formState.file,
      );

      setLoading(false);

      closeModal?.();

      displayToast({
        title: "Fantastic!",
        content: `File has been added`,
      });
    } catch (error) {
      setLoading(false);
      closeModal?.();
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
  };

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<AddMappingFileFormState>({
    initialState: AddMappingFileInitialState,
    onSubmit: handleAddMappingFile,
    validators,
  });

  return { formState, handleFormChange, handleFormSubmit, formError, setError, loading };
};
