import { useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import displayToast from "../../../../common/components/Toast/displayToast";
import { isNativePlatform } from "../../../../common/models";
import useWorkspaceAppsApiConsumer from "../../../consumers/useWorkspaceAppsApiConsumer";
import { useAppContext } from "../../../context/App/App.context";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useAdministrationDeps } from "../Administration";
import { determineInitialSelectedAppId } from "../util/determineInitialSelectedApp";

export default function useAppsCrashesViewConsumer() {
  const { administrationService } = useAdministrationDeps();
  const { fetchAllApps } = useWorkspaceAppsApiConsumer();
  const { appKey } = useParams();

  const { apps, loading: appsLoading } = useAppContext();
  const { selectedWorkspace, selectedApp: globalSelectedApp } = useAppSelectionContext();

  const [localSelectedAppId, setLocalSelectedAppId] = useState<string | undefined>(
    determineInitialSelectedAppId(globalSelectedApp, apps, window.location.search, appKey),
  );

  const localSelectedApp = useMemo(() => apps.find((app) => app.id === localSelectedAppId), [localSelectedAppId, apps]);

  const selectedWorkspaceId = useMemo(() => selectedWorkspace?.id, [selectedWorkspace]);

  const [loading, setLoading] = useState(false);

  async function getMissingDsyms() {
    try {
      if (!selectedWorkspace?.id || !localSelectedApp || appsLoading || !isNativePlatform(localSelectedApp)) return [];
      const { data } = await administrationService.getMappingFiles(
        selectedWorkspace.id,
        localSelectedApp.id,
        localSelectedApp.platform.os,
      );

      const sortedByDate = data
        .map((obj) => {
          return { ...obj, created: new Date(obj.created) };
        })
        .sort((objA, objB) => objB.created.getTime() - objA.created.getTime());

      return sortedByDate;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while getting mapping files",
      });
      return [];
    }
  }

  const deleteMappingFile = async (fileId: string) => {
    try {
      if (!selectedWorkspace?.id || !localSelectedApp) return;

      setLoading(true);

      await administrationService.deleteMappingFile(selectedWorkspace.id, localSelectedApp.id, fileId);

      await refetchMissingDysms();

      displayToast({
        content: "File has been successfully deleted!",
      });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while deleting file",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedWorkspace || localSelectedAppId) return;
    fetchAllApps(selectedWorkspace.id).then((allApps) => {
      setLocalSelectedAppId(determineInitialSelectedAppId(globalSelectedApp, allApps, window.location.search, appKey));
    });
  }, [selectedWorkspace, fetchAllApps, localSelectedAppId, globalSelectedApp, appKey]);

  useEffect(() => {
    if (appKey) {
      setLocalSelectedAppId(determineInitialSelectedAppId(globalSelectedApp, apps, window.location.search, appKey));
    }
  }, [appKey, apps, globalSelectedApp]);

  const { data: mappingFiles, refetch: refetchMissingDysms } = useQuery(
    [`mapping files`, { localSelectedAppId, selectedWorkspaceId }],
    getMissingDsyms,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled:
        selectedWorkspace &&
        Boolean(selectedWorkspaceId) &&
        Boolean(localSelectedAppId) &&
        localSelectedApp &&
        isNativePlatform(localSelectedApp),
    },
  );

  return {
    refetchMissingDysms,
    deleteMappingFile,
    allApps: apps,
    loading,
    mappingFiles: mappingFiles ?? [],
    localSelectedApp,
  };
}
