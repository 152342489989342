import styled, { css } from "styled-components";

import InputComponent from "../../../../../../common/components/Input/Input";
import BaseTooltip from "../../../../../../common/components/Tooltip/Tooltip";
import { vars } from "../../../../../../common/styles";

export const FormContainer = styled.div`
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  svg {
    margin-top: 1.4rem;
    color: ${vars.colors.grey60};
  }

  p {
    font-size: 1.4rem;
  }
`;

export const Form = styled.form<{ $isSetPassword?: boolean }>`
  ${(props) =>
    props.$isSetPassword &&
    css`
      padding-top: 2.4rem;
    `}

  > *:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`;

export const Input = styled(InputComponent)`
  > p.helper {
    font-size: 1.2rem;
  }
`;

export const Tooltip = styled(BaseTooltip)`
  display: flex;
  justify-content: center;
  cursor: default;
  padding-top: 0.1rem;
  padding-right: 0.8rem;
`;
