import { useCallback, useEffect, useState } from "react";

import displayToast from "../../../../common/components/Toast/displayToast";
import { Attribute } from "../../../../common/hooks/filtering/sharedTypes";
import { AvailableRule, Rule } from "../../../../common/models/Rule.model";
import { useAdministrationDeps } from "../Administration";

export const useAvailableRulesConsumer = (rule?: Rule) => {
  const { administrationService } = useAdministrationDeps();
  const [availableRules, setAvailableRules] = useState<AvailableRule>();
  const [predefinedCondAttributes, setPredefinedCondAttributes] = useState<Attribute[]>([]);
  const [predefinedActionAttributes, setPredefinedActionAttributes] = useState<Attribute[]>([]);
  const [loading, setLoading] = useState(false);

  const getAvailableRules = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await administrationService.getAvailableRules();

      setAvailableRules(data);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while getting rules",
      });
    } finally {
      setLoading(false);
    }
  }, [administrationService]);

  useEffect(() => {
    if (rule && availableRules) {
      setPredefinedCondAttributes([]);
      setPredefinedActionAttributes([]);
      rule.conditions.map((cond) => {
        setPredefinedCondAttributes((condAttrs) => {
          return [
            ...condAttrs,
            {
              attributeOperator: condAttrs.length ? "and" : undefined,
              field_name: availableRules?.condition_types.find((type) => type.id === cond.type)?.method ?? "",
              filter: {
                filter_type:
                  availableRules?.condition_types.find((type) => type.id === cond.type)?.method === "issue_title"
                    ? "query"
                    : "filter",
                filter_value:
                  availableRules?.condition_types.find((type) => type.id === cond.type)?.method === "issue_title"
                    ? "prefix"
                    : "term",
                fieldValue: cond.args.value,
              },
            },
          ];
        });
      });
      rule.actions.map((action) => {
        setPredefinedActionAttributes((actionAttrs) => {
          return [
            ...actionAttrs,
            {
              attributeOperator: actionAttrs.length ? "and" : undefined,
              field_name: availableRules?.action_types.find((type) => type.id === action.type)?.method ?? "",
              filter: { filter_type: "filter", filter_value: "term", fieldValue: action.args.value },
            },
          ];
        });
      });
    }
    if (!rule) {
      setPredefinedActionAttributes([]);
      setPredefinedCondAttributes([]);
    }
  }, [rule, availableRules]);

  useEffect(() => {
    getAvailableRules();
  }, [getAvailableRules]);

  return { loading, availableRules, predefinedCondAttributes, predefinedActionAttributes };
};
