import React, { useEffect, useState } from "react";

import * as Styled from "./Accordion.styles";
import { ReactComponent as CaretDownIcon } from "../../assets/images/shared-icons/caret-down-icon.svg";
import { ReactComponent as CaretUpIcon } from "../../assets/images/shared-icons/caret-up-icon.svg";

interface AccordionProps {
  title: string;
  subtitle?: string;
  content: JSX.Element;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  testId?: string;
}

const Accordion = ({ title, content, subtitle, isOpen, testId }: AccordionProps) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isOpen === undefined) return;

    setIsActive(isOpen);
  }, [isOpen]);

  return (
    <Styled.AccordionItem>
      <Styled.Header onClick={() => setIsActive(!isActive)}>
        <Styled.Flex
          $justifyContent="space-between"
          $alignItems="center"
        >
          <Styled.Heading
            testId={testId}
            heading4
            marginBottom={0}
          >
            {title}
          </Styled.Heading>

          <Styled.IconContainer>{getIcon(isActive)}</Styled.IconContainer>
        </Styled.Flex>
        {subtitle && <Styled.Paragraph>{subtitle}</Styled.Paragraph>}
      </Styled.Header>
      {isActive && <Styled.Content>{content}</Styled.Content>}
    </Styled.AccordionItem>
  );
};

const getIcon = (isActive: boolean) => {
  if (isActive) {
    return <CaretUpIcon />;
  }
  return <CaretDownIcon />;
};

export default Accordion;
