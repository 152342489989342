import { useEffect, useRef, useState } from "react";

import { useIntl } from "react-intl";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

import { useForm } from "../../../../common/hooks/useForm";
import { deleteUTMTags, getUTMTags, saveUTMTags, UTMTags } from "../../../../common/util/parseUTMTags";
import { validateEmail, validatePassword } from "../../../../common/util/ValidatorFunctions";
import { setUserTokenAction } from "../../../context/Auth/Auth.actions";
import { useAuthDispatch } from "../../../context/Auth/Auth.context";
import { USER_TOKEN_LOCAL_STORAGE_KEY } from "../../../context/Auth/Auth.types";
import { RoutePaths } from "../../../router/config/routePaths";
import { parseGoogleCallback } from "../../SignIn/utils/parseGoogleCallback";
import { useGoogleSignIn } from "../../SignIn/utils/useGoogleSignIn";
import { useSignUpDeps } from "../SignUp";

export interface SignUpFormState {
  email: string;
  password: string;
}

interface EmailPrefillState {
  prefillEmail: string;
}

export type ReferrerState = {
  from?: string;
};

export const FROM_SIGNUP = "from_signup";

interface Params {
  setSuccessEmail?: (email: string) => void;
}

const validators = {
  email: validateEmail,
  password: validatePassword,
};

enum ErrorStatusCodes {
  EMAIL_NOT_APPROVED = 404,
  EMAIL_ALREADY_EXISTS = 409,
}

export const useSignUpFormConsumer = (params?: Params) => {
  const intl = useIntl();
  const navigate = useNavigate();
  saveUTMTags(new URL(window.location.href));

  const authDispatch = useAuthDispatch();
  const { signUpService, signInService } = useSignUpDeps();

  const [loading, setLoading] = useState(false);

  const currentURL = new URL(window.location.href);
  const { redirectToGoogle } = useGoogleSignIn({ currentURL });
  const { code, error, restoreOriginalURL, redirectURL } = parseGoogleCallback({ currentURL });

  const { state } = useLocation();
  const { prefillEmail = "" }: EmailPrefillState = (state as EmailPrefillState) || {};

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<SignUpFormState>({
    initialState: { email: prefillEmail, password: "" },
    onSubmit: handleEmailSignup,
    validators,
  });

  const [isErrorOnSubmit, setIsErrorOnSubmit] = useState<boolean>(false);

  async function handleEmailSignup() {
    try {
      setLoading(true);
      setIsErrorOnSubmit(false);

      const utmTags = getUTMTags() || ({} as UTMTags);
      await signUpService.signUp(formState.email, formState.password, utmTags);
      deleteUTMTags();

      params?.setSuccessEmail?.(formState.email);

      document.title = intl.formatMessage({ id: "email.check.title" });
    } catch (error) {
      if (error.response.status === ErrorStatusCodes.EMAIL_ALREADY_EXISTS) {
        setError({ email: new Error("Someone's already using this email.") });
        setIsErrorOnSubmit(true);
      }
      if (error.response.status === ErrorStatusCodes.EMAIL_NOT_APPROVED) {
        setError({ email: new Error("This email is not approved") });
        setIsErrorOnSubmit(true);
      }
    } finally {
      setLoading(false);
    }
  }

  const signupWithGoogleRef = useRef(async (googleCode: string, redirectUrl: URL) => {
    try {
      setLoading(true);

      const utmTags = getUTMTags() || ({} as UTMTags);
      const { data: userToken } = await signInService.signInWithGoogleCode(googleCode, redirectUrl.toString(), utmTags);
      deleteUTMTags();

      localStorage.setItem(USER_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(userToken));
      authDispatch(setUserTokenAction(userToken));

      navigate(generatePath(RoutePaths.ROOT), { replace: true, state: { from: FROM_SIGNUP } });
    } catch (error) {
      restoreOriginalURL?.();
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (code && redirectURL) {
      !loading && signupWithGoogleRef.current(code, redirectURL);
    }
  }, [code, redirectURL, loading]);

  useEffect(() => {
    error && restoreOriginalURL?.();
  }, [error, restoreOriginalURL]);

  return { redirectToGoogle, handleFormChange, handleFormSubmit, formState, formError, loading, isErrorOnSubmit };
};
