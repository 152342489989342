import React, { Fragment, useEffect, useMemo, useState } from "react";

import * as Styled from "./InviteMembersModalBody.styles";
import { Form } from "./ModalBody.styles";
import AppPlaceholderPhoto from "../../../../../common/assets/images/shared-icons/app-icon-placeholder.svg";
import Button from "../../../../../common/components/Button/Button";
import Input, { InputElementType } from "../../../../../common/components/Input/Input";
import { ModalProps } from "../../../../../common/components/Modal/Modal";
import { ModalHeaderProps } from "../../../../../common/components/Modal/ModalHeader/ModalHeader";
import Option from "../../../../../common/components/Option/Option";
import Paragraph from "../../../../../common/components/Paragraph/Paragraph";
import SelectDropdown from "../../../../../common/components/SelectDropdown/SelectDropdown";
import { App } from "../../../../../common/models";
import { MemberRole } from "../../../../../common/models/Member.model";
import { getActiveApps } from "../../../../../common/util/appsUtil";
import { t } from "../../../../../common/util/translator";
import { Validators } from "../../../../../common/util/Validators";
import { useAppContext } from "../../../../context/App/App.context";
import { getRoleOptions } from "../../util/getRoleOptions";

interface Props {
  handleInvite: (emails: string[], role: MemberRole, appIds?: string[], isResend?: boolean) => void;
  loading: boolean;
  toggle: () => void;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export type AppType = {
  id: string;
  name: string;
};

export default function InviteMembersModalBody({ handleInvite, loading, toggle, Modal, internalProps }: Props) {
  const { apps } = useAppContext();
  const activeApps = useMemo(() => getActiveApps(apps), [apps]);
  const [selectedRole, setSelectedRole] = useState<MemberRole>(MemberRole.ADMIN);
  const [selectedAppIds, setSelectedAppIds] = useState<string[]>(apps.map((app) => app.id));
  const [emails, setEmails] = useState("");
  const [error, setError] = useState<string>();

  const handleInviteClick = (e?: React.MouseEvent<Element, MouseEvent>) => {
    if (!e) return;
    e.preventDefault();

    if (emails.trim() === "") {
      setError("Whoopsie, that doesn't look like a valid email.");
      return;
    }

    const membersEmails = emails
      .toLocaleLowerCase()
      .replace(/\s/, "")
      .split(/[/,;]+/)
      .reduce(function (trimmedEmails: string[], item) {
        if (item.trim() !== "") {
          trimmedEmails.push(item.trim());
        }
        return trimmedEmails;
      }, []);
    if (!membersEmails.every(Validators.validEmail) || emails === "") {
      setError("Whoopsie, that doesn't look like a valid email.");
      return;
    }
    handleInvite(membersEmails, selectedRole, selectedAppIds);
    toggle();
    setSelectedRole(MemberRole.ADMIN);
    setEmails("");
  };

  useEffect(() => {
    if (selectedRole !== MemberRole.GUEST) {
      setSelectedAppIds(activeApps.map((a) => a.id));
    }
  }, [selectedRole, setSelectedAppIds, activeApps]);

  const roleOptions = getRoleOptions(activeApps.length);

  return (
    <Modal
      {...internalProps}
      heading="Invite people"
      size="full"
      buttonElement={
        <Button
          type="submit"
          size="small"
          disabled={!emails.length || loading || (selectedRole === MemberRole.GUEST && !selectedAppIds.length)}
          tooltip={{
            position: "top",
            text: !emails.length ? "Please enter at least one email first" : "Please choose at least one app first",
            disabled:
              (Boolean(emails.length) && !Boolean(selectedRole === MemberRole.GUEST && !selectedAppIds.length)) ||
              loading,
          }}
          loadingText={loading && "Sending invite..."}
          onClick={(e) => handleInviteClick(e)}
        >
          {t("Invite")}
        </Button>
      }
    >
      <Form onSubmit={(event) => event.preventDefault()}>
        <Input
          elementType={InputElementType.TEXTAREA}
          id="email"
          label={t("Email")}
          value={emails}
          placeholder="andy@company.com, bernadette@example.com, etc."
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
            setError(undefined);
            setEmails(e.currentTarget.value);
          }}
          rows={4}
          error={!!error}
          helper={
            error ? (
              error
            ) : (
              <div style={{ paddingTop: "0.2rem" }}>
                To invite lots of people at once, just separate their emails with <Styled.Key>,</Styled.Key>,{" "}
                <Styled.Key>;</Styled.Key> or <Styled.Key>/</Styled.Key>
              </div>
            )
          }
        />

        <SelectDropdown
          options={roleOptions}
          label="Invite as"
          onChange={(event) => setSelectedRole(event.value as MemberRole)}
          defaultValue={roleOptions.find((option) => option.value === selectedRole)}
          isSearchable={false}
          hasSubtitles
        />

        {Boolean(activeApps.length) && (
          <Fragment>
            <Paragraph fontWeight={500}>Give access to:</Paragraph>

            <Option
              label="All apps"
              checked={Boolean(selectedAppIds?.length === activeApps.length) || selectedRole !== MemberRole.GUEST}
              handleCheck={() => setSelectedAppIds(handleSelectAllApps(selectedAppIds, activeApps))}
              disabled={loading || selectedRole !== MemberRole.GUEST}
            />

            <Styled.OptionsContainer>
              {activeApps.map((app) => (
                <Option
                  key={app.id}
                  label={app.name}
                  checked={Boolean(selectedAppIds.find((a) => a === app.id))}
                  handleCheck={() => {
                    setSelectedAppIds(handleCheck(selectedAppIds, app.id));
                  }}
                  disabled={loading || selectedRole !== MemberRole.GUEST}
                  image={app.logo_url ? app.logo_url : AppPlaceholderPhoto}
                />
              ))}
            </Styled.OptionsContainer>
          </Fragment>
        )}
      </Form>
    </Modal>
  );
}

const handleCheck = (selectedApps: string[], appId: string) => {
  if (selectedApps.find((app) => app === appId)) {
    return selectedApps.filter((app) => app !== appId);
  }
  return selectedApps.concat(appId);
};

const handleSelectAllApps = (selectedApps: string[], activeApps: App[]) => {
  if (selectedApps.length === activeApps.length) {
    return [];
  }
  return activeApps.map((app) => app.id);
};
