import React, { Fragment } from "react";

import * as Icon from "./icons";
import { Platform, PlatformName } from "../../models";
import { CrashesFieldName } from "./modules/useCrashesAttributes";
import { FeedbackFieldName } from "./modules/useFeedbackAttributes";
import { UsersFieldName } from "./modules/useUsersAttributes";
import {
  AllFieldNameOperators,
  AllFieldNameUXOperators,
  Attribute,
  Filter,
  FilteringBody,
  RangeFilter,
  UXOperatorDisplay,
} from "./sharedTypes";
import { DeprecatedBanner } from "./ui/Attributes.styles";
import { getCustomFieldIcon } from "../../../main/pages/shared/components/CustomFields/CustomFields";
import { MetadataIcon } from "../../../main/pages/shared/icons/icons";
import SDK from "../../assets/images/details-pane-icons/shake-sdk-icon.svg";
import * as DetailsPaneIcons from "../../components/DetailsPane/icons";
import { CustomFieldType, IssueFilter } from "../../models/issueTracking";
import { Member } from "../../models/Member.model";

export function partialFilterFor(operator: string): Partial<Filter | RangeFilter> {
  switch (operator as AllFieldNameOperators) {
    case "is":
      return {
        filter_type: "filter",
        filter_value: "term",
      };
    case "is_not":
      return {
        filter_type: "exclude",
        filter_value: "term",
      };
    case "contains":
      return {
        filter_type: "query",
        filter_value: "prefix",
      };
    case "greater_than":
      return {
        filter_type: "filter",
        filter_value: "range",
        filter_operator: "gt",
      };
    case "less_than":
      return {
        filter_type: "filter",
        filter_value: "range",
        filter_operator: "lt",
      };
  }
}

export function attributeLabelFor(
  fieldName: FeedbackFieldName | CrashesFieldName | UsersFieldName | string,
  list?: boolean,
) {
  switch (fieldName) {
    case "title":
    case "issue_title":
      return "Ticket title";
    case "message":
      return "Message";
    case "assignee_id":
      return "Assignee";
    case "assign_issue":
      return "Set assignee";
    case "app_version.full_version":
    case "issue_app_version":
      return "App version";
    case "browser_version.full_version":
      return "Browser version";
    case "browser_name":
      return "Browser name";
    case "os_name":
      return "OS name";
    case "cpu_arch":
      return "CPU";
    case "authentication":
      return "Authentication";
    case "city":
      return "City";
    case "country":
      return "Country";
    case "current_view":
    case "issue_current_view":
      return "Current View";
    case "device":
      return "Device";
    case "device_orientation":
      return "Device Orientation";
    case "locale":
      return "Locale";
    case "tags":
    case "tag":
    case "issue_contains_tag":
      return "Tag";
    case "tag_issue":
      return "Add tag";
    case "status":
      return "Status";
    case "set_issue_status":
      return "Set status";
    case "priority":
      return "Priority";
    case "set_issue_priority":
      return "Set priority";
    case "low_power_mode":
      return "Low Power Mode";
    case "xcode_version":
      return "IDE version";
    case "network_type":
      return "Network type";
    case "is_mobile":
      return "Website mode";
    case "cookies_enabled":
      return "Cookies";
    case "nfc":
      return "NFC";
    case "os_version.full_version":
      return "OS version";
    case "tester_email":
      return list ? (
        <Fragment>
          Email <DeprecatedBanner>Deprecated</DeprecatedBanner>
        </Fragment>
      ) : (
        "Email"
      );
    case "sdk_version.full_version":
      return "SDK version";
    case "metadata_.first_name":
      return "User first name";
    case "metadata_.last_name":
      return "User last name";
    case "banned":
      return "User mode";
    case "end_user_id":
      return "User ID";
    case "log_type":
      return "Log type";
    case "response_body":
      return "Response body";
    case "request_body":
      return " Request body";
    case "device_orientation":
      return "Orientation";
    case "unread":
      return "Notification";
  }
  if (fieldName.includes("metadata_.") || fieldName.includes("custom_field.")) {
    return fieldName.split(".")?.[1];
  }
  return fieldName;
}

export function attributeIconFor(
  fieldName: FeedbackFieldName | CrashesFieldName | UsersFieldName | string,
  customFields?: { [key: string]: string }[],
  platform?: Platform,
) {
  switch (fieldName) {
    case "title":
    case "issue_title":
    case "message":
    case "response_body":
    case "request_body":
      return <Icon.TitleIcon />;
    case "unread":
      return <Icon.NotificationIcon />;
    case "assignee_id":
    case "assign_issue":
      return <Icon.AssigneeIcon />;
    case "locale":
      return <DetailsPaneIcons.LanguageIcon />;
    case "nfc":
      return <DetailsPaneIcons.NfcIcon />;
    case "xcode_version":
      return <DetailsPaneIcons.IDEIcon />;
    case "browser_name":
      return <DetailsPaneIcons.BrowserIcon />;
    case "os_name":
      return <DetailsPaneIcons.OSNameIcon />;
    case "cpu_arch":
      return <DetailsPaneIcons.CPUIcon />;
    case "browser_version.full_version":
      return <DetailsPaneIcons.BrowserVersionIcon />;
    case "app_version.full_version":
    case "issue_app_version":
      return <Icon.AppVersionIcon />;
    case "authentication":
      return <DetailsPaneIcons.AuthIcon />;
    case "device_orientation":
      return <DetailsPaneIcons.OrientationIcon />;
    case "tags":
    case "tag_issue":
    case "issue_contains_tag":
      return <Icon.TagIcon />;
    case "current_view":
    case "issue_current_view":
      return <Icon.CurrentViewIcon />;
    case "is_mobile":
      return <DetailsPaneIcons.MobileDesktopIcon />;
    case "cookies_enabled":
      return <DetailsPaneIcons.CookiesIcon />;
    case "tester_email":
      return <Icon.EmailDeprecated />;
    case "low_power_mode":
      return <DetailsPaneIcons.LowPowerModeIcon />;
    case "device":
      if (platform?.name === PlatformName.WEB) {
        return <DetailsPaneIcons.DeviceWebIcon />;
      }
      return <Icon.DeviceIcon />;
    case "os_version.full_version":
      return <Icon.OSIcon />;
    case "network_type":
      return <DetailsPaneIcons.NetworkIcon />;
    case "priority":
    case "set_issue_priority":
      return <Icon.PriorityIcon />;
    case "status":
    case "set_issue_status":
      return <Icon.StatusIcon />;
    case "sdk_version.full_version":
      return (
        <img
          src={SDK}
          width={20}
          height={20}
        />
      );
    case "metadata_.first_name":
      return <Icon.UserFirstNameIcon />;
    case "metadata_.last_name":
      return <Icon.UserNameIcon />;
    case "end_user_id":
      return <Icon.UserIdIcon />;
    case "log_type":
      return <Icon.LogTypeIcon />;
    case "banned":
      return <Icon.UserModeIcon />;
    case "city":
    case "country":
      return <Icon.CityCountryIcon />;
    default:
      if (customFields && findValueByKey(fieldName, customFields)) {
        return getCustomFieldIcon(findValueByKey(fieldName, customFields) as CustomFieldType) ?? <MetadataIcon />;
      }
      return <MetadataIcon />;
  }
}

export function uxOperatorLabelFor(fieldNameUXOperator: AllFieldNameUXOperators) {
  switch (fieldNameUXOperator) {
    case "contains":
      return "contains";
    case "greater_than":
      return "is greater than";
    case "is":
      return "is";
    case "is_not":
      return "is not";
    case "is_unknown":
      return "is unknown";
    case "not_available":
      return "not available";
    case "less_than":
      return "is less than";
  }
}

export function operatorLabelFor(fieldNameUXOperator: AllFieldNameUXOperators) {
  switch (fieldNameUXOperator) {
    case "contains":
      return "contains";
    case "greater_than":
      return "is greater than";
    case "is":
      return "is";
    case "is_not":
      return "is not";
    case "is_unknown":
      return "is";
    case "not_available":
      return "is";
    case "less_than":
      return "is less than";
  }
  return "";
}

export function isRangeFilter(filter: Filter | RangeFilter): filter is RangeFilter {
  return (filter as RangeFilter).filter_operator != undefined;
}

export function getFieldOperatorForFilter(filter: Filter | RangeFilter): AllFieldNameOperators {
  if (isRangeFilter(filter)) {
    if (filter.filter_operator == "gt") return "greater_than";
    return "less_than";
  }

  if (filter.filter_type == "filter") return "is";

  if (filter.filter_type == "exclude") return "is_not";

  if (filter.filter_type == "query") return "contains";

  return "is";
}

/// Specific for feedback currently

export function getUXOperatorForFilter(filter: Filter | RangeFilter, fieldName: string): UXOperatorDisplay {
  const elasticOperator = getFieldOperatorForFilter(filter);

  let uxOperator = elasticOperator as string;

  if (fieldName === "asignee_id" && filter.fieldValue == null) {
    uxOperator = "is_unknown";
  }

  if ((fieldName === "metadata_.first_name" || fieldName === "metadata_.last_name") && filter.fieldValue == null) {
    uxOperator = "not_available";
  }

  return {
    uxLabel: uxOperatorLabelFor(uxOperator) ?? "",
    uxOperator,
    label: operatorLabelFor(uxOperator),
    operator: elasticOperator,
  };
}

export const prepareFiltersForRequest = (attributes: Attribute[]) => {
  const filteringBody: FilteringBody[] = [];
  attributes.map((element) => {
    if (element.filter) {
      filteringBody.push({
        filter_type: element.filter.filter_type,
        filter_value: element.filter.filter_value,
        filter_operator: "filter_operator" in element.filter ? element.filter.filter_operator : undefined,
        field_name: element.field_name,
        field_value: getFieldValueForRequest(element.field_name, element.filter.fieldValue),
      });
    }
  });
  return filteringBody;
};

const getFieldValueForRequest = (fieldName: string, fieldValue?: string | null) => {
  if (!fieldValue && fieldValue !== null) return "";
  if (fieldValue === null && (fieldName === "assignee_id" || fieldName === "assign_issue")) return "NULL";
  if (fieldValue === null && (fieldName === "metadata_.first_name" || fieldName === "metadata_.last_name")) {
    return "Not available";
  }
  return fieldValue;
};

export const findAssigneeFilterHits = (query: string, filtered: IssueFilter[] = []) => {
  if (!filtered) return [];

  return filtered.filter((hit) => hit.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
};

export function getMappedAssignees(availableFilterHits: IssueFilter[], fieldName: string, wsTeamMembers?: Member[]) {
  if (!wsTeamMembers || !Boolean(wsTeamMembers.length)) return [];

  const mapped = [] as IssueFilter[];
  if (fieldName === "assignee_id" && availableFilterHits.length) {
    availableFilterHits.map((filter) =>
      wsTeamMembers.map((member: Member) => {
        if (member.id === filter.value) {
          mapped.push({ value: member.id, name: member.user.name });
        }
      }),
    );
  }

  if (fieldName === "assign_issue") {
    wsTeamMembers.map((member: Member) => {
      mapped.push({ value: member.id, name: member.user.name });
    });
  }
  return mapped;
}

export function getFieldValue(fieldName: string, members?: Member[], fieldValue?: string | null) {
  if (!fieldName || typeof fieldValue == "undefined") return;

  if (fieldValue == null && (fieldName === "assignee_id" || fieldName === "assign_issue")) return "unknown";

  if (fieldValue === null && (fieldName === "metadata_.first_name" || fieldName === "metadata_.last_name")) {
    return "not available";
  }

  if (fieldName === "unread") {
    return fieldValue !== undefined ? getUnreadNames(fieldValue) : " is missing value";
  }

  if (fieldName === "banned") {
    return fieldValue !== undefined ? getBannedNames(fieldValue) : " is missing value";
  }
  if (
    (fieldName === "assignee_id" || fieldName === "assign_issue") &&
    members &&
    members.find((member) => member.id === fieldValue)
  ) {
    return members.find((member) => member.id === fieldValue)?.user.name;
  }

  return fieldValue;
}

export function getUXOperators(availableUXOperators: Array<AllFieldNameUXOperators>): Array<UXOperatorDisplay> {
  return availableUXOperators.map((uxOperator) => {
    return {
      uxLabel: uxOperatorLabelFor(uxOperator) ?? "",
      operator: uxOperator === "is_unknown" || uxOperator === "not_available" ? "is" : uxOperator,
      uxOperator,
      label: operatorLabelFor(uxOperator),
    };
  });
}

function getBannedNames(value?: string | null) {
  switch (value) {
    case "true":
      return "Blocked";
    default:
      return "Active";
  }
}

function getUnreadNames(value?: string | null) {
  switch (value) {
    case "true":
      return "Unread";
    default:
      return "Read";
  }
}

export function getAvailableFieldNamesByString(text?: string, isMetadata?: boolean) {
  switch (text) {
    case "Notification":
      return "unread";
    case "App version":
      return "app_version.full_version";
    case "Current view":
      return "current_view";
    case "OS":
      return "os_version.full_version";
    case "Device":
      return "device";
    case "Orientation":
      return "device_orientation";
    case "Locale":
      return "locale";
    case "Authentication to unlock device":
      return "authentication";
    case "Low power mode":
      return "low_power_mode";
    case "Network":
      return "network_type";
    case "IDE":
      return "xcode_version";
    case "NFC":
      return "nfc";
    case "Website mode":
      return "is_mobile";
    case "Cookies":
      return "cookies_enabled";
    case "Email":
      return "tester_email";
    case "SDK not up to date":
    case "SDK up to date":
      return "sdk_version.full_version";
    case "Location":
      return "city";
    case "App user ID":
      return "end_user_id";
    default:
      if (isMetadata) return `metadata_.${text}`;
      return text ? text : "";
  }
}

function findValueByKey(keyName: string, array: { [key: string]: string }[]): string {
  let value;

  for (const metadata of array) {
    if (metadata.hasOwnProperty(keyName)) {
      value = metadata[keyName];
    }
  }

  return value ?? "";
}
