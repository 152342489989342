import React, { useCallback, useEffect, useRef } from "react";

import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import env from "../environments";

type PaginationParams = { offset: number; limit: number };

type FetchFnArguments<TDeps> = {
  deps: TDeps;
  pagination: PaginationParams;
};

type FetchFn<TItemType> = (args: FetchFnArguments<Deps> & { signal: AbortSignal }) => Promise<Page<TItemType>>;

type Deps = Array<string | boolean | Array<unknown> | null | undefined>;

type Props<TItemType, TReturnType> = {
  deps: Deps;
  fetchFn: FetchFn<TItemType>;
  enabled: (deps: Deps) => boolean;
  select?: {
    mapper: (items: Array<TItemType>, deps: Deps, mapperDeps: Array<unknown>) => Array<TReturnType>;
    mapperDeps: Array<unknown>;
  };
  onError?: (e: Error) => void;
  queryKey: string;
};

const DEFAULT_LIMIT = env.issuesLimit;

const MAX_LIMIT = 100;

const DEFAULT_PARAMS: PaginationParams = {
  limit: DEFAULT_LIMIT,
  offset: 0,
};

export type Page<TItemType> = {
  total: number;
  items: Array<TItemType>;
};

const defaultPage = { total: 0, items: [] };

export const useShakePagination = <TItemType, TReturnType>({
  deps,
  fetchFn,
  enabled,
  select,
  onError,
  queryKey,
}: Props<TItemType, TReturnType>) => {
  const enabledRef = useRef(enabled);
  const fetchFnRef = useRef(fetchFn);

  const onErrorRef = useRef(onError);
  const errorRef = useRef<Error>();

  const abortRef = useRef(new AbortController());
  const paginationParamsRef = useRef<PaginationParams>(DEFAULT_PARAMS);
  const pageRef = useRef<number>(DEFAULT_PARAMS.offset);

  const queryClient = useQueryClient();

  const {
    data,
    error,
    hasNextPage,
    fetchNextPage,
    refetch: refetchQuery,
    isFetchingNextPage,
    isInitialLoading,
  } = useInfiniteQuery([queryKey, { ...deps, queryKey }], ({ pageParam = 0 }) => executeFetch(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      const nextPage =
        lastPage?.items?.length === DEFAULT_LIMIT
          ? (allPages.length - 1) * DEFAULT_LIMIT + DEFAULT_LIMIT
          : lastPage?.items?.length === MAX_LIMIT
          ? (allPages.length - 1) * MAX_LIMIT + DEFAULT_LIMIT
          : undefined;

      if (nextPage && nextPage === pageRef.current) return nextPage + MAX_LIMIT;
      return nextPage;
    },
    refetchOnReconnect: false,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    notifyOnChangeProps: ["isLoading", "data", "isFetchingNextPage"],
    enabled: enabledRef.current(deps),
  });

  function executeFetch(pageParam: number) {
    return fetchFnRef
      .current({
        deps,
        pagination: {
          limit: pageParam && pageParam !== 0 ? MAX_LIMIT : DEFAULT_LIMIT,
          offset: pageParam ?? 0,
        },
        signal: abortRef.current.signal,
      })
      .then((res: Page<TItemType>) => {
        if (abortRef.current.signal.aborted) return undefined;

        errorRef.current = undefined;

        return res ?? defaultPage;
      })
      .catch((e: Error) => {
        if (abortRef.current.signal.aborted || (e.name && e.name === "CanceledError")) {
          return undefined;
        }
        !!e && onErrorRef.current?.(e);

        if (e && !e.toString().includes("TypeError")) errorRef.current = e;

        return undefined;
      });
  }

  React.useEffect(() => {
    if (
      data &&
      hasNextPage &&
      data.pages.length === 1 &&
      data.pages[0] &&
      data.pages[0].items.length <= DEFAULT_LIMIT
    ) {
      queryClient.fetchQuery([queryKey], fetchNextPage);
    }
  }, [data, queryClient, queryKey, hasNextPage, fetchNextPage]);

  useEffect(() => {
    if (!enabledRef.current(deps)) return;

    paginationParamsRef.current = DEFAULT_PARAMS;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, queryKey]);

  const loadNext = useCallback(() => {
    if (!enabledRef.current(deps)) return;

    abortRef.current.abort();
    abortRef.current = new AbortController();

    fetchNextPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  const refetch = useCallback(() => {
    if (!enabledRef.current(deps)) return;

    abortRef.current.abort();
    abortRef.current = new AbortController();

    paginationParamsRef.current = DEFAULT_PARAMS;

    refetchQuery();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  return {
    isLoading: isInitialLoading,
    isFetchingNextPage,
    isError: Boolean(error) || Boolean(errorRef.current),
    data: data?.pages?.flatMap((page) => page?.items) as Array<TItemType>,
    error: error ? error : errorRef.current ? errorRef.current : undefined,
    mappedData: React.useMemo(() => {
      if (!data?.pages || !select) return undefined;
      // eslint-disable-next-line
      return select.mapper(
        data?.pages?.flatMap((page) => page?.items ?? []),
        deps,
        select.mapperDeps,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.pages, select, ...(select?.mapperDeps ?? []), ...deps]),
    hasMore: hasNextPage ?? false,
    total: data?.pages?.[0]?.total ?? 0,
    loadNext,
    refetchData: refetch,
    deps,
    paginationParamsRef,
  };
};
