import { Plan } from "./Plan.model";

export enum SubscriptionStatus {
  TRIALING = "trialing",
  ACTIVE = "active",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
  PAUSED = "paused",
}

export interface Subscription {
  created: string;
  updated: string;
  current_period_end: string;
  active: boolean;
  status: string;
  cancel_at_period_end: boolean;
  id: string;
  items: Item[];
}

export interface Item {
  active: boolean;
  canceled_at: string | null;
  id: string;
  plan: Plan;
}
