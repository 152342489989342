import React from "react";

import CrashesService from "./services/Crashes.service";
import CrashEventActivityHistoryPageView from "./views/CrashEventActivityHistoryPageView";
import CrashEventPageView from "./views/CrashEventPageView";
import CrashOverviewPageView from "./views/CrashOverviewPageView";
import CrashPageView from "./views/CrashPageView";
import AppsService from "../../../common/services/Apps.service";
import dependencyInjection from "../../../common/util/dependencyInjection";

interface Services {
  crashesService: CrashesService;
  appsService: AppsService;
}

const crashesService = new CrashesService();
const appsService = AppsService.getInstance();

const [CrashesDependencyProvider, useCrashesDeps] = dependencyInjection<Services>({
  services: {
    crashesService,
    appsService,
  },
});

export function CrashesPage() {
  return (
    <CrashesDependencyProvider>
      <CrashPageView />
    </CrashesDependencyProvider>
  );
}

export function CrashOverviewPage() {
  return (
    <CrashesDependencyProvider>
      <CrashOverviewPageView />
    </CrashesDependencyProvider>
  );
}

export function CrashEventPage() {
  return (
    <CrashesDependencyProvider>
      <CrashEventPageView />
    </CrashesDependencyProvider>
  );
}

export function CrashEventActivityHistoryPage() {
  return (
    <CrashesDependencyProvider>
      <CrashEventActivityHistoryPageView />
    </CrashesDependencyProvider>
  );
}

export { useCrashesDeps };
