import { AxiosResponse } from "axios";

import ApiService from "./Api.service";
import { Notification, UserNotificationsRequest, UserReleasesNotificationsRequest } from "../models/notifications";

export default class NotificationsService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  getUnreadNotifications(workspaceId: string, signal?: AbortSignal): Promise<AxiosResponse<Notification[]>> {
    return this.apiService.get({
      resource: `accounts/${workspaceId}/unread_notifications`,
      config: {
        signal,
      },
    });
  }

  markNotificationAsRead(workspaceId: string, notificationId: string) {
    return this.apiService.patch({
      resource: `accounts/${workspaceId}/issue_notification/${notificationId}/mark_read`,
      data: { is_read: true },
    });
  }

  updateUserNotificationSettings(userId: string, workspaceId: string, updateData: UserNotificationsRequest) {
    return this.apiService.patch({
      resource: `accounts/teams/${workspaceId}/members/${userId}/alerts`,
      data: updateData,
    });
  }

  changeUserNotifications(userId: string, updateData: UserReleasesNotificationsRequest) {
    return this.apiService.patch({
      resource: `/accounts/users/${userId}/change_notifications`,
      data: updateData,
    });
  }
}
