import React, { Fragment } from "react";

import Button from "../../../../../../../common/components/Button/Button";
import { HelperText } from "../../../../../../../common/components/HelperText/HelperText";
import Input from "../../../../../../../common/components/Input/Input";
import { ModalFooter } from "../../../../../../../common/components/Modal/ModalFooter/ModalFooter";
import Paragraph from "../../../../../../../common/components/Paragraph/Paragraph";
import { Workspace } from "../../../../../../../common/models";
import { Jira } from "../../../../../../../common/models/integrations";
import { vars } from "../../../../../../../common/styles";
import { Flex } from "../../../../../../../common/styles/reusable/Flex/Flex.styles";
import { Form } from "../../ModalBody.styles";
import { IntegrationHelperText } from "../components/IntegrationHelperText";
import { useJiraServerIntegrationFormConsumer } from "../consumers/useJiraServerIntegrationFormConsumer";
import { BaseIntegrationModalProps } from "../GithubIntegrationModalBody";

interface Props extends BaseIntegrationModalProps {
  integration?: Jira;
  selectedAppId: string;
  workspace: Workspace;
}

export const JiraServerIntegrationModalBody = (props: Props) => {
  const { selectedAppId, workspace, onFinish } = props;

  const { formState, formError, handleFormChange, handleFormSubmit, loading } = useJiraServerIntegrationFormConsumer({
    selectedAppId,
    workspace,
    onFinish,
  });

  const { clientId, clientSecret, baseURL } = formState;
  const { clientId: clientIdErr, clientSecret: clientSecretErr, baseURL: baseUrlErr } = formError;

  const buttonDisabled = !clientId || !clientSecret || !baseURL;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }
  return (
    <Fragment>
      <div style={{ maxHeight: "62vh", overflowY: "auto", overflowX: "hidden" }}>
        <Form
          noValidate
          onSubmit={(event) => event.preventDefault()}
        >
          <HelperText>
            Your server must run over &nbsp;<em>https</em>&nbsp; for the integration to work.
          </HelperText>
          <IntegrationHelperText
            arrowColor={vars.colors.jiraBlue}
            titleComponent={<Fragment>On your Jira Server</Fragment>}
            textElements={[
              <Paragraph key="1">In the upper-right corner choose Administration › Applications</Paragraph>,
              <Paragraph key="2">Under the Integrations choose Application links</Paragraph>,
              <Paragraph key="3">Click Create link and then External application</Paragraph>,
              <div key="4">
                <Paragraph>Select Incoming as the direction and fill in these details</Paragraph>
                <Flex>
                  <Paragraph fontSize={14}>Name&nbsp;&nbsp;</Paragraph>
                  <Paragraph
                    fontSize={14}
                    color={vars.colors.grey30}
                  >
                    Shake
                  </Paragraph>
                </Flex>
                <Flex>
                  <Paragraph fontSize={14}>Redirect URL&nbsp;&nbsp;</Paragraph>
                  <Paragraph
                    fontSize={14}
                    color={vars.colors.grey30}
                  >
                    https://app.shakebugs.com/integration-activation?type=jira
                  </Paragraph>
                </Flex>
                <Flex>
                  <Paragraph fontSize={14}>Permission&nbsp;&nbsp;</Paragraph>
                  <Paragraph
                    fontSize={14}
                    color={vars.colors.grey30}
                  >
                    Admin
                  </Paragraph>
                </Flex>
              </div>,
              <Paragraph key="5">Click Save and paste the data below.</Paragraph>,
            ]}
          />
          <Input
            name="clientId"
            disabled={loading}
            value={clientId}
            label="Client ID"
            error={!!clientIdErr}
            helper={clientIdErr && clientIdErr.message}
            onChange={handleChange}
            placeholder="e.g. xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
          />
          <Input
            name="clientSecret"
            disabled={loading}
            value={clientSecret}
            label="Client secret"
            error={!!clientSecretErr}
            helper={clientSecretErr && clientSecretErr.message}
            onChange={handleChange}
            placeholder="e.g. xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
          />
          <Input
            name="baseURL"
            disabled={loading}
            value={baseURL}
            label="Base URL"
            error={!!baseUrlErr}
            helper={baseUrlErr && baseUrlErr.message}
            onChange={handleChange}
            placeholder="e.g. https://jira.shakebugs.com/"
          />
        </Form>
      </div>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            type="submit"
            disabled={loading || buttonDisabled}
            tooltip={{
              text: "Please enter Client ID, Client secret and Base URL first",
              position: "top",
              disabled: !buttonDisabled,
            }}
            loadingText={loading && "Connecting..."}
            onClick={handleFormSubmit}
          >
            Connect
          </Button>
        }
      />
    </Fragment>
  );
};
