export const getAndParseItemFromLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : undefined;
};

export const getTimedItem = (key: string) => {
  const result = getAndParseItemFromLocalStorage(key);
  if (result) {
    // if the entry is expired
    // remove the entry and return null
    if (result.expireTime <= Date.now()) {
      localStorage.removeItem(key);
      return null;
    }
    return result.data;
  }
  return null;
};

// default expiry is 30 days in milliseconds
export const setTimedItem = (key: string, value: any, maxAge = 30 * 60 * 60 * 1000) => {
  // store the value as object
  // along with expiry date
  const result: {
    data: any;
    expireTime: number | undefined;
  } = {
    data: value,
    expireTime: undefined,
  };

  if (maxAge) {
    // set the expiry
    // from the current date
    result.expireTime = Date.now() + maxAge;
  }

  // stringify the result
  // and the data in original storage
  localStorage.setItem(key, JSON.stringify(result));
};
