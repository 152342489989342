import React, { Fragment } from "react";

import Heading from "../../../../../common/components/Heading/Heading";
import PageTitle from "../../../../../common/components/PageTitle/PageTitle";
import PageWrap from "../../../../../common/components/PageWrap/PageWrap";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import CommonCommentsCard from "../../components/CommonCommentCard/CommonCommentCard";
import { useCommonCommentsConsumer } from "../../consumers/useCommonCommentsConsumer";

export default function CommonCommentsView(): JSX.Element {
  const { loading, commonComments, getCommonComments, deleteCommonComment } = useCommonCommentsConsumer();

  return (
    <Fragment>
      <PageTitle>
        <Heading
          as="h1"
          heading1
          marginBottom={8}
        >
          Common comments
        </Heading>
        <p>Prepare frequent chat replies and notes, that any of you can later use with one click.</p>
      </PageTitle>

      {loading ? (
        <Spinner />
      ) : (
        <PageWrap>
          <CommonCommentsCard
            commonComments={commonComments}
            getCommonComments={getCommonComments}
            disabled={loading}
            deleteCommonComment={deleteCommonComment}
          />
        </PageWrap>
      )}
    </Fragment>
  );
}
