import Paragraph from "@common/components/Paragraph/Paragraph";
import { vars } from "@common/styles";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.8rem;
  margin-bottom: 6.4rem;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 30rem;
  height: 30rem;
  width: 100rem;
  overflow: visible;
`;

export const Title = styled(Paragraph)`
  text-align: center;
  height: 10rem;
  color: ${vars.colors.grey30};
  width: 40rem;
`;

export const Subtitle = styled(Paragraph)`
  text-align: center;
  color: ${vars.colors.grey30};
  width: 40rem;
`;
