import React from "react";

import * as Styled from "./ToggleSection.styles";
import { vars } from "../../styles";
import { Flex } from "../../styles/reusable/Flex/Flex.styles";
import Paragraph from "../Paragraph/Paragraph";
import { ToggleButton } from "../ToggleButton/ToggleButton";

interface ToggleSectionProps {
  title: string;
  subtitle: string;
  bannerText?: string;
  checked: boolean;
  disabled?: boolean;
  name?: string;
  onChange: (checked: boolean, name?: string) => void;
  tooltipText?: string;
  testId?: string;
}

export const ToggleSection = ({
  title,
  subtitle,
  bannerText,
  checked,
  disabled,
  name,
  onChange,
  tooltipText,
  testId,
}: ToggleSectionProps) => {
  return (
    <Styled.Flex
      $justifyContent="space-between"
      $gap={2}
    >
      <Flex
        $gap={0.4}
        $column
      >
        <Flex>
          <Paragraph
            color={vars.colors.grey30}
            marginBottom={0}
          >
            {title}
          </Paragraph>
          {bannerText && <Styled.Banner>{bannerText}</Styled.Banner>}
        </Flex>
        <Paragraph p2>{subtitle}</Paragraph>
      </Flex>
      <Flex
        $alignItems="center"
        $justifyContent="center"
      >
        <Styled.Tooltip
          position="top"
          text={tooltipText ?? ""}
          disabled={!tooltipText}
        >
          <ToggleButton
            testId={testId}
            checked={checked}
            onChange={() => {
              onChange(!checked, name);
            }}
            disabled={disabled}
          />
        </Styled.Tooltip>
      </Flex>
    </Styled.Flex>
  );
};
