import styled, { css } from "styled-components";

import checkIcon from "../../assets/images/icon-check.svg";
import { vars } from "../../styles";

export const Option = styled.div<{
  $checked?: boolean;
  $withIcon?: boolean;
  $withCanvas: boolean;
  $invisibleUntilHovered?: boolean;
  $disabled?: boolean;
}>`
  label {
    position: relative;
    margin-bottom: 0;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    padding-left: 2.4rem;
    font-size: 1.6rem;
    font-weight: 500;
    ${(props) =>
      props.$disabled &&
      css`
        cursor: not-allowed;
        color: ${vars.colors.grey30};
      `}
    &::before {
      content: "";
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 0.5rem;
      border: solid 2px ${vars.colors.grey50};
      transition: ${vars.transitions.hover};
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      ${(props) =>
        props.$disabled &&
        css`
          opacity: 0.3;
        `}
    }

    &::after {
      content: "";
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 0.5rem;
      background-color: ${vars.colors.purple};
      ${(props) =>
        props.$disabled &&
        css`
          background-image: url(${checkIcon});
        `}
      background-image: url(${checkIcon});
      background-position: center;
      background-repeat: no-repeat;
      transition: ${vars.transitions.hover};
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      bottom: 0;
    }
  }

  ${(props) =>
    props.$invisibleUntilHovered &&
    css`
      opacity: 0;
      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    `}

  /* checked */
  ${(props) =>
    props.$checked &&
    css`
      label {
        &::after {
          opacity: 1;
        }
      }
    `}

  ${(props) =>
    props.$checked &&
    props.$disabled &&
    css`
      label {
        &::before {
          border: solid 2px ${vars.colors.purple};
        }
        &::after {
          opacity: 0.3;
        }
      }
    `}
  /* with icon */
  ${(props) =>
    props.$withIcon &&
    css`
      label {
        padding-left: 1.6rem;

        &::before,
        &::after {
          bottom: 0;
        }
      }
    `}
    /* with letter */
  ${(props) => props.$withCanvas && css``}
  input {
    display: none;
    visibility: hidden;
  }

  .image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .canvas-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.8rem;
  }
`;

export const Image = styled.img`
  border-radius: 0.8rem;
  margin-right: 0.8rem;
  margin-left: 0.2rem;
  width: 2.8rem;
  height: 2.8rem;
  object-fit: cover;
`;
