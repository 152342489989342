import React, { Fragment } from "react";

import Button from "../../../../../../common/components/Button/Button";
import Input from "../../../../../../common/components/Input/Input";
import { ModalFooter } from "../../../../../../common/components/Modal/ModalFooter/ModalFooter";
import Paragraph from "../../../../../../common/components/Paragraph/Paragraph";
import { Slack } from "../../../../../../common/models/integrations";
import { vars } from "../../../../../../common/styles";
import { Form } from "../ModalBody.styles";
import { IntegrationHelperText } from "./components/IntegrationHelperText";
import { useSlackIntegrationFormConsumer } from "./consumers/useSlackIntegrationFormConsumer";

export interface BaseIntegrationModalProps {
  onFinish: () => void;
}

interface Props extends BaseIntegrationModalProps {
  integration?: Slack;
  selectedAppId: string;
}

export const SlackIntegrationModalBody = ({ integration, selectedAppId, onFinish }: Props) => {
  const { formState, formError, handleFormChange, handleFormSubmit, disabled } = useSlackIntegrationFormConsumer({
    selectedAppId,
    onFinish,
    integration,
  });

  const { webhook, channel } = formState;
  const { webhook: webhookErr, channel: channelErr } = formError;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isButtonDisabled =
    !webhook || !channel || (webhook === integration?.webhook_url && channel === integration?.channel_name);
  const tooltipText = !webhook || !channel ? "Please enter webhook and channel first" : "No changes have been made";

  return (
    <Fragment>
      <Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <IntegrationHelperText
          arrowColor={vars.colors.slackYellow}
          titleComponent={
            <Fragment>
              Click this link to&nbsp;
              <a
                href="https://my.slack.com/services/new/incoming-webhook"
                target="_blank"
                rel="noreferrer"
              >
                Add Incoming WebHook
              </a>
              &nbsp;on Slack
            </Fragment>
          }
          textElements={[
            <Paragraph key="1">Choose a channel to forward tickets into</Paragraph>,
            <Paragraph key="2">Click Add Incoming WebHooks integration button</Paragraph>,
            <Paragraph key="3">Paste the generated webhook URL and the channel name below</Paragraph>,
          ]}
        />

        <Input
          name="webhook"
          label="Webhook URL"
          value={webhook}
          placeholder="e.g. https://hooks.slack.com/services/T03BC430S/CF8QCE"
          onChange={handleChange}
          error={!!webhookErr}
          helper={webhookErr && webhookErr.message}
          disabled={disabled}
          autoFocus
        />
        <Input
          name="channel"
          disabled={disabled}
          value={channel}
          label="Channel"
          error={!!channelErr}
          helper={channelErr && channelErr.message}
          onChange={handleChange}
          placeholder="e.g. #general"
        />
      </Form>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: tooltipText,
              disabled: !isButtonDisabled || disabled,
            }}
            disabled={isButtonDisabled || disabled}
            loadingText={disabled && (integration ? "Saving..." : "Connecting...")}
            onClick={handleFormSubmit}
          >
            {integration ? "Save changes" : "Connect"}
          </Button>
        }
      />
    </Fragment>
  );
};
