import AppsService from "../common/services/Apps.service";
import AuthService from "../common/services/Auth.service";
import NotificationsService from "../common/services/Notifications.service";
import dependencyInjection from "../common/util/dependencyInjection";

interface AppServices {
  appsService: AppsService;
  notificationsService: NotificationsService;
}

interface AuthServices {
  authService: AuthService;
}

const appsService = AppsService.getInstance();
const notificationsService = new NotificationsService();

const authService = AuthService.getInstance();

const [AppDependencyProvider, useAppDeps] = dependencyInjection<AppServices>({
  services: { appsService, notificationsService },
});

const [AuthDependencyProvider, useAuthDeps] = dependencyInjection<AuthServices>({
  services: { authService },
});

export { AppDependencyProvider, useAppDeps, AuthDependencyProvider, useAuthDeps };
