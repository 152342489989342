import { AxiosResponse } from "axios";

import { EmailSignUpResponse } from "../../../../common/models";
import ApiService from "../../../../common/services/Api.service";
import { UTMTags } from "../../../../common/util/parseUTMTags";

export class SignUpService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  resendConfirmationEmail(email: string): Promise<AxiosResponse<unknown>> {
    return this.apiService.post({
      resource: "accounts/users/resend_confirmation_email",
      data: {
        email: email,
      },
    });
  }

  signUp(email: string, password: string, utmTags: UTMTags): Promise<AxiosResponse<EmailSignUpResponse>> {
    return this.apiService.post<EmailSignUpResponse>({
      resource: `accounts/users`,
      data: {
        email,
        password,
        ...utmTags,
      },
    });
  }
}
