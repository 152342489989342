import React from "react";

import FeaturesLatest from "./FeaturesLatest";
import FeaturesPopular from "./FeaturesPopular";
import dependencyInjection from "../../../../../common/util/dependencyInjection";
import { FeaturesService } from "../../services/Features.service";

interface Services {
  featuresService: FeaturesService;
}

const featuresService = new FeaturesService();

const [FeaturesDependencyProvider, useFeaturessDeps] = dependencyInjection<Services>({
  services: {
    featuresService,
  },
});

export function FeaturesLatestPage() {
  return (
    <FeaturesDependencyProvider>
      <FeaturesLatest />
    </FeaturesDependencyProvider>
  );
}

export function FeaturesPopularPage() {
  return (
    <FeaturesDependencyProvider>
      <FeaturesPopular />
    </FeaturesDependencyProvider>
  );
}

export { useFeaturessDeps };
