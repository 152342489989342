import styled, { css } from "styled-components";

import { vars } from "../../../../../../common/styles";

export const CaretLinks = styled.div<{ $previousEventId?: string; $nextEventId?: string }>`
  div {
    margin-left: 2rem;
    margin-right: 0.2rem;

    &:nth-child(1) {
      ${({ $previousEventId, $nextEventId }) =>
        !$previousEventId &&
        !$nextEventId &&
        `
        width: 1rem;
        opacity: 0;
        pointer-events: none; 
      `}
    }
    a {
      color: ${vars.colors.grey70};

      &:hover,
      &:focus,
      &:active {
        color: ${vars.colors.grey20};
      }
    }
  }
`;

export const CrashNavigationLinks = styled.div`
  display: flex;
  margin-left: 5rem;
`;

export const CrashNavigationLink = styled.a<{
  $active?: boolean;
}>`
  margin: 0 1rem;
  color: ${vars.colors.grey70};
  font-weight: normal;
  &:hover,
  &:focus,
  &:active {
    color: ${vars.colors.grey20};
    border-bottom: 0.1rem dashed ${vars.colors.grey20};
    text-decoration: none;
  }
  ${(props) =>
    props.$active &&
    css`
      color: ${vars.colors.grey20};
      border-bottom: 0.1rem dashed ${vars.colors.grey20};
      text-decoration: none;
    `}
`;
