import Button from "@common/components/Button/Button";
import Paragraph from "@common/components/Paragraph/Paragraph";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48rem;
`;

export const Subtitle = styled(Paragraph)`
  white-space: nowrap;
`;

export const InputWrapper = styled.div`
  margin-bottom: 2.4rem;
  width: 100%;
`;

export const CreateButton = styled(Button)`
  width: 100%;
`;
