import { useState } from "react";

import displayToast from "../../../../../common/components/Toast/displayToast";
import { BaseUserDataRequest, ChangePasswordRequest, SetPasswordRequest } from "../../../../../common/models";
import useUserDataApiConsumer from "../../../../consumers/useUserDataApiConsumer";
import { useAuthContext } from "../../../../context/Auth/Auth.context";
import { useAccountDeps } from "../../Account";

enum ErrorStatusCodes {
  EMAIL_ALREADY_IN_USE = 409,
}

export default function usePersonalInfoApiConsumer() {
  const { userData } = useAuthContext();
  const { fetchUserDataAndDispatch } = useUserDataApiConsumer();

  const [loading, setLoading] = useState(false);

  const { accountService } = useAccountDeps();

  const updateUserInfoBasics = async (updateData: BaseUserDataRequest, oldUserInfo?: BaseUserDataRequest) => {
    try {
      if (!userData?.id) return;

      await accountService.updateBasicAccountInfo(userData.id, updateData);
      await fetchUserDataAndDispatch();

      if (oldUserInfo) renderPersonalInfoUpdateConfirmationToast(oldUserInfo, updateData);
    } catch (error) {
      if (error.response.status === ErrorStatusCodes.EMAIL_ALREADY_IN_USE) {
        displayToast({
          title: "Something went wrong",
          content: "The provided email is already in use, please use a different one",
        });
        return;
      }
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    }
  };

  const changePassword = async (data: ChangePasswordRequest) => {
    try {
      if (!userData?.id) return;

      await accountService.changePassword(userData.id, data);
      await fetchUserDataAndDispatch();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const setPassword = async (data: SetPasswordRequest) => {
    try {
      if (!userData?.id) return;

      await accountService.setPassword(userData.id, data);
      await fetchUserDataAndDispatch();
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    }
  };

  async function handlePictureUpdate(picture: File, toggle?: () => void) {
    try {
      setLoading(true);

      if (!userData?.id || !picture) return;

      await accountService.updatePicture(userData.id, picture);
      await fetchUserDataAndDispatch();
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      toggle?.();
      setLoading(false);
    }
  }

  async function deletePicture(toggle?: () => void) {
    try {
      setLoading(true);

      if (!userData?.id) return;

      await accountService.deletePicture(userData.id);
      await fetchUserDataAndDispatch();
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      toggle?.();
      setLoading(false);
    }
  }

  return { updateUserInfoBasics, changePassword, setPassword, handlePictureUpdate, loading, deletePicture };
}

const renderPersonalInfoUpdateConfirmationToast = (
  oldUserInfo: BaseUserDataRequest,
  newUserInfo: BaseUserDataRequest,
) => {
  if (oldUserInfo.name !== newUserInfo.name && oldUserInfo.email !== newUserInfo.email) {
    return displayToast({
      title: "Check your email",
      content: `Name has been successfully updated and confirmation link to update the email has been sent to ${newUserInfo.email}.`,
    });
  }

  if (oldUserInfo.name !== newUserInfo.name) {
    return displayToast({
      title: "Fantastic!",
      content: "Name successfully updated.",
    });
  }

  if (oldUserInfo.email !== newUserInfo.email) {
    return displayToast({
      title: "Check your email",
      content: `A confirmation link to update the email has been sent to ${newUserInfo.email}.`,
    });
  }
};
