import { useEffect } from "react";

import { Issue } from "@common/models/issueTracking";
import { ServerToClientEvents, SocketEvents } from "@common/models/webSocketEvents";
import useNotificationsApiConsumer from "@main/consumers/useNotificationsApiConsumer";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { Socket } from "socket.io-client";

interface Params {
  socket?: Socket<ServerToClientEvents, never>;
  onNewItem: (newItem: Issue) => void;
}

export default function useUserFeedbackAppendSocketConsumer({ socket, onNewItem }: Params) {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const { fetchUnreadNotificationsAndDispatch } = useNotificationsApiConsumer();

  useEffect(() => {
    if (!selectedWorkspace || !selectedApp || !socket) return;
    const handleIssueAppendEvent = async (event: Issue) => {
      if (selectedWorkspace.notify_new_bug) {
        await fetchUnreadNotificationsAndDispatch(selectedWorkspace.id);
      }

      onNewItem(event);
    };

    socket.on(SocketEvents.APPEND_ISSUE, handleIssueAppendEvent);
    return () => {
      socket.off(SocketEvents.APPEND_ISSUE, handleIssueAppendEvent);
    };
  }, [selectedWorkspace, selectedApp, socket, fetchUnreadNotificationsAndDispatch, onNewItem]);
}
