import { darken } from "polished";

export const vars = {
  colors: {
    pureWhite: "#ffffff",
    white: "#f2f2f7",
    purple: "#6552ff",
    purpleDark: "#342892",
    purpleDarken: darken(0.05, "#6552ff"),
    green: "#46d39a",
    greenLighter: "#46d39a4D",
    greenDarken: "#2CA473",
    red: "#eb5757",
    redDarken: darken(0.05, "#eb5757"),
    redLighter: "#eb57575a",
    pureBlack: "#000000",
    blackDarker: "#161618",
    black: "#1C1C1E",
    grey100: "#2c2c2e",
    grey100Darken: darken(0.05, "#2c2c2e"),
    grey90: "#3A3A3C",
    grey80: "#48484a",
    grey70: "#636366",
    grey60: "#8e8e93",
    grey50: "#aeaeb2",
    grey40: "#c7c7cc",
    grey30: "#D1D1D6",
    grey20: "#E5E5EA",
    grey10: "#F2F2F7",
    plum: "#880fff",
    lavender: "#b77dff",
    grape: "#d75ed7",
    floral: "#e7a5ff",
    sky: "#64b1fd",
    skyLighter: "#64b1fd33",
    orange: "#ff9f0a",
    zapierOrange: "#FF4A00",
    slackYellow: "#ECB22E",
    jiraBlue: "#1B80FF",
    azureBlue: "#0078D4",
    lemon: "#fffb70",
    mustard: "#FBC000",
    lime: "#c2e550",
    limeTransparent: "#c2e5504d",
    mint: "#61f0b6",
    moss: "#4cd266",
    brightRed: "#FF0238",
    darkerRed: "#A62C81",
    brightGreen: "#80E182",
    darkerGreen: "#5EB7B8",
    platinum: "#EBEBEB",
    transparent: "#FFFFFF00",
  },
  fonts: {
    fontSize: "1.6rem",
    fontFamily:
      'Roobert, Roboto, system, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  transitions: {
    hover: "all 0.2s ease-in-out 0s",
    drawer: "all 0.3s ease-out 0s",
  },
  zIndex: {
    sidebar: 0,
    tooltip: 1,
    drawer: 50,
    modalOverlay: 500,
    modalContent: 501,
    toast: 600,
    envRibbon: 999,
    selectDropdownMenu: 50,
  },
};
