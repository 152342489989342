import React, { Fragment } from "react";

import { helpCenterArticles, showHelpCenterArticle } from "@common/util/helpCenterArticles";

import { getStatusColor } from "./LogDetails/NetworkRequestDetails";
import { HelperText } from "../../../../../common/components/HelperText/HelperText";
import { ConsoleLogEventRes, ConsoleLogType, LogType, LogUnionType } from "../../../../../common/models/eventTracking";
import { vars } from "../../../../../common/styles";
import { Flex } from "../../../../../common/styles/reusable/Flex/Flex.styles";
import * as Icon from "../../icons/icons";

export const iconSrcForLog = (log: LogUnionType) => {
  switch (log.instance_type) {
    case LogType.USER_EVENT:
      if (log.class_name.startsWith("#SHAKE")) return <Icon.ShakeIcon />;
      return <Icon.UserEventIcon />;
    case LogType.NOTIFICATION:
      return <Icon.NotificationIcon />;
    case LogType.SYSTEM_EVENT:
      return <Icon.SystemEventIcon />;
    case LogType.SCREEN_EVENT:
      return <Icon.ScreenChangeEventIcon />;
    case LogType.CONSOLE_LOG:
      return getConsoleLogIcon(log);
    case LogType.CUSTOM_LOG:
      return <Icon.CustomLogIcon />;
    case LogType.NETWORK_REQUEST:
      return <Icon.NetworkLogIcon />;
    default:
      return <></>;
  }
};

const getConsoleLogIcon = (log: ConsoleLogEventRes) => {
  switch (log.type) {
    case ConsoleLogType.VERBOSE:
      return <Icon.ConsoleLogIconVerbose />;
    case ConsoleLogType.DEBUG:
      return <Icon.ConsoleLogIconDebug />;
    case ConsoleLogType.INFO:
      return <Icon.ConsoleLogIconInfo />;
    case ConsoleLogType.WARNING:
      return <Icon.ConsoleLogIconWarning />;
    case ConsoleLogType.ERROR:
      return <Icon.ConsoleLogIconError />;
    default:
      return <Icon.ConsoleLogIconVerbose />;
  }
};

export const messageForLog = (log: LogUnionType) => {
  switch (log.instance_type) {
    case LogType.USER_EVENT:
      if (log.class_name.startsWith("#SHAKE"))
        return (
          <p style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {`${log.description}`}
          </p>
        );
      const property = !log.property ? "" : log.property;
      return (
        <p style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {`${log.description} ${log.class_name} ${property}`}
        </p>
      );
    case LogType.NOTIFICATION:
      return (
        <p
          style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >{`${log.title} / ${log.description}`}</p>
      );
    case LogType.SYSTEM_EVENT:
      return (
        <p
          style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >{`${log.description}`}</p>
      );
    case LogType.SCREEN_EVENT:
      return (
        <p
          style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >{`${log.description}`}</p>
      );
    case LogType.CONSOLE_LOG:
      return (
        <p style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{log.message}</p>
      );
    case LogType.CUSTOM_LOG:
      return (
        <p style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{log.message}</p>
      );
    case LogType.NETWORK_REQUEST:
      return (
        <Flex
          $alignItems="center"
          style={{
            overflow: "hidden",
            flex: "1",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <p style={{ color: `${vars.colors.grey30}` }}>{`${log.method}`} </p>
          {log.status_code && (
            <div style={{ paddingLeft: "2rem", display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "0.9rem",
                  height: "0.9rem",
                  borderRadius: "50%",
                  backgroundColor: getStatusColor(log.status_code),
                  marginRight: "0.4rem",
                  color: `${vars.colors.white}`,
                }}
              />
              <p style={{ color: `${vars.colors.grey30}` }}>{log.status_code}</p>
            </div>
          )}
          <p
            style={{
              paddingLeft: "2rem",
            }}
          >
            {log.url}
          </p>
        </Flex>
      );
  }
};

export const getStringLogMessage = (log: LogUnionType) => {
  switch (log.instance_type) {
    case LogType.USER_EVENT:
      if (log.class_name.startsWith("#SHAKE")) return log.description;
      const property = !log.property ? "" : log.property;
      return `${log.description} ${log.class_name} ${property}`;
    case LogType.NOTIFICATION:
      return `${log.title} / ${log.description}`;
    case LogType.SYSTEM_EVENT:
      return `${log.description}`;
    case LogType.SCREEN_EVENT:
      return `${log.description}`;
    case LogType.CONSOLE_LOG:
      return log.message;
    case LogType.CUSTOM_LOG:
      return log.message;
    case LogType.NETWORK_REQUEST:
      return `${log.method} ${log.status_code} ${log.url}`;
  }
};

export const getActivityHistoryHelperText = () => {
  return (
    <div style={{ position: "fixed", right: 20, top: 16 }}>
      <HelperText>
        <a onClick={() => showHelpCenterArticle(helpCenterArticles.activityHistory)}>{"Customize"}</a>
        {" Activity history."}
      </HelperText>
    </div>
  );
};
