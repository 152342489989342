import { Environment } from "../Environment.model";

export const uat: Environment = {
  production: false,
  name: "uat",
  apiHost: "https://api.staging5h4k3.com/api/1.0/",
  publicApiHost: "https://api.staging5h4k3.com/api/public",
  socketioHost: "https://ws.staging5h4k3.com",
  shakeSDKApiKey: "6hbIv8ETS8zVY5J4SEFIsH2LF36Qtmtfhdwj9K7n8fgWjPsDBo34bLc",
  oAuthClientId: "shake_dashboard",
  oAuthClientSecret: "",
  oAuthDemoClientId: "shake_demo",
  oAuthDemoClientSecret: "1jtgxGIyTm3rIbQK15hBe58XmyutdgxhyW5wzl4xGhVGLkRrZIQteJ1",
  oAuthHost: "https://api.staging5h4k3.com/auth/oauth2/",
  segmentWriteKey: "T1WcTo0VWBnxCNYuGx1kxy2zLAXboegS",
  stripeKey:
    "pk_test_51JVcsvGzf6YP1nt4Uys5J22oFFrWOErvYKaAZVDWZzMRSSFvqZImUrHDlQxe1M6aJ2o1Lw2I8NPkIcXliuIe4Xzk00kxpsv5xF",
  sentryDsn: "https://107ce352dff84220bd0ff18a652089dd@o257007.ingest.sentry.io/5312361",
  appURL: "https://app.staging5h4k3.com",
  wwwURL: "https://app.staging5h4k3.com",
  documentationUrl: "https://staging5h4k3.com/docs",
  shareDomain: "uat-react.shk.sh",
  issuesLimit: 20,
  googleAuthEnabled: true,
  youTrackEnabled: false,
  azureEnabled: true,
  zapierEnabled: true,
  trelloEnabled: true,
  trelloAppKey: "1c2affc5f6df8f2b8e567309114f52f5",
  asanaEnabled: true,
  asanaClientId: "1200915521511811",
  githubEnabled: true,
  githubClientId: "10e956f3863d4612e8b0",
  clickupEnabled: true,
  clickupClientId: "V3QX7UYTNTKQXQX6GYMS4H5VLBYK04ZJ",
  cannyUrl: "https://feedback.shakebugs.com",
  logsEnabled: true,
  logsCustomLogsEnabled: true,
  logsNotificationsTrackingEnabled: true,
  blackboxEnabled: true,
  notificationsEnabled: true,
  unreadNotificationsEmailEnabled: true,
  tagsEnabled: true,
  deepSearchEnabled: true,
  billingEnabled: true,
  billingInvoicesEnabled: true,
  crashReportingEnabled: true,
  googleClientId: "846486073736-ss5r3c6tb91b1pdccdh2en1nl76pdv6a.apps.googleusercontent.com",
  jiraCloudClientId: "1gg8obtuJdbt7ivcYbr3Vv1lPO64RF6X",
  linearClientId: "2b8038a74b9e80ffc1ca9bf66d8f73a4",
  sentryEnabled: true,
  sentryClientId: "c6122fa45de8f10151f0d6d7b461349dc98470e604cd87d4554df01a38f7842f",
};
