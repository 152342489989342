import React from "react";

import Linkify from "linkify-react";
import { generatePath, Link } from "react-router-dom";

import * as Styled from "./ChatMessage.styles";
import { ReactComponent as ReadIcon } from "../../../../../common/assets/images/checkmark-read-icon.svg";
import ArrowRight from "../../../../../common/assets/images/tiny-chat-triangle-right.svg";
import ArrowLeft from "../../../../../common/assets/images/tiny-chat-triangle.svg";
import RoundedCanvas from "../../../../../common/components/RoundedCanvas/RoundedCanvas";
import Tooltip from "../../../../../common/components/Tooltip/Tooltip";
import { App, AppUserShared, getUserInitials, getUserName, Workspace } from "../../../../../common/models";
import { IssueUser } from "../../../../../common/models/issueTracking";
import { Member } from "../../../../../common/models/Member.model";
import { Flex } from "../../../../../common/styles/reusable/Flex/Flex.styles";
import identifiers from "../../../../../common/util/identifiers.json";
import { useAppContext } from "../../../../context/App/App.context";
import { useAppSelectionContext } from "../../../../context/App/AppSelectionContext";
import { RoutePaths } from "../../../../router/config/routePaths";
import { getRandomColor } from "../../helpers/getRandomColorHelper";

interface Props {
  messageContent: string;
  messageTimestamp: string;
  user: IssueUser;
  isRead?: boolean;
  appUser?: AppUserShared;
}

export default function ChatMessage({ messageContent, messageTimestamp, user, isRead, appUser }: Props) {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const { wsTeamMembers } = useAppContext();

  return (
    <Flex style={{ marginTop: "1.2rem" }}>
      {user.role === "REGULAR_USER" && getCanvas(user, appUser, wsTeamMembers)}

      <Flex style={{ width: "100%" }}>
        {user.role === "REGULAR_USER" && (
          <img
            src={ArrowLeft}
            style={{ position: "relative", left: "1.2rem" }}
            width={22}
            height={18}
          />
        )}

        <Styled.MessageWrap
          style={{
            marginLeft: user.role !== "REGULAR_USER" ? "4.3rem" : "0rem",
            marginRight: user.role === "REGULAR_USER" ? "4.3rem" : "0rem",
          }}
        >
          <Styled.MessageContentWrap>
            <Styled.MessageMetadata>
              {getUserNameElement(user, appUser, selectedWorkspace, selectedApp)}
              <Styled.MessageTimestamp data-testid={identifiers["central.column.comment.time"]}>
                {messageTimestamp}
              </Styled.MessageTimestamp>

              {user.role === "REGULAR_USER" && (
                <div style={{ marginLeft: "1.2rem", display: "flex" }}>
                  {isRead ? (
                    <Tooltip
                      style={{ width: "1.8rem", height: "1.8rem" }}
                      text="Seen"
                      position="top"
                    >
                      <ReadIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      style={{ width: "1.8rem", height: "1.8rem" }}
                      text="Not seen"
                      position="top"
                    >
                      <Styled.NotRead
                        width={18}
                        height={18}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
            </Styled.MessageMetadata>

            <Styled.MessageContent data-testid={identifiers["central.column.comment.msg"]}>
              <Linkify options={{ target: "_blank" }}>{messageContent}&nbsp;&nbsp;&nbsp;&nbsp;</Linkify>
            </Styled.MessageContent>
          </Styled.MessageContentWrap>
        </Styled.MessageWrap>

        {user.role !== "REGULAR_USER" && (
          <img
            src={ArrowRight}
            style={{ position: "relative", left: "-1.2rem" }}
            width={22}
            height={18}
          />
        )}
      </Flex>
      {user.role !== "REGULAR_USER" && getCanvas(user, appUser, wsTeamMembers)}
    </Flex>
  );
}

const getUserNameElement = (
  user: IssueUser,
  appUser?: AppUserShared,
  selectedWorkspace?: Workspace,
  selectedApp?: App,
) => {
  if (user.role === "MOBILE_SDK" && appUser) {
    return (
      <Link
        data-testid={identifiers["central.column.comment.username"]}
        to={generatePath(RoutePaths.USER_DETAILS, {
          workspaceSlug: selectedWorkspace?.slug ?? null,
          appKey: selectedApp?.key ?? null,
          userId: appUser.id,
        })}
        style={{ textDecoration: "underline" }}
      >
        {getUserName(appUser)}
      </Link>
    );
  }
  return (
    <Styled.MessageSender data-testid={identifiers["central.column.comment.username"]}>
      {user.name}
    </Styled.MessageSender>
  );
};

const getUserNameInitials = (user: IssueUser, appUser?: AppUserShared) => {
  if (user.role === "MOBILE_SDK" && appUser) {
    return getUserInitials(getUserName(appUser));
  }
  return getUserInitials(user.name);
};

const getCanvas = (user: IssueUser, appUser?: AppUserShared, wsTeamMembers?: Member[]) => {
  return (
    <RoundedCanvas
      letter={getUserNameInitials(user, appUser)}
      bgColor={
        user.role === "MOBILE_SDK" && appUser
          ? undefined
          : getRandomColor(user.id)
          ? getRandomColor(user.id).background
          : undefined
      }
      txtColor={
        user.role === "MOBILE_SDK" && appUser
          ? undefined
          : getRandomColor(user.id)
          ? getRandomColor(user.id).text
          : undefined
      }
      size="xxsmall"
      image={
        user.role === "MOBILE_SDK" && appUser
          ? undefined
          : user
          ? wsTeamMembers?.find((member) => member.user.id === user.id)?.user.picture
            ? wsTeamMembers?.find((member) => member.user.id === user.id)?.user.picture ?? undefined
            : undefined
          : undefined
      }
    />
  );
};
