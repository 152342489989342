import React, { Fragment } from "react";

import { ReactComponent as RuleIcon } from "../../../../../../common/assets/images/rule-icon.svg";
import Dropdown, { DropdownItem } from "../../../../../../common/components/Dropdown/Dropdown";
import { CellEnd, CenteredCell } from "../../../../../../common/components/GridTable/GridTable";
import Paragraph from "../../../../../../common/components/Paragraph/Paragraph";
import { Rule } from "../../../../../../common/models/Rule.model";
import { vars } from "../../../../../../common/styles";
import identifiers from "../../../../../../common/util/identifiers.json";

type RulesCardRowProps = {
  rule: Rule;
  setSelectedRule: (rule: Rule) => void;
  modalsOpened?: boolean;
  toggleRemove: () => void;
  toggleRuleModal: () => void;
};

export default function RulesCardRow({
  rule,
  modalsOpened,
  setSelectedRule,
  toggleRemove,
  toggleRuleModal,
}: RulesCardRowProps) {
  return (
    <Fragment>
      <CenteredCell
        maxWidth="500"
        minWidth="500"
      >
        <RuleIcon />

        <Paragraph
          color={vars.colors.grey30}
          data-testid={identifiers["member.approvedDomain.name"]}
        >
          {rule.name}
        </Paragraph>
      </CenteredCell>
      <CenteredCell maxWidth="56">
        <Paragraph>{rule.active ? "Live" : "Paused"}</Paragraph>
      </CenteredCell>

      <CellEnd>
        <Dropdown
          position="right"
          isOpened={modalsOpened ? false : undefined}
        >
          <DropdownItem
            onClick={() => {
              setSelectedRule(rule);
              toggleRuleModal();
            }}
          >
            Settings
          </DropdownItem>
          <DropdownItem
            error
            onClick={() => {
              setSelectedRule(rule);
              toggleRemove();
            }}
          >
            Remove
          </DropdownItem>
        </Dropdown>
      </CellEnd>
    </Fragment>
  );
}
