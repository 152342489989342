import { AxiosResponse } from "axios";

import { TicketSortOption } from "../../../../common/components/ShakeTable/ui/SortHeaderCell";
import { FilteringBody } from "../../../../common/hooks/filtering/sharedTypes";
import { FetchFNResult } from "../../../../common/hooks/filtering/useFilterHitsQuery";
import { Blackbox } from "../../../../common/models/blackbox";
import { CrashActivity, CrashEvent, CrashReport, CrashReportsResponse } from "../../../../common/models/crashReporting";
import { Log } from "../../../../common/models/eventTracking";
import { IssueChatRequest, IssueMessageResponse } from "../../../../common/models/issueTracking";
import { Tag } from "../../../../common/models/Tag.model";
import { TicketPriority } from "../../../../common/models/TicketPriority.model";
import { TicketStatus } from "../../../../common/models/TicketStatus.model";
import ApiService from "../../../../common/services/Api.service";

export default class CrashesService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  getCrashReports(
    workspaceId: string,
    appId: string,
    limit: number,
    offset: number,
    sort: TicketSortOption = "key",
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<CrashReportsResponse>> {
    return this.apiService.get({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_groups?limit=${limit}&offset=${offset}&sort=${sort}`,
      config: { signal: abortSignal },
    });
  }

  getCrashReportsByFilters(
    workspaceId: string,
    appId: string,
    offset = 0,
    limit = 21,
    filters: FilteringBody[],
    sort: TicketSortOption = "key",
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<CrashReportsResponse>> {
    return this.apiService.post({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_groups/filter`,
      config: { signal: abortSignal },
      data: { filters: filters, limit, offset, sort },
    });
  }

  getCrashReportsFiltersHits(
    workspaceId: string,
    appId: string,
    keyword: string,
    filterField: string,
    abortSignal?: AbortSignal,
    limit?: number,
  ): Promise<AxiosResponse<FetchFNResult>> {
    return this.apiService.get({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_groups/filter?search_field=${filterField}&search_text=${encodeURI(
        keyword,
      )}${limit ? `&limit=${limit}` : ""}`,
      config: { signal: abortSignal },
    });
  }

  getCrashReport(
    workspaceId: string,
    appId: string,
    crashReportKey: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<CrashReport>> {
    return this.apiService.get({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_groups/${crashReportKey}`,
      config: { signal: abortSignal },
    });
  }

  getCrashActivity(
    workspaceId: string,
    appId: string,
    crashReportId: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<CrashActivity[]>> {
    return this.apiService.get({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_groups/${crashReportId}/activity`,
      config: { signal: abortSignal },
    });
  }

  deleteCrashReport(workspaceId: string, appId: string, crashReportKey: string): Promise<AxiosResponse<void>> {
    return this.apiService.delete({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_groups/${crashReportKey}`,
    });
  }

  // the response contains all issue tags, not just the one created
  createCrashReportTag(workspaceId: string, crashReportId: string, tagName: string): Promise<AxiosResponse<Tag[]>> {
    return this.apiService.post({
      resource: `crash_reporting/${workspaceId}/crash_groups/${crashReportId}/tag/${tagName}`,
      data: {},
    });
  }

  deleteCrashReportTag(workspaceId: string, crashReportId: string, tagName: string): Promise<AxiosResponse<Tag[]>> {
    return this.apiService.delete({
      resource: `crash_reporting/${workspaceId}/crash_groups/${crashReportId}/tag/${tagName}`,
    });
  }

  getCrashReportEvent(
    workspaceId: string,
    appId: string,
    crashReportEventId: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<CrashEvent>> {
    return this.apiService.get({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_event/${crashReportEventId}`,
      config: { signal: abortSignal },
    });
  }

  getCrashReportEventMessages(
    workspaceId: string,
    appId: string,
    crashReportEventId: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<IssueMessageResponse[]>> {
    return this.apiService.get({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_events/${crashReportEventId}/chat`,
      config: { signal: abortSignal },
    });
  }

  sendCrashReportEventMessage(
    workspaceId: string,
    appId: string,
    crashReportEventId: string,
    message: IssueChatRequest,
  ): Promise<AxiosResponse<IssueMessageResponse[]>> {
    return this.apiService.post({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_events/${crashReportEventId}/chat`,
      data: message,
    });
  }

  updateCrashEvent(
    workspaceId: string,
    appId: string,
    crashEventId: string,
    updateValue: Partial<CrashEvent>,
  ): Promise<AxiosResponse<CrashEvent>> {
    return this.apiService.patch({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_event/${crashEventId}`,
      data: updateValue,
    });
  }

  deleteCrashIssueNote(
    workspaceId: string,
    appId: string,
    messageId: string,
  ): Promise<AxiosResponse<IssueMessageResponse[]>> {
    return this.apiService.delete({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_events_chat/${messageId}`,
    });
  }

  editMessage(
    workspaceId: string,
    appId: string,
    messageId: string,
    newValue: string,
  ): Promise<AxiosResponse<IssueMessageResponse[]>> {
    return this.apiService.patch({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_events_chat/${messageId}`,
      data: {
        message: newValue,
      },
    });
  }

  changeStatus(workspaceId: string, appId: string, crashGroupId: string, status: TicketStatus) {
    return this.apiService.post<{ status: string }>({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_groups/${crashGroupId}/status`,
      data: { status },
    });
  }

  changePriority(workspaceId: string, appId: string, crashGroupId: string, priority: TicketPriority) {
    return this.apiService.post<{ priority: string }>({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_groups/${crashGroupId}/priority`,
      data: { priority },
    });
  }

  changeAssignee(workspaceId: string, appId: string, crashGroupId: string, assigneeId: string | null) {
    return this.apiService.post<{ assignee_id: string }>({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_groups/${crashGroupId}/assignee`,
      data: { assignee_id: assigneeId },
    });
  }

  bulkDeleteIssues(workspaceId: string, appId: string, issues: string[]) {
    return this.apiService.post({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_groups/bulk_delete`,
      data: { crash_groups: issues },
    });
  }

  getActivityHistory(
    workspaceId: string,
    appId: string,
    crashEventId: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<Log>> {
    return this.apiService.get({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_events/${crashEventId}/log`,
      config: { signal: abortSignal },
    });
  }

  downloadActivityHistory(workspaceId: string, appId: string, crashEventId: string) {
    return this.apiService.get<BlobPart>({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_events/${crashEventId}/log/download`,
      config: { responseType: "blob" },
    });
  }

  getBlackbox(workspaceId: string, appId: string, crashEventId: string) {
    return this.apiService.get<Blackbox>({
      resource: `crash_reporting/${workspaceId}/apps/${appId}/crash_events/${crashEventId}/blackbox`,
    });
  }

  bulkChangePriority(workspaceId: string, appId: string, crash_groups: string[], priority: TicketPriority) {
    return this.apiService.post({
      resource: `/crash_reporting/${workspaceId}/apps/${appId}/crash_groups/priority`,
      data: { crash_groups, priority },
    });
  }

  bulkChangeStatus(workspaceId: string, appId: string, crash_groups: string[], status: TicketStatus) {
    return this.apiService.post({
      resource: `/crash_reporting/${workspaceId}/apps/${appId}/crash_groups/status`,
      data: { crash_groups, status },
    });
  }

  bulkChangeAssignee(workspaceId: string, appId: string, crash_groups: string[], assigneeId: string | null) {
    return this.apiService.post({
      resource: `/crash_reporting/${workspaceId}/apps/${appId}/crash_groups/assignee`,
      data: { crash_groups, assignee_id: assigneeId },
    });
  }

  bulkAddTags(workspaceId: string, appId: string, crash_groups: string[], tags: string[]) {
    return this.apiService.post({
      resource: `/crash_reporting/${workspaceId}/apps/${appId}/crash_groups/tag`,
      data: { crash_groups, tags },
    });
  }

  bulkMergeInto(workspaceId: string, appId: string, issues: string[], issue: string) {
    return this.apiService.post({
      resource: `/crash_reporting/${workspaceId}/apps/${appId}/crash_groups/${issue}/link`,
      data: { issues },
    });
  }

  unMergeTicket(workspaceId: string, appId: string, issues: string[], issue: string) {
    return this.apiService.delete({
      resource: `/crash_reporting/${workspaceId}/apps/${appId}/crash_groups/${issue}/link`,
      config: { data: { issues } },
    });
  }

  getLinkedIssues(workspaceId: string, appId: string, issueId: string) {
    return this.apiService.get<CrashReport[]>({
      resource: `/crash_reporting/${workspaceId}/apps/${appId}/crash_groups/${issueId}/link`,
    });
  }
}
