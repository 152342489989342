import styled from "styled-components";

import { ReactComponent as BaseRuleIcon } from "../../../../../common/assets/images/rule-icon.svg";
import { HelperText as BaseHelperText } from "../../../../../common/components/HelperText/HelperText";
import BaseRoundedCanvas from "../../../../../common/components/RoundedCanvas/RoundedCanvas";
import BaseTooltip from "../../../../../common/components/Tooltip/Tooltip";
import { vars } from "../../../../../common/styles";

export const MessageContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToggleWrap = styled.div`
  cursor: default;
  align-self: flex-end;
  margin-right: 4rem;
  height: 3.2rem;

  display: flex;
  align-items: center;
  color: ${vars.colors.grey60};

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const ToggleLabel = styled.span`
  user-select: none;
  white-space: nowrap;
  font-size: 1.2rem;
`;

export const MessageInputWrap = styled.div`
  margin-top: 4rem;
`;

export const SystemMessage = styled.div`
  user-select: text;
  display: flex;
  padding-top: 1.2rem;
  color: ${vars.colors.grey50};
  font-size: 1.4rem;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;

  svg {
    margin-right: 0.8rem;
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const Date = styled.p`
  margin-top: 0.5rem;
  align-items: center;
  font-size: 1.2rem;
  color: ${vars.colors.grey60};
  display: inline;
  text-align: left;
  word-break: keep-all;
`;

export const HelperText = styled(BaseHelperText)`
  margin-top: 2.4rem;
`;

export const Tooltip = styled(BaseTooltip)`
  > span {
    left: -1rem;
  }
`;

export const CommonCommentsTooltip = styled(BaseTooltip)`
  align-items: center;
  display: flex;
`;

export const RoundedCanvas = styled(BaseRoundedCanvas)`
  margin-right: 0.8rem;
`;

export const RuleIcon = styled(BaseRuleIcon)`
  path {
    stroke: ${vars.colors.grey50};
  }
`;

export const ActionButton = styled.div`
  color: ${vars.colors.grey60};
  margin-left: 1.2rem;
  cursor: pointer;
  display: inline;
  font-size: 1.2rem;

  &:hover {
    text-decoration: underline;
    color: ${vars.colors.white};
  }
`;
