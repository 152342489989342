import React from "react";

import * as Styled from "./VideoSection.styles";
import { VideoType } from "../../helpers/videos";
import Video from "../Video/Video";

interface Props {
  videos: VideoType[];
}

export default function VideosSection({ videos }: Props) {
  return (
    <Styled.VideosSection>
      <Styled.Cards>
        {videos.map((video, index) => {
          return (
            <Video
              key={index}
              url={video.url}
              title={video.title}
              duration={video.duration}
              thumbnailUrl={video.thumbnailUrl}
              hasControls
              wide
            />
          );
        })}
      </Styled.Cards>
    </Styled.VideosSection>
  );
}
