import React, { Fragment, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import Button from "../../../../../../../common/components/Button/Button";
import { ModalFooter } from "../../../../../../../common/components/Modal/ModalFooter/ModalFooter";
import SelectDropdown, { Option } from "../../../../../../../common/components/SelectDropdown/SelectDropdown";
import { ToggleSection } from "../../../../../../../common/components/ToggleSection/ToggleSection";
import { forwardingOptions } from "../../../../../../../common/models/integrations";
import { Jira } from "../../../../../../../common/models/integrations";
import { Form } from "../../ModalBody.styles";
import { useJiraCloudIntegrationFormConsumer } from "../consumers/useJiraIntegrationFormConsumer";
import { BaseIntegrationModalProps } from "../GithubIntegrationModalBody";

interface Props extends BaseIntegrationModalProps {
  integration?: Jira;
  selectedAppId: string;
  showToggles: boolean;
}

export const JiraIntegrationModalBody = (props: Props) => {
  const { integration, selectedAppId, onFinish } = props;

  const {
    forwardingOption,
    projectOption,
    projectOptions,
    handleFormChange,
    handleFormSubmit,
    disabled,
    loading,
    twoWaySync,
    fieldMapping,
    isButtonDisabled,
    tooltipText,
  } = useJiraCloudIntegrationFormConsumer({ selectedAppId, onFinish, integration });

  const [searchParams, setSearchParams] = useSearchParams();

  const optionDidChange = (option: Option, name: string | undefined) => {
    name && handleFormChange(name, option);
  };

  useEffect(() => {
    if (searchParams.get("type") && searchParams.get("appId")) {
      searchParams.delete("type");
      searchParams.delete("appId");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const switchDidChange = (newValue: boolean, name?: string) => {
    if (!name) return;
    handleFormChange(name, newValue);
  };

  return (
    <Fragment>
      <Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <SelectDropdown
          name="forwardingOption"
          value={forwardingOption}
          options={forwardingOptions}
          placeholder="Please choose"
          label="Forward tickets"
          onChange={optionDidChange}
          isSearchable={false}
          disabled={disabled || loading}
          hasSubtitles
        />
        <SelectDropdown
          name="projectOption"
          value={projectOption}
          options={projectOptions}
          placeholder="Please choose"
          label="Project"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || loading}
        />

        {props.showToggles && (
          <Fragment>
            <ToggleSection
              name="twoWaySync"
              title="Jira updates Shake"
              bannerText="Unstable &nbsp;• &nbsp;Deprecated"
              subtitle="Changing Jira ticket’s status category will automatically update its Shake status. All other changes to Jira ticket will be listed in Shake ticket too."
              checked={twoWaySync}
              onChange={switchDidChange}
              disabled={disabled || !projectOption || loading}
            />
            <ToggleSection
              name="fieldMapping"
              title="Field mapping"
              bannerText="Unstable &nbsp;• &nbsp;Deprecated"
              subtitle="Shake ticket elements are individually mapped to their own custom Jira fields."
              checked={fieldMapping}
              onChange={switchDidChange}
              disabled={disabled || !projectOption || loading}
            />
          </Fragment>
        )}
      </Form>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: tooltipText,
              disabled: !isButtonDisabled || disabled,
            }}
            disabled={isButtonDisabled || loading}
            loadingText={loading && "Saving..."}
            onClick={handleFormSubmit}
          >
            Save changes
          </Button>
        }
      />
    </Fragment>
  );
};
