import React from "react";

import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath, matchPath, NavLink, Outlet, useLocation } from "react-router-dom";

import Header from "../../../common/components/Header/Header";
import Icon from "../../../common/components/Icon/Icon";
import { SubNavigation } from "../../../common/components/Navigation/SubNavigation/SubNavigation.styles";
import PageContent from "../../../common/components/PageContent/PageContent";
import { ScrollContainer } from "../../../common/components/ScrollContainer/ScrollContainer";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import { Workspace } from "../../../common/models";
import { Flex } from "../../../common/styles/reusable/Flex/Flex.styles";
import { useAppSelectionContext } from "../../context/App/AppSelectionContext";
import { accountRouteConfigs, PathRouteConfig, RouteConfigType } from "../../router/config/routesConfig";

export default function AccountLayoutView() {
  const { selectedWorkspace } = useAppSelectionContext();
  const location = useLocation();

  if (!selectedWorkspace) {
    return <Spinner />;
  }

  const accountLinks = accountRouteConfigs
    .filter(
      (routeConfig) =>
        routeConfig.type === RouteConfigType.PATH && routeConfig.path != RoutePaths.ACCOUNT_NOTIFICATIONS_CONFIRMATION,
    )
    .map((pathRouteConfig) => {
      const config = pathRouteConfig as PathRouteConfig;
      return mapRouteConfigToNavLink(config, selectedWorkspace, !!matchPath(config.path, location.pathname));
    });

  return (
    <>
      <Header>My Account</Header>
      <Flex style={{ flex: 1, paddingLeft: "2rem", height: "100%" }}>
        <SubNavigation className="subnavigation">
          <ul>{accountLinks}</ul>
        </SubNavigation>
        <ScrollContainer>
          <PageContent>
            <Outlet />
          </PageContent>
        </ScrollContainer>
      </Flex>
    </>
  );
}

const mapRouteConfigToNavLink = (config: PathRouteConfig, workspace: Workspace, isActive: boolean) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const to = generatePath(config.path, { workspaceSlug: workspace.slug });

  return (
    <li
      key={config.name}
      className={isActive ? "active" : ""}
    >
      <NavLink
        to={to}
        data-testid={config.testId}
      >
        {config.name}
        <Icon
          icon="caret-right"
          size={10}
        />
      </NavLink>
    </li>
  );
};
