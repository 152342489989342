import { vars } from "@common/styles";
import styled, { css } from "styled-components";

export const Header = styled.div<{ $bottomBorder?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 6.1rem;
  background-color: ${vars.colors.black};
  padding: 1.2rem 2rem 1.2rem 0.8rem;
  z-index: 9;
  cursor: default;
  margin-left: 2.8rem;
  margin-right: 2.8rem;

  ${({ $bottomBorder }) =>
    $bottomBorder &&
    css`
      border-bottom: 1px solid ${vars.colors.grey100};
    `}
  > * {
    &:not(:last-child) {
      margin-right: 3.2rem;
    }
  }
`;
