import { useCallback, useEffect, useState } from "react";

import displayToast from "@common/components/Toast/displayToast";
import { Member } from "@common/models/Member.model";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { useAuthContext } from "@main/context/Auth/Auth.context";

import { useAccountDeps } from "../Account";

export default function useNotificationsConfirmationConsumer() {
  const { userData } = useAuthContext();
  const { selectedWorkspace } = useAppSelectionContext();
  const { accountService } = useAccountDeps();

  const [member, setMember] = useState<Member>();

  const confirmNotifications = useCallback(async () => {
    if (!userData || !selectedWorkspace) return;
    try {
      const { data } = await accountService.confirmNotifications(selectedWorkspace.id, userData.id);
      setMember(data);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
  }, [accountService, selectedWorkspace, userData]);

  useEffect(() => {
    confirmNotifications();
  }, [confirmNotifications]);

  return {
    member,
  };
}
