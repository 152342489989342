import { rgba } from "polished";
import { NavLink as BaseNavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import Paragraph from "../../../../common/components/Paragraph/Paragraph";
import { vars } from "../../../../common/styles";
import { Flex as BaseFlex } from "../../../../common/styles/reusable/Flex/Flex.styles";

export const SidebarWrap = styled.div<{ $active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${vars.colors.grey100};
  width: 7.2rem;
  margin-bottom: 0.3rem;
  border-right: 1px solid ${vars.colors.grey90};

  ${(props) =>
    props.$active &&
    css`
      z-index: 100;
    `};
`;

export const Label = styled(Paragraph)`
  color: ${vars.colors.grey60};
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
  margin-top: 0.2rem;
`;

export const SidebarIconWrap = styled.div`
  position: relative;
`;

export const SidebarCanvasWrap = styled.div`
  > div {
    cursor: pointer;
  }

  padding: 2rem 1.2rem;
  display: flex;
  justify-content: center;
`;

export const SidebarMenu = styled.ul<{ $active: boolean; $account?: boolean }>`
  display: inline-block;
  max-width: 34rem;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 1.3rem 0 #1c1c1e;
  background-color: ${vars.colors.grey100};
  list-style: none;
  padding: 1rem 0;
  margin: 0;
  position: absolute;
  top: 2rem;
  left: 7.1rem;
  transition: ${vars.transitions.hover};
  pointer-events: none;
  opacity: 0;
  z-index: ${vars.zIndex.modalContent};
  min-width: 24rem;
  ${(props) =>
    props.$account &&
    css`
      min-width: 24rem;
      bottom: 2rem;
      top: auto;
    `}
  ${(props) =>
    props.$active &&
    css`
      pointer-events: auto;
      opacity: 1;
    `}
`;

export const SidebarMenuDivider = styled.li`
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: ${vars.colors.grey90};
`;

export const SidebarMenuScroll = styled.div`
  max-height: calc(100vh - 30rem);
  overflow-y: overlay;
  margin-right: 0.8rem;
`;

export const SidebarMenuHeading = styled.li`
  color: ${vars.colors.grey60};
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  word-break: break-word;
`;

export const SidebarMenuItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 3.6rem;
  white-space: nowrap;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: normal;
  color: ${vars.colors.grey30};
  line-height: 1.8rem;
  transition: ${vars.transitions.hover};

  > *:not(:last-child) {
    margin-right: 0.8rem;
  }

  &:hover {
    background-color: ${vars.colors.grey90};

    a > svg {
      filter: invert(0%) sepia(100%) saturate(3%) hue-rotate(159deg) brightness(103%) contrast(101%);
    }
  }

  a {
    display: flex;
    align-items: center;
    color: ${vars.colors.grey30};

    > *:not(:last-child) {
      margin-right: 0.8rem;
    }

    svg {
      margin: -0.4rem -0.9rem;
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: ${vars.colors.white};
    }
  }

  .menu-icon {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-right: 0.8rem;
  }
`;

export const SidebarMenuItemLink = styled.div`
  width: 100%;

  color: ${vars.colors.grey30};
  &:hover {
    text-decoration: none;
    color: ${vars.colors.white};
  }

  a,
  button {
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    padding: 0.8rem 2rem;
    color: ${vars.colors.grey30};
    &:hover {
      text-decoration: none;
      color: ${vars.colors.white};
    }
  }
`;

export const SidebarMenuItemText = styled.span`
  width: 24rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-size: 1.4rem;
`;

export const SidebarLinks = styled.ul`
  flex: 1;
  margin-top: 1rem;
  padding: 0;
  list-style: none;
  z-index: 5;

  a {
    text-decoration: none;

    &::before {
      content: "";
      display: block;
      width: 0.4rem;
      height: 3.6rem;
      border-radius: 2px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0.1rem;
      bottom: 0;
      transition: ${vars.transitions.hover};
    }

    &:hover,
    &:focus-within,
    &:active {
      text-decoration: none;
      p {
        color: ${vars.colors.grey30};
        cursor: pointer;
      }
    }

    &.active {
      &::before {
        height: 1.7rem;
        background-color: ${vars.colors.purple};
      }

      p {
        text-decoration: none;
        color: ${vars.colors.grey30};
      }

      svg {
        path {
          stroke: ${vars.colors.grey30};
        }
      }
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 7.2rem;

      position: relative;

      svg {
        transition: ${vars.transitions.hover};

        path {
          stroke: ${vars.colors.grey60};
        }
      }

      .__react_component_tooltip {
        box-shadow: none;
        margin-right: -3rem;
      }
    }
  }
`;

export const SidebarTop = styled.div``;

export const SidebarBottom = styled.div`
  margin-top: 4rem;

  > * {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
`;

export const SidebarMenuButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${vars.colors.grey30};
  font-size: 1.4rem;
`;

export const NavLink = styled(BaseNavLink)<{ $disabled?: boolean }>`

  p {
      text-decoration: none;
  }
  
  li {
    flex-direction: column;
    cursor: pointer;

    p {
      text-decoration: none;
    }

    &:hover {
      svg {
        path {
          stroke: ${vars.colors.grey30};
        }
      }
      p {
        color: stroke: ${vars.colors.grey30};
        text-decoration: none;
      }
    }
    ${(props) =>
      props.$disabled &&
      css`
        cursor: not-allowed;
        &:hover {
          svg {
            path {
              stroke: ${vars.colors.grey60};
            }
          }
        }
      `}
  }
`;

export const Flex = styled(BaseFlex)`
  color: ${vars.colors.grey30};
  &:hover {
    color: ${vars.colors.white};
  }
`;

export const ModalOverlay = styled.div<{ active?: boolean }>`
  background-color: ${rgba("#000", 0)};
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: ${vars.transitions.hover};
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  /* active */
  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      pointer-events: auto;
      z-index: ${vars.zIndex.modalOverlay};
    `}
`;

export const NotificationIndicator = styled.div`
  border-radius: 50%;
  background-color: ${vars.colors.red};

  width: 2.2rem;
  height: 2.2rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotifBox = styled.div`
  top: 1.2rem;
  right: -1.2rem;
  width: 3rem;
  position: absolute;
  height: 3rem;
  padding: 0.4rem;
  border-radius: 50%;

  background-color: ${vars.colors.grey100};

  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SmallDot = styled.div`
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;

  background-color: ${vars.colors.white};
`;

export const NewBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem 0.6rem 0.2rem;
  gap: 1rem;

  position: absolute;
  width: 3.6rem;
  height: 1.8rem;
  left: 1.4rem;
  top: 4.8rem;

  background: ${vars.colors.green};
  border-radius: 6px;
`;

export const CluesDiv = styled.div`
  padding-top: 0.1rem;
`;
