import React, { PropsWithChildren } from "react";

import * as Styled from "./Form.styles";
import Button from "../../../../../../common/components/Button/Button";
import { ModalProps } from "../../../../../../common/components/Modal/Modal";
import { ModalHeaderProps } from "../../../../../../common/components/Modal/ModalHeader/ModalHeader";
import identifiers from "../../../../../../common/util/identifiers.json";
import { useAuthContext } from "../../../../../context/Auth/Auth.context";
import usePersonalInfoSetPasswordFormConsumer from "../../../consumers/form/usePersonalInfoSetPasswordFormConsumer";

interface Props {
  onSubmitCallback?: () => void;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
  heading?: string;
}

export default function SetPasswordForm({
  children,
  onSubmitCallback,
  Modal,
  internalProps,
  heading,
}: PropsWithChildren<Props>) {
  const { userData } = useAuthContext();
  const { formState, formError, handleFormChange, handleFormSubmit, loading } = usePersonalInfoSetPasswordFormConsumer({
    onSubmitCallback,
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isButtonDisabled = loading || !formState.newPassword;

  return (
    <Modal
      {...internalProps}
      testId={
        userData?.hasPassword
          ? identifiers["info.security.modal.header.change"]
          : identifiers["info.security.modal.header.set"]
      }
      heading={heading}
    >
      <Styled.FormContainer>
        {children}

        <Styled.Form
          onSubmit={handleFormSubmit}
          noValidate
          $isSetPassword
        >
          <Styled.Input
            disabled={loading}
            name="newPassword"
            label="Choose password"
            type="password"
            helper={formError.newPassword?.message}
            error={!!formError.newPassword}
            value={formState.newPassword}
            onChange={handleChange}
            testId={identifiers["info.security.modal.input.set"]}
          />
          <Button
            type="submit"
            disabled={isButtonDisabled}
            testId={identifiers["info.security.modal.button.set"]}
            tooltip={{
              position: "top",
              text: "Please enter your current and new password first",
              disabled: Boolean(formState.newPassword),
            }}
            loadingText={loading && "Saving..."}
          >
            Set password
          </Button>
        </Styled.Form>
      </Styled.FormContainer>
    </Modal>
  );
}
