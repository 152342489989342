import styled from "styled-components";

import { vars } from "../../styles";

export const LargeButtonGroup = styled.div`
  margin-top: 1.6rem;
  border-radius: 1rem;
  border: 1px solid ${vars.colors.grey100};
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
