import React, { Fragment } from "react";

import { Link } from "react-router-dom";

import { BlackboxChartType, BlackboxData, BlackboxNetworkData } from "../../models/blackbox";
import { vars } from "../../styles";
import BlackboxChart from "./BlackboxChart/BlackboxChart";
import BlackboxNetworkChart from "./BlackboxChart/BlackboxNetworkChart";
import { LinkElement } from "./DetailsPane";
import * as Styled from "./DetailsPane.styles";
import { DetailsPaneInfoElement } from "./DetailsPaneInfoElement/DetailsPaneInfoElement";
import { GroupedTicketDetailsElement } from "./helper";

interface DetailsPaneSectionProps {
  info: (GroupedTicketDetailsElement | undefined)[];
  link?: LinkElement;
  title?: string;
  headerTestId?: string;
}

export const DetailsPaneSection: React.FC<DetailsPaneSectionProps> = ({
  info,
  link,
  title = "Ticket details",
  headerTestId,
}): JSX.Element => {
  return (
    <Styled.DetailsPaneSection>
      <Styled.HeadingFlex
        $justifyContent="start"
        $chipButtonBelow={
          Boolean(info[0]?.infoElements.length) &&
          !info[0]?.infoElements[0]?.Dropdown &&
          !info[0]?.infoElements[0]?.isButton
        }
        $noMargin={!Boolean(info[0]?.infoElements.length)}
      >
        <Styled.Heading
          heading4
          testId={headerTestId}
          marginBottom={0}
          fontWeight={500}
        >
          {title}
        </Styled.Heading>
        {link && (
          <Styled.Tooltip
            text={link.tooltipText}
            position="top"
          >
            {getLinkComponent(link)}
          </Styled.Tooltip>
        )}
      </Styled.HeadingFlex>

      <Styled.EssentialsDetails>
        <Styled.Group>
          {Boolean(info.length) &&
            info.map((el, index) => {
              if (el && el?.infoElements && el.infoElements.length) {
                return (
                  <Fragment key={index}>
                    {el.title && (
                      <Styled.Title
                        lineHeight={16}
                        fontSize={12}
                        color={vars.colors.grey70}
                        $chipButton={
                          info[index - 1] &&
                          info[index - 1]?.infoElements[0] &&
                          !info[index - 1]?.infoElements[0]?.Dropdown &&
                          !info[index - 1]?.infoElements[0]?.isButton
                        }
                      >
                        {el.title}
                      </Styled.Title>
                    )}

                    <Styled.DetailsPaneGroupElements>
                      {el.infoElements.map((element, index) => {
                        if (element)
                          if (!element.blackbox)
                            return (
                              <Styled.DetailsPaneColumn
                                key={index}
                                $error={element.isError}
                                $isButton={element.isButton}
                                onClick={element.onElementClick}
                                $isDropdown={Boolean(element.Dropdown)}
                                $fullWidth={element.fullwidth}
                                onMouseEnter={() => element.setHover?.(true)}
                                onMouseLeave={() => element.setHover?.(false)}
                                onMouseOver={() => element.setHover?.(true)}
                                $disabled={element.disabled && !element.isError}
                                $notClickable={element.notClickable}
                              >
                                <DetailsPaneInfoElement
                                  iconComponent={element.iconComponent}
                                  text={element.text}
                                  tip={element.tip}
                                  error={element.isError}
                                  Dropdown={element.Dropdown}
                                  testId={element.testId}
                                  subtitle={element.subtitle}
                                />
                              </Styled.DetailsPaneColumn>
                            );
                        if (element?.blackbox && element.blackbox.data) {
                          return (
                            <Styled.DetailsPaneColumn
                              $blackbox
                              key={index}
                            >
                              {element.blackbox.key === BlackboxChartType.NETWORK ? (
                                <BlackboxNetworkChart
                                  data={element.blackbox.data as BlackboxNetworkData[]}
                                  keyName={element.blackbox.key}
                                  keyValue={element.blackbox.keyValue}
                                />
                              ) : (
                                <BlackboxChart
                                  data={element.blackbox.data as BlackboxData[]}
                                  keyName={element.blackbox.key}
                                  keyValue={element.blackbox.keyValue}
                                />
                              )}
                            </Styled.DetailsPaneColumn>
                          );
                        }
                      })}
                    </Styled.DetailsPaneGroupElements>
                  </Fragment>
                );
              }
            })}
        </Styled.Group>
      </Styled.EssentialsDetails>
    </Styled.DetailsPaneSection>
  );
};

const getLinkComponent = (link: LinkElement) => {
  if (link.isExternal) {
    return (
      <a
        href={link.link}
        target="_blank"
        rel="noreferrer"
      >
        <Styled.PlusIcon />
      </a>
    );
  }
  return (
    <Link
      to={link.link}
      test-id={link.testId}
    >
      <Styled.PlusIcon />
    </Link>
  );
};
