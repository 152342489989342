import React, { Fragment } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import * as Styled from "./Crashes.styles";
import ErrorImage from "../../../../common/assets/images/FTUX/not-found-graphic.png";
import EmptyStateElement from "../../../../common/components/EmptyStateElement/components/EmptyStateElement";
import { Spinner } from "../../../../common/components/Spinner/Spinner";
import Tooltip from "../../../../common/components/Tooltip/Tooltip";
import { useCustomElementInjection } from "../../../../common/hooks/useCustomElementInjection";
import { Flex } from "../../../../common/styles/reusable/Flex/Flex.styles";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import NavigationCustomElement from "../../../layouts/components/PageNavigation/NavigationCustomElement/NavigationCustomElement";
import { ELEMENT_CONTAINER_ID, NAV_LIST } from "../../../layouts/components/PageNavigation/PageNavigation";
import { RoutePaths } from "../../../router/config/routePaths";
import { getActivityHistoryHelperText } from "../../shared/components/ActivityHistoryTable/ActivityHistoryHelpers";
import { ActivityHistoryTable } from "../../shared/components/ActivityHistoryTable/ActivityHistoryTable";
import useCrashEventApiConsumer from "../consumers/useCrashEventApiConsumer";
import useCrashOverviewApiConsumer from "../consumers/useCrashOverviewApiConsumer";

export default function CrashEventActivityHistoryPageView() {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const navigate = useNavigate();
  const params = useParams<{
    workspaceSlug: string;
    appKey: string;
    crashReportKey: string;
    crashReportEventId: string;
  }>();

  const consumer = useCrashOverviewApiConsumer({
    selectedWorkspaceId: selectedWorkspace?.id,
    selectedAppId: selectedApp?.id,
    crashReportKey: params.crashReportKey,
  });

  const eventConsumer = useCrashEventApiConsumer({
    teamId: selectedWorkspace?.id,
    appId: selectedApp?.id,
    crashReportKey: params.crashReportKey,
    crashReportEventId: params.crashReportEventId,
  });

  const CustomElement = consumer.crashOverview ? (
    <Fragment>
      <NavigationCustomElement>
        <Styled.NavigationTitle
          $isButton
          onClick={() =>
            navigate(
              generatePath(RoutePaths.CRASH_REPORT_OVERVIEW, {
                workspaceSlug: selectedWorkspace?.slug ?? null,
                appKey: selectedApp?.key ?? null,
                crashReportKey: params.crashReportKey ?? null,
              }),
            )
          }
        >
          <div>
            <p>{consumer.crashOverview?.title ?? "No description"}</p>
          </div>
        </Styled.NavigationTitle>
        <Styled.NavigationTitle $isButton={false}>
          <Flex>
            <p>Activity history</p>

            <div>
              <Tooltip
                text={!eventConsumer.crashEvent?.log ? "No logs to download" : "Download as .log file"}
                position="bottom"
              >
                <Styled.DownloadIcon
                  disabled={!eventConsumer.crashEvent?.log}
                  onClick={() => {
                    if (eventConsumer.crashEvent?.log) eventConsumer.downloadActivityHistory();
                  }}
                  $disabled={!eventConsumer.crashEvent?.log}
                />
              </Tooltip>
            </div>
          </Flex>
        </Styled.NavigationTitle>
        {getActivityHistoryHelperText()}
      </NavigationCustomElement>
    </Fragment>
  ) : (
    <Fragment />
  );

  useCustomElementInjection({
    parentElementId: NAV_LIST,
    element: CustomElement,
    containerId: ELEMENT_CONTAINER_ID,
    tagName: "li",
  });

  if (consumer.error) {
    return (
      <EmptyStateElement
        title="There was an error loading crash overview. Try refreshing."
        subtitle="The link you followed may be broken, or the app may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  if (!params.crashReportEventId) return <Fragment />;

  if (!eventConsumer.crashEvent) {
    return <Spinner />;
  }

  return (
    <ActivityHistoryTable
      ticketKey={params.crashReportEventId}
      hasLogs={Boolean(eventConsumer.crashEvent?.log)}
      type="crash"
    />
  );
}
