import { AxiosResponse } from "axios";

import ApiService from "../../../../common/services/Api.service";

export class ForgotPasswordService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  forgotPassword(email: string): Promise<AxiosResponse> {
    return this.apiService.post({
      resource: `accounts/users/forgot_password`,
      data: { email },
    });
  }
}
