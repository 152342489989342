import React, { useEffect, useState } from "react";

import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";

import * as Styled from "./QuickActionsMenu.styles";
import AddAppIcon from "../../../../../common/assets/images/floating-menu/mini-icon-add-new-app@2x.png";
import AskAiIcon from "../../../../../common/assets/images/floating-menu/mini-icon-ask-ai@2x.png";
import SendFeedbackIcon from "../../../../../common/assets/images/floating-menu/mini-icon-send-us-feedback@2x.png";
import { ReactComponent as ArrowDownIcon } from "../../../../../common/assets/images/rotating-caret.svg";
import Paragraph from "../../../../../common/components/Paragraph/Paragraph";
import { useLocalStorage } from "../../../../../common/hooks/filtering/useLocalStorage";
import { isModuleRoute } from "../../../../../common/util/moduleRouteHelper";
import { showShake } from "../../../../../common/util/shakeSdkUtils";
import { useAuthContext } from "../../../../context/Auth/Auth.context";
import { RoutePaths } from "../../../../router/config/routePaths";

export function QuickActionsMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceSlug } = useParams();
  const { userData } = useAuthContext();
  const [isOpened, setOpened] = useState(false);

  const { state: wasOpened, setState: setWasOpened } = useLocalStorage<boolean | undefined>(
    "shakebugs.quickMenuWasOpened",
    false,
  );

  useEffect(() => {
    if (isModuleRoute(location.pathname) && !wasOpened) {
      setOpened(true);
      setWasOpened(true);
    }
  }, [location.pathname, setOpened, setWasOpened, wasOpened]);

  const onAddNewAppClick = () => {
    navigate(generatePath(RoutePaths.ADD_APP, { workspaceSlug: workspaceSlug ?? null }));
    setOpened(false);
  };

  const onAskAIClick = () => {
    window.openInkeep();
    setOpened(false);
  };

  const onSendUsFeedbackClick = () => {
    showShake();
    setOpened(false);
  };

  const toggleMenu = () => {
    if (isOpened) {
      setOpened(false);
    } else {
      setOpened(true);
    }
  };

  return (
    <Styled.QuickActionsContainer>
      {isOpened ? (
        <Styled.QuickActions>
          <Styled.QuickActionsInner>
            {userData ? (
              <QuickActionAction
                onClick={onAddNewAppClick}
                image={AddAppIcon}
                text={"Add new app"}
              />
            ) : null}
            <QuickActionAction
              onClick={onAskAIClick}
              image={AskAiIcon}
              text={"Ask AI"}
            />
            <QuickActionAction
              onClick={onSendUsFeedbackClick}
              image={SendFeedbackIcon}
              text={"Send us feedback"}
            />
          </Styled.QuickActionsInner>
        </Styled.QuickActions>
      ) : null}
      <QuickActionsButton
        onClick={toggleMenu}
        isOpened={isOpened}
      />
    </Styled.QuickActionsContainer>
  );
}

interface QuickActionsButtonProps {
  onClick: () => void;
  isOpened: boolean;
}

export const QuickActionsButton = ({ onClick, isOpened }: QuickActionsButtonProps) => {
  const style = {
    transform: isOpened ? "" : "rotate(180deg)",
    transition: "transform 500ms ease",
  };

  return (
    <Styled.QuickActionsButton onClick={onClick}>
      <Styled.QuickActionsButtonInner>
        <ArrowDownIcon style={style} />
      </Styled.QuickActionsButtonInner>
    </Styled.QuickActionsButton>
  );
};

interface QuickActionActionProps {
  onClick: () => void;
  image: string;
  text: string;
}

export const QuickActionAction = ({ onClick, text, image }: QuickActionActionProps) => {
  return (
    <Styled.QuickActionsAction onClick={onClick}>
      <Styled.QuickActionsActionIcon src={image} />
      <Paragraph p2>{text}</Paragraph>
    </Styled.QuickActionsAction>
  );
};
