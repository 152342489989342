import React from "react";

import ErrorImage from "@common/assets/images/FTUX/not-found-graphic.png";
import EmptyStateElement from "@common/components/EmptyStateElement/components/EmptyStateElement";
import PageWrap from "@common/components/PageWrap/PageWrap";
import { BaseTable } from "@common/components/ShakeTable/BaseTable";
import { Spinner } from "@common/components/Spinner/Spinner";
import { useUsersAttributes } from "@common/hooks/filtering/modules/useUsersAttributes";
import { Attributes } from "@common/hooks/filtering/ui/Attributes";
import { AttributesContainer } from "@common/hooks/filtering/ui/Attributes.styles";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { EmptyStateViewConf, EmptyTableView } from "@main/pages/shared/components/EmptyTableView/EmptyTableView";
import { UsersModuleEmptyState } from "@main/pages/shared/components/ModuleEmptyState/UserModuleEmptyState";
import { SdkSetupSteps } from "@main/pages/shared/components/SDKSetup/SdkSetupSteps";
import { resolveEmptyStateView } from "@main/pages/shared/helpers/resolveEmptyStateView";
import useUsersPageApiConsumer from "@main/pages/Users/consumers/useUsersPageApiConsumer";

import { UsersModuleAddAppFooter } from "../../shared/components/AppFooter/AddAppFooter/AddAppFooter";

export default function UsersPageView() {
  const { selectedApp } = useAppSelectionContext();

  const usersAttributeState = useUsersAttributes();

  const { items, loading, error, userTableProps, refetchData } = useUsersPageApiConsumer({
    attributesProps: usersAttributeState,
  });

  // Show SDK not added screen
  if (!selectedApp?.is_approved) {
    return <SdkSetupSteps />;
  }

  // Show error screen
  if (error) {
    return (
      <EmptyStateElement
        title="There was an error loading users. Try refreshing."
        subtitle="The link you followed may be broken, or the workspace may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  const isInitialLoading = typeof items == "undefined" || loading;
  const hasAttributes = Boolean(usersAttributeState.validAttributes.length);
  const hasItems = Boolean(items && items.length > 0);

  const buildAppFooter = () => {
    if (!isInitialLoading && !hasAttributes && !error && !hasItems) return <UsersModuleEmptyState />;
    if (selectedApp?.is_sample) return <UsersModuleAddAppFooter />;
    return null;
  };

  const emptyViewConfig = resolveEmptyStateView(
    loading,
    "users",
    hasItems,
    selectedApp,
    Boolean(usersAttributeState.validAttributes.length),
    Boolean(error),
    refetchData,
  ) as EmptyStateViewConf | undefined;

  return (
    <>
      <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column", overflow: "hidden" }}>
        <AttributesContainer>
          <Attributes
            {...usersAttributeState}
            searchField="end_user_id"
            enableKeyboardShortcut={true}
          />
        </AttributesContainer>
        {emptyViewConfig && (
          <EmptyTableView
            withFilters
            {...emptyViewConfig}
          />
        )}
        {isInitialLoading && <Spinner />}
        {!isInitialLoading && !emptyViewConfig && (
          <BaseTable
            {...userTableProps}
            footer={buildAppFooter()}
            isFetchingNext={userTableProps.isFetchingNext}
          />
        )}
      </PageWrap>
    </>
  );
}
