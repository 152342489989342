import React, { Fragment, useState } from "react";

import { CSSProperties } from "styled-components";

import * as Styled from "./UnsupportedAttachment.styles";
import Paragraph from "../../../../../../common/components/Paragraph/Paragraph";
import Tooltip from "../../../../../../common/components/Tooltip/Tooltip";
import { Attachment, SupportedFileTypes } from "../../../../../../common/models";
import { vars } from "../../../../../../common/styles";
import { Flex } from "../../../../../../common/styles/reusable/Flex/Flex.styles";
import { resolveFileType } from "../../../../../../common/util/files";
import * as Icon from "../icons";

interface Props {
  attachment: Attachment;
  style?: CSSProperties;
}

export const UnsupportedAttachment = ({ attachment, style }: Props) => {
  const [hover, setHover] = useState(false);

  if (!attachment.url) return <Fragment />;

  const url = new URL(attachment.url);

  const filename = decodeURIComponent(url.pathname.split("/").pop()?.trim() || "Unknown file name");

  return (
    <Styled.AttachmentContainer
      style={style}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Styled.Title $alignItems="flex-start">
        <Flex $alignItems="flex-start">{getTypeIcon(resolveFileType(attachment.url))}</Flex>
        <Paragraph
          fontSize={14}
          lineHeight={18}
          color={vars.colors.grey30}
        >
          {getTitle(filename, resolveFileType(attachment.url))}
        </Paragraph>
      </Styled.Title>

      {hover && <DownloadButton url={attachment.url} />}

      {attachment.text && (
        <Styled.AttachmentText $longTitle={filename.length > 32}>
          <Paragraph
            fontSize={14}
            lineHeight={18}
            color={vars.colors.grey70}
          >
            {attachment.text}
          </Paragraph>
        </Styled.AttachmentText>
      )}
    </Styled.AttachmentContainer>
  );
};

const getTypeIcon = (type?: SupportedFileTypes) => {
  switch (type) {
    case SupportedFileTypes.DOC:
      return <Icon.DocxIcon />;
    case SupportedFileTypes.PDF:
      return <Icon.PdfIcon />;
    case SupportedFileTypes.PPT:
      return <Icon.PowerpointIcon />;
    case SupportedFileTypes.TXT:
      return <Icon.LogTxtIcon />;
    case SupportedFileTypes.XLS:
      return <Icon.SheetsIcon />;
    default:
      return <Icon.AttachmentIcon />;
  }
};

const DownloadButton = ({ url }: { url: string }) => {
  return (
    <Styled.ButtonContainer>
      <Tooltip
        text="Download"
        position="top"
      >
        <Styled.DownloadButton
          href={url}
          download
          target="_blank"
        >
          <Icon.DownloadIcon />
        </Styled.DownloadButton>
      </Tooltip>
    </Styled.ButtonContainer>
  );
};

const getTitle = (filename: string, type?: SupportedFileTypes) => {
  const splitFileName = filename.split(".");

  const ext = splitFileName[splitFileName.length - 1];

  const name = filename.slice(0, -ext.length - 1);

  if (type === SupportedFileTypes.TXT) {
    return name.length > 50 ? `${name.slice(0, 50)}....${ext}` : filename;
  }
  return name.length > 55 ? `${name.slice(0, 55)}....${ext}` : filename;
};
