import { useCallback, useEffect, useState } from "react";

import displayToast from "../../../../common/components/Toast/displayToast";
import useAbortController from "../../../../common/hooks/useAbortController";
import { IssueChat, IssueChatRequest, IssueMessage, mapToMessage } from "../../../../common/models/issueTracking";
import { SocketEvents } from "../../../../common/models/webSocketEvents";
import { useAppContext } from "../../../context/App/App.context";
import { useUserFeedbackDeps } from "../UserFeedback";

interface Props {
  selectedWorkspaceId?: string;
  selectedAppId?: string;
  userFeedbackId?: string;
  activityRefresh?: boolean;
  setRefreshActivity?: (refreshActivity: boolean) => void;
}

export default function useUserFeedbackMessagesConsumer({
  selectedWorkspaceId,
  selectedAppId,
  userFeedbackId,
  activityRefresh,
  setRefreshActivity,
}: Props) {
  const [messages, setMessages] = useState<IssueMessage[]>([]);
  const { userFeedbackService } = useUserFeedbackDeps();
  const { abortSignal } = useAbortController();
  const { socket } = useAppContext();

  const getUserFeedbackMessages = useCallback(() => {
    if (!selectedWorkspaceId || !selectedAppId || !userFeedbackId) return;

    userFeedbackService
      .getUserFeedbackMessages(selectedWorkspaceId, selectedAppId, userFeedbackId, abortSignal)
      .then(({ data }) => data.map(mapToMessage))
      .then((messages) => {
        setMessages(messages);
        setRefreshActivity?.(false);
      })
      .catch(() => {
        if (abortSignal.aborted) return;
        displayToast({ title: "Something went wrong", content: "Please try again." });
      });
  }, [selectedWorkspaceId, selectedAppId, userFeedbackId, userFeedbackService, abortSignal, setRefreshActivity]);

  useEffect(() => {
    getUserFeedbackMessages();

    const eventHandler = () => getUserFeedbackMessages();
    socket?.on(SocketEvents.ISSUE_CHAT_CHANGED, eventHandler);

    return () => {
      socket?.off(SocketEvents.ISSUE_CHAT_CHANGED, eventHandler);
    };
  }, [getUserFeedbackMessages, socket]);

  useEffect(() => {
    if (activityRefresh) {
      getUserFeedbackMessages();
    }
  }, [activityRefresh, getUserFeedbackMessages]);

  const sendMessage = (message: IssueChatRequest) => {
    if (!selectedWorkspaceId || !selectedAppId || !userFeedbackId) return;

    userFeedbackService
      .sendUserFeedbackMessage(selectedWorkspaceId, selectedAppId, userFeedbackId, message)
      .then(({ data }) => data.map(mapToMessage))
      .then((messages) => setMessages(messages))
      .catch(() => {
        displayToast({ title: "Something went wrong", content: "Please try again." });
      });
  };

  const deleteMessage = useCallback(
    (message: IssueMessage) => {
      if (!selectedWorkspaceId || !selectedAppId) return;
      userFeedbackService
        .deleteIssueNote(selectedWorkspaceId, selectedAppId, message.id)
        .then(({ data }) => data.map(mapToMessage))
        .then((messages) => setMessages(messages))
        .then(() => {
          displayToast({ content: "Message deleted" });
        })
        .catch(() => {
          displayToast({ title: "Something went wrong", content: "Please try again." });
        });
    },
    [selectedWorkspaceId, selectedAppId, userFeedbackService],
  );

  const editMessage = useCallback(
    (editedMessage: IssueChat) => {
      if (!selectedWorkspaceId || !selectedAppId) return;

      userFeedbackService
        .editIssueNote(selectedWorkspaceId, selectedAppId, editedMessage.id, editedMessage.message)
        .then(({ data }) => data.map(mapToMessage))
        .then((messages) => setMessages(messages))
        .catch(() => {
          displayToast({ title: "Something went wrong", content: "Please try again." });
        });
    },
    [selectedWorkspaceId, selectedAppId, userFeedbackService],
  );

  return { messages, sendMessage, deleteMessage, editMessage };
}
