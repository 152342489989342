import React, { useEffect } from "react";

import * as Styled from "./ModalBody.styles";
import Button from "../../../../../common/components/Button/Button";
import Input, { InputElementType } from "../../../../../common/components/Input/Input";
import { ModalProps } from "../../../../../common/components/Modal/Modal";
import { ModalHeaderProps } from "../../../../../common/components/Modal/ModalHeader/ModalHeader";
import { CommonComment } from "../../../../../common/models/CommonComment.model";
import { useCommonCommentsFormConsumer } from "../../consumers/useCommonCommentsFormConsumer";

interface Props {
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
  commonComment?: CommonComment;
  closeModal?: () => void;
  getCommonComments?: () => Promise<void>;
}

export const CommonCommentModalBody = ({
  Modal,
  internalProps,
  commonComment,
  closeModal,
  getCommonComments,
}: Props) => {
  const { loading, formState, handleFormChange, handleFormSubmit, clearForm } = useCommonCommentsFormConsumer(
    commonComment,
    closeModal,
    getCommonComments,
  );

  const isButtonDisabled =
    (formState.message === commonComment?.message && formState.title === commonComment?.title) ||
    !formState.message ||
    !formState.title;

  const tooltipText =
    !formState.message || !formState.title
      ? "Please enter title and message first"
      : "Please change entered data first";

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void => {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  };

  useEffect(() => {
    if (!internalProps?.active && !commonComment) {
      clearForm();
    }
  }, [internalProps?.active, clearForm, commonComment]);

  return (
    <Modal
      {...internalProps}
      heading={commonComment ? "Edit common comment" : "Add common comment"}
      buttonElement={
        <Button
          disabled={loading || isButtonDisabled}
          size="small"
          type="submit"
          tooltip={{
            position: "top",
            text: tooltipText,
            disabled: !isButtonDisabled || loading,
          }}
          loadingText={loading && "Saving..."}
          onClick={handleFormSubmit}
        >
          {commonComment ? "Save changes" : "Add"}
        </Button>
      }
    >
      <Styled.Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <Input
          disabled={loading}
          name="title"
          label="Common comment name"
          value={formState.title}
          onChange={handleChange}
          placeholder="e.g. Thank you message"
        />
        <Input
          disabled={loading}
          name="message"
          label="Text"
          placeholder={`e.g. Nice. Thanks for the feedback.\n\nWe’ll look into it as soon as possible.`}
          value={formState.message}
          onChange={handleChange}
          rows={15}
          elementType={InputElementType.TEXTAREA}
        />
      </Styled.Form>
    </Modal>
  );
};
