import React, { Fragment, useState } from "react";

import Button from "../../../../../../common/components/Button/Button";
import Dropdown, { DropdownItem } from "../../../../../../common/components/Dropdown/Dropdown";
import { CellEnd, CenteredCell, GridTable } from "../../../../../../common/components/GridTable/GridTable";
import { HelperText } from "../../../../../../common/components/HelperText/HelperText";
import Input from "../../../../../../common/components/Input/Input";
import { ModalFooter } from "../../../../../../common/components/Modal/ModalFooter/ModalFooter";
import Paragraph from "../../../../../../common/components/Paragraph/Paragraph";
import displayToast from "../../../../../../common/components/Toast/displayToast";
import { Webhook } from "../../../../../../common/models/integrations/Webhook.model";
import { vars } from "../../../../../../common/styles";
import { Form, WebhookSecretIcon } from "../ModalBody.styles";
import { useWebhookIntegrationFormConsumer } from "./consumers/useWebhookIntegrationFormConsumer";

export interface BaseIntegrationModalProps {
  onFinish: () => void;
}

interface Props extends BaseIntegrationModalProps {
  integration?: Webhook;
  selectedAppId: string;
}

export const WebhookIntegrationModalBody = ({ integration, selectedAppId, onFinish }: Props) => {
  const { formState, formError, handleFormChange, handleFormSubmit, disabled } = useWebhookIntegrationFormConsumer({
    selectedAppId,
    onFinish,
    integration,
  });

  const { webhook_url, webhook_secret } = formState;
  const { webhook_url: webhookErr } = formError;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isButtonDisabled = !webhook_url || webhook_url === integration?.webhook_url;
  const tooltipText = !webhook_url ? "Please enter webhook and channel first" : "No changes have been made";

  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

  return (
    <Fragment>
      <Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <Paragraph>
          Click the Connect button and a unique Webhook Secret will be generated below — use it to verify the webhook
          events on your end.{" "}
          <a
            href="https://help.shakebugs.com/en/articles/8074301-how-to-add-a-webhook"
            target="_blank"
            rel="noreferrer"
          >
            Webhook specification
          </a>
        </Paragraph>

        <Input
          name="webhook_url"
          label="Endpoint URL"
          value={webhook_url}
          placeholder="e.g. https://api.yourcompany.com/1.0/app-tickets"
          onChange={handleChange}
          error={!!webhookErr}
          helper={webhookErr && webhookErr.message}
          disabled={disabled}
          autoFocus
        />

        <HelperText>
          Click the <i>Connect</i> button and a unique Webhook Secret will be generated below — use it to verify the
          webhook events on your end.
        </HelperText>

        <GridTable
          gridTemplateColumns="repeat(3, auto)"
          style={{ width: "100%" }}
        >
          <CenteredCell gap={8}>
            <WebhookSecretIcon />

            <Paragraph color={vars.colors.grey30}>Webhook Secret</Paragraph>
          </CenteredCell>
          <CenteredCell>
            <Paragraph color={!webhook_secret ? vars.colors.grey80 : vars.colors.grey60}>
              {!webhook_secret ? "Click Connect to generate" : "••••••••••••••••••••••••••••••••••••••"}
            </Paragraph>
          </CenteredCell>

          <CellEnd>
            <Dropdown
              isOpened={isDropdownOpened}
              setIsOpened={setIsDropdownOpened}
            >
              <DropdownItem
                disabled={!webhook_secret}
                onClick={() => {
                  if (!webhook_secret) return;
                  navigator.clipboard.writeText(webhook_secret);
                  displayToast({
                    content: `Webhook secret copied to the clipboard`,
                  });
                  setIsDropdownOpened(false);
                }}
              >
                Copy to clipboard
              </DropdownItem>
            </Dropdown>
          </CellEnd>
        </GridTable>
      </Form>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: tooltipText,
              disabled: !isButtonDisabled || disabled,
            }}
            disabled={isButtonDisabled || disabled}
            loadingText={disabled && (integration ? "Saving..." : "Connecting...")}
            onClick={handleFormSubmit}
          >
            {integration ? "Save changes" : "Connect"}
          </Button>
        }
      />
    </Fragment>
  );
};
