import styled from "styled-components";

import { vars } from "../../styles";

export const HelperText = styled.div`
  padding: 7px 9px 6px 7px;

  display: flex;
  gap: 1rem;

  background-color: ${vars.colors.skyLighter};
  border: 1px solid ${vars.colors.skyLighter};
  border-radius: 0.6rem;

  svg,
  path {
    color: ${vars.colors.white} !important;
    stroke: ${vars.colors.transparent} !important;
  }

  a {
    text-decoration: underline;
  }

  p {
    color: ${vars.colors.white} !important;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;
  }
`;
