import React, { Fragment } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import * as Styled from "./UserFeedback.styles";
import ErrorImage from "../../../../common/assets/images/FTUX/not-found-graphic.png";
import EmptyStateElement from "../../../../common/components/EmptyStateElement/components/EmptyStateElement";
import { Spinner } from "../../../../common/components/Spinner/Spinner";
import Tooltip from "../../../../common/components/Tooltip/Tooltip";
import { useCustomElementInjection } from "../../../../common/hooks/useCustomElementInjection";
import { Flex } from "../../../../common/styles/reusable/Flex/Flex.styles";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import NavigationCustomElement from "../../../layouts/components/PageNavigation/NavigationCustomElement/NavigationCustomElement";
import { ELEMENT_CONTAINER_ID, NAV_LIST } from "../../../layouts/components/PageNavigation/PageNavigation";
import { RoutePaths } from "../../../router/config/routePaths";
import { getActivityHistoryHelperText } from "../../shared/components/ActivityHistoryTable/ActivityHistoryHelpers";
import { ActivityHistoryTable } from "../../shared/components/ActivityHistoryTable/ActivityHistoryTable";
import useUserFeedbackDetailsApiConsumer from "../consumers/useUserFeedbackDetailsApiConsumer";

export default function UserFeedbackActivityHistoryPageView() {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const navigate = useNavigate();
  const params = useParams<{
    workspaceSlug: string;
    appKey: string;
    userFeedbackKey: string;
  }>();

  const consumer = useUserFeedbackDetailsApiConsumer({
    selectedWorkspaceId: selectedWorkspace?.id,
    selectedAppId: selectedApp?.id,
    userFeedbackKey: params.userFeedbackKey,
  });

  const CustomElement = consumer.userFeedbackDetails ? (
    <Fragment>
      <NavigationCustomElement>
        <Styled.NavigationTitle
          $isButton
          onClick={() =>
            navigate(
              generatePath(RoutePaths.USER_FEEDBACK_DETAILS, {
                workspaceSlug: selectedWorkspace?.slug ?? null,
                appKey: selectedApp?.key ?? null,
                userFeedbackKey: params.userFeedbackKey ?? null,
              }),
            )
          }
        >
          <div>
            <p>{consumer.userFeedbackDetails?.title ?? "No description"}</p>
          </div>
        </Styled.NavigationTitle>
        <Styled.NavigationTitle $isButton={false}>
          <Flex>
            <p>Activity history</p>
            <div>
              <Tooltip
                text={!consumer.userFeedbackDetails.log ? "No logs to download" : "Download as .log file"}
                position="bottom"
              >
                <Styled.DownloadIcon
                  disabled={!consumer.userFeedbackDetails.log}
                  onClick={() => {
                    if (consumer.userFeedbackDetails?.log)
                      consumer.downloadActivityHistory(consumer.userFeedbackDetails?.key.toString());
                  }}
                  $disabled={!consumer.userFeedbackDetails.log}
                />
              </Tooltip>
            </div>
          </Flex>
        </Styled.NavigationTitle>
        {getActivityHistoryHelperText()}
      </NavigationCustomElement>
    </Fragment>
  ) : (
    <Fragment />
  );

  useCustomElementInjection({
    parentElementId: NAV_LIST,
    element: CustomElement,
    containerId: ELEMENT_CONTAINER_ID,
    tagName: "li",
  });

  if (consumer.error) {
    return (
      <EmptyStateElement
        title="There was an error loading user feedback. Try refreshing."
        subtitle="The link you followed may be broken, or the app may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  if (!params.userFeedbackKey) return <Fragment />;

  if (!consumer.userFeedbackDetails) {
    return <Spinner />;
  }

  return (
    <ActivityHistoryTable
      ticketKey={params.userFeedbackKey}
      hasLogs={Boolean(consumer.userFeedbackDetails?.log)}
      type="user_feedback"
    />
  );
}
