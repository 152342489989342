import React from "react";

import Card from "../../../../../common/components/Card/Card";
import Heading from "../../../../../common/components/Heading/Heading";
import { ToggleSection } from "../../../../../common/components/ToggleSection/ToggleSection";
import { useAuthContext } from "../../../../context/Auth/Auth.context";
import useNotificationSettingsApiConsumer from "../../consumers/api/useNotificationSettingsApiConsumer";

export default function NotifyMajorReleases() {
  const { userData } = useAuthContext();
  const { updateUserMajorReleasesNotificationSettings, loading } = useNotificationSettingsApiConsumer();

  const toggleNewFeaturesNotificationSettings = () => {
    return updateUserMajorReleasesNotificationSettings({ notify_major_releases: !userData?.notify_major_releases });
  };

  return (
    <Card>
      <Heading
        as="h3"
        heading3
        marginBottom={24}
      >
        Major features releases
      </Heading>

      <div style={{ marginBottom: "0.8rem" }}>
        <ToggleSection
          title="Send me an email"
          subtitle="In average, every 3 months Shake releases a big feature — and I want to stay in the loop"
          disabled={loading}
          checked={!!userData?.notify_major_releases}
          onChange={toggleNewFeaturesNotificationSettings}
        />
      </div>
    </Card>
  );
}
