import React, { useEffect, useMemo, useState } from "react";

import * as Styled from "./ModalBody.styles";
import useFilterByTitleConsumer from "../../../../../../main/consumers/useFilterByTitleConsumer";
import { getStatusIcon } from "../../../../../../main/pages/shared/icons/getIconsHelper";
import { Flex } from "../../../../../styles/reusable/Flex/Flex.styles";
import { CrashReportTableRowItem, IssueTableRowItem, RowType } from "../../../../MasterTable/models/MasterTableModel";
import { ModalHeader } from "../../../../Modal/ModalHeader/ModalHeader";
import Paragraph from "../../../../Paragraph/Paragraph";
import { Spinner } from "../../../../Spinner/Spinner";

export function MergeIntoModalBody<TData>({
  title,
  confirmAction,
  close,
  selectedRows,
  type,
  deselectRows,
}: {
  title: string;
  confirmAction: (rows: TData[], mergeRow: TData) => void;
  close: () => void;
  selectedRows: TData[];
  type?: RowType;
  deselectRows?: () => void;
}) {
  const { filter, filteredData, setFilteredData, loading } = useFilterByTitleConsumer({
    // eslint-disable-next-line
    // @ts-ignore
    row_type: type,
    // eslint-disable-next-line
    // @ts-ignore
    selectedIds: useMemo(() => selectedRows.map((row) => row?.id), [selectedRows]),
  });

  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (!searchValue) {
      setFilteredData([]);
      return;
    }
    const getData = setTimeout(() => {
      filter(searchValue);
    }, 500);

    return () => clearTimeout(getData);
  }, [searchValue, filter, setFilteredData]);

  return (
    <Flex $column>
      <Styled.HeaderContainer>
        <ModalHeader
          heading={title}
          noContent
        />
      </Styled.HeaderContainer>
      <Styled.Container $smallerPadding>
        <Styled.SearchContainer>
          <Styled.Search
            placeholder="Search..."
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.currentTarget.value)}
          />
        </Styled.SearchContainer>

        <Styled.ScrollContainer>
          {loading && <Spinner />}
          {searchValue && !loading && !Boolean(filteredData.length) && (
            <Flex
              $alignItems="center"
              $justifyContent="center"
              style={{ height: "100%" }}
            >
              <Paragraph fontSize={14}>No results to show</Paragraph>
            </Flex>
          )}
          {!searchValue && !Boolean(filteredData.length) && (
            <Flex
              $alignItems="center"
              $justifyContent="center"
              style={{ height: "100%" }}
            >
              <Paragraph fontSize={14}>Start typing to view results</Paragraph>
            </Flex>
          )}
          {!loading && Boolean(filteredData.length) && (
            <Flex $column>
              {filteredData.map((issue: CrashReportTableRowItem | IssueTableRowItem) => {
                return (
                  <Styled.Button
                    onClick={() => {
                      confirmAction(selectedRows, issue as unknown as TData);
                      deselectRows?.();
                      close();
                    }}
                    key={issue.id}
                  >
                    {getStatusIcon(issue.status)}
                    <p
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "35rem",
                      }}
                    >
                      {issue.row_type === RowType.USER_FEEDBACK ? issue.prettyTitle : issue.name}
                    </p>
                  </Styled.Button>
                );
              })}
            </Flex>
          )}
        </Styled.ScrollContainer>
      </Styled.Container>
    </Flex>
  );
}
