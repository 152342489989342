import { AxiosResponse } from "axios";

import environments from "../../../../common/environments";
import { UserToken } from "../../../../common/models";
import ApiService from "../../../../common/services/Api.service";

export class ConfirmSignupService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  confirmSignup(code: string): Promise<AxiosResponse<UserToken>> {
    return this.apiService.post<UserToken>({
      resource: `accounts/users/activation`,
      data: {
        code,
        client_id: environments.oAuthClientId,
      },
    });
  }
}
