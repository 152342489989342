import styled, { css } from "styled-components";

import BaseParagraph from "../../../../../../../common/components/Paragraph/Paragraph";
import { vars } from "../../../../../../../common/styles";

export const HelperTextContainer = styled.div`
  gap: 0.4rem;
  display: flex;
  flex-direction: column;
`;

export const Paragraph = styled(BaseParagraph)`
  a {
    color: ${vars.colors.grey40};
  }
`;

export const Arrow = styled.p<{ $color: string }>`
  ${(props) =>
    props.$color &&
    css`
      color: ${props.$color};
    `}
`;
