interface HelpCenterArticle {
  url: string;
  id: number;
}

const helpCenterArticles = {
  permittedUrl: {
    url: "https://help.shakebugs.com/en/articles/10091201-why-do-we-use-permitted-urls-protecting-your-api-keys",
    id: 10091201,
  },
  sentryExtension: {
    url: "https://help.shakebugs.com/en/articles/9891543-sentry-extension",
    id: 9891543,
  },
  deprecatedClientKeys: {
    url: "https://help.shakebugs.com/en/articles/10080315-why-did-shake-switch-from-client-id-to-api-keys",
    id: 10080315,
  },
  approvedDomains: {
    url: "https://help.shakebugs.com/en/articles/10080319-use-permitted-domains-to-limit-access-and-avoid-spam",
    id: 10080319,
  },
  rules: {
    url: "https://help.shakebugs.com/en/articles/7979662-defining-rules-define-rules-adding-a-rule",
    id: 7979662,
  },
  commonComments: {
    url: "https://help.shakebugs.com/en/articles/7983651-common-comments-auto-reply-messages",
    id: 7983651,
  },
  blockUsers: {
    url: "https://help.shakebugs.com/en/articles/7919363-blocking-spammy-users",
    id: 7919363,
  },
  activityHistory: {
    url: "https://help.shakebugs.com/en/articles/10717951-activity-history",
    id: 10717951,
  },
  integrationStoppedWorking: {
    url: "https://help.shakebugs.com/en/articles/5660834-our-integration-appears-to-have-stopped-working-and-i-m-getting-the-a-hiccup-happened-message",
    id: 5660834,
  },
  slackStoppedWorking: {
    url: "https://help.shakebugs.com/en/articles/4949336-our-slack-integration-appears-to-have-stopped-working",
    id: 4949336,
  },
  jiraDoesntAcceptTickets: {
    url: "https://help.shakebugs.com/en/articles/3474366-i-m-having-trouble-integrating-shake-with-my-jira-project-it-connects-fine-but-jira-doesn-t-accept-tickets",
    id: 3474366,
  },
  jiraNoAttachments: {
    url: "https://help.shakebugs.com/en/articles/5248924-an-integration-with-jira-works-but-the-screenshot-and-other-files-aren-t-attached",
    id: 5248924,
  },
  pushNotifAndroid: {
    url: "https://help.shakebugs.com/en/articles/7931355-how-to-enable-push-notifications-on-android",
    id: 7931355,
  },
  pushNotifIos: {
    url: "https://help.shakebugs.com/en/articles/7931290-how-to-enable-push-notifications-on-ios",
    id: 7931290,
  },
};

const showHelpCenterArticle = (article: HelpCenterArticle) => {
  if (typeof Intercom !== "undefined") {
    Intercom("showArticle", article.id);
  } else {
    window.open(article.url, "_blank")?.focus();
  }
};

export { helpCenterArticles, showHelpCenterArticle };
