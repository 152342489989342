import React, { ReactElement } from "react";

import * as Styled from "./ModalFooter.styles";
import { Flex } from "../../../styles/reusable/Flex/Flex.styles";

export type ModalFooterProps = {
  toggle?: () => void;
  button?: ReactElement;
  leftButton?: ReactElement;
};

export interface ModalButton {
  text: string;
  size?: "small" | "extraSmall" | "full";
  color?: "red" | "grey";
  styling?: "outline" | "light" | "textual";
  onClick?: () => void;
}

export const ModalFooter = ({ toggle, button, leftButton }: ModalFooterProps) => {
  return (
    <Styled.Flex $justifyContent="space-between">
      <Flex $justifyContent="start">{leftButton && leftButton}</Flex>
      <Flex $justifyContent="flex-end">
        {toggle ? (
          <Styled.Button
            $hasPaddingRight={button && true}
            styling="outline"
            onClick={toggle}
            size="small"
          >
            Cancel
          </Styled.Button>
        ) : null}
        {button && button}
      </Flex>
    </Styled.Flex>
  );
};
