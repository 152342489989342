import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import Paragraph from "../../../common/components/Paragraph/Paragraph";
import { vars } from "../../../common/styles";

export const Container = styled.div`
  margin-bottom: 1.2rem;

  li {
    &:hover {
      text-decoration: none;
      p {
        color: ${vars.colors.white};
      }
    }
  }
`;

export const Subtitle = styled(Paragraph)<{ $paddingTop?: boolean }>`
  padding-left: 1.6rem;
  margin-bottom: 0.8rem;

  ${(props) =>
    props.$paddingTop &&
    css`
      padding-top: 1.2rem;
    `}
`;

export const BaseParagraph = styled.div<{ $active?: boolean }>`
  padding: 1.4rem 1.6rem;
  width: 26rem;
  user-select: none;

  &:hover,
  &:active {
    color: ${vars.colors.white};
  }

  ${(props) =>
    props.$active &&
    css`
      color: ${vars.colors.white};
    `}
`;
