import styled from "styled-components";

import { vars } from "../../styles";

export const Toast = styled.div`
  .Toastify__toast-container {
    background-color: transparent;
    max-width: 35rem;
    min-width: 35rem;
  }

  .Toastify__toast {
    border: 1px solid ${vars.colors.grey90};
    border-radius: 1rem;
    background-color: ${vars.colors.grey100};
    box-shadow: 0 2px 10px ${vars.colors.black};
    padding: 2rem;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__close-button {
    color: ${vars.colors.grey80};

    &:hover,
    &:focus,
    &:active {
      color: ${vars.colors.grey30};
    }
  }
`;

export const ToastTitle = styled.h6`
  color: ${vars.colors.grey30};
  font-weight: 100;
  word-break: break-word;
  font-size: 1.6rem;
  padding-bottom: 0.4rem;
`;

export const ToastIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  margin-right: 2.7rem;
  object-fit: contain;
`;

export const ToastContent = styled.div`
  display: flex;
  font-family: ${vars.fonts.fontFamily};

  p {
    font-size: 1.4rem;
    color: ${vars.colors.grey60};
    line-height: 1.29;
    word-break: break-word;
  }

  a {
    text-decoration: underline;
  }
`;
