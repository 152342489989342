import { useState } from "react";

import displayToast from "../../../../common/components/Toast/displayToast";
import { BasicWorkspace } from "../../../../common/models";
import useUserDataApiConsumer from "../../../consumers/useUserDataApiConsumer";
import useWorkspaceAndAppChange from "../../../consumers/useWorkspaceAndAppChange";
import { userWorkspaceJoinDeps } from "../WorkspaceJoin";

const toastCopy = {
  success: {
    title: "Fantastic!",
    content: "You've successfully joined selected workspaces, woohoo.",
  },
};

interface Props {
  toggle?: () => void;
}

export default function useWorkspaceJoinApiConsumer({ toggle }: Props) {
  const { workspaceJoinService } = userWorkspaceJoinDeps();
  const { handleWorkspaceChange } = useWorkspaceAndAppChange();

  const { fetchUserDataAndDispatch } = useUserDataApiConsumer();
  const [loading, setLoading] = useState(false);

  const joinWorkspaces = async (workspaces: BasicWorkspace[]) => {
    if (workspaces.length === 0) return;

    setLoading(true);
    try {
      const workspaceIds = workspaces.map((workspace) => workspace.id);

      await workspaceJoinService.joinWorkspaces(workspaceIds);
      const { workspaces: newWorkspaces } = await fetchUserDataAndDispatch();

      const firstJoinableWS = newWorkspaces.find((ws) => ws.id === workspaceIds[0]);
      if (firstJoinableWS) await handleWorkspaceChange(firstJoinableWS);

      displayToast({ ...toastCopy.success });
    } catch {
    } finally {
      if (toggle) toggle();

      setLoading(false);
    }
  };

  return { joinWorkspaces, loading };
}
