import { useEffect, useState } from "react";

import { Option } from "../../../../../../../common/components/SelectDropdown/SelectDropdown";
import displayToast from "../../../../../../../common/components/Toast/displayToast";
import { useForm } from "../../../../../../../common/hooks/useForm";
import {
  automaticForwardOption,
  IntegrationType,
  manualForwardOption,
} from "../../../../../../../common/models/integrations";
import {
  Github,
  GithubIntegrationInfo,
  GithubIntegrationUpdatePayload,
} from "../../../../../../../common/models/integrations/Github.model";
import { useIntegrationFormConsumer } from "../../../../consumers/useIntegrationFormConsumer";

interface Props {
  integration?: Github;
  selectedAppId: string;
  onFinish: () => void;
}

interface FormState {
  projectOption: Option;
  forwardingOption: Option;
}

export interface GithubOption {
  repoId: string;
  login: string;
  integrationId: string;
}

const initialFormState = {
  projectOption: { value: "{}", label: "Fetching... Please wait, large projects may take a few minutes." },
  forwardingOption: automaticForwardOption,
};

export const useGithubIntegrationFormConsumer = ({ selectedAppId, onFinish, integration }: Props) => {
  const { integrationInfo, integrate, updateIntegration } = useIntegrationFormConsumer<GithubIntegrationInfo[]>({
    selectedAppId,
    type: IntegrationType.GITHUB,
    toggle: onFinish,
  });

  const [loading, setLoading] = useState(false);

  const [projectOptions, setProjectOptions] = useState<Option[]>([]);

  const { formState, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: initialFormState,
    onSubmit: handleConnect,
    validators: {},
  });

  const { projectOption, forwardingOption } = formState;

  async function handleConnect() {
    setLoading(true);
    try {
      if (!integrationInfo) throw new Error();

      setLoading(true);

      const selectedGithubOption = JSON.parse(projectOption.value);

      const payload: GithubIntegrationUpdatePayload = {
        automatic_forward: !!forwardingOption.value,
        integration_id: selectedGithubOption.integrationId,
        repository_id: selectedGithubOption.repoId,
      };

      const endpoint = !!integration ? updateIntegration : integrate;

      await endpoint<Github>(payload);
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      onFinish();
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!integrationInfo) return;

    if (!integrationInfo.length) {
      setProjectOptions([]);
      handleFormChange("projectOption", null);
      return;
    }

    const githubOptions = integrationInfo.flatMap((info) => {
      return info.repositories.map((repo) => {
        const githubOption: GithubOption = {
          repoId: repo.repository_id,
          login: info.login,
          integrationId: info.integration_id,
        };
        return { value: JSON.stringify(githubOption), label: repo.repository_name };
      });
    });

    setProjectOptions([...githubOptions]);

    const preSelectedProject = integration
      ? githubOptions.find((option) => {
          const githubOption = JSON.parse(option.value) as GithubOption;
          return githubOption.repoId === integration.repository_id;
        }) || null
      : null;

    handleFormChange("projectOption", preSelectedProject);

    const preselectedForwardingOption = integration
      ? integration.automatic_forward
        ? automaticForwardOption
        : manualForwardOption
      : automaticForwardOption;

    handleFormChange("forwardingOption", preselectedForwardingOption);
  }, [integrationInfo, handleFormChange, integration]);

  const disabled = !integrationInfo;

  return {
    projectOption,
    projectOptions,
    forwardingOption,
    handleFormChange,
    handleFormSubmit,
    disabled,
    loading,
  };
};
