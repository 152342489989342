import useUserDataApiConsumer from "./useUserDataApiConsumer";

export default function useWorkspaceDataApiConsumer() {
  const { fetchUserDataAndDispatch } = useUserDataApiConsumer();

  const fetchWorkspaceDataAndDispatch = async () => {
    const { workspaces } = await fetchUserDataAndDispatch();
    return { workspaces };
  };

  return { fetchWorkspaceDataAndDispatch };
}
