import { useCallback, useEffect, useState } from "react";

import displayToast from "../../../../common/components/Toast/displayToast";
import { Plan } from "../../../../common/models/billing";
import { isAdmin } from "../../../../common/models/Member.model";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useAdministrationDeps } from "../Administration";

export const useUpcomingBillingConsumer = (newPlan?: Plan, newAddOn?: Plan) => {
  const { selectedWorkspace } = useAppSelectionContext();
  const { administrationService } = useAdministrationDeps();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState<number>();

  const getUpcomingInfo = useCallback(async () => {
    if (!selectedWorkspace || !isAdmin(selectedWorkspace?.role || (!newPlan && !newAddOn))) return;
    const plans = [] as string[];
    setLoading(true);

    try {
      if (newPlan) plans.push(newPlan.id);
      if (newAddOn) plans.push(newAddOn.id);

      const { data } = await administrationService.getUpcomingInfo(selectedWorkspace.id, plans);
      setAmount(data.amount);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while fetching customer info",
      });
    } finally {
      setLoading(false);
    }
  }, [administrationService, selectedWorkspace, newAddOn, newPlan]);

  useEffect(() => {
    getUpcomingInfo();
  }, [getUpcomingInfo]);

  return {
    getUpcomingInfo,
    loading,
    amount,
  };
};
