import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { AppNotification } from "../../../common/models/notifications";

const UNREAD_NOTIFICATIONS_KEY = "shakebugs.dash.unread";

type Context = {
  unreadNotifications: Record<string, AppNotification[]>;
  setUnreadNotifications: (newUnread: Record<string, AppNotification[]>) => void;
};

const unreadNotificationsContext = React.createContext<Context>({
  unreadNotifications: {},
  setUnreadNotifications: () => {
    return;
  },
});

export function useUnreadNotificationsContext() {
  return useContext(unreadNotificationsContext);
}

export const UnreadNotificationsProvider = ({ children }: { children: ReactNode }) => {
  const { state: unreadNotifications, setState: setUnreadNotifications } =
    useTabStorageState<Record<string, AppNotification[]>>(UNREAD_NOTIFICATIONS_KEY);

  const setUnread = useCallback(
    (newUnread: Record<string, AppNotification[]>) => {
      setUnreadNotifications(newUnread);
    },
    [setUnreadNotifications],
  );

  return (
    <unreadNotificationsContext.Provider
      value={{ unreadNotifications: unreadNotifications ?? {}, setUnreadNotifications: setUnread }}
    >
      {children}
    </unreadNotificationsContext.Provider>
  );
};

const useStorageState = <T,>(key: string) => {
  const [state, setState] = useState<T | undefined>(() => {
    const stored = localStorage.getItem(key);
    if (!stored) return undefined;
    let state;
    try {
      state = JSON.parse(stored) as T;
    } catch (error) {}
    return state;
  });

  useEffect(() => {
    if (!state) {
      localStorage.removeItem(key);
      return;
    }

    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      localStorage.removeItem(key);
    }
  }, [state, key]);

  return {
    state,
    setState,
  };
};

const useTabStorageState = <T,>(key: string) => {
  const { state, setState } = useStorageState<T>(key);

  useEffect(() => {
    const storageListener = () => {
      const stored = localStorage.getItem(key);
      if (!stored) {
        setState(undefined);
        return;
      }

      let state;
      try {
        state = JSON.parse(stored) as T;
      } catch (error) {}

      setState(state);
    };
    window.addEventListener("storage", storageListener);
    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, [key, setState]);

  return { state, setState };
};
