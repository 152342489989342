import React from "react";

import { SignInService } from "./services/SignInService";
import { SignInView } from "./views/SignInView";
import dependencyInjection from "../../../common/util/dependencyInjection";
import RedirectIfAuthenticated from "../shared/components/RedirectIfAuthenticated";

interface Services {
  signInService: SignInService;
}

const signInService = new SignInService();

const [SignInDependencyProvider, useSignInDeps] = dependencyInjection<Services>({
  services: { signInService },
});

const SignInPage = () => {
  return (
    <RedirectIfAuthenticated>
      <SignInDependencyProvider>
        <SignInView />
      </SignInDependencyProvider>
    </RedirectIfAuthenticated>
  );
};

export { useSignInDeps, SignInPage };
