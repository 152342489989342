import { Tag } from "../../../models";
import { LogUnionType } from "../../../models/eventTracking";
import { Issue } from "../../../models/issueTracking";
import { TicketPriority } from "../../../models/TicketPriority.model";
import { TicketStatus } from "../../../models/TicketStatus.model";

export type RowItemUnionType = IssueTableRowItem | CrashReportTableRowItem | UserTableRowItem | LogUnionRowItem;

export type UserTableRowItemRes = {
  id: string;
  firstName: string;
  lastName: string;
  endUserId: string;
  banned?: boolean;
  city: string;
  country: string;
  signedUp: string;
  tickets_count: number;
};

export interface UserTableRowItem extends UserTableRowItemRes {
  row_type: RowType.USER;
}

export type CrashReportTableRowItemRes = {
  id: string;
  key: number;
  name: string;
  line: string;
  method: string;
  tags: Tag[];
  highestVersion: number;
  events: number;
  status: TicketStatus;
  priority: TicketPriority;
  assignee: { name: string; id: string | null; picture: string | null };
  linked_crash_groups_count: number;
};

export interface CrashReportTableRowItem extends CrashReportTableRowItemRes {
  row_type: RowType.CRASH_REPORT;
}

export interface IssueTableRowItemRes {
  id: string;
  key: number;
  prettyTitle: string;
  appId: string;
  orientation: string;
  status: Exclude<TicketStatus, TicketStatus.LOCKED>;
  tags: Tag[];
  priority: TicketPriority;
  issue_reported_time: string;
  showNotificationIndicator: boolean;
  original: Issue;
  assignee: { name: string; id: string | null; picture: string | null };
  app_user_id: string | null;
  linked_issues_count: number;
}

export interface IssueTableRowItem extends IssueTableRowItemRes {
  row_type: RowType.USER_FEEDBACK;
}

export type LogUnionRowItem = LogUnionType & {
  row_type: RowType.LOG_UNION;
  messageElement: () => JSX.Element | string;
  logIcon: () => JSX.Element;
  response_body: string | null;
  request_body: string | null;
};

export enum RowType {
  USER = "user",
  USER_FEEDBACK = "user_feedback",
  CRASH_REPORT = "crash_report",
  LOG_UNION = "log_union",
}
