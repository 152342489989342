import React from "react";

import { DropdownItem } from "../../../components/Dropdown/Dropdown.styles";
import { useAttributesContext } from "../context/AttributesProvider";
import { attributeLabelFor } from "../utils";
import * as Styled from "./Attributes.styles";

export function AttributeSelectorPopover() {
  const { availableAttributes, add, getAttributeIcon } = useAttributesContext();
  return (
    <Styled.PopoverInput>
      <Styled.AttributesSelectorDropdownScroll>
        {availableAttributes.map((field, index) => {
          return (
            <Styled.AttributesSelector
              key={index}
              style={{}}
            >
              {field.title && (
                <Styled.FieldGroupTitle>
                  <p>{field.title}</p>
                </Styled.FieldGroupTitle>
              )}
              {field.values
                ? field.values.map((attributeName) => {
                    return (
                      <DropdownItem
                        key={attributeName}
                        onClick={() => add(attributeName)}
                      >
                        {getAttributeIcon(attributeName)}
                        <Styled.FieldName>{attributeLabelFor(attributeName, true)}</Styled.FieldName>
                      </DropdownItem>
                    );
                  })
                : field?.valuesWithId?.map((value) => {
                    return (
                      <DropdownItem
                        key={value.id}
                        onClick={() => add(value.name, value.id)}
                      >
                        {getAttributeIcon(value.name)}
                        <Styled.FieldName>{attributeLabelFor(value.name, true)}</Styled.FieldName>
                      </DropdownItem>
                    );
                  })}
            </Styled.AttributesSelector>
          );
        })}
      </Styled.AttributesSelectorDropdownScroll>
    </Styled.PopoverInput>
  );
}
