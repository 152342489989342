import { BaseUserDataRequest, BaseUserDataResponse, ChangePasswordRequest, SetPasswordRequest } from "@common/models";
import { Member } from "@common/models/Member.model";
import ApiService from "@common/services/Api.service";
import { AxiosResponse } from "axios";

export class AccountService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  updateBasicAccountInfo(
    userId: string,
    updateData: BaseUserDataRequest,
  ): Promise<AxiosResponse<BaseUserDataResponse>> {
    return this.apiService.put({
      resource: `accounts/users/${userId}`,
      data: updateData,
    });
  }

  setPassword(userId: string, data: SetPasswordRequest): Promise<AxiosResponse<void>> {
    return this.apiService.post({
      resource: `accounts/users/${userId}/set_password`,
      data,
    });
  }

  changePassword(userId: string, data: ChangePasswordRequest): Promise<AxiosResponse<void>> {
    return this.apiService.post({
      resource: `accounts/users/${userId}/change_password`,
      data,
    });
  }

  updatePicture(userId: string, picture: File): Promise<AxiosResponse<void>> {
    const form = new FormData();
    if (picture) form.append("file", picture);

    return this.apiService.post({
      resource: `accounts/users/${userId}/picture`,
      data: form,
    });
  }

  deletePicture(userId: string) {
    return this.apiService.delete({
      resource: `accounts/users/${userId}/picture`,
    });
  }

  confirmNotifications(teamId: string, userId: string) {
    return this.apiService.post<Member>({
      resource: `accounts/teams/${teamId}/members/${userId}/confirm_notifications`,
    });
  }
}
