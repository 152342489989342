import React from "react";

import { ReceiptRow } from "./ReceiptRow";
import { AdministrationCard } from "../../../../../common/components/AdministrationCard/AdministrationCard";
import Button from "../../../../../common/components/Button/Button";
import { GridTable } from "../../../../../common/components/GridTable/GridTable";
import { useModal } from "../../../../../common/components/Modal/Modal";
import { Customer, Invoice } from "../../../../../common/models/billing";
import identifiers from "../../../../../common/util/identifiers.json";
import CustomizeBillingModalBody from "../ModalBody/CustomizeBillingModalBody";

interface ReceiptsCardProps {
  receipts: Invoice[];
  customer: Customer;
}

export default function ReceiptsCard({ receipts, customer }: ReceiptsCardProps): JSX.Element {
  const { Modal: CustomizeBillingModal, toggle, modalProps } = useModal({ size: "narrow" });

  return (
    <AdministrationCard
      title="Receipts"
      subtitle="View and download all your previous receipts here."
      topLeftAccessory={
        <Button
          size="small"
          onClick={toggle}
          testId={identifiers["billing.button.customize"]}
        >
          Customize billing
        </Button>
      }
      testId={identifiers["billing.header.receipts"]}
      isEmpty={!receipts || !Boolean(receipts.length)}
    >
      <GridTable gridTemplateColumns="repeat(4, auto)">
        {receipts.map((receipt) => {
          return (
            <ReceiptRow
              key={receipt.id}
              receipt={receipt}
            />
          );
        })}
      </GridTable>

      <CustomizeBillingModalBody
        customer={customer}
        toggle={toggle}
        Modal={CustomizeBillingModal}
        internalProps={modalProps}
      />
    </AdministrationCard>
  );
}
