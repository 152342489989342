import { useState } from "react";

import { generatePath, useLocation, useNavigate } from "react-router-dom";

import displayToast from "../../../../common/components/Toast/displayToast";
import { removeAttributesFromLocalStorage } from "../../../../common/util/removeAttributesFromLocalStorage";
import useUserDataApiConsumer from "../../../consumers/useUserDataApiConsumer";
import { RoutePaths } from "../../../router/config/routePaths";
import { useWorkspaceCreateDeps } from "../WorkspaceCreate";

interface Props {
  toggle?: () => void;
}

export default function useWorkspaceCreateApiConsumer({ toggle }: Props) {
  const { workspaceCreateService } = useWorkspaceCreateDeps();
  const navigate = useNavigate();
  const location = useLocation();

  const { fetchUserDataAndDispatch } = useUserDataApiConsumer();

  const [isLoading, setIsLoading] = useState(false);

  const createWorkspace = async (workspaceName: string, clearForm: () => void) => {
    setIsLoading(true);
    try {
      const { data } = await workspaceCreateService.createWorkspace(workspaceName);
      const { workspaces } = await fetchUserDataAndDispatch();

      const newWorkspace = workspaces.find((workspace) => workspace.slug === data.slug) ?? workspaces?.[0];

      if (newWorkspace) {
        removeAttributesFromLocalStorage();
        navigate(generatePath(RoutePaths.USER_FEEDBACK_MODULE_ROOT, { workspaceSlug: newWorkspace.slug }), {
          state: location.state,
        });
      }

      displayToast({
        title: "Workspace created",
        content: `Workspace ${workspaceName} has been successfully created.`,
      });
      clearForm();
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      if (toggle) {
        toggle();
      }
      setIsLoading(false);
    }
  };

  return { createWorkspace, isLoading };
}
