import styled from "styled-components";

import { HelperText as BaseHelperText } from "../../../../../../common/components/HelperText/HelperText";
import BaseParagraph from "../../../../../../common/components/Paragraph/Paragraph";
import BaseSelectDropdown from "../../../../../../common/components/SelectDropdown/SelectDropdown";
import { vars } from "../../../../../../common/styles";
import { Flex as BaseFlex } from "../../../../../../common/styles/reusable/Flex/Flex.styles";
import { StripeLockIcon as BaseLockedIcon } from "../../../../shared/icons/icons";

export const ButtonNote = styled(BaseParagraph)`
  display: flex;
  align-items: center;
`;

export const SelectDropdown = styled(BaseSelectDropdown)`
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
`;

export const Flex = styled(BaseFlex)`
  padding-top: 2.4rem;
  border-top: 1px solid ${vars.colors.grey90};
`;

export const LockedIcon = styled(BaseLockedIcon)`
  margin-right: 0.8rem;
  height: 100%;
  align-items: center;
  display: inline-block;
`;

export const Container = styled.div`
  margin-right: 1.6rem;
`;

export const HelperText = styled(BaseHelperText)`
  margin-top: 2.4rem;
`;

export const PlanModalContainer = styled.div`
  width: 28.2rem;
`;
