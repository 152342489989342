import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import React from "react";

import { useAppContext } from "../App/App.context";
import { useAppSelectionContext } from "../App/AppSelectionContext";

export enum SubscriptionState {
  good = "good",
  noApps = "no_apps",
  usageExceedsCurrentSubscription = "usage_exceeds",
}

type SubContext = {
  state: SubscriptionState | undefined;
};
const SubscriptionContext = createContext<SubContext>({} as SubContext);

export const SubscriptionProvider = ({ children }: { children: ReactNode }) => {
  const value = useSubscriptionState();
  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>;
};

export const useSubscriptionContext = () => useContext(SubscriptionContext);

const useSubscriptionState = () => {
  const { apps, loading: appsLoading, updatingSelectedWorkspace } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();
  const [state, setState] = useState<SubscriptionState>();

  useEffect(() => {
    if (appsLoading || !selectedWorkspace || updatingSelectedWorkspace) return setState(undefined);

    if (!selectedWorkspace.is_data_available) return setState(SubscriptionState.usageExceedsCurrentSubscription);

    if (apps.length === 0 || !apps.find((a) => a.active)) return setState(SubscriptionState.noApps);

    return setState(SubscriptionState.good);
  }, [appsLoading, apps, selectedWorkspace, updatingSelectedWorkspace]);

  return { state };
};

/* We might need this at some point.
const didWSReachLimit = (subscription: Subscription, activeApps: App[], teamData: WorkspaceData) => {
  const currentPlan = subscription.items.find((item) => item.plan.type === "plan");
  if (currentPlan && currentPlan.plan.product_name === PlanProductName.STARTER && activeApps.length > 4) {
    return true;
  }
  if (currentPlan && currentPlan.plan.product_name === PlanProductName.PROFESSIONAL && activeApps.length > 10) {
    return true;
  }

  return didWSReachDevicesLimit(subscription, teamData);
};

const didWSReachDevicesLimit = (subscription: Subscription, teamData: WorkspaceData) => {
  const currentAddOn = subscription.items.find((item) => item.plan.type === "devices");
  if (!currentAddOn && teamData?.unique_devices > 10000) {
    return true;
  }
  if (currentAddOn?.plan && currentAddOn.plan.nickname.includes("100K") && teamData?.unique_devices > 100000) {
    return true;
  }
  if (currentAddOn?.plan && currentAddOn.plan.nickname.includes("1M") && teamData?.unique_devices > 1000000) {
    return true;
  }
  if (currentAddOn?.plan && currentAddOn.plan.nickname.includes("10M") && teamData?.unique_devices > 10000000) {
    return true;
  }
  if (currentAddOn?.plan && currentAddOn.plan.nickname.includes("50M") && teamData?.unique_devices > 50000000) {
    return true;
  }
  return false;
};
*/
