import React, { lazy, Suspense, useEffect } from "react";

import ErrorImage from "@common/assets/images/FTUX/not-found-graphic.png";
import { FeedbackPreviewDialogBody } from "@common/components/Dialog/configurations/FeedbackPreviewDialogBody";
import { SpaceToPreviewDialog } from "@common/components/Dialog/SpaceToPreviewDialog";
import EmptyStateElement from "@common/components/EmptyStateElement/components/EmptyStateElement";
import PageWrap from "@common/components/PageWrap/PageWrap";
import { Spinner } from "@common/components/Spinner/Spinner";
import { useFeedbackAttributes } from "@common/hooks/filtering/modules/useFeedbackAttributes";
import { Attributes } from "@common/hooks/filtering/ui/Attributes";
import { AttributesContainer } from "@common/hooks/filtering/ui/Attributes.styles";
import { PlatformName } from "@common/models";
import { isFreePlan } from "@common/util/workspaceUtil";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { FeedbackModuleChromeAppFooter } from "@main/pages/shared/components/AppFooter/ChromeAppFooter/ChromeAppFooter";
import { EmptyStateViewConf, EmptyTableView } from "@main/pages/shared/components/EmptyTableView/EmptyTableView";
import { FeedbackModuleEmptyState } from "@main/pages/shared/components/ModuleEmptyState/FeedbackModuleEmptyState";
import { SdkSetupSteps } from "@main/pages/shared/components/SDKSetup/SdkSetupSteps";
import { resolveEmptyStateView } from "@main/pages/shared/helpers/resolveEmptyStateView";
import useUserFeedbackApiConsumer from "@main/pages/UserFeedback/consumers/useUserFeedbackApiConsumer";

import { FeedbackModuleAddAppFooter } from "../../shared/components/AppFooter/AddAppFooter/AddAppFooter";
import { FeedbackModuleFreePlanFooter } from "../../shared/components/AppFooter/FreePlanFooter/FreePlanFooter";
import useSearchFieldsApiConsumer from "../consumers/useSearchFieldsApiConsumer";

const Table = lazy(() =>
  import("@common/components/ShakeTable/BaseTable").then((module) => ({ default: module.BaseTable })),
);

const renderLoader = () => <Spinner />;

export const UserFeedbackListPageView: React.FC = () => {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();

  const { customFields, metadataFields } = useSearchFieldsApiConsumer();

  /// Attributes filter logic
  const feedbackAttributeState = useFeedbackAttributes(false, metadataFields, customFields);

  const { items, loading, hasMore, total, error, refetchData, feedbackTableProps, spaceToPreviewDialogProps } =
    useUserFeedbackApiConsumer({
      attributesProps: feedbackAttributeState,
    });

  const hasAttributes = Boolean(feedbackAttributeState.validAttributes.length);
  const isError = Boolean(error);

  useEffect(() => {
    if (feedbackAttributeState.validAttributes) {
      feedbackTableProps.table.resetRowSelection();
    }
    // eslint-disable-next-line
  }, [feedbackAttributeState.validAttributes]);

  // Show SDK not added screen
  if (!selectedApp?.is_approved) {
    return <SdkSetupSteps />;
  }

  // Show error screen
  if (error && !hasAttributes) {
    return (
      <EmptyStateElement
        title="There was an error loading issues. Try refreshing."
        subtitle="The link you followed may be broken, or the app may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  const isInitialLoading = typeof items == "undefined" || loading;
  const hasItems = (items && items.length > 0) || total > 0;

  const emptyViewConfig = resolveEmptyStateView(
    loading,
    "feedback",
    hasItems,
    selectedApp,
    Boolean(feedbackAttributeState.validAttributes.length),
    Boolean(error),
    refetchData,
  ) as EmptyStateViewConf | undefined;

  const buildAppFooter = () => {
    if (!isInitialLoading && !isError && !hasItems && !selectedApp.has_issue) return <FeedbackModuleEmptyState />;
    if (Boolean(selectedApp?.is_sample)) return <FeedbackModuleAddAppFooter />;
    if (Boolean(selectedApp?.is_extension) && selectedApp?.title === "My First Project" && items && items.length < 2)
      return <FeedbackModuleChromeAppFooter />;
    if (isFreePlan(selectedWorkspace) && !Boolean(hasMore) && selectedApp.platform.name !== PlatformName.WEB)
      return <FeedbackModuleFreePlanFooter />;
    return null;
  };

  return (
    <>
      <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column", overflow: "hidden" }}>
        <AttributesContainer>
          <Attributes
            {...feedbackAttributeState}
            searchField="title"
            enableKeyboardShortcut={true}
          />
        </AttributesContainer>
        {emptyViewConfig && (
          <EmptyTableView
            withFilters
            {...emptyViewConfig}
          />
        )}
        {isInitialLoading && <Spinner />}
        {!emptyViewConfig && !isInitialLoading && (
          <Suspense fallback={renderLoader()}>
            {
              // eslint-disable-next-line
              // @ts-ignore
              <Table
                {...feedbackTableProps}
                footer={buildAppFooter()}
                isFetchingNext={feedbackTableProps.isFetchingNext}
              />
            }
          </Suspense>
        )}
      </PageWrap>
      <SpaceToPreviewDialog {...spaceToPreviewDialogProps}>
        <FeedbackPreviewDialogBody />
      </SpaceToPreviewDialog>
    </>
  );
};
