import React, { PropsWithChildren } from "react";

import { HelperText } from "@common/components/HelperText/HelperText";
import { helpCenterArticles, showHelpCenterArticle } from "@common/util/helpCenterArticles";

import * as Styled from "./ExtensionsCards.styles";
import SentryIllustration from "../../../../../common/assets/images/extensions/sentry-illustration.png";
import Heading from "../../../../../common/components/Heading/Heading";
import { ToggleButton } from "../../../../../common/components/ToggleButton/ToggleButton";
import { Flex } from "../../../../../common/styles/reusable/Flex/Flex.styles";

type SentryCardProps = {
  isSentryActive: boolean;
  handleActiveChange: () => void;
  disabled: boolean;
};

export const SentryCard = ({ isSentryActive, handleActiveChange, disabled }: PropsWithChildren<SentryCardProps>) => {
  return (
    <Styled.AdministrationCardWrap>
      <Flex $justifyContent="space-between">
        <div>
          <Styled.HeadingWrap>
            <ToggleButton
              checked={isSentryActive}
              onChange={handleActiveChange}
              disabled={disabled}
            />
            <Heading
              as="h3"
              heading3
              marginBottom={0}
            >
              Sentry
            </Heading>
          </Styled.HeadingWrap>
          <Styled.SubtitleWrap>See related Sentry logs for every Shake ticket.</Styled.SubtitleWrap>
        </div>
      </Flex>
      <Styled.Image
        src={SentryIllustration}
        alt="screenshot"
      />
      <HelperText>
        <a onClick={() => showHelpCenterArticle(helpCenterArticles.sentryExtension)}>{"Learn more"}</a>
        {" about Sentry x Shake."}
      </HelperText>
    </Styled.AdministrationCardWrap>
  );
};
