import React, { useEffect, useMemo, useRef, useState } from "react";

import * as Styled from "./AppNotificationSettings.styles";
import AppPlaceholderPhoto from "../../../../../common/assets/images/shared-icons/app-icon-placeholder.svg";
import { ModalProps } from "../../../../../common/components/Modal/Modal";
import { ModalHeaderProps } from "../../../../../common/components/Modal/ModalHeader/ModalHeader";
import Option from "../../../../../common/components/Option/Option";
import { BasicAppNotificationSettings, mapAppToBasicNotificationSettings } from "../../../../../common/models";
import { getActiveApps } from "../../../../../common/util/appsUtil";
import { useAppContext } from "../../../../context/App/App.context";
import useNotificationSettingsApiConsumer from "../../consumers/api/useNotificationSettingsApiConsumer";

interface Props {
  toggleModal: () => void;
  setSelectedAppsNumber: (selectedAppsNumber: number) => void;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function AppNotificationSettings({ toggleModal, setSelectedAppsNumber, Modal, internalProps }: Props) {
  const { apps } = useAppContext();

  const activeApps = useMemo(() => getActiveApps(apps), [apps]);

  const [appNotificationSettings, setAppNotificationSettings] = useState<BasicAppNotificationSettings[]>(
    activeApps.map(mapAppToBasicNotificationSettings),
  );

  useEffect(() => {
    setAppNotificationSettings(activeApps.map(mapAppToBasicNotificationSettings));
  }, [activeApps]);

  const appsToUpdateMapRef = useRef<Record<string, BasicAppNotificationSettings>>({});

  const handleCheck = (clickedSetting: BasicAppNotificationSettings) => {
    const settingsCopy = [...appNotificationSettings];

    const indexToUpdate = settingsCopy.findIndex((setting) => setting.id === clickedSetting.id);
    settingsCopy[indexToUpdate].follow_app = !clickedSetting.follow_app;

    const isFollowAppNotChanged =
      activeApps.find((app) => app.id === clickedSetting.id)?.follow_app === clickedSetting.follow_app;

    if (isFollowAppNotChanged) {
      delete appsToUpdateMapRef.current[clickedSetting.id];
    } else {
      appsToUpdateMapRef.current[clickedSetting.id] = { ...clickedSetting };
    }

    setAppNotificationSettings(settingsCopy);
  };

  const { updateAppsNotificationSettings, loading } = useNotificationSettingsApiConsumer();

  const saveAppNotificationSettings = async () => {
    await updateAppsNotificationSettings(Object.values(appsToUpdateMapRef.current));

    const countNotificationsOff = Object.values(appsToUpdateMapRef.current).filter((a) => !a.follow_app).length;
    const countNotificationsOn = Object.values(appsToUpdateMapRef.current).filter((a) => a.follow_app).length;

    const selectedAppsCount =
      activeApps.filter((app) => app.follow_app).length - countNotificationsOff + countNotificationsOn;

    setSelectedAppsNumber(selectedAppsCount);
    appsToUpdateMapRef.current = {};
    toggleModal();
  };

  const isButtonDisabled = !Object.keys(appsToUpdateMapRef.current).length;

  return (
    <Modal
      {...internalProps}
      heading="Notify me about user feedback in these apps"
      buttonElement={
        <Styled.Button
          size="small"
          onClick={saveAppNotificationSettings}
          disabled={isButtonDisabled || loading}
          tooltip={{
            position: "top",
            text: "No changes have been made",
            disabled: !isButtonDisabled,
          }}
          loadingText={loading && "Saving..."}
        >
          Save changes
        </Styled.Button>
      }
    >
      <Styled.ModalContent>
        <Styled.OptionsContainer>
          {appNotificationSettings.map((setting) => (
            <Option
              key={setting.id}
              label={setting.name}
              checked={!!setting?.follow_app}
              handleCheck={() => handleCheck(setting)}
              disabled={loading}
              image={setting.logo_url ?? AppPlaceholderPhoto}
            />
          ))}
        </Styled.OptionsContainer>
      </Styled.ModalContent>
    </Modal>
  );
}
