import { motion } from "framer-motion";
import { rgba } from "polished";
import styled, { css } from "styled-components";

import { vars } from "../../styles";
import { Flex } from "../../styles/reusable/Flex/Flex.styles";

export const DialogContentWrap = styled(motion.div)<{
  $dialogWidth: string;
  $noBackground?: boolean;
  $transparent?: boolean;
  $maxHeight?: string;
  $fullHeight?: boolean;
  $noPadding?: boolean;
}>`
  z-index: ${vars.zIndex.modalContent};
  background-color: ${vars.colors.grey100};
  border-radius: 2rem;
  padding: 2.4rem 3.2rem;
  min-height: 20rem;

  overflow-y: auto;
  position: absolute;
  overflow: initial;

  &:focus-visible {
    outline: transparent;
  }

  ${({ $noPadding }) =>
    $noPadding &&
    css`
      padding: 0;
    `}

  ${({ $fullHeight }) =>
    !$fullHeight &&
    css`
      max-height: 90%;
    `}

  ${({ $dialogWidth }) =>
    $dialogWidth &&
    css`
      width: ${$dialogWidth};
    `}

  ${({ $noBackground }) =>
    $noBackground &&
    css`
      background-color: transparent;
    `}

  ${({ $transparent }) =>
    $transparent &&
    css`
      background-color: ${rgba(vars.colors.grey100, 0.9)};
    `}

    ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight};
    `}
`;

export const EssentialItemWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  stroke: ${vars.colors.grey50};

  svg {
    width: 2rem;
    height: 2rem;
    path {
      stroke: ${vars.colors.grey50};
    }
  }

  p {
    color: ${vars.colors.grey30};
    font-size: 1.4rem;
    font-weight: 500;
    line-height: inherit;
    max-width: 16rem;
    word-break: break-word;
  }
`;

export const EssentialsGrid = styled(Flex)<{ $borderBottom?: boolean }>`
  row-gap: 1rem;

  ${({ $borderBottom }) =>
    $borderBottom &&
    css`
      border-bottom: 1px solid ${vars.colors.grey90};
    `}
`;

export const DialogTitle = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${vars.colors.white};
  font-size: 2rem;
  max-width: 51rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const PreviewFlexWrap = styled.div<{ $wide?: boolean }>`
  ${({ $wide }) =>
    $wide &&
    css`
      width: 57.2rem;
    `}

  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  &:focus-visible {
    outline: transparent;
  }
  margin-bottom: 0.8rem;
`;

export const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};

export const containerVariant = {
  initial: {
    transform: "scale(0.85)",

    transition: { type: "opacity .25s cubic-bezier(0,1,.4,1),transform .25s cubic-bezier(.18,1.25,.4,1)" },
  },
  isOpen: { transform: "scale(1)" },
  exit: {
    transform: "scale(0)",
    transition: { type: "opacity .25s cubic-bezier(0,1,.4,1),transform .25s cubic-bezier(.18,1.25,.4,1)" },
  },
};
