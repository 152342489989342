import React, { Fragment, useState } from "react";

import { generatePath } from "react-router-dom";

import * as Styled from "./LinkedTicketsTable.styles";
import { ReactComponent as Duplicates } from "../../../../../common/assets/images/details-pane-icons/duplicate-title-icon.svg";
import NoAssignee from "../../../../../common/assets/images/unassigned-icon.png";
import { GridTable } from "../../../../../common/components/GridTable/GridTable";
import { getTagColor } from "../../../../../common/components/Input/InputWithTags/InputWithTags";
import RoundedCanvas from "../../../../../common/components/RoundedCanvas/RoundedCanvas";
import Tag from "../../../../../common/components/Tag/Tag";
import { RemoveBackground } from "../../../../../common/hooks/filtering/ui/Attributes.styles";
import { getUserInitials } from "../../../../../common/models";
import { CrashReport } from "../../../../../common/models/crashReporting";
import { getTimeSinceLong } from "../../../../../common/models/helpers/time/timeSince";
import { Issue } from "../../../../../common/models/issueTracking";
import { Member } from "../../../../../common/models/Member.model";
import { vars } from "../../../../../common/styles";
import useNotificationsApiConsumer from "../../../../consumers/useNotificationsApiConsumer";
import { useAppContext } from "../../../../context/App/App.context";
import { useAppSelectionContext } from "../../../../context/App/AppSelectionContext";
import { RoutePaths } from "../../../../router/config/routePaths";
import { getRandomColor } from "../../helpers/getRandomColorHelper";
import { getPriorityIcon, getStatusIcon } from "../../icons/getIconsHelper";

interface Props {
  linkedTickets?: (Issue | CrashReport)[];
}

export default function LinkedTicketsTable({ linkedTickets }: Props) {
  const { wsTeamMembers } = useAppContext();
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();
  const { unreadNotifications } = useNotificationsApiConsumer();
  const [hover, setHover] = useState<string | undefined>(undefined);

  if (!linkedTickets || !Boolean(linkedTickets.length)) return <Fragment />;

  return (
    <div style={{ marginBottom: "4rem" }}>
      <GridTable
        gridTemplateColumns="repeat(5,auto)"
        style={{
          backgroundColor: "transparent",
          border: `1px solid ${vars.colors.grey100}`,
          borderRadius: "1rem",
        }}
      >
        <Styled.Cell
          $title
          $borderTopLeft
          $leftPadding
        >
          <Duplicates />
          {`${linkedTickets.length} duplicates`}
        </Styled.Cell>
        <Styled.Cell $title>Tags</Styled.Cell>
        <Styled.Cell $title>Build</Styled.Cell>
        <Styled.Cell $title>Created</Styled.Cell>
        <Styled.Cell
          $title
          $borderTopRight
        />

        {linkedTickets.map((ticket, index) => {
          const assignee = ticket.assignee && getAssignee(ticket?.assignee?.assignee_id ?? "", wsTeamMembers);
          const iconColor = assignee && getRandomColor(assignee.user.id);
          return (
            <Styled.LinkContainer
              key={ticket.id}
              onMouseEnter={() => setHover(ticket.id)}
              onMouseLeave={() => setHover(undefined)}
              to={generatePath(RoutePaths.USER_FEEDBACK_DETAILS, {
                workspaceSlug: selectedWorkspace?.slug ?? null,
                appKey: selectedApp?.key ?? null,
                userFeedbackKey: ticket.key.toString(),
              })}
            >
              <Styled.Cell
                $leftPadding
                $borderBottomLeft={linkedTickets.length - 1 === index}
                $lastRow={linkedTickets.length - 1 === index}
              >
                <Styled.Title>
                  {selectedApp && unreadNotifications[selectedApp?.id]?.find((issue) => issue.id === ticket.id) && (
                    <Styled.Indicator />
                  )}
                  {getStatusIcon(ticket.status.status)}
                  {getPriorityIcon(ticket.priority.priority)}

                  <p
                    style={{ maxWidth: "24.2rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {ticket.title ?? "No description"}
                  </p>
                </Styled.Title>
              </Styled.Cell>
              <Styled.Cell $lastRow={linkedTickets.length - 1 === index}>
                <Styled.BaseFlex>
                  {ticket.tags.map((tag) => {
                    return (
                      <Tag
                        key={tag.id}
                        value={tag.name}
                        color={getTagColor(tag.name)}
                        border
                      />
                    );
                  })}
                  <RemoveBackground
                    className="__tagsFade"
                    $removeHover={hover === ticket.id ? false : true}
                    $gradientColor={vars.colors.black}
                    $heightPercent={90}
                    $gradientReachPercent={hover === ticket.id ? 0 : 100}
                  />
                </Styled.BaseFlex>
              </Styled.Cell>
              <Styled.Cell $lastRow={linkedTickets.length - 1 === index}>{(ticket as Issue).app_version}</Styled.Cell>
              <Styled.Cell $lastRow={linkedTickets.length - 1 === index}>
                {getTimeSinceLong(ticket.created)}
              </Styled.Cell>
              <Styled.Cell
                $borderBottomRight={linkedTickets.length - 1 === index}
                $lastRow={linkedTickets.length - 1 === index}
              >
                <RoundedCanvas
                  size="xsmall"
                  letter={assignee ? getUserInitials(assignee.user.name) : undefined}
                  bgColor={iconColor && iconColor.background}
                  txtColor={iconColor && iconColor.text}
                  image={assignee ? (assignee?.user.picture ? assignee?.user.picture : undefined) : NoAssignee}
                />
              </Styled.Cell>
            </Styled.LinkContainer>
          );
        })}
      </GridTable>
    </div>
  );
}

const getAssignee = (assigneeId: string, members?: Member[]) => {
  if (!members) return;
  return members.find((member) => member.id === assigneeId);
};
