import React from "react";

import { ChangeEmailConfirmationService } from "./services/ChangeEmailConfirmationService";
import ChangeEmailConfirmationView from "./views/ChangeEmailConfirmationView";
import dependencyInjection from "../../../common/util/dependencyInjection";

interface Services {
  changeEmailConfirmationService: ChangeEmailConfirmationService;
}

const changeEmailConfirmationService = new ChangeEmailConfirmationService();

const [ChangeEmailConfirmationDependencyProvider, useChangeEmailConfirmationDeps] = dependencyInjection<Services>({
  services: { changeEmailConfirmationService },
});

export const ChangeEmailConfirmationPage = () => {
  return (
    <ChangeEmailConfirmationDependencyProvider>
      <ChangeEmailConfirmationView />
    </ChangeEmailConfirmationDependencyProvider>
  );
};

export { useChangeEmailConfirmationDeps };
