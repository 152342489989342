import { useState } from "react";

import { generatePath, useNavigate } from "react-router-dom";

import displayToast from "../../../../common/components/Toast/displayToast";
import { useForm } from "../../../../common/hooks/useForm";
import { validatePassword } from "../../../../common/util/ValidatorFunctions";
import { useAuthDeps } from "../../../App.dependencies";
import { setUserTokenAction } from "../../../context/Auth/Auth.actions";
import { useAuthDispatch } from "../../../context/Auth/Auth.context";
import { USER_TOKEN_LOCAL_STORAGE_KEY } from "../../../context/Auth/Auth.types";
import { RoutePaths } from "../../../router/config/routePaths";
import { useResetPasswordDeps } from "../ResetPassword";

interface ResetPasswordFormState {
  password: string;
}

const validators = {
  password: validatePassword,
};

export const useResetPasswordFormConsumer = ({ resetCode }: { resetCode: string | null }) => {
  const navigate = useNavigate();

  const { authService } = useAuthDeps();
  const { resetPasswordService } = useResetPasswordDeps();
  const authDispatch = useAuthDispatch();

  const [loading, setLoading] = useState(false);

  const { handleFormChange, formState, formError, handleFormSubmit } = useForm<ResetPasswordFormState>({
    initialState: { password: "" },
    onSubmit: handleChangePassword,
    validators,
  });

  async function handleChangePassword() {
    if (!resetCode) return;

    try {
      setLoading(true);
      const { data: resetPasswordResponse } = await resetPasswordService.resetPassword(resetCode, formState.password);
      const { email } = resetPasswordResponse;

      const { data: token } = await authService.signIn(email, formState.password);

      localStorage.setItem(USER_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(token));
      authDispatch(setUserTokenAction(token));

      navigate(generatePath(RoutePaths.ROOT), { replace: true });

      displayToast({ content: "Your password has been updated successfully." });
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Failed to update your password" });
    } finally {
      setLoading(false);
    }
  }

  return { handleFormChange, formState, formError, handleFormSubmit, loading };
};
