import React from "react";

import IcoMoon from "react-icomoon";

import iconSet from "../../assets/icons/selection.json";

export interface IcoMoonProps {
  icon: string;
  color?: string;
  size?: string | number;
  className?: string;
}

const Icon: React.FC<IcoMoonProps> = ({ ...props }: IcoMoonProps) => {
  return (
    <IcoMoon
      iconSet={iconSet}
      {...props}
      className={`icon ${props.className}`}
    />
  );
};

export default Icon;
