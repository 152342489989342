import React, { useState } from "react";

import Dropzone, { Accept } from "react-dropzone";

import * as Styled from "./ModalBody.styles";
import DragNDropImage from "../../../../../common/assets/images/drag-and-drop@2x.png";
import displayToast from "../../../../../common/components/Toast/displayToast";
import { vars } from "../../../../../common/styles";
import { Flex } from "../../../../../common/styles/reusable/Flex/Flex.styles";

interface Props<T> {
  handleSelectedFile: (selectedFile: T) => void;
  acceptedFileTypes: Accept;
  bottomText?: string;
  bottomView?: JSX.Element;
  dropzoneText?: string;
  imageSrc?: string;
}

export default function UploadFileModalBody({
  handleSelectedFile,
  acceptedFileTypes,
  bottomText,
  bottomView,
  dropzoneText,
  imageSrc,
}: Props<File>) {
  const [entered, setEntered] = useState<boolean>(false);
  return (
    <Dropzone
      accept={acceptedFileTypes}
      onDrop={(acceptedFiles) => handleSelectedFile(acceptedFiles[0] as File)}
      onDragEnter={() => setEntered(true)}
      onDragLeave={() => setEntered(false)}
      onDropRejected={() =>
        displayToast({
          content: `Whoopsie, that doesn't look like a valid file format"`,
        })
      }
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <Styled.DragnDrop
            {...getRootProps()}
            $entered={entered}
          >
            <input {...getInputProps()} />

            <div>
              <Flex
                $alignItems="center"
                $justifyContent="center"
              >
                <img
                  src={imageSrc ? imageSrc : DragNDropImage}
                  width={!imageSrc ? "120px" : undefined}
                  height="120px"
                />
              </Flex>

              <Styled.DragnDropText
                fontWeight={500}
                fontSize={12}
                color={entered ? vars.colors.orange : undefined}
              >
                {dropzoneText ? dropzoneText : "Choose a file or drag it here to upload"}
              </Styled.DragnDropText>
            </div>
          </Styled.DragnDrop>
          {bottomText ? (
            <Styled.DragnDropText
              fontWeight={500}
              fontSize={12}
            >
              {bottomText}
            </Styled.DragnDropText>
          ) : undefined}
          {bottomView}
        </section>
      )}
    </Dropzone>
  );
}
