import React from "react";

import Button from "@common/components/Button/Button";
import Input from "@common/components/Input/Input";
import { ModalProps } from "@common/components/Modal/Modal";
import { ModalHeaderProps } from "@common/components/Modal/ModalHeader/ModalHeader";
import { useForm } from "@common/hooks/useForm";
import identifiers from "@common/util/identifiers.json";
import { t } from "@common/util/translator";
import { validateDomain, validateEmail } from "@common/util/ValidatorFunctions";

import * as Styled from "./ModalBody.styles";

interface Props {
  handleAddApprovedDomain: (domain: string, verification_email: string) => void;
  loading: boolean;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

interface FormState {
  domain: string;

  email: string;
}

const initialState: FormState = {
  domain: "",
  email: "",
};

export default function AddDomainsModalBody({ handleAddApprovedDomain, loading, Modal, internalProps }: Props) {
  const { formState, formError, handleFormChange, handleFormSubmit, clearForm } = useForm<FormState>({
    initialState,
    onSubmit: () => {
      handleAddApprovedDomain(domain, email);
      clearForm();
    },
    validators: {
      email: handleValidateEmail,
      domain: handleValidateDomain,
    },
  });

  const { email, domain } = formState;
  const { email: emailError, domain: domainError } = formError;

  function handleValidateDomain(domain: string): Error | undefined {
    return validateDomain(domain);
  }

  function handleValidateEmail(email: string): Error | undefined {
    if (validateDomain(formState.domain)) return;

    const isValidEmailError = validateEmail(email);
    if (isValidEmailError) return isValidEmailError;

    const domainComponentFromEmail = email.split("@").pop() || "";

    if (domainComponentFromEmail && domainComponentFromEmail === formState.domain) return;
    return new Error("You must use the same domain as in your email");
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isButtonDisabled = !email || !domain;

  return (
    <Modal
      {...internalProps}
      heading={t("administration.members.approvedDomain.modal.heading")}
      testId={identifiers["members.approvedDomain.modal.header"]}
      buttonElement={
        <Button
          disabled={isButtonDisabled || loading}
          size="small"
          type="submit"
          testId={identifiers["members.approvedDomain.modal.button"]}
          tooltip={{
            position: "top",
            text: "Please enter domain and email first",
            disabled: !isButtonDisabled || loading,
          }}
          loadingText={loading && "Adding..."}
          onClick={handleFormSubmit}
        >
          Add domain
        </Button>
      }
    >
      <Styled.Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <Input
          id="domain"
          name="domain"
          type="text"
          label={t("administration.members.approvedDomain.modal.input.domain")}
          value={domain}
          error={!!domainError}
          helper={domainError && domainError.message}
          placeholder="e.g. company.com"
          onChange={handleChange}
          testId={identifiers["members.approvedDomain.modal.input.domain"]}
          autoFocus
        />
        <Input
          name="email"
          type="email"
          label={t("administration.members.approvedDomain.modal.input.verification_email")}
          disabled={false}
          value={email}
          error={!!emailError}
          helper={emailError && emailError.message}
          onChange={handleChange}
          placeholder="e.g. john@company.com"
          testId={identifiers["members.approvedDomain.modal.input.email"]}
        />
      </Styled.Form>
    </Modal>
  );
}
