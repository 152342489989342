import React, { Fragment, useEffect, useState } from "react";

import { AdministrationCard } from "@common/components/AdministrationCard/AdministrationCard";
import Button from "@common/components/Button/Button";
import { GridTable } from "@common/components/GridTable/GridTable";
import { HelperText } from "@common/components/HelperText/HelperText";
import { useModal } from "@common/components/Modal/Modal";
import { App, Workspace } from "@common/models";
import { Domain } from "@common/models/Domain.model";
import { isAdmin } from "@common/models/Member.model";
import { helpCenterArticles, showHelpCenterArticle } from "@common/util/helpCenterArticles";
import { useShakingAnimation } from "@main/context/ShakingAnimation/ShakingAnimationContext";
import AddDomainsValidationModalBody from "@main/pages/Administration/components/ModalBody/AddAppDomainsValidationModalBody";
import { useNavigate } from "react-router-dom";

import { DomainRow } from "./DomainRow";
import DeleteDomainModalBody from "../../ModalBody/DeleteDomainModalBody";

interface DomainsCardProps {
  app: App;
  loading: boolean;
  addAppDomain: (domain: string, verification_email: string) => Promise<void>;
  deleteAppDomain: (id: string) => Promise<void>;
  selectedWorkspace?: Workspace;
}

export default function DomainsCard({
  loading,
  app,
  addAppDomain,
  deleteAppDomain,
  selectedWorkspace,
}: DomainsCardProps): JSX.Element {
  const { domains } = app;
  const { triggerShake } = useShakingAnimation();
  const navigate = useNavigate();
  const [selectedDomain, setSelectedDomain] = useState<Domain>();
  const { Modal: DeleteDomainModal, toggle, modalProps } = useModal({ size: "narrow" });
  const {
    Modal: AddDomainModal,
    toggle: toggleAddDomain,
    modalProps: addDomainInternal,
  } = useModal({ size: "narrow" });

  useEffect(() => {
    if (location.hash === "#addUrls" && isAdmin(selectedWorkspace?.role)) {
      if (isAdmin(selectedWorkspace?.role)) {
        toggleAddDomain();
      }
      navigate(location.pathname);
    }
  }, [toggleAddDomain, navigate, selectedWorkspace?.role]);

  const handleDomainDelete = async () => {
    if (app.is_sample) {
      triggerShake("AddAppButton");
      return;
    }
    if (!selectedDomain) return;
    await deleteAppDomain(selectedDomain.id);
    toggle();
  };

  const handleDomainCreate = async (domain: string, verification_email: string) => {
    if (app.is_sample) {
      triggerShake("AddAppButton");
      return;
    }
    await addAppDomain(domain, verification_email);
  };

  const tooltipDisabled = app.active && isAdmin(selectedWorkspace?.role) && !app.is_sample;
  const tooltipText = app.is_sample
    ? "Sample app can't be modified"
    : !isAdmin(selectedWorkspace?.role)
    ? "Only admins can add Domains"
    : "Domains can be added on active apps only";

  return (
    <Fragment>
      <AdministrationCard
        title={"Permitted URLs"}
        subtitle={"Only tickets from the specified URLs will be accepted. Leave blank to accept tickets from any URL."}
        topLeftAccessory={
          <Button
            size="small"
            onClick={() => (app.is_sample ? triggerShake("AddAppButton") : toggleAddDomain())}
            disabled={loading || !app.active || !isAdmin(selectedWorkspace?.role)}
            loadingText={loading && "Adding..."}
            tooltip={{
              text: tooltipText,
              disabled: tooltipDisabled,
              position: "top",
            }}
          >
            Add URLs
          </Button>
        }
      >
        {domains && (
          <GridTable gridTemplateColumns="repeat(3, auto)">
            {domains.map((domain) => {
              return (
                <DomainRow
                  key={domain.id}
                  setSelectedDomain={setSelectedDomain}
                  toggleDelete={toggle}
                  domain={domain}
                  isAdmin={isAdmin(selectedWorkspace?.role)}
                />
              );
            })}
          </GridTable>
        )}
        <HelperText>
          <a onClick={() => showHelpCenterArticle(helpCenterArticles.permittedUrl)}>{"Read all about"}</a>
          {" how and why teams set up their Permitted URLs."}
        </HelperText>
      </AdministrationCard>

      <AddDomainsValidationModalBody
        handleAddAppDomain={handleDomainCreate}
        loading={loading}
        toggle={toggleAddDomain}
        Modal={AddDomainModal}
        internalProps={addDomainInternal}
      />

      <DeleteDomainModalBody
        deleteApiKey={handleDomainDelete}
        loading={loading}
        Modal={DeleteDomainModal}
        internalProps={modalProps}
        subtitle={`This will remove ${selectedDomain?.domain} from Permitted URLs. This action in irreversible.`}
      />
    </Fragment>
  );
}
