import React from "react";

import PageWrap from "../../../../../common/components/PageWrap/PageWrap";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import { PlatformName } from "../../../../../common/models";
import { useAppContext } from "../../../../context/App/App.context";
import { useAppSelectionContext } from "../../../../context/App/AppSelectionContext";
import ApiKeysCard from "../../components/ApiKeysCard/ApiKeysCard";
import DomainsCard from "../../components/AppsCards/DomainsCard/DomainsCard";
import { EssentialsCard } from "../../components/AppsCards/EssentialsCard/EssentialsCard";
import { RulesCard } from "../../components/AppsCards/RulesCard/RulesCard";
import StatusCard from "../../components/AppsCards/StatusCard";
import NoAppsView from "../../components/NoAppsView";
import useAppsGeneralViewConsumer from "../../consumers/useAppsGeneralViewConsumer";

export default function AppsGeneralView(): JSX.Element {
  const { loading: appsLoading } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();

  const {
    updateApp,
    activateInactivateApp,
    saving,
    allApps,
    localSelectedApp,
    updateAppLogo,
    deleteAppLogo,
    rules,
    refetchRules,
    deleteRule,
    addApiKey,
    deleteApiKey,
    addAppDomain,
    deleteAppDomain,
    loading,
  } = useAppsGeneralViewConsumer();

  if (!appsLoading && allApps.length === 0) {
    <NoAppsView selectedWorkspace={selectedWorkspace} />;
  }

  if (!localSelectedApp) {
    return <Spinner />;
  }

  const localLoading = appsLoading || loading;

  return (
    <PageWrap style={{ marginTop: "4rem" }}>
      <StatusCard
        isAppActive={localSelectedApp.active}
        handleActiveChange={() => activateInactivateApp(localSelectedApp.active)}
        loading={appsLoading}
        saving={saving}
      />
      <EssentialsCard
        key={localSelectedApp.id}
        selectedApp={localSelectedApp}
        appName={localSelectedApp.name}
        updateApp={updateApp}
        loading={appsLoading}
        saving={saving}
        updateAppLogo={updateAppLogo}
        deleteAppLogo={deleteAppLogo}
        uploading={saving}
      />
      {localSelectedApp.platform.name === PlatformName.WEB && (
        <DomainsCard
          app={localSelectedApp}
          loading={appsLoading}
          addAppDomain={addAppDomain}
          deleteAppDomain={deleteAppDomain}
          selectedWorkspace={selectedWorkspace}
        />
      )}
      <ApiKeysCard
        app={localSelectedApp}
        loading={appsLoading}
        addApiKey={addApiKey}
        deleteApiKey={deleteApiKey}
        selectedWorkspace={selectedWorkspace}
      />
      <RulesCard
        selectedApp={localSelectedApp}
        loading={localLoading}
        rules={rules}
        getRules={refetchRules}
        deleteRule={deleteRule}
      />
    </PageWrap>
  );
}
