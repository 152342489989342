import React, { Fragment, useEffect, useState } from "react";

import { QueryObserverResult } from "@tanstack/react-query";
import { Accept } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";

import * as Styled from "./AppsCard.styles";
import { AdministrationCard } from "../../../../../common/components/AdministrationCard/AdministrationCard";
import Button from "../../../../../common/components/Button/Button";
import { GridTable } from "../../../../../common/components/GridTable/GridTable";
import { useModal } from "../../../../../common/components/Modal/Modal";
import { App, PlatformOs } from "../../../../../common/models";
import { MappingFile } from "../../../../../common/models";
import identifiers from "../../../../../common/util/identifiers.json";
import { useShakingAnimation } from "../../../../context/ShakingAnimation/ShakingAnimationContext";
import { MappingFilesRow } from "../MappingFilesRow/MappingFilesRow";
import AddMappingFileDataModalBody from "../ModalBody/AddMappingFileDataModalBody";
import UploadFileModalBody from "../ModalBody/UploadFileModalBody";

const Text = {
  iOS: {
    subtitle:
      "Crashes reported by Shake SDK will be deobfuscated using the dSYM files archived in the .zip file you upload below.",
    crashDocsLink: "https://www.shakebugs.com/docs/ios/crash-reports/symbolicate/",
  },
  Android: {
    subtitle:
      "Crashes reported by Shake SDK will be deobfuscated using the Proguard mapping files contained in the .txt file you upload below.",
    crashDocsLink: "https://www.shakebugs.com/docs/android/crash-reports/deobfuscation/",
  },
};

const FileType = {
  iOS: {
    type: { "application/zip": [".zip"] },
  },
  Android: {
    type: { "application/txt": [".txt"] },
  },
};

interface Props {
  selectedDropdownApp: App;
  mappingFiles: MappingFile[];
  getMissingDsyms: () => Promise<QueryObserverResult<MappingFile[] | undefined>>;
  deleteMappingFile: (fileId: string) => Promise<void>;
  disabled?: boolean;
  isSample?: boolean;
}

export default function MappingFilesCard({
  selectedDropdownApp,
  mappingFiles,
  getMissingDsyms,
  deleteMappingFile,
  disabled,
  isSample,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { triggerShake } = useShakingAnimation();
  useEffect(() => {
    return () => {
      setSelectedFile(undefined);
    };
  }, []);

  const {
    Modal: UploadMappingFileModal,
    toggle: toggleUpload,
    modalProps: uploadMappingInternal,
  } = useModal({ size: "narrow" });
  const {
    Modal: AddMappingFileDataModal,
    toggle: toggleAddData,
    modalProps: addMappingInternal,
    active: addModalActive,
  } = useModal({ size: "narrow" });

  useEffect(() => {
    if (location.hash === "#mapping") {
      toggleUpload();
      navigate(location.pathname);
    }
  }, [toggleUpload, location, navigate]);

  const [selectedFile, setSelectedFile] = useState<File>();
  const os = Object.values(PlatformOs).find((value) => value === selectedDropdownApp.platform.os);

  if (!os || os === PlatformOs.WEB) return <Fragment />;

  const subtitle = Text[os].subtitle;
  const crashDocsLink = Text[os].crashDocsLink;
  const acceptedFileType: Accept = FileType[os].type;

  const handleFileSelected = (file: File) => {
    setSelectedFile(file);
    toggleUpload();
    toggleAddData();
  };

  const handleCloseModal = () => {
    toggleAddData();
    getMissingDsyms();
  };

  return (
    <AdministrationCard
      title="Crash reports mapping files"
      subtitle={subtitle}
      testId={identifiers["mapping.header"]}
      topLeftAccessory={
        <Button
          disabled={disabled || !selectedDropdownApp.active}
          onClick={() => (isSample ? triggerShake("AddAppButton") : toggleUpload())}
          size="small"
          testId={identifiers["mapping.button.choose"]}
          tooltip={{
            text: isSample ? "Sample app can't be modified" : "Mapping files can be added on active apps only",
            disabled: !isSample && (disabled || selectedDropdownApp.active),
            position: "top",
          }}
        >
          Choose a file
        </Button>
      }
    >
      {mappingFiles && Boolean(mappingFiles.length) && (
        <GridTable gridTemplateColumns="repeat(1, minmax(30rem, auto)) repeat(2, minmax(12rem, auto)) repeat(1, auto)">
          {mappingFiles.map((mappingFile) => {
            const key = os === PlatformOs.ANDROID ? mappingFile.id : mappingFile.uuid;
            return (
              <MappingFilesRow
                key={key}
                mappingFile={mappingFile}
                isAppActive={selectedDropdownApp.active}
                os={os}
                onDeleteFile={deleteMappingFile}
              />
            );
          })}
        </GridTable>
      )}

      <Styled.HelperText>
        Read how to start sending yourself app crashes on&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href={crashDocsLink}
        >
          Shake Crashes Docs page
        </a>
        .
      </Styled.HelperText>

      <UploadMappingFileModal
        {...uploadMappingInternal}
        heading={`Upload a ${selectedDropdownApp?.name} ${selectedDropdownApp?.platform.os} mapping file`}
        testId={identifiers["mapping.modal.header"]}
      >
        <UploadFileModalBody
          handleSelectedFile={handleFileSelected}
          acceptedFileTypes={acceptedFileType}
        />
      </UploadMappingFileModal>

      {selectedFile && (
        <AddMappingFileDataModalBody
          selectedFile={selectedFile}
          closeModal={handleCloseModal}
          isModalActive={addModalActive}
          selectedApp={selectedDropdownApp}
          Modal={AddMappingFileDataModal}
          internalProps={addMappingInternal}
        />
      )}
    </AdministrationCard>
  );
}
