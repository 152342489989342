import { useQuery } from "@tanstack/react-query";

import { Platform, PlatformName, PlatformOs } from "../../../../common/models";
import { useAppDeps } from "../../../App.dependencies";

export const usePlatformsConsumer = () => {
  const { appsService } = useAppDeps();

  const getPlatforms = () => {
    return appsService.getPlatforms().then(({ data }) => {
      return (sortPlatforms(data) as Platform[]) ?? [];
    });
  };

  const { data: platforms } = useQuery([`platforms`], getPlatforms, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return { platforms };
};

const sortPlatforms = (platforms: Platform[]) => {
  return platforms
    .map((platform) => {
      if (platform.name === PlatformName.WEB && platform.os === PlatformOs.WEB) return { ...platform, sort_num: 0 };
      if (platform.name === PlatformName.IOS && platform.os === PlatformOs.IOS) return { ...platform, sort_num: 1 };
      if (platform.name === PlatformName.ANDROID && platform.os === PlatformOs.ANDROID)
        return { ...platform, sort_num: 2 };
      if (platform.name === PlatformName.REACT_NATIVE && platform.os === PlatformOs.IOS)
        return { ...platform, sort_num: 3 };
      if (platform.name === PlatformName.REACT_NATIVE && platform.os === PlatformOs.ANDROID)
        return { ...platform, sort_num: 4 };
      if (platform.name === PlatformName.FLUTTER && platform.os === PlatformOs.IOS) return { ...platform, sort_num: 5 };
      if (platform.name === PlatformName.FLUTTER && platform.os === PlatformOs.ANDROID)
        return { ...platform, sort_num: 6 };
      return { sort_num: 0 };
    })
    .sort((a, b) => a.sort_num - b.sort_num);
};
