import * as React from "react";

import { flexRender } from "@tanstack/react-table";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { HeaderContentsWrap, RowContentsWrap } from "./ui/BaseTableStyles";
import { useBaseTable } from "./useBaseTable";
import { SpaceToPreviewMetaProvider } from "./useSpaceToPreview";
import { Attribute } from "../../hooks/filtering/sharedTypes";
import { Flex } from "../../styles/reusable/Flex/Flex.styles";
import { Spinner } from "../Spinner/Spinner.styles";

interface BaseTableProps<TData> extends ReturnType<typeof useBaseTable<TData>> {
  footer?: React.ReactNode;
}

export function BaseTable<TData>({
  table,
  gridTemplateColumns,
  onContainerScroll,
  containerRef,
  renderRowLinkPath,
  spaceToPreview,
  selectable,
  onRowClick,
  validAttributes,
  withBorder,
  isFetchingNext,
  footer,
}: BaseTableProps<TData>) {
  const { onMouseEnterContainer, onMouseLeaveContainer, onMouseOverRow } = spaceToPreview;

  const headerRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <SpaceToPreviewMetaProvider {...spaceToPreview}>
          <div
            onMouseLeave={onMouseLeaveContainer}
            style={{
              position: "relative",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflowX: "hidden",
            }}
          >
            <div
              id="table-container"
              ref={containerRef}
              onMouseEnter={onMouseEnterContainer}
              onScroll={() => onContainerScroll()}
              style={{ position: "relative", overflow: "overlay", flex: 1, overflowX: "hidden", outline: "none" }}
            >
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns,
                  paddingBottom: "6rem",
                  paddingLeft: "3rem",
                  paddingRight: "3rem",
                }}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <HeaderContentsWrap
                    $selected={table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()}
                    key={`${headerGroup.id}-contents`}
                    $tableSelectionEnabled={selectable}
                  >
                    {headerGroup.headers.map((header) => {
                      // zIndex 1 is default for all headers, zIndex 2 makes the popover work

                      return (
                        <div
                          ref={headerRef}
                          key={header.id}
                          style={{ position: "sticky", top: 0, zIndex: 3 /* isSelectionHeader ? 2 : 1 */ }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      );
                    })}
                  </HeaderContentsWrap>
                ))}
                {table.getRowModel().rows.map((row) => {
                  const linkPath = renderRowLinkPath?.(row.original);
                  return (
                    <RowItem
                      path={linkPath}
                      selected={row.getIsSelected()}
                      onRowClick={onRowClick ? () => onRowClick(row.original) : undefined}
                      key={`${row.id}-contents`}
                      onMouseOver={(e) => {
                        onMouseOverRow(e, row.index);
                      }}
                      tableSelectionEnabled={selectable}
                      originalRow={row.original}
                      validAttributes={validAttributes}
                      withBorder={withBorder}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <React.Fragment key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </React.Fragment>
                      ))}
                    </RowItem>
                  );
                })}
              </div>
              {footer}
              {isFetchingNext && (
                <Flex
                  $justifyContent="center"
                  $alignItems="center"
                  style={{ position: "fixed", bottom: "0.4rem", left: "50%" }}
                >
                  <Spinner style={{ width: "2.8rem", height: "2.8rem" }} />
                </Flex>
              )}
            </div>
          </div>
        </SpaceToPreviewMetaProvider>
      </DndProvider>
    </>
  );
}

class RowItem extends React.Component<{
  children: React.ReactNode;
  path: string | undefined;
  selected: boolean;
  onMouseOver?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  tableSelectionEnabled: boolean;
  onRowClick: (() => void) | undefined;
  // eslint-disable-next-line
  originalRow: any;
  validAttributes?: Attribute[];
  withBorder?: boolean;
}> {
  // eslint-disable-next-line
  shouldComponentUpdate(nextProps: any) {
    if (!nextProps || !this.props) return false;

    return (
      nextProps.selected !== this.props.selected ||
      nextProps.path !== this.props.path ||
      nextProps.tableSelectionEnabled !== this.props.tableSelectionEnabled ||
      nextProps.validAttributes !== this.props.validAttributes ||
      nextProps.originalRow?.status !== this.props.originalRow?.status ||
      nextProps.originalRow?.priority !== this.props.originalRow?.priority ||
      nextProps.originalRow?.assignee?.id !== this.props.originalRow?.assignee?.id ||
      nextProps.originalRow?.prettyTitle !== this.props.originalRow?.prettyTitle ||
      nextProps.originalRow?.tags !== this.props.originalRow?.tags ||
      nextProps.originalRow?.linked_issues_count !== this.props.originalRow?.linked_issues_count ||
      nextProps.originalRow?.linked_crash_groups_count !== this.props.originalRow?.linked_crash_groups_count ||
      nextProps.originalRow?.issue_reported_time !== this.props.originalRow?.issue_reported_time ||
      nextProps.originalRow?.showNotificationIndicator !== this.props.originalRow?.showNotificationIndicator ||
      nextProps.originalRow?.description !== this.props.originalRow?.description ||
      nextProps.originalRow?.messageElement !== this.props.originalRow.messageElement
    );
  }

  render() {
    const { selected, path, onMouseOver, tableSelectionEnabled, onRowClick, children, withBorder } = this.props;

    return (
      <MemoRow
        selected={selected}
        path={path}
        onMouseOver={onMouseOver}
        tableSelectionEnabled={tableSelectionEnabled}
        onRowClick={onRowClick}
        withBorder={withBorder}
      >
        {children}
      </MemoRow>
    );
  }
}

const MemoRow = React.memo(
  ({
    children,
    selected,
    path,
    onMouseOver,
    tableSelectionEnabled,
    onRowClick,
    withBorder,
  }: {
    children: React.ReactNode;
    path: string | undefined;
    selected: boolean;
    onMouseOver?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    tableSelectionEnabled: boolean;
    onRowClick: (() => void) | undefined;
    withBorder?: boolean;
  }) => {
    return (
      <LinkRowWrap
        selected={selected}
        path={path}
        onMouseOver={onMouseOver}
        tableSelectionEnabled={tableSelectionEnabled}
        onRowClick={onRowClick}
        withBorder={withBorder}
      >
        {children}
      </LinkRowWrap>
    );
  },
);

MemoRow.displayName = "MemoRow";

function LinkRowWrap({
  children,
  selected,
  path,
  onMouseOver,
  tableSelectionEnabled,
  onRowClick,
  withBorder,
}: {
  children: React.ReactNode;
  path: string | undefined;
  selected: boolean;
  onMouseOver?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  tableSelectionEnabled: boolean;
  onRowClick: (() => void) | undefined;
  withBorder?: boolean;
}) {
  if (path)
    return (
      <RowContentsWrap
        to={path}
        $selected={selected}
        onMouseOver={onMouseOver}
        $tableSelectionEnabled={tableSelectionEnabled}
      >
        {children}
      </RowContentsWrap>
    );

  return (
    <RowContentsWrap
      as="div"
      $selected={selected}
      onMouseOver={onMouseOver}
      onClick={onRowClick}
      $tableSelectionEnabled={tableSelectionEnabled}
      $withBorder={withBorder}
    >
      {children}
    </RowContentsWrap>
  );
}
