import React, { useEffect } from "react";

import LoginImage from "common/assets/images/chrome-extension-login.png";
import Button from "common/components/Button/Button";
import { BaseDialog, useBaseDialog } from "common/components/Dialog/BaseDialog";
import { ModalFooter } from "common/components/Modal/ModalFooter/ModalFooter";
import { ModalHeader } from "common/components/Modal/ModalHeader/ModalHeader";
import Paragraph from "common/components/Paragraph/Paragraph";
import * as Styled from "main/pages/shared/components/ChromeExtensionLoginDialog/ChromeExtensionLoginDialog.styles";

export const LOCAL_STORAGE_SRC = "shakebugs.src";

const ChromeExtensionLoginDialog = () => {
  const loginDialog = useBaseDialog({});

  useEffect(() => {
    if (!loginDialog.open && localStorage.getItem(LOCAL_STORAGE_SRC) === "sdk") {
      loginDialog.setOpen(true);
      localStorage.removeItem(LOCAL_STORAGE_SRC);
    }
  }, [loginDialog]);

  return (
    <BaseDialog {...loginDialog}>
      <ModalHeader
        heading="Login successful"
        hasBorderBottom
      />
      <Styled.Container>
        <Paragraph>
          {"You can close this tab and return to the page where you were trying to submit the ticket."}
        </Paragraph>
        <Styled.Image src={LoginImage} />
      </Styled.Container>
      <ModalFooter
        button={
          <Button
            size="extraSmall"
            onClick={() => {
              loginDialog.setOpen(false);
            }}
          >
            {"OK"}
          </Button>
        }
      />
    </BaseDialog>
  );
};

const rememberUrlSrcParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const srcParam: string | null = urlParams.get("src");
  if (srcParam) {
    localStorage.setItem(LOCAL_STORAGE_SRC, srcParam);
  } else {
    localStorage.removeItem(LOCAL_STORAGE_SRC);
  }
};

export { ChromeExtensionLoginDialog, rememberUrlSrcParam };
