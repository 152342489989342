import styled from "styled-components";

import BaseParagraph from "../../../../../common/components/Paragraph/Paragraph";
import BaseTooltip from "../../../../../common/components/Tooltip/Tooltip";
import { vars } from "../../../../../common/styles";

export const CustomFieldsContainer = styled.div`
  margin-bottom: 4rem;
`;

export const Figure = styled.div`
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  display: flex;
`;

export const LeftBox = styled.div`
  display: flex;
  flex: 1;
`;

export const RightBox = styled.div`
  display: flex;
  flex: 2;
  max-width: 35rem;
`;

export const Tooltip = styled(BaseTooltip)`
  width: auto;
`;

export const Label = styled(BaseParagraph)`
  margin-left: 0.8rem;
  font-size: 1.4rem;
  color: ${vars.colors.grey50};
  user-select: text;
`;

export const Value = styled(BaseParagraph)`
  font-size: 1.4rem;
  color: ${vars.colors.grey30};
  max-width: 35rem;
  word-wrap: break-word;
  user-select: text;
`;
