import { useEffect } from "react";

import { ServerToClientEvents, SocketEvents } from "@common/models/webSocketEvents";
import useNotificationsApiConsumer from "@main/consumers/useNotificationsApiConsumer";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { Socket } from "socket.io-client";

interface Params {
  socket?: Socket<ServerToClientEvents, never>;
  refetchItems?: () => void;
}

export default function useUserFeedbackAndSocketConsumer({ socket, refetchItems }: Params) {
  const { selectedWorkspace } = useAppSelectionContext();
  const { fetchUnreadNotificationsAndDispatch } = useNotificationsApiConsumer();

  useEffect(() => {
    if (!selectedWorkspace || !socket) return;
    const handleIssueAddedEvent = async () => {
      if (selectedWorkspace.notify_new_bug) {
        await fetchUnreadNotificationsAndDispatch(selectedWorkspace.id);
      }
      refetchItems?.();
    };

    socket.on(SocketEvents.ISSUE_ADDED, handleIssueAddedEvent);
    return () => {
      socket.off(SocketEvents.ISSUE_ADDED, handleIssueAddedEvent);
    };
  }, [selectedWorkspace, socket, refetchItems, fetchUnreadNotificationsAndDispatch]);

  useEffect(() => {
    if (!selectedWorkspace || !socket) return;

    async function handleIssueChatChangedEvent() {
      if (!selectedWorkspace || !selectedWorkspace.notify_new_chat_message) return;
      await fetchUnreadNotificationsAndDispatch(selectedWorkspace.id);
    }

    socket.on(SocketEvents.ISSUE_CHAT_CHANGED, handleIssueChatChangedEvent);
    return () => {
      socket.off(SocketEvents.ISSUE_CHAT_CHANGED, handleIssueChatChangedEvent);
    };
  }, [selectedWorkspace, socket, fetchUnreadNotificationsAndDispatch]);
}
