import { useState } from "react";

import displayToast, { dismissToast, updateToast } from "../../../../common/components/Toast/displayToast";
import { App } from "../../../../common/models";
import { useAdministrationDeps } from "../../Administration/Administration";

const MAX_IMPORT_FILE_SIZE = 100; // In MB

export const useImportTicketsConsumer = (app?: App) => {
  const { administrationService } = useAdministrationDeps();
  const [loading, setLoading] = useState(false);

  const uploadImportFile = async (file: File) => {
    if (!file || !app) return;

    const fileSize = file.size / 1024 / 1024;
    if (fileSize > MAX_IMPORT_FILE_SIZE) {
      displayToast({
        title: "File too large",
        content: `Maximum file size you can upload is ${MAX_IMPORT_FILE_SIZE} MB.`,
      });
      return;
    }

    const toastId = Date.now().toString();

    try {
      setLoading(true);

      displayToast({
        id: toastId,
        title: "File upload in progress",
        content: "Don't close this tab until the file has been uploaded. This may take a few minutes.",
        duration: false,
        progress: 0,
        hideProgress: false,
      });

      await administrationService.uploadImportFile(file, app.team_id, app.id, (event) => {
        const progress = event.loaded / event.total;
        updateToast({ id: toastId, options: { progress } });
      });

      dismissToast({ id: toastId });
      setTimeout(() => {
        // Wait 2s to close progress notification
        displayToast({
          title: "Data is being imported to Shake",
          content:
            "Within 15 minutes you'll receive an email that your tickets are ready on Shake Dashboard. " +
            "You can now leave this page.",
        });
      }, 1000);

      setLoading(false);
    } catch (error) {
      dismissToast({ id: toastId });
      setTimeout(() => {
        // Wait 2s to close progress notification
        displayToast({
          title: "Import error",
          content:
            "There was an error and your file could not be uploaded. " +
            "Please try again or contact us if the problem persists.",
        });
      }, 1000);

      setLoading(false);
    }
  };

  return { uploadImportFile, loading };
};
