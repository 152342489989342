import React, { Fragment } from "react";

import Button from "@common/components/Button/Button";
import { CenteredCell, GridTable } from "@common/components/GridTable/GridTable";
import { HelperText } from "@common/components/HelperText/HelperText";
import { ModalProps } from "@common/components/Modal/Modal";
import { ModalHeaderProps } from "@common/components/Modal/ModalHeader/ModalHeader";
import Paragraph from "@common/components/Paragraph/Paragraph";
import RoundedCanvas from "@common/components/RoundedCanvas/RoundedCanvas";
import Tooltip from "@common/components/Tooltip/Tooltip";
import { getUserInitials } from "@common/models";
import { MemberRole } from "@common/models/Member.model";
import identifiers from "@common/util/identifiers.json";
import { useAppContext } from "@main/context/App/App.context";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { getRandomColor } from "@main/pages/shared/helpers/getRandomColorHelper";
import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath, useNavigate } from "react-router-dom";

import * as Styled from "./SubscriptionPaymentIssueElement.styles";

interface Props {
  isModal?: boolean;
  toggleModal?: () => void;
  Modal?: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function SubscriptionPaymentIssueElement({ isModal, Modal, internalProps, toggleModal }: Props) {
  const navigate = useNavigate();
  const { wsTeamMembers } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();

  const form = () => {
    return (
      <Fragment>
        <Paragraph>
          The last payment for your Shake subscription failed, and your current plan may remain active for a brief
          period. To ensure uninterrupted service, please update your billing information.
        </Paragraph>
        <Paragraph style={{ marginBottom: "2rem" }}>Only workspace administrators can perform this action:</Paragraph>
        <GridTable
          style={{ marginBottom: "2rem" }}
          gridTemplateColumns="repeat(1, minmax(20rem, auto)) repeat(1, minmax(10rem, auto))"
        >
          {wsTeamMembers
            ?.filter((member) => member.role === MemberRole.ADMIN)
            .map((teamMember) => {
              return (
                <>
                  <CenteredCell gap={12}>
                    <div
                      style={{ flexShrink: 0 }}
                      data-testid={identifiers["member.myTeam.user.icon"]}
                    >
                      <RoundedCanvas
                        letter={getUserInitials(teamMember.user.name)}
                        bgColor={getRandomColor(teamMember.user.id) && getRandomColor(teamMember.user.id).background}
                        txtColor={getRandomColor(teamMember.user.id) && getRandomColor(teamMember.user.id).text}
                        size="small"
                        image={teamMember.user.picture ?? undefined}
                      />
                    </div>
                    <Styled.TeamMemberName>{teamMember.user.name}</Styled.TeamMemberName>
                  </CenteredCell>

                  <Tooltip
                    position="top"
                    text={teamMember.user.email}
                  >
                    <CenteredCell>
                      <Styled.TeamMemberEmail>{teamMember.user.email}</Styled.TeamMemberEmail>
                    </CenteredCell>
                  </Tooltip>
                </>
              );
            })}
        </GridTable>
        <HelperText className={"not-danger"}>
          {
            "If your billing information is accurate, please contact your card provider to understand why this payment is failing."
          }
        </HelperText>
      </Fragment>
    );
  };

  if (isModal && Modal) {
    return (
      <Modal
        {...internalProps}
        heading="Charge failed"
        buttonElement={
          <Button
            size="small"
            type="button"
            disabled={false}
            onClick={() => {
              navigate(
                generatePath(RoutePaths.ADMINISTRATION_BILLING, { workspaceSlug: selectedWorkspace?.slug || "" }),
              );
              toggleModal && toggleModal();
            }}
            loadingText={""}
          >
            Update billing info
          </Button>
        }
      >
        {form()}
      </Modal>
    );
  }

  return form();
}
