import { useEffect, useState } from "react";

import { Option } from "../../../../../../../common/components/SelectDropdown/SelectDropdown";
import displayToast from "../../../../../../../common/components/Toast/displayToast";
import { useForm } from "../../../../../../../common/hooks/useForm";
import {
  automaticForwardOption,
  IntegrationType,
  Jira,
  JiraIntegrationInfo,
  JiraIntegrationUpdatePayload,
  manualForwardOption,
} from "../../../../../../../common/models/integrations";
import { useIntegrationFormConsumer } from "../../../../consumers/useIntegrationFormConsumer";

interface Props {
  integration?: Jira;
  selectedAppId: string;
  onFinish: () => void;
}

interface FormState {
  projectOption: Option;
  forwardingOption: Option;
  twoWaySync: boolean;
  fieldMapping: boolean;
}

interface JiraOption {
  repoName: string;
  repoKey: string;
  integrationId: string;
}

const initialFormState = {
  projectOption: { value: "{}", label: "Fetching... Please wait, large projects may take a few minutes." },
  forwardingOption: automaticForwardOption,
  twoWaySync: false,
  fieldMapping: false,
};

export const useJiraCloudIntegrationFormConsumer = ({ integration, selectedAppId, onFinish }: Props) => {
  const { integrationInfo, integrate, updateIntegration } = useIntegrationFormConsumer<JiraIntegrationInfo[]>({
    selectedAppId,
    type: IntegrationType.JIRA,
    toggle: onFinish,
  });

  const [loading, setLoading] = useState(false);

  const [projectOptions, setProjectOptions] = useState<Option[]>([]);

  const { formState, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: initialFormState,
    onSubmit: handleConnect,
    validators: {},
  });

  const { projectOption, forwardingOption, twoWaySync, fieldMapping } = formState;

  async function handleConnect() {
    setLoading(true);
    try {
      if (!integrationInfo) throw new Error();

      const selectedJiraOption = JSON.parse(projectOption.value) as JiraOption;

      const payload: JiraIntegrationUpdatePayload = {
        app_id: selectedAppId,
        automatic_forward: !!forwardingOption.value,
        field_mapping_enabled: formState.fieldMapping,
        integration_id: selectedJiraOption.integrationId,
        key: selectedJiraOption.repoKey,
        two_way_sync_enabled: formState.twoWaySync,
      };

      const endpoint = integration && (integration.project_id || integration.cloud_id) ? updateIntegration : integrate;

      await endpoint<Jira>(payload);
      onFinish();
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
      onFinish();
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!integrationInfo) return;

    if (!integrationInfo.length) {
      setProjectOptions([]);
      handleFormChange("projectOption", null);
      return;
    }

    const jiraProjectOptions = infoToJiraProjectInfo(integrationInfo);

    setProjectOptions([...jiraProjectOptions]);

    const preSelectedProject = preselectedProject(integration, jiraProjectOptions);

    handleFormChange("projectOption", preSelectedProject);

    const preselectedForwardingOption = integration
      ? integration.automatic_forward
        ? automaticForwardOption
        : manualForwardOption
      : automaticForwardOption;

    handleFormChange("forwardingOption", preselectedForwardingOption);

    const preselectedTwoWaySync = integration ? integration.two_way_sync_enabled : false;

    handleFormChange("twoWaySync", preselectedTwoWaySync);

    const preselectedFieldMapping = integration ? integration.field_mapping_enabled : false;

    handleFormChange("fieldMapping", preselectedFieldMapping);
  }, [integrationInfo, handleFormChange, integration]);

  const shouldDisableButton = shouldDisableButtonDueToNoChanges(integration, formState, integrationInfo);

  const disabled = !integrationInfo;
  const isButtonDisabled = !integrationInfo || shouldDisableButton;

  const tooltipText = shouldDisableButton ? "No changes have been made" : "";

  return {
    projectOption,
    projectOptions,
    forwardingOption,
    handleFormChange,
    handleFormSubmit,
    disabled,
    loading,
    twoWaySync,
    fieldMapping,
    isButtonDisabled,
    tooltipText,
  };
};

const shouldDisableButtonDueToNoChanges = (
  integration: Jira | undefined,
  formState: FormState,
  integrationInfo: JiraIntegrationInfo[] | undefined,
): boolean | undefined => {
  if (!integrationInfo || formState.projectOption === null) return true;

  if (formState.projectOption.value === "Fetching... Please wait, large projects may take a few minutes.") return true;

  const jiraOption = JSON.parse(formState.projectOption.value) as Partial<JiraOption>;

  if (!integration) return false;
  return (
    formState.fieldMapping === integration.field_mapping_enabled &&
    formState.twoWaySync === integration.two_way_sync_enabled &&
    Boolean(formState.forwardingOption.value) === integration.automatic_forward &&
    Boolean(jiraOption.repoKey) &&
    integration.project_id === jiraOption.repoKey
  );
};

const infoToJiraProjectInfo = (integrationInfo: JiraIntegrationInfo[]) => {
  return integrationInfo.flatMap((info) => {
    return info.projects.map((project) => {
      const jiraOption: JiraOption = {
        repoKey: project.key,
        repoName: project.name,
        integrationId: project.integration_id,
      };
      return { value: JSON.stringify(jiraOption), label: project.name };
    });
  });
};

const preselectedProject = (integration: Jira | undefined, jiraProjectOptions: { label: string; value: string }[]) => {
  return integration
    ? jiraProjectOptions.find((option) => {
        const jiraOption = JSON.parse(option.value) as JiraOption;
        return jiraOption.repoKey === integration.project_id;
      }) || null
    : null;
};
