import { addDays, addMonths, isAfter, isSameDay, lastDayOfMonth, nextSunday, subDays, subYears } from "date-fns";

import { Analytics, TimeGroup, TimeRange } from "../../../../common/models/Analytics.model";
import { resolveDateString } from "../../../../common/models/helpers/time/creationTime";

export const getStartDate = (range: TimeRange) => {
  switch (range) {
    case TimeRange.NINETY:
      return subDays(new Date(), 90);
    case TimeRange.SEVEN:
      return subDays(new Date(), 7);
    case TimeRange.TWENTY_EIGHT:
      return subDays(new Date(), 28);
    case TimeRange.YEAR:
      return subYears(new Date(), 1);
  }
};

export const formatLabels = (labels: string[], time: TimeRange, cummulative?: boolean) => {
  const formattedLabels: string[] = [];
  labels.map((label, index) => {
    if (index === 0) formattedLabels.push(resolveDateString(getStartDate(time).toString()));
    else {
      formattedLabels.push(resolveDateString(label));
    }
  });

  if (cummulative && !formattedLabels.find((a) => a === resolveDateString(new Date().toString()))) {
    formattedLabels.push(resolveDateString(new Date().toString()));
  }
  return formattedLabels;
};

export const formatTooltipDateLabels = (label: string) => {
  return resolveDateString(label).split(",")[0];
};

export const formatTooltipDateRangeLabels = (label: string, group: TimeGroup, time?: TimeRange) => {
  if (group === TimeGroup.WEEK && time) {
    if (isSameDay(new Date(label), getStartDate(time))) {
      return `${resolveDateString(label).split(",")[0]} - ${
        resolveDateString(nextSunday(new Date(label)).toString()).split(",")[0]
      }`;
    } else return `${resolveDateString(label).split(",")[0]} - ${getEndDate(addDays(new Date(label), 6))}`;
  }
  if (group === TimeGroup.MONTH && time) {
    if (isSameDay(new Date(label), getStartDate(time))) {
      return `${resolveDateString(label).split(",")[0]} - ${
        resolveDateString(lastDayOfMonth(new Date(label)).toString()).split(",")[0]
      }`;
    } else
      return `${resolveDateString(label).split(",")[0]} - ${getEndDate(subDays(addMonths(new Date(label), 1), 1))}`;
  }
  return resolveDateString(label).split(",")[0];
};

export const nFormatter = (num: number) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const valuesSum = (data: Analytics[]) => {
  const sum = data.reduce((sum, current) => sum + current.value, 0);
  if (!sum) return 0;
  return sum / data.length;
};

export const groupPretty = (group: TimeGroup) => {
  if (group === TimeGroup.DAY) return "Daily";
  if (group === TimeGroup.WEEK) return "Weekly";
  return "Monthly";
};

export const getMaxValue = (data?: Analytics[], cummulative?: boolean) => {
  if (!data) return 0;

  const values: number[] = [];

  if (!cummulative) {
    data.map((a) => values.push(a.value));
  } else {
    data.map((a) => values.push(a.cum_value));
  }

  const max = Math.max(...values);

  if (max < 100) return Math.ceil(max / 10) * 10;
  if (max < 1000) return Math.ceil(max / 100) * 100;
  if (max < 10000) return Math.ceil(max / 1000) * 1000;
  if (max < 100000) return Math.ceil(max / 10000) * 10000;
  if (max < 1000000) return Math.ceil(max / 100000) * 100000;
  else {
    return max;
  }
};

export const getMinValue = (data?: Analytics[], cummulative?: boolean) => {
  if (!data) return 0;

  const values: number[] = [];

  if (!cummulative) {
    data.map((a) => values.push(a.value));
  } else {
    data.map((a) => values.push(a.cum_value));
  }

  const min = Math.min(...values);

  if (min < 100) return Math.floor(min / 10) * 10;
  if (min < 1000) return Math.floor(min / 100) * 100;
  if (min < 10000) return Math.floor(min / 1000) * 1000;
  if (min < 100000) return Math.floor(min / 10000) * 10000;
  if (min < 1000000) return Math.floor(min / 100000) * 100000;
  else {
    return min ? min : 0;
  }
};

export const getEndDate = (date: Date) => {
  if (isAfter(date, new Date())) {
    return resolveDateString(new Date().toString()).toString().split(",")[0];
  }
  return resolveDateString(date.toString()).toString().split(",")[0];
};
