import styled from "styled-components";

import { ReactComponent as GlobeIcon } from "../../../../../common/assets/images/shared-icons/globe-certificate-icon.svg";
import { HelperText } from "../../../../../common/components/HelperText/HelperText";
import BaseInput from "../../../../../common/components/Input/Input";
import { vars } from "../../../../../common/styles/abstracts/vars";

export const Globe = styled(GlobeIcon)`
  path {
    stroke: ${vars.colors.grey30};
  }
`;

export const Helper = styled(HelperText)`
  margin-top: 1.2rem;
`;

export const Input = styled(BaseInput)`
  label {
    cursor: pointer;
  }
  cursor: pointer;
  margin-top: 1.2rem;
`;
