import { useCallback, useEffect, useState } from "react";

import displayToast from "../../../../common/components/Toast/displayToast";
import useAbortController from "../../../../common/hooks/useAbortController";
import { WorkspaceData } from "../../../../common/models";
import { useAppDeps } from "../../../App.dependencies";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useAdministrationDeps } from "../Administration";

export default function useExtensionsViewApiConsumer() {
  const { administrationService } = useAdministrationDeps();
  const { appsService } = useAppDeps();
  const { abortSignal } = useAbortController();
  const [teamData, setTeamData] = useState<WorkspaceData>();
  const { selectedWorkspace } = useAppSelectionContext();
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const getTeamData = useCallback(async () => {
    try {
      if (!selectedWorkspace) return;

      const { data } = await appsService.getTeamData(selectedWorkspace.id, abortSignal);
      setTeamData(data);
      setInitialLoading(false);
    } catch (error) {
      if (abortSignal.aborted) return;
      displayToast({
        title: "Something went wrong",
        content: "There was an error while fetching team data",
      });
    } finally {
    }
  }, [abortSignal, appsService, selectedWorkspace]);

  const disableSentry = async () => {
    try {
      if (!selectedWorkspace) throw new Error();
      setLoading(true);

      await administrationService.disconnectSentry(selectedWorkspace.id).then(getTeamData);

      displayToast({ content: "Sentry has been disabled" });
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "There was an error while disabling Sentry" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTeamData();
  }, [getTeamData]);

  return {
    teamData,
    disableSentry,
    selectedWorkspace,
    initialLoading,
    loading,
  };
}
