import styled, { css } from "styled-components";

import { vars } from "../../../styles";

export const SubNavigation = styled.nav<{
  $inModal?: boolean;
  $maxWidthText?: string;
  $maxWidth?: string;
  $rowHeight?: string;
}>`
  position: relative;
  width: 26rem;

  ${(props) =>
    props.$maxWidth &&
    css`
      width: ${props.$maxWidth}px;
    `}

  cursor: default;

  ${(props) =>
    !props.$inModal &&
    css`
      padding-top: 1.6rem;
    `}

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      color: ${vars.colors.grey50};
      position: relative;
      display: flex;
      align-items: center;
      line-height: 1.25;
      border-radius: 1rem;
      transition: ${vars.transitions.hover};
      cursor: pointer;

      ${(props) =>
        props.$rowHeight &&
        css`
          height: ${props.$rowHeight}px;
        `}

      p {
        cursor: pointer;
        ${(props) =>
          props.$maxWidthText &&
          css`
            max-width: ${props.$maxWidthText}px;
          `}
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          color: ${vars.colors.white};
        }
      }

      &:hover {
        text-decoration: none;
        color: ${vars.colors.white};
      }

      &.active {
        p {
          color: ${vars.colors.white};
        }
        color: ${vars.colors.white};

        ${(props) =>
          !props.$inModal &&
          css`
            background-color: ${vars.colors.purple};
          `}

        ${(props) =>
          props.$inModal &&
          css`
            p {
              color: ${vars.colors.white};
            }
          `}

        &:hover {
          color: ${vars.colors.white};
        }
      }

      a {
        text-decoration: none;
        width: 100%;
        padding: 1.4rem 1.6rem;

        color: ${vars.colors.grey50};

        &:hover {
          color: ${vars.colors.white};
        }

        &.active {
          color: ${vars.colors.white};
        }
      }

      .label {
        margin-left: 0.8rem;
      }

      .icon {
        position: absolute;
        margin: auto;
        top: 0;
        right: 2rem;
        bottom: 0;
      }
    }
  }
`;
