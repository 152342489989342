import { TicketPriority } from "../../models/TicketPriority.model";
import { TicketStatus } from "../../models/TicketStatus.model";
import identifiers from "../../util/identifiers.json";

const detailsBarTestIdentifiers = {
  status: {
    [TicketStatus.IN_PROGRESS]: identifiers["central.column.status.dropdown.inProgress"],
    [TicketStatus.NEW]: identifiers["central.column.status.dropdown.new"],
    [TicketStatus.CLOSED]: identifiers["central.column.status.dropdown.closed"],
  },
  priority: {
    [TicketPriority.HIGH]: identifiers["central.column.priority.dropdown.high"],
    [TicketPriority.MEDIUM]: identifiers["central.column.status.dropdown.new"],
    [TicketPriority.LOW]: identifiers["central.column.priority.dropdown.low"],
  },
};

export const getTestIdForStatus = (ticketStatus: Exclude<TicketStatus, TicketStatus.LOCKED>) =>
  detailsBarTestIdentifiers.status[ticketStatus];
export const getTestIdForPriority = (ticketPriority: TicketPriority) =>
  detailsBarTestIdentifiers.priority[ticketPriority];
