import styled, { css } from "styled-components";

import { vars } from "../../../styles/abstracts/vars";
import { Flex as BaseFlex } from "../../../styles/reusable/Flex/Flex.styles";
import BaseButton from "../../Button/Button";

export const Flex = styled(BaseFlex)`
  padding-top: 2.4rem;
  border-top: 1px solid ${vars.colors.grey90};
  margin-top: 2.4rem;
  width: 100%;
  position: relative;
  z-index: 0;
`;

export const Button = styled(BaseButton)<{ $hasPaddingRight?: boolean }>`
  ${(props) =>
    props.$hasPaddingRight &&
    css`
      margin-right: 1.6rem;
    `}

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
`;
