import { useCallback } from "react";

import { useAppDeps } from "../App.dependencies";
import { setAppsAction, setLoadingAction } from "../context/App/App.actions";
import { useAppDispatch } from "../context/App/App.context";

export default function useWorkspaceAppsApiConsumer() {
  const { appsService } = useAppDeps();
  const appDispatch = useAppDispatch();

  const fetchAllApps = useCallback(
    async (workspaceId: string, signal?: AbortSignal) => {
      try {
        appDispatch(setLoadingAction(true));
        const allApps = await appsService.getApps(workspaceId, signal);
        if (signal && signal.aborted) return [];
        appDispatch(setAppsAction(allApps));
        return allApps;
      } catch (error) {
        return [];
      } finally {
        if (signal && signal.aborted) return [];
        appDispatch(setLoadingAction(false));
      }
    },
    [appsService, appDispatch],
  );

  const updateAllApps = useCallback(
    async (workspaceId: string, signal?: AbortSignal) => {
      try {
        const allApps = await appsService.getApps(workspaceId, signal);
        if (signal && signal.aborted) return [];
        appDispatch(setAppsAction(allApps));
        return allApps;
      } catch (error) {
        return [];
      } finally {
        if (signal && signal.aborted) return [];
      }
    },
    [appsService, appDispatch],
  );

  return { fetchAllApps, updateAllApps };
}
