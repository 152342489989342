import { rgba } from "polished";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { TrashIcon } from "../../../../main/pages/shared/icons/icons";
import checkIcon from "../../../assets/images/icon-check.svg";
import { vars } from "../../../styles";

export const SelectionCell = styled.div<{ $withBackgroundColor?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  border-bottom: 1px solid ${vars.colors.grey100};

  ${({ $withBackgroundColor }) =>
    $withBackgroundColor &&
    css`
      background-color: ${vars.colors.black};
    `}
`;

export const BaseCell = styled.div.attrs({ className: "__table_base_cell" })<{
  $noYPadding?: boolean;
  $noLeftPadding?: boolean;
  centered?: boolean;
  $withBorder?: boolean;
}>`
  box-sizing: border-box;
  position: relative;

  height: 100%;

  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;

  ${({ $noYPadding }) =>
    $noYPadding &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}

  ${({ $noLeftPadding }) =>
    $noLeftPadding &&
    css`
      padding-left: 0;
    `}

  /* Visible to allow non-portalled popovers like tooltips to be visible */
  overflow: visible;

  /* Base min width to allow contracting */
  min-width: 5rem;

  /* Enables text truncation */
  white-space: nowrap;

  background-color: ${vars.colors.black};

  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7; /* Fix app fonts globally and delete this */

  color: ${vars.colors.grey60};

  ${({ $withBorder }) =>
    !$withBorder &&
    css`
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;

        background-color: ${vars.colors.grey100};
      }
    `}
`;

export const HeaderCell = styled(BaseCell)`
  color: ${vars.colors.grey30};
`;

export const RowContentsWrap = styled(Link)<{
  $selected?: boolean;
  $tableSelectionEnabled?: boolean;
  $withBorder?: boolean;
}>`
  position: relative;
  display: contents;
  user-select: none;

  .__spaceToPreview {
    opacity: 0;
  }

  /* Selected state */
  ${({ $selected }) =>
    $selected &&
    css`
      .__table_checkbox {
        opacity: 1;
      }

      & > * {
        background-color: ${rgba(vars.colors.purple, 0.25)};
        color: ${vars.colors.white};
      }

      .__tagsFade {
        display: none;
      }
    `}

  ${({ $selected, $withBorder }) =>
    $selected &&
    $withBorder &&
    css`
      .__table_checkbox {
        opacity: 0;
      }

      & > * {
        background-color: transparent;
        color: ${vars.colors.white};
        border-top: 2px solid var(--brand-primary, #6552ff);
        border-bottom: 2px solid var(--brand-primary, #6552ff);
      }
      & > :first-child {
        margin-right: -2rem;
        border-left: 2px solid var(--brand-primary, #6552ff);
        border-radius: 10px;
        border-top-right-radius: 0rem;
        border-bottom-right-radius: 0rem;
      }

      .__tagsFade {
        display: none;
      }
    `}

  /* Row Highlight if not selected */
  ${({ $selected }) =>
    !$selected &&
    css`
      .__table_checkbox {
        opacity: 0;
      }
      &:hover {
        & > * {
          background-color: ${vars.colors.grey100};
          color: ${vars.colors.white};
        }
        .__table_checkbox {
          opacity: 1;
        }
        .__spaceToPreview {
          opacity: 1;
        }
        .__tagsFade {
          display: none;
        }
      }
    `}

   /* Base Cell rounded corners for first and last cell */

  & > :first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  & > :last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;

export const HeaderContentsWrap = styled.div<{ $selected?: boolean; $tableSelectionEnabled?: boolean }>`
  display: contents;

  /* Selected state */
  ${({ $selected }) =>
    $selected &&
    css`
      .__table_checkbox {
        opacity: 1;
      }
    `}

  /* Row Highlight if not selected */
  ${({ $selected }) =>
    !$selected &&
    css`
      .__table_checkbox {
        opacity: 0;
      }
      &:hover {
        .__table_checkbox {
          opacity: 1;
        }
      }
    `}
`;

export const InputCheckboxWrap = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 4rem;

  input[type="checkbox"] {
    display: none;
  }

  label {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  input[type="checkbox"] + label:before {
    content: "";
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.5rem;
    border: solid 2px ${vars.colors.grey50};
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-clip: border-box;
    background-color: ${vars.colors.grey100};
  }

  input[type="checkbox"]:checked + label:before {
    background-image: url(${checkIcon});
    background-color: ${vars.colors.purple};
    border: none;
  }
`;

export const SVGButtonWrap = styled.button<{ $size?: number; $visible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background-color: transparent;

  > svg {
    height: 100%;
    width: 100%;
    opacity: 1;
  }

  ${({ $size }) =>
    $size &&
    css`
      height: ${$size}px;
      aspect-ratio: 1;
    `}

  /* Hover highlight only when visible */
  ${({ $visible }) =>
    $visible &&
    css`
      &:hover {
        > svg {
          opacity: 0.5;
        }
      }
    `}
`;

export const Indicator = styled.div`
  display: inline-block;

  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  background-color: ${vars.colors.red};
`;

// Table text truncating is manual -> reason: tooltip requires visible overflow on the BaseCell
export const Truncate = styled.p`
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: unset;
`;

export const Trash = styled(TrashIcon)`
  path {
    stroke: ${vars.colors.red};
  }
`;

export const HeaderButtons = styled.div`
  height: 2.8rem;
  max-width: 5.4rem;
  border-radius: 1rem;
  border: 1px solid ${vars.colors.grey90};
  background: ${vars.colors.grey100};
  display: flex;
  align-items: center;
  padding: 0.3rem;
`;

export const Grab = styled.div`
  display: flex;
  align-items: center;
  cursor: grab !important;
`;

export const InfoModalContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3px;
`;

export const InfoElement = styled.div`
  height: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.6rem 3.6rem;
`;
