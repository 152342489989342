import Paragraph from "@common/components/Paragraph/Paragraph";
import { vars } from "@common/styles";
import styled from "styled-components";

export const ChromeExtensionButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  width: 28.8rem;
  height: 4rem;
  top: 1.1rem;
  right: 2.8rem;
  border-radius: 1rem;
  border: 1px solid ${vars.colors.grey80};
  background: ${vars.colors.grey90};
  z-index: ${vars.zIndex.drawer};
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const ChromeExtensionButtonIcon = styled.img`
  margin-left: -1rem;
  width: 5.4rem;
`;

export const ChromeExtensionButtonText = styled(Paragraph)`
  color: ${vars.colors.grey20};
`;
