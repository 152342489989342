import React, { Fragment, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import Button from "../../../../../../common/components/Button/Button";
import { ModalFooter } from "../../../../../../common/components/Modal/ModalFooter/ModalFooter";
import SelectDropdown, { Option } from "../../../../../../common/components/SelectDropdown/SelectDropdown";
import { Workspace } from "../../../../../../common/models";
import { forwardingOptions } from "../../../../../../common/models/integrations";
import { Asana } from "../../../../../../common/models/integrations/Asana.model";
import { Form } from "../ModalBody.styles";
import { ProjectOption, useAsanaIntegrationFormConsumer } from "./consumers/useAsanaIntegrationFormConsumer";
import { BaseIntegrationModalProps } from "./GithubIntegrationModalBody";

interface Props extends BaseIntegrationModalProps {
  integration?: Asana;
  selectedAppId: string;
  workspace: Workspace;
}

export const AsanaIntegrationModalBody = ({ integration, selectedAppId, onFinish }: Props) => {
  const {
    disabled,
    loading,
    sectionOption,
    sectionOptions,
    projectOption,
    projectOptions,
    forwardingOption,
    handleFormChange,
    handleFormSubmit,
  } = useAsanaIntegrationFormConsumer({ integration, selectedAppId, onFinish });

  const [searchParams, setSearchParams] = useSearchParams();

  const optionDidChange = (option: Option, name: string | undefined) => {
    name && handleFormChange(name, option);
  };

  useEffect(() => {
    if (searchParams.get("type") && searchParams.get("appId")) {
      searchParams.delete("type");
      searchParams.delete("appId");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const isButtonDisabled = checkIsButtonDisabled(disabled, projectOption, sectionOption, forwardingOption, integration);

  return (
    <Fragment>
      <Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <SelectDropdown
          name="forwardingOption"
          value={forwardingOption}
          options={forwardingOptions}
          placeholder="Please choose"
          label="Forward tickets"
          onChange={optionDidChange}
          isSearchable={false}
          disabled={disabled || loading}
          hasSubtitles
        />
        <SelectDropdown
          name="projectOption"
          value={projectOption}
          options={projectOptions}
          placeholder="Please choose"
          label="Project"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || loading}
        />
        <SelectDropdown
          name="sectionOption"
          value={sectionOption}
          options={sectionOptions}
          placeholder={!projectOption ? "Pick a project first" : "Please choose"}
          label="Section"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || !projectOption || loading}
        />
      </Form>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: "No changes have been made",
              disabled: !isButtonDisabled || disabled,
            }}
            disabled={isButtonDisabled || loading}
            loadingText={loading && "Saving..."}
            onClick={handleFormSubmit}
          >
            Save changes
          </Button>
        }
      />
    </Fragment>
  );
};

const checkIsButtonDisabled = (
  disabled: boolean,
  projectOption: Option,
  sectionOption: Option,
  forwardingOption: Option,
  integration?: Asana,
) => {
  if (disabled) return true;
  if (!projectOption) return true;
  if (!integration) return false;

  const parsedProjectOption = JSON.parse(projectOption.value) as ProjectOption;
  if (
    sectionOption &&
    sectionOption.value !== "" &&
    (parsedProjectOption.projectId !== integration?.project_id ||
      !!forwardingOption.value !== integration?.automatic_forward ||
      sectionOption.value !== integration?.section_id)
  ) {
    return false;
  }
  return true;
};
