import React from "react";

import myWebAppBackground from "@common/assets/images/empty-state/my-web-app-visual.png";

import * as Styled from "./ChromeAppFooter.styles";

export interface AddAppFooter {
  text: string;
}

const AddAppFooter = ({ text }: AddAppFooter) => {
  return (
    <Styled.Container>
      <Styled.Content style={{ backgroundImage: `url(${myWebAppBackground})` }}>
        <Styled.Title
          marginTop={0}
          marginBottom={45}
          p1
        >
          {text}
        </Styled.Title>
      </Styled.Content>
    </Styled.Container>
  );
};

export const FeedbackModuleChromeAppFooter = () => {
  return (
    <AddAppFooter
      text={
        "We’ve created this first web app for you—think of it as a folder for storing tickets.\nYou can use the Chrome extension to send tickets here, delete this app, or create new apps to stay organized."
      }
    />
  );
};
