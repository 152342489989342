import React, { PropsWithChildren } from "react";

import { Link, useNavigate } from "react-router-dom";

import * as Styled from "./Sidebar.styles";
import { vars } from "../../../../common/styles";

interface Props {
  text?: string;
  to?: string;
  onClick: () => void;
  externalLink?: boolean;
  testId?: string;
}

interface DelayedLinkProps extends Omit<Props, "externalLink" | "onClick"> {
  requestBeforeReroute: () => Promise<void>;
}

export default function SidebarMenuItem({
  children,
  text,
  to,
  onClick,
  externalLink,
  testId,
}: PropsWithChildren<Props>) {
  return (
    <Styled.SidebarMenuItem>
      <Styled.SidebarMenuItemLink>
        {externalLink ? (
          <a
            href={to}
            target="_blank"
            onClick={onClick}
            rel="noreferrer"
            data-testid={testId}
          >
            {text}
            {children}
          </a>
        ) : to ? (
          <Link
            to={to}
            onClick={onClick}
          >
            {text}
            {children}
          </Link>
        ) : (
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: vars.colors.grey30,
            }}
            onClick={onClick}
            data-testid={testId}
          >
            <Styled.Flex
              $alignItems="center"
              $justifyContent="start"
              $gap={2}
            >
              {text}
              {children}
            </Styled.Flex>
          </button>
        )}
      </Styled.SidebarMenuItemLink>
    </Styled.SidebarMenuItem>
  );
}

export function SidebarMenuDelayedLinkItem({
  children,
  text,
  to,
  requestBeforeReroute,
  testId,
}: PropsWithChildren<DelayedLinkProps>) {
  const navigate = useNavigate();

  const onClickHandler = () => {
    requestBeforeReroute().then(() => {
      to && navigate(to);
    });
  };

  return (
    <Styled.SidebarMenuItem>
      <Styled.SidebarMenuItemLink>
        <Styled.SidebarMenuButton
          onClick={onClickHandler}
          data-testid={testId}
        >
          {text}
          {children}
        </Styled.SidebarMenuButton>
      </Styled.SidebarMenuItemLink>
    </Styled.SidebarMenuItem>
  );
}
