import styled, { css } from "styled-components";

import { vars } from "../../styles";
import { Flex } from "../../styles/reusable/Flex/Flex.styles";

export const CodeBlock = styled(Flex)`
  flex: 1;
  flex-direction: column;
`;

export const TabsContent = styled(Flex)`
  flex: 1;
  flex-direction: row;

  padding-bottom: 1.4rem;
`;

export const CodeContent = styled(Flex)`
  flex: 1;
  flex-direction: column;

  background-color: ${vars.colors.grey100};
  border: 0.1rem solid ${vars.colors.grey90};
  border-radius: 1rem;
`;

export const CodeTitle = styled.p`
  padding: 1.4rem 2rem;

  border-width: 0 0 0.1rem 0;
  border-style: solid;
  border-color: ${vars.colors.grey90};

  font-family: Inconsolata, monospace;
  font-size: 1.3rem;
`;

export const CodeText = styled.div`
  float: left;
  display: flex;
  flex-direction: column;

  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  margin-bottom: 0.1rem;
  min-width: 100%;
`;

export const CodeGroup = styled.span<{ $highlight?: boolean }>`
  position: relative;
  float: left;

  padding-left: 2rem;
  padding-right: 2rem;

  background-color: transparent;

  ${(props) =>
    props.$highlight &&
    css`
      background-color: ${vars.colors.grey90};
    `}
  &:hover {
    .copyButton {
      display: flex;
      justify-content: center;
      align-items: center;
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const CodeLine = styled.div`
  font-family: Inconsolata, monospace;
  font-size: 1.3rem;
  line-height: 1.8rem;
  word-break: break-word;

  .keyword {
    color: #64b1fd !important;
    font-style: normal !important;
  }

  .string {
    color: #d75ed7 !important;
    font-style: normal !important;
  }

  .function {
    color: #46d39a !important;
    font-style: normal !important;
  }

  .class-name {
    color: #b77dff !important;
    font-style: normal !important;
  }

  .punctuation {
    color: #8e8e93 !important;
  }

  .boolean {
    color: #c2e550 !important;
  }
`;
