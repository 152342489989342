import React from "react";

import * as Styled from "./SentryIntegrationContainer.styles";
import { SentryIntegrationIssue } from "../../../../../common/models/integrations";
import { Flex } from "../../../../../common/styles/reusable/Flex/Flex.styles";

export const SentryIssueRow = ({ issue }: { issue: SentryIntegrationIssue }) => {
  return (
    <Styled.IssueRow>
      <Styled.Flex
        $gap={2}
        $justifyContent="start"
      >
        <Styled.LinkTitle
          href={issue.permalink}
          target="_blank"
          rel="noreferrer"
        >
          {issue.title ? issue.title : issue.type}
        </Styled.LinkTitle>
        {issue.value && <Styled.Subtitle p2>{issue.culprit}</Styled.Subtitle>}
      </Styled.Flex>
      <Flex>
        <Styled.Description p3>{issue.value ? issue.value : issue.culprit}</Styled.Description>
      </Flex>
    </Styled.IssueRow>
  );
};
