import React from "react";

import CodeIcon from "@common/assets/images/add-app/add-app-code.png";
import ExtensionIcon from "@common/assets/images/add-app/add-app-extension.png";
import InviteIcon from "@common/assets/images/add-app/add-app-invite.png";
import Heading from "@common/components/Heading/Heading";
import LargeButton from "@common/components/LargeButton/LargeButton";
import { LargeButtonGroup } from "@common/components/LargeButton/LargeButtonGroup.styles";
import PageWrap from "@common/components/PageWrap/PageWrap";
import Paragraph from "@common/components/Paragraph/Paragraph";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath, useNavigate } from "react-router-dom";

import * as Styled from "./AddAppIntroPage.styles";

interface Props {
  onInstallationTypeSelected: (installationType: InstallationType) => void;
}

enum InstallationType {
  MANUAL,
  EXTENSION,
}

const AddAppIntroPage = ({ onInstallationTypeSelected }: Props) => {
  const navigate = useNavigate();
  const { selectedWorkspace } = useAppSelectionContext();

  const onUseExtensionClick = () => {
    onInstallationTypeSelected(InstallationType.EXTENSION);
  };

  const onAddMyselfClick = () => {
    onInstallationTypeSelected(InstallationType.MANUAL);
  };

  const onInviteClick = () => {
    navigate(generatePath(RoutePaths.ADMINISTRATION_MEMBERS, { workspaceSlug: selectedWorkspace?.slug ?? null }));
  };

  return (
    <PageWrap style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <Styled.Container>
        <Heading
          className={"center"}
          heading1
          marginBottom={24}
        >
          {"How will you use Shake in this app?"}
        </Heading>

        <Paragraph
          marginBottom={28}
          fontWeight={500}
        >
          {"Do you know how to code — or do you want to invite your colleague to do this?"}
        </Paragraph>
        <LargeButtonGroup>
          <LargeButton
            image={ExtensionIcon}
            title={"Use Chrome extension"}
            subtitle={`The simplest way \n to report web tickets`}
            onClick={onUseExtensionClick}
          />
          <LargeButton
            image={CodeIcon}
            title={"Add the SDK to your code"}
            subtitle={"I love to code \n and customize"}
            onClick={onAddMyselfClick}
          />
          <LargeButton
            image={InviteIcon}
            title={"Invite your developer"}
            subtitle={"My teammate will add \n the SDK to our code"}
            onClick={onInviteClick}
          />
        </LargeButtonGroup>
      </Styled.Container>
    </PageWrap>
  );
};

export { AddAppIntroPage, InstallationType };
