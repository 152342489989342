import { App, PlatformName } from "../../../../common/models";
import { getAppPlatformLabel, getAppPlatformOsLabel } from "../../../../common/util/contentResolvers";
import { docLink } from "../../../../common/util/docs";

export const resolveEmptyStateView = (
  loading: boolean,
  type: "feedback" | "crashes" | "users" | "logs",
  hasItems: boolean,
  selectedApp?: App,
  hasAttributes?: boolean,
  isError?: boolean,
  refetchData?: () => void,
) => {
  if (!loading && hasAttributes && isError) {
    return {
      title: "Something went wrong",
      firstParagraph: "Sorry, this action was unsuccessful.",
      button: { text: "Try again", onClick: refetchData },
    };
  }
  if (!hasItems && !loading && hasAttributes) {
    return {
      title: "No results found",
      firstParagraph: "No results match filter criteria.",
      secondParagraph: "Remove filter to show results.",
    };
  }
  if (!hasItems && !loading) {
    return getEmptyStateByObjectType(type, selectedApp);
  }
  return undefined;
};
const getEmptyStateByObjectType = (type: "feedback" | "crashes" | "users" | "logs", selectedApp?: App) => {
  const isNative =
    selectedApp?.platform.name !== PlatformName.REACT_NATIVE && selectedApp?.platform.name !== PlatformName.FLUTTER;
  const isWeb = selectedApp?.platform.name === PlatformName.WEB;
  switch (type) {
    case "logs":
      return {
        title: "No activity history",
        firstParagraph:
          "Shake tracks user’s interaction with your app, their network traffic, notifications, logs and system events, and automatically attaches all of those to the ticket.",
        secondParagraph: " activity history to see all the logs.",
        paragraphLink: {
          text: "Enable",
          link: docLink(selectedApp?.platform.name).enableLogs,
        },
        infoBlockContent: getInfoBlockContent(type, selectedApp, isNative, isWeb),
      };
    default:
      return undefined;
  }
};
const getInfoBlockContent = (
  type: "feedback" | "crashes" | "users" | "logs",
  selectedApp?: App,
  isNative?: boolean,
  isWeb?: boolean,
) => {
  if ((!isNative || isWeb) && type === "crashes") {
    const notNativeLabelInLink = selectedApp?.platform.name === PlatformName.REACT_NATIVE ? "react-native" : "flutter";
    return {
      textBeforeLink: "If you'd use it,",
      linkText: "upvote it",
      link: isWeb
        ? "https://feedback.shakebugs.com/web-sdk-feature-requests/p/shakes-web-sdk-enhancing-with-crash-reporting"
        : `https://feedback.shakebugs.com/feature-requests/p/${notNativeLabelInLink}-sdk-crash-reports`,
      textAfterLink: " on our Public Roadmap so we know there’s demand for it.",
    };
  }

  return {
    textBeforeLink: "Something’s wrong?",
    linkText: "Let us know",
    link: "javascript:Intercom('showNewMessage')",
    textAfterLink: ` and our ${selectedApp?.platform.name} engineers will help you.`,
    sameTab: true,
  };
};

export const getCrashNotSupportedConfig = (selectedApp: App) => {
  return {
    title: `Interested in Crash reports for ${getAppPlatformLabel(selectedApp?.platform)}?`,
    firstParagraph: `Shake SDK is alive and kicking in your ${
      selectedApp?.name !== PlatformName.WEB ? selectedApp.name : ""
    } ${getAppPlatformOsLabel(
      selectedApp?.platform,
    )} app, but we haven’t developed the Crash reports module for ${getAppPlatformLabel(
      selectedApp?.platform,
    )}... yet.`,
    secondParagraph: undefined,
    paragraphLink: undefined,
    infoBlockContent: getInfoBlockContent(
      "crashes",
      selectedApp,
      false,
      selectedApp.platform.name === PlatformName.WEB,
    ),
  };
};
