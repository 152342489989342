import styled from "styled-components";

import { ReactComponent as MappingFileIcon } from "../../../../../common/assets/images/shared-icons/mapping-file-icon.svg";
import { vars } from "../../../../../common/styles";

export const Number = styled.p`
  padding-right: 1rem;
  color: ${vars.colors.grey30};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Version = styled.p`
  padding-right: 1rem;

  color: ${vars.colors.grey60};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CustomIcon = styled(MappingFileIcon)`
  width: 2.4rem;
  height: 2.4rem;
  svg {
    path {
      stroke: ${vars.colors.grey30};
    }
  }
`;
