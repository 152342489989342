import React from "react";

import freePlanFooterBackground from "@common/assets/images/empty-state/learn-more-visual.png";
import Button from "@common/components/Button/Button";
import Icon from "@common/components/Icon/Icon";

import * as Styled from "./FreePlanFooter.styles";

export interface FreePlanFooterProps {
  text: string;
}

const FreePlanFooter = ({ text }: FreePlanFooterProps) => {
  const onLearnMoreClick = () => {
    window.open("https://www.shakebugs.com/pricing/", "_blank");
  };

  return (
    <Styled.Container>
      <Styled.Content style={{ backgroundImage: `url(${freePlanFooterBackground})` }}>
        <Styled.Title
          marginTop={8}
          marginBottom={2}
          p1
        >
          {text}
        </Styled.Title>
        <Button onClick={onLearnMoreClick}>
          <Icon
            icon="spark"
            size={24}
          />
          {"Learn more"}
        </Button>
      </Styled.Content>
    </Styled.Container>
  );
};

export const FeedbackModuleFreePlanFooter = () => {
  return (
    <FreePlanFooter
      text={
        "Tickets older than 1 month are hidden. Every paid plan includes at least a year of data retention and additional premium benefits."
      }
    />
  );
};

export const CrashModuleFreePlanFooter = () => {
  return (
    <FreePlanFooter
      text={
        "Crashes older than 1 month are hidden. Every paid plan includes at least a year of data retention and additional premium benefits."
      }
    />
  );
};
