import { vars } from "@common/styles";
import styled from "styled-components";

import BaseDropdown from "../../../../../common/components/Dropdown/Dropdown";
import BaseParagraph from "../../../../../common/components/Paragraph/Paragraph";
import { Flex as BaseFlex } from "../../../../../common/styles/reusable/Flex/Flex.styles";

export const Flex = styled(BaseFlex)`
  flex-wrap: nowrap;
`;

export const Table = styled.div<{ $enabled: boolean }>`
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.$enabled ? "solid" : "dashed")} 1px ${vars.colors.grey100};
  cursor: ${(props) => (props.$enabled ? "default" : "pointer")};
  border-radius: 1.2rem;
  margin-top: 1.2rem;
  margin-bottom: 2.8rem;
`;

export const TableHeader = styled.div<{ $isBodyOpen?: boolean; $enabled?: boolean }>`
  display: flex;
  padding: 1.2rem 2rem;
  border-bottom: ${(props) =>
    props.$isBodyOpen ? `${props.$enabled ? "solid" : "dashed"} 1px ${vars.colors.grey100}` : "none"};
  justify-content: space-between;
`;

export const TableBody = styled.div`
  padding: 0;
`;

export const Image = styled.img`
  width: 2rem;
  height: 100%;
  flex-shrink: 0;
  display: inline-block;
`;

export const Header = styled.span`
  color: #d1d1d6;
  margin: auto;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
`;

export const ProjectDropdown = styled(BaseDropdown)`
  background: ${vars.colors.grey100};
  border: 1px solid ${vars.colors.grey90};
  border-radius: 1rem;

  > div:first-child {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  span {
    text-color: ${vars.colors.grey30};
    white-space: nowrap;
  }
`;

export const GreyBox = styled.div`
  background: ${vars.colors.grey100};
  width: 200px;
  padding: 0.6rem 2rem;
  border-radius: 0.2rem;
  margin: 2.1rem 2.1rem;
`;

export const IssueRow = styled.div`
  padding: 1.2rem 2rem;
  border-bottom: solid 1px ${vars.colors.grey100};

  &:last-child {
    border-bottom: none;
  }
`;

export const LinkTitle = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 500;
  color: #80acff;
`;

export const Subtitle = styled(BaseParagraph)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${vars.colors.grey60};
`;

export const Description = styled(BaseParagraph)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0.6rem;
  color: ${vars.colors.grey30};
`;

export const Pill = styled.div`
  background-color: ${vars.colors.limeTransparent};
  color: ${vars.colors.lime};
  border-radius: 1.5rem;
  padding: 0.2rem 0.6rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;
