import styled, { css } from "styled-components";

import { vars } from "../../../styles/abstracts/vars";
import { Flex as BaseFlex } from "../../../styles/reusable/Flex/Flex.styles";
import Heading from "../../Heading/Heading";
import Paragraph from "../../Paragraph/Paragraph";

export const Title = styled(Heading)`
  color: ${vars.colors.grey30};
  word-break: break-word;
`;

export const Subtitle = styled(Paragraph)`
  word-break: break-word;
`;

export const Logo = styled.img`
  width: 2.4rem;
`;

export const Flex = styled(BaseFlex)<{ $hasBorderBottom?: boolean; $noContent?: boolean }>`
  ${(props) =>
    props.$hasBorderBottom &&
    css`
      border-bottom: 1px solid ${vars.colors.grey90};
      padding-bottom: 2.4rem;
      margin-bottom: 2.4rem;
    `};

  ${(props) =>
    props.$noContent &&
    css`
      padding-bottom: 2.4rem;
    `};
`;
