import { AxiosResponse } from "axios";

import ApiService from "../../../../common/services/Api.service";

export class WorkspaceJoinService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  joinWorkspaces(workspaceIds: string[]): Promise<AxiosResponse> {
    return this.apiService.post({
      resource: "accounts/teams/joinable_teams",
      data: { team_ids: workspaceIds },
    });
  }
}
