import { useEffect, useMemo, useRef, useState } from "react";

import { ColumnDef, getCoreRowModel, RowSelectionState, useReactTable } from "@tanstack/react-table";

import { useInfiniteScrollDetector } from "./useInfiniteScrollDetector";
import { useSpaceToPreview } from "./useSpaceToPreview";
import { useAppSelectionContext } from "../../../main/context/App/AppSelectionContext";
import { Attribute } from "../../hooks/filtering/sharedTypes";
import { TicketPriority } from "../../models/TicketPriority.model";
import { TicketStatus } from "../../models/TicketStatus.model";

type Props<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
  selectable?: boolean;
  hasMore: boolean;
  isLoading: boolean;
  isFetchingNext: boolean;
  loadMore: () => void;
  renderRowLinkPath?: (row: TData) => string;
  onDeleteRequest: (rows: TData[]) => void;
  onMarkAsReadRequest?: (rows: TData[]) => void;
  onBulkChangeStatus?: (rows: TData[], status: TicketStatus, resetSelection?: () => void) => void;
  onBulkChangePriority?: (rows: TData[], priority: TicketPriority, resetSelection?: () => void) => void;
  onRowClick?: (row: TData) => void;
  onPreviewRequest?: (row: TData) => void;
  onPreviewClose?: () => void;
  validAttributes?: Attribute[];
  withBorder?: boolean;
  total?: number;
  selectedSortOption?: string;
};

export function useBaseTable<TData>({
  data,
  columns,
  selectable = true,
  hasMore,
  isLoading,
  isFetchingNext,
  loadMore,
  renderRowLinkPath,
  onPreviewRequest = () => {
    return;
  },
  onPreviewClose = () => {
    return;
  },
  onRowClick,
  validAttributes,
  withBorder,
  selectedSortOption,
  total,
}: Props<TData>) {
  const { selectedApp } = useAppSelectionContext();
  const containerRef = useRef<HTMLDivElement>(null);

  /// Row Selection
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const gridTemplateColumns = useMemo(() => {
    return columns.reduce((prevTemplate, current) => {
      const columnMeta = current.meta;

      // eslint-disable-next-line
      if ((columnMeta as any)?.columnTemplate) {
        // eslint-disable-next-line
        return prevTemplate + " " + `${(columnMeta as any).columnTemplate}`;
      }

      if (current.minSize || current.maxSize) {
        return prevTemplate + `minmax(${current.minSize}fr, ${current.maxSize}fr)`;
      }
      return prevTemplate + " " + `${current.size ?? 1}fr`;
    }, "");
  }, [columns]);

  const table = useReactTable({
    // eslint-disable-next-line
    data: useMemo(() => data, [data, total]),
    // eslint-disable-next-line
    columns: useMemo(() => columns, [total, selectedSortOption]),
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    table.resetRowSelection();
    //eslint-disable-next-line
  }, [selectedApp]);

  /// Space to preview
  const spaceToPreviewData = useSpaceToPreview({
    onPreviewRequest: (index) => {
      const row = table.getRowModel().rows[index];
      onPreviewRequest(row.original);
    },
    onPreviewClosed: onPreviewClose,
  });

  const { onContainerScroll } = useInfiniteScrollDetector({
    hasMore,
    isLoading: isFetchingNext,
    loadMore,
    containerRef,
  });

  return {
    table,
    gridTemplateColumns,
    onContainerScroll,
    containerRef,
    renderRowLinkPath,
    spaceToPreview: spaceToPreviewData,
    isLoading,
    selectable,
    onRowClick,
    validAttributes,
    withBorder,
    isFetchingNext,
    selectedSortOption,
  };
}
