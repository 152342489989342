import styled from "styled-components";

import { Flex as BaseFlex } from "../../../../../common/styles/reusable/Flex/Flex.styles";
import { PlayIcon } from "../../../shared/components/Attachments/icons";

export const Video = styled.div`
  position: relative;
  cursor: pointer;
  width: 28rem;
  height: 15.8rem;
  margin-top: 2rem;
  img {
    border-radius: 2rem;
  }
`;

export const Flex = styled(BaseFlex)`
  > div {
    display: flex;
    justify-content: center;
    border-radius: 2rem;
    .react-player {
      overflow: hidden;
      border: 1.5px solid green;
    }
  }
`;

export const Play = styled(PlayIcon)`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;
