import { useEffect, useState } from "react";

import { Option } from "../../../../../../../common/components/SelectDropdown/SelectDropdown";
import displayToast from "../../../../../../../common/components/Toast/displayToast";
import { useForm } from "../../../../../../../common/hooks/useForm";
import {
  Asana,
  AsanaIntegrationInfo,
  AsanaIntegrationUpdatePayload,
  automaticForwardOption,
  IntegrationType,
  manualForwardOption,
} from "../../../../../../../common/models/integrations";
import { useIntegrationFormConsumer } from "../../../../consumers/useIntegrationFormConsumer";

interface Props {
  integration?: Asana;
  selectedAppId: string;
  onFinish: () => void;
}

export interface ProjectOption {
  name: string;
  projectId: string;
  integrationId: string;
}

interface FormState {
  projectOption: Option;
  forwardingOption: Option;
  sectionOption: Option;
}

const initialFormState = {
  projectOption: { value: "{}", label: "Fetching... Please wait, large projects may take a few minutes." },
  forwardingOption: automaticForwardOption,
  sectionOption: { value: "", label: "Fetching... Please wait, large projects may take a few minutes." },
};

export const useAsanaIntegrationFormConsumer = ({ selectedAppId, integration, onFinish }: Props) => {
  const { integrationInfo, integrate, updateIntegration } = useIntegrationFormConsumer<AsanaIntegrationInfo[]>({
    selectedAppId,
    type: IntegrationType.ASANA,
    toggle: onFinish,
  });

  const [loading, setLoading] = useState(false);

  const [projectOptions, setProjectOptions] = useState<Option[]>([]);
  const [sectionOptions, setSectionOptions] = useState<Option[]>([]);

  const { formState, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: initialFormState,
    onSubmit: handleConnect,
    validators: {},
  });

  const { projectOption, forwardingOption, sectionOption } = formState;

  async function handleConnect() {
    try {
      if (!integrationInfo) throw new Error();

      setLoading(true);

      const asanaProjectInfo = JSON.parse(projectOption.value) as ProjectOption;

      const payload: AsanaIntegrationUpdatePayload = {
        automatic_forward: !!forwardingOption.value,
        integration_id: asanaProjectInfo.integrationId,
        project_id: asanaProjectInfo.projectId,
        section_id: sectionOption.value,
      };

      const endpoint = !!integration ? updateIntegration : integrate;

      endpoint<Asana>(payload);
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      setLoading(false);
      onFinish();
    }
  }

  useEffect(() => {
    if (!integrationInfo) return;

    if (!integrationInfo.length) {
      setProjectOptions([]);
      setSectionOptions([]);
      handleFormChange("projectOption", null);
      handleFormChange("sectionOption", { value: "", label: "/" });
      return;
    }

    const newProjectOptions: Option[] = integrationInfo.flatMap((info) => {
      return info.projects.map((projectInfo) => {
        const projectOption: ProjectOption = {
          name: projectInfo.name,
          projectId: projectInfo.project_id,
          integrationId: info.integration_id,
        };
        return { value: JSON.stringify(projectOption), label: projectInfo.name };
      });
    });

    setProjectOptions([...newProjectOptions]);

    const preSelectedProject = integration
      ? newProjectOptions.find((option) => {
          const projectOption = JSON.parse(option.value) as ProjectOption;
          return projectOption.projectId === integration.project_id;
        }) || null
      : null;

    handleFormChange("projectOption", preSelectedProject);

    const preselectedForwardingOption = integration
      ? integration.automatic_forward
        ? automaticForwardOption
        : manualForwardOption
      : automaticForwardOption;

    handleFormChange("forwardingOption", preselectedForwardingOption);
  }, [integrationInfo, handleFormChange, integration]);

  useEffect(() => {
    if (!integrationInfo) return;

    if (projectOption === null) {
      handleFormChange("sectionOption", null);
      return;
    }

    const selectedProjectOption = JSON.parse(projectOption.value) as ProjectOption;

    const allProjects = integrationInfo.flatMap((info) => {
      return info.projects.map((projectInfo) => {
        return projectInfo;
      });
    });

    const sectionsForSelectedProjectOption = allProjects
      .find((project) => project.project_id === selectedProjectOption.projectId)
      ?.sections.map(({ section_id, section_name }) => {
        return { value: section_id, label: section_name };
      });

    sectionsForSelectedProjectOption && setSectionOptions(sectionsForSelectedProjectOption);

    if (!sectionsForSelectedProjectOption) return;

    const preSelectedSection = integration
      ? sectionsForSelectedProjectOption.find((sectionOption) => {
          return integration.section_id === sectionOption.value;
        }) || sectionsForSelectedProjectOption[0]
      : sectionsForSelectedProjectOption[0];

    preSelectedSection && handleFormChange("sectionOption", preSelectedSection);
  }, [projectOption, integrationInfo, handleFormChange, integration]);

  const disabled = !integrationInfo;

  return {
    disabled,
    loading,
    projectOptions,
    projectOption,
    sectionOption,
    sectionOptions,
    forwardingOption,
    handleFormChange,
    handleFormSubmit,
  };
};
