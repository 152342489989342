import React, { Fragment, useEffect, useRef, useState } from "react";

import { ReactComponent as AnalyticsIcon } from "@common/assets/images/sidebar-icons/analytics-icon.svg";
import { ReactComponent as AppUsersIcon } from "@common/assets/images/sidebar-icons/app-users-icon.svg";
import { ReactComponent as CrashReportsIcon } from "@common/assets/images/sidebar-icons/crash-reports-icon.svg";
import { ReactComponent as InfoIcon } from "@common/assets/images/sidebar-icons/info-module.svg";
import { ReactComponent as UserFeedbackIcon } from "@common/assets/images/sidebar-icons/user-feedback-icon.svg";
import RoundedCanvas from "@common/components/RoundedCanvas/RoundedCanvas";
import useOnClickOutside from "@common/hooks/useOnClickOutside";
import { App, getUserInitials, UserData, Workspace } from "@common/models";
import { ChangelogNotification } from "@common/models/Changelog.model";
import { AppNotification } from "@common/models/notifications";
import { vars } from "@common/styles";
import identifiers from "@common/util/identifiers.json";
import useSignOutApiConsumer from "@main/consumers/useSignOutApiConsumer";
import useWorkspaceAndAppChange from "@main/consumers/useWorkspaceAndAppChange";
import { useAppContext } from "@main/context/App/App.context";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { useUnreadNotificationsContext } from "@main/context/App/UnreadNotificationsContext";
import { useAuthContext } from "@main/context/Auth/Auth.context";
import { SubscriptionState, useSubscriptionContext } from "@main/context/Subscription/SubscriptionContext";
import { getRandomColor } from "@main/pages/shared/helpers/getRandomColorHelper";
import { RoutePaths } from "@main/router/config/routePaths";
import { useCurrentPathRouteConfig } from "@main/router/util/useCurrentPathRouteConfig";
import { generatePath, useParams } from "react-router-dom";

import * as Styled from "./Sidebar.styles";
import SidebarLink from "./SidebarLink";
import SidebarMenuUserProfile from "./SidebarMenuUserProfile";
import SidebarMenuWorkspace from "./SidebarMenuWorkspace";

export const Sidebar = () => {
  const { userData } = useAuthContext();
  const { workspaces } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();

  if (!userData || !workspaces || !selectedWorkspace) {
    return <SidebarSkeleton />;
  }
  return (
    <SidebarInternal
      selectedWorkspace={selectedWorkspace}
      workspaces={workspaces}
      user={userData}
    />
  );
};

interface Props {
  selectedWorkspace: Workspace;
  workspaces: Workspace[];
  user: UserData;
}

const SidebarInternal = ({ workspaces, selectedWorkspace, user }: Props) => {
  const { toCrashes, toFeedback, toUsers, toInfo, toStats } = useSidebarAppLinks();
  const { unreadNotifications } = useUnreadNotificationsContext();
  const { unreadChangeLogNotifications } = useAppContext();
  const { signOut } = useSignOutApiConsumer();
  const { handleWorkspaceChange } = useWorkspaceAndAppChange();
  const { selectedApp } = useAppSelectionContext();

  const { firstPillName } = useCurrentPathRouteConfig();

  const [isWorkspaceMenuOpen, setIsWorkspaceMenuOpen] = useState(false);
  const [isUserProfileMenuOpen, setIsUserProfileMenuOpen] = useState(false);
  const { workspaceSlug } = useParams();

  const filteredWorkspaces = workspaces.filter((workspace) => workspace.slug !== workspaceSlug);

  const workspaceMenuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(workspaceMenuRef, () => {
    setIsWorkspaceMenuOpen(false);
  });

  const userProfileMenuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(userProfileMenuRef, () => {
    setIsUserProfileMenuOpen(false);
  });

  const toggleWorkspaceMenu = () => {
    setIsWorkspaceMenuOpen(!isWorkspaceMenuOpen);
  };

  const toggleUserProfileMenu = () => {
    setIsUserProfileMenuOpen(!isUserProfileMenuOpen);
  };

  return (
    <Styled.SidebarWrap
      className="sidebar"
      $active={isWorkspaceMenuOpen}
    >
      <Styled.SidebarTop>
        <Styled.SidebarIconWrap ref={workspaceMenuRef}>
          <Styled.SidebarCanvasWrap>
            <RoundedCanvas
              letter={selectedWorkspace.name[0]?.toUpperCase()}
              onClick={toggleWorkspaceMenu}
              testId={identifiers["main.workspaceIcon"]}
              image={selectedWorkspace?.logo_url ?? undefined}
              bgColor={getRandomColor(selectedWorkspace?.id).background}
              txtColor={getRandomColor(selectedWorkspace?.id).text}
              arrow={isWorkspaceMenuOpen ? "right" : "down"}
            />
          </Styled.SidebarCanvasWrap>

          <SidebarMenuWorkspace
            active={isWorkspaceMenuOpen}
            setIsActive={setIsWorkspaceMenuOpen}
            workspaces={filteredWorkspaces}
            selectedWorkspace={selectedWorkspace}
            toggleVisibility={toggleWorkspaceMenu}
            handleWorkspaceChange={handleWorkspaceChange}
          />
        </Styled.SidebarIconWrap>
      </Styled.SidebarTop>

      <Styled.SidebarLinks>
        <Styled.CluesDiv>
          <SidebarLink
            to={toFeedback}
            icon={
              <FeedbackIcon
                unreadNotif={unreadNotifications}
                selectedApp={selectedApp}
                displayNotifications={firstPillName === "User feedback"}
              />
            }
            testId={identifiers["main.moduleIcon.userFeedback"]}
            disabled={false}
            label="Feedback"
          />

          <SidebarLink
            to={toCrashes}
            icon={<CrashReportsIcon />}
            testId={identifiers["main.moduleIcon.crashReports"]}
            disabled={false}
            label="Crashes"
          />

          <SidebarLink
            to={toUsers}
            icon={<AppUsersIcon />}
            testId={identifiers["main.moduleIcon.users"]}
            disabled={false}
            label="Users"
          />

          <SidebarLink
            to={toStats}
            icon={<AnalyticsIcon />}
            disabled={false}
            label="Stats"
          />
          <SidebarLink
            to={toInfo}
            icon={<InfoNotificationIcon unreadNotif={unreadChangeLogNotifications} />}
            disabled={false}
            label="Info"
          />
        </Styled.CluesDiv>
      </Styled.SidebarLinks>

      <Styled.SidebarBottom>
        <Styled.SidebarIconWrap ref={userProfileMenuRef}>
          <Styled.SidebarCanvasWrap>
            <RoundedCanvas
              letter={getUserInitials(user.name)}
              bgColor={getRandomColor(user.id) && getRandomColor(user.id).background}
              txtColor={getRandomColor(user.id) && getRandomColor(user.id).text}
              onClick={toggleUserProfileMenu}
              testId={identifiers["main.profileIcon"]}
              image={user.picture ?? undefined}
              arrow={isUserProfileMenuOpen ? "right" : "down"}
            />
          </Styled.SidebarCanvasWrap>
          <SidebarMenuUserProfile
            active={isUserProfileMenuOpen}
            toggleVisibility={toggleUserProfileMenu}
            handleSignOut={signOut}
            userName={user.name}
          />
        </Styled.SidebarIconWrap>
      </Styled.SidebarBottom>
      <Styled.ModalOverlay active={isWorkspaceMenuOpen || isUserProfileMenuOpen} />
    </Styled.SidebarWrap>
  );
};
export const SidebarSkeleton = () => {
  return <Styled.SidebarWrap className="sidebar"></Styled.SidebarWrap>;
};

function useSidebarAppLinks() {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();
  const { state: subscriptionState } = useSubscriptionContext();
  const { loading: appsLoading } = useAppContext();

  const [links, setLinks] = useState(resolveLinks());

  function generateAppLinkPath(route: RoutePaths, tempSelectedApp: App | undefined) {
    if (tempSelectedApp && (route === RoutePaths.FEATURES_LATEST || route === RoutePaths.ADD_APP)) {
      return `${generatePath(route, {
        workspaceSlug: selectedWorkspace?.slug ?? null,
      })}?returnTo=${tempSelectedApp.key}`;
    }
    const pickedApp = tempSelectedApp ?? selectedApp;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return generatePath(route, {
      workspaceSlug: selectedWorkspace?.slug ?? null,
      appKey: pickedApp?.key ?? null,
    });
  }

  function generateModuleRootLinkPath(route: RoutePaths) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return generatePath(route, {
      workspaceSlug: selectedWorkspace?.slug ?? null,
    });
  }

  function resolveLinks() {
    const pickedApp = selectedApp;

    const isSidebarLinkDisabled = subscriptionState !== SubscriptionState.good || !pickedApp || appsLoading;

    if (isSidebarLinkDisabled) {
      return {
        toInfo: generateModuleRootLinkPath(RoutePaths.INFO_ROOT),
        toFeedback: generateModuleRootLinkPath(RoutePaths.USER_FEEDBACK_MODULE_ROOT),
        toCrashes: generateModuleRootLinkPath(RoutePaths.CRASH_MODULE_ROOT),
        toUsers: generateModuleRootLinkPath(RoutePaths.USERS_MODULE_ROOT),
        toStats: generateAppLinkPath(RoutePaths.STATS_MODULE_ROOT, pickedApp),
        toAddApp: generateAppLinkPath(RoutePaths.ADD_APP, pickedApp),
      };
    }

    return {
      toInfo: generateAppLinkPath(RoutePaths.FEATURES_LATEST, pickedApp),
      toFeedback: generateAppLinkPath(RoutePaths.USER_FEEDBACK, pickedApp),
      toCrashes: generateAppLinkPath(RoutePaths.CRASH_REPORTS, pickedApp),
      toUsers: generateAppLinkPath(RoutePaths.USERS, pickedApp),
      toStats: generateAppLinkPath(RoutePaths.STATS, pickedApp),
      toAddApp: generateAppLinkPath(RoutePaths.ADD_APP, pickedApp),
    };
  }

  useEffect(() => {
    setLinks(resolveLinks());
  }, [selectedApp, selectedWorkspace, subscriptionState, appsLoading]);

  return links;
}

const FeedbackIcon = ({
  selectedApp,
  unreadNotif,
  displayNotifications,
}: {
  selectedApp?: App;
  unreadNotif?: Record<string, AppNotification[]>;
  displayNotifications?: boolean;
}) => {
  if (!unreadNotif) return <Fragment />;
  if ((!selectedApp || window.location.pathname.includes("info")) && unreadNotif) {
    if (Object.keys(unreadNotif).some((a) => Boolean(a.length))) {
      return IconWithIndicator(<UserFeedbackIcon />, undefined, <Styled.SmallDot />);
    } else {
      return <UserFeedbackIcon />;
    }
  }

  if (selectedApp) {
    const unread = unreadNotif[selectedApp.id];
    if (!unread || !Boolean(unread.length) || displayNotifications) {
      return <UserFeedbackIcon />;
    }
    return IconWithIndicator(<UserFeedbackIcon />, unread.length > 99 ? "99" : unread.length.toString());
  } else return <Fragment />;
};

const InfoNotificationIcon = ({ unreadNotif }: { unreadNotif?: ChangelogNotification[] }) => {
  if (!unreadNotif) return <Fragment />;

  if (!Boolean(unreadNotif.length)) {
    return <InfoIcon />;
  }

  return IconWithIndicator(<InfoIcon />, unreadNotif.length > 99 ? "99" : unreadNotif.length.toString());
};

const IconWithIndicator = (icon: JSX.Element, text?: string, el?: JSX.Element) => {
  return (
    <Fragment>
      <Styled.NotifBox>
        <Styled.NotificationIndicator>
          {text && <span style={{ fontSize: "12px", color: vars.colors.white }}>{text}</span>}
          {el && el}
        </Styled.NotificationIndicator>
      </Styled.NotifBox>
      {icon}
    </Fragment>
  );
};
