import React, { Fragment, useEffect } from "react";

import webImage from "@common/assets/images/empty-state/click-the-floating-button.png";
import mobileImage from "@common/assets/images/empty-state/deep-purple.png";
import background from "@common/assets/images/empty-state/feedback-module-empty-heading.png";
import HouseIcon from "@common/assets/images/FTUX/house-icon.svg";
import shakeExtensionButtonImage from "@common/assets/images/FTUX/shake-extension-button.png";
import shakeExtensionPickerImage from "@common/assets/images/FTUX/shake-extension-picker.png";
import Paragraph from "@common/components/Paragraph/Paragraph";
import displayToast from "@common/components/Toast/displayToast";
import Toast from "@common/components/Toast/Toast";
import { App, PlatformName } from "@common/models";
import { vars } from "@common/styles";
import { docLink } from "@common/util/docs";
import { SHAKE_EXTENSION_PAGE } from "@common/util/urls";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { FeedbackModuleDescriptionBox } from "@main/pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";
import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath, useNavigate } from "react-router-dom";

import * as Styled from "./ModuleEmptyState.styles";
import { SetupStep } from "../SetupStep/SetupStep";

const FEEDBACK_SOMETHING_NOT_WORKING_TOAST = "feedback-something-not-working";

export const FeedbackModuleEmptyState = () => {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedWorkspace) return;
    displayToast({
      icon: HouseIcon,
      containerId: FEEDBACK_SOMETHING_NOT_WORKING_TOAST,
      title: "Something not working?",
      content: (
        <Fragment>
          Whenever you need help, visit{" "}
          {
            <Styled.StyledLink
              onClick={() => navigate(generatePath(RoutePaths.INFO_ROOT, { workspaceSlug: selectedWorkspace.slug }))}
            >
              Info
            </Styled.StyledLink>
          }{" "}
          where you will find links to help center, our Slack community and to private chat with our support engineers.
        </Fragment>
      ),
    });
  }, [navigate, selectedWorkspace]);

  if (selectedApp?.platform.name === PlatformName.WEB) {
    if (selectedApp?.is_extension) return <EmptyFeedbackModuleChromeSteps selectedApp={selectedApp} />;
    return <EmptyFeedbackModuleWebSteps selectedApp={selectedApp} />;
  }
  return <EmptyFeedbackModuleMobileView selectedApp={selectedApp} />;
};

interface EmptyViewProps {
  selectedApp?: App;
}

const EmptyFeedbackModuleMobileView = ({ selectedApp }: EmptyViewProps) => {
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <FeedbackModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={"Shake your device"}
        />
        <Styled.StepContent>
          <Paragraph
            lineHeight={18}
            marginBottom={10}
          >
            Shake is alive in your {selectedApp?.name} {selectedApp?.platform.name} app. Open the app
            <Styled.StepReference>1</Styled.StepReference> and shake the device
            <Styled.StepReference>2</Styled.StepReference> to report the first ticket.
          </Paragraph>
          <Styled.Steps>
            <Paragraph
              lineHeight={18}
              marginBottom={1}
              color={vars.colors.grey70}
              fontSize={14}
            >
              <Styled.StepReference>1</Styled.StepReference> Make sure you have an app build with Shake SDK inside.
            </Paragraph>
            <Paragraph
              lineHeight={18}
              marginBottom={10}
              color={vars.colors.grey70}
              fontSize={14}
            >
              <Styled.StepReference>2</Styled.StepReference> Or{" "}
              <Styled.Link
                target="_blank"
                rel="noreferrer"
                href={docLink(selectedApp?.platform.name).invoke}
              >
                any other invocation
              </Styled.Link>{" "}
              method you have chosen.
            </Paragraph>
            <Styled.Image src={mobileImage} />
          </Styled.Steps>
        </Styled.StepContent>
      </Styled.Container>
      <Toast
        containerId={FEEDBACK_SOMETHING_NOT_WORKING_TOAST}
        autoClose={false}
      />
    </Styled.BackgroundContainer>
  );
};

const EmptyFeedbackModuleWebSteps = ({ selectedApp }: EmptyViewProps) => {
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <FeedbackModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={"Click the floating button"}
        />
        <Styled.StepContent>
          <Paragraph
            lineHeight={18}
            marginBottom={10}
          >
            Shake is live in your {selectedApp?.name ?? ""} website. Visit it
            <Styled.StepReference>1</Styled.StepReference> and click the new floating button
            <Styled.StepReference>2</Styled.StepReference> to report the first ticket.
          </Paragraph>
          <Styled.Steps>
            <Paragraph
              lineHeight={18}
              marginBottom={1}
              color={vars.colors.grey70}
              fontSize={14}
            >
              <Styled.StepReference>1</Styled.StepReference> Make sure your website version contains the Shake SDK.
            </Paragraph>
            <Paragraph
              lineHeight={18}
              marginBottom={10}
              color={vars.colors.grey70}
              fontSize={14}
            >
              <Styled.StepReference>2</Styled.StepReference> Or{" "}
              <Styled.Link
                target="_blank"
                rel="noreferrer"
                href={docLink(selectedApp?.platform.name).invoke}
              >
                any other invocation
              </Styled.Link>{" "}
              method you have chosen.
            </Paragraph>
            <Styled.Image src={webImage} />
          </Styled.Steps>
        </Styled.StepContent>
      </Styled.Container>
      <Toast
        containerId={FEEDBACK_SOMETHING_NOT_WORKING_TOAST}
        autoClose={false}
      />
    </Styled.BackgroundContainer>
  );
};

const EmptyFeedbackModuleChromeSteps = ({ selectedApp }: EmptyViewProps) => {
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <FeedbackModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={"Install Shake chrome extension"}
        />
        <Styled.StepContent>
          <Paragraph
            lineHeight={18}
            marginBottom={10}
          >
            {"Install it from "}{" "}
            {
              <a
                href={SHAKE_EXTENSION_PAGE}
                target={"_blank"}
                rel="noreferrer"
              >
                {"its Chrome web store page."}
              </a>
            }
          </Paragraph>
        </Styled.StepContent>
        <SetupStep
          number={2}
          title={"Open the extension"}
        />
        <Styled.StepContent>
          <Paragraph lineHeight={18}>
            {
              "Click the newly installed Shake extension in the top-right corner of your browser → the Shake modal will open up."
            }
          </Paragraph>
          <Styled.Image src={shakeExtensionButtonImage} />
        </Styled.StepContent>
        <SetupStep
          number={3}
          title={"Report the first test ticket"}
        />
        <Styled.StepContent>
          <Paragraph lineHeight={18}>
            {`Choose the ${
              selectedApp?.name ?? ""
            } app (you have to be signed in) → submit the ticket → it will instantly appear here.`}
          </Paragraph>
          <Styled.Image src={shakeExtensionPickerImage} />
        </Styled.StepContent>
      </Styled.Container>
      <Toast
        containerId={FEEDBACK_SOMETHING_NOT_WORKING_TOAST}
        autoClose={false}
      />
    </Styled.BackgroundContainer>
  );
};
