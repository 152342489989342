import styled, { css } from "styled-components";

import { Centered } from "../../styles/reusable/Centered/Centered.styles";
import BaseButton from "../Button/Button";
import BaseHeading from "../Heading/Heading";
import BaseParagraph from "../Paragraph/Paragraph";

export const FullCenter = styled(Centered)`
  align-items: center;
`;

export const Heading = styled(BaseHeading)<{ $noMargin?: boolean }>`
  ${(props) =>
    props.$noMargin &&
    css`
      margin-bottom: 0.8rem;
    `}
`;

export const Header = styled(BaseHeading)`
  margin-top: 2rem;
`;

export const Button = styled(BaseButton)`
  margin-top: 2.4rem;
`;

export const ShakeImage = styled.img`
  margin-bottom: 1rem;
`;

export const Paragraph = styled(BaseParagraph)<{ $noMargin?: boolean }>`
  ${(props) =>
    props.$noMargin &&
    css`
      margin-bottom: 0.8rem;
    `}
`;
