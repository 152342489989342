import { useState } from "react";

import displayToast from "@common/components/Toast/displayToast";
import { useForm } from "@common/hooks/useForm";
import { Workspace } from "@common/models";
import { validateBundleId } from "@common/util/ValidatorFunctions";
import { useAppDeps } from "@main/App.dependencies";
import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { useUpdateSelectedWorkspace } from "@main/layouts/views/WorkspaceRootLayout";
import { InstallationType } from "@main/pages/AddApp/views/IntroPage/AddAppIntroPage";
import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath, useNavigate } from "react-router-dom";

enum ErrorStatusCodes {
  BUNDLE_ID_ALREADY_IN_USE = 409,
}

interface AddAppFormState {
  appName: string;
  bundleId: string | null;
}

const AddAppInitialState: AddAppFormState = {
  appName: "",
  bundleId: null,
};

const validators = {
  bundleId: validateBundleId,
};

interface Props {
  platformId: string;
  installationType: InstallationType;
}

export const useAddAppConsumer = ({ platformId, installationType }: Props) => {
  const { appsService } = useAppDeps();
  const navigate = useNavigate();
  const { selectedWorkspace } = useAppSelectionContext();
  const { updateSelectedWorkspace } = useUpdateSelectedWorkspace();

  const [loading, setLoading] = useState(false);

  const addApp = async () => {
    if (!selectedWorkspace || !platformId) return;

    setLoading(true);

    try {
      const addedApp = await appsService.addApp(
        (selectedWorkspace as Workspace).id,
        formState.appName,
        formState.bundleId,
        platformId,
        installationType === InstallationType.EXTENSION,
      );

      /// Will cause a duplicate request. Kept in case of sockets stop
      await updateSelectedWorkspace(selectedWorkspace);

      navigate(
        generatePath(RoutePaths.USER_FEEDBACK, {
          workspaceSlug: selectedWorkspace.slug,
          appKey: addedApp.key,
        }),
      );
    } catch (error) {
      if (error.response && error.response.status === ErrorStatusCodes.BUNDLE_ID_ALREADY_IN_USE) {
        setError({ bundleId: Error("Please choose another bundle ID, this one already exist.") });
        return;
      }

      displayToast({
        title: "Something went wrong",
        content:
          error?.response?.data?.message.json && error?.response?.data?.message.json.bundle_id
            ? error?.response?.data?.message.json.bundle_id
            : error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<AddAppFormState>({
    initialState: AddAppInitialState,
    onSubmit: addApp,
    validators,
  });

  return { addApp, loading, handleFormChange, formState, formError, handleFormSubmit, setError };
};
