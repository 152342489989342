import React from "react";

import { ResetPasswordService } from "./services/ResetPassword.service";
import { ResetPasswordView } from "./views/ResetPasswordView";
import dependencyInjection from "../../../common/util/dependencyInjection";
import { ForgotPasswordService } from "../ForgotPassword/services/ForgotPassword.service";

interface Services {
  resetPasswordService: ResetPasswordService;
  forgotPasswordService: ForgotPasswordService;
}

const resetPasswordService = new ResetPasswordService();
const forgotPasswordService = new ForgotPasswordService();

const [ResetPasswordDependencyProvider, useResetPasswordDeps] = dependencyInjection<Services>({
  services: {
    resetPasswordService,
    forgotPasswordService,
  },
});

const ResetPasswordPage = () => (
  <ResetPasswordDependencyProvider>
    <ResetPasswordView />
  </ResetPasswordDependencyProvider>
);

export { ResetPasswordPage, useResetPasswordDeps };
