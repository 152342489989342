import React, { Fragment, useCallback, useEffect, useState } from "react";

import { helpCenterArticles, showHelpCenterArticle } from "@common/util/helpCenterArticles";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import * as Styled from "./ApprovedDomainsCard.styles";
import { AdministrationCard } from "../../../../../common/components/AdministrationCard/AdministrationCard";
import Button from "../../../../../common/components/Button/Button";
import Dropdown, { DropdownItem } from "../../../../../common/components/Dropdown/Dropdown";
import { CellEnd, CenteredCell, GridTable } from "../../../../../common/components/GridTable/GridTable";
import { InputElementType } from "../../../../../common/components/Input/Input";
import { useModal } from "../../../../../common/components/Modal/Modal";
import Paragraph from "../../../../../common/components/Paragraph/Paragraph";
import displayToast from "../../../../../common/components/Toast/displayToast";
import { isAdmin } from "../../../../../common/models/Member.model";
import { ApprovedDomain } from "../../../../../common/models/organization";
import { vars } from "../../../../../common/styles";
import identifiers from "../../../../../common/util/identifiers.json";
import { useAppSelectionContext } from "../../../../context/App/AppSelectionContext";
import AddDomainsModalBody from "../ModalBody/AddDomainsModalBody";

export interface Props {
  domains: ApprovedDomain[];
  addApprovedDomain: (domain: string, verification_email: string) => Promise<void>;
  deleteApprovedDomain: (domain: ApprovedDomain) => Promise<void>;
  loading: boolean;
}

export default function ApprovedDomainsCard({ addApprovedDomain, deleteApprovedDomain, domains, loading }: Props) {
  const { selectedWorkspace } = useAppSelectionContext();
  const [selectedDomain, setSelectedDomain] = useState<ApprovedDomain>();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    Modal: AddDomainsModal,
    modalProps: addDomainsInternal,
    toggle: toggleAddDomains,
  } = useModal({ size: "narrow" });

  const {
    Modal: RemoveDomainsModal,
    modalProps: removeDomainsInternal,
    toggle: toggleRemoveDomains,
  } = useModal({ size: "narrow" });

  useEffect(() => {
    if (location.hash === "#domains" && isAdmin(selectedWorkspace?.role)) {
      if (isAdmin(selectedWorkspace?.role)) {
        toggleAddDomains();
      }
      navigate(location.pathname);
    }
  }, [toggleAddDomains, location, navigate, selectedWorkspace]);

  // todo this needs to be refactored in the scope of some other task
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const t = (id: string, values?: any) => {
    return intl.formatMessage({ id }, undefined, values);
  };

  const handleAddApprovedDomain = useCallback(
    (domain: string, verification_email: string) => {
      addApprovedDomain(domain, verification_email).finally(() => {
        toggleAddDomains();
      });
    },
    [addApprovedDomain, toggleAddDomains],
  );

  const handleDeleteApprovedDomain = useCallback(
    (domain: ApprovedDomain) => {
      deleteApprovedDomain(domain).finally(() => {
        toggleRemoveDomains();
      });
    },
    [deleteApprovedDomain, toggleRemoveDomains],
  );

  const handleRemoveDomainDropdownClick = (domain: ApprovedDomain) => {
    setSelectedDomain(domain);
    toggleRemoveDomains();
  };

  const getApprovedDomainsStr = () => {
    let str = "";
    const filtered = domains.filter((dom) => dom.verified);
    filtered.map((domain, index) => {
      return (str = str.concat(`${domain.name}${index < filtered.length - 1 ? " or " : ""}`));
    });

    return str;
  };

  const inputValue = `Hello everyone, we are gathering our ${
    selectedWorkspace?.name
  } team over on Shake. Just sign up at www.shk.sh/join using your ${getApprovedDomainsStr()} email. Catch you there!`;

  return (
    <>
      <AdministrationCard
        title={t("administration.members.approvedDomain.title")}
        subtitle="When people with an email address from approved domains sign up, they can automatically join this workspace as users."
        testId={identifiers["members.approvedDomain.header"]}
        topLeftAccessory={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: t("administration.members.approvedDomain.button_tooltip"),
              disabled: isAdmin(selectedWorkspace?.role),
            }}
            disabled={!isAdmin(selectedWorkspace?.role)}
            onClick={() => toggleAddDomains()}
            testId={identifiers["members.approvedDomain.button.add"]}
          >
            {t("administration.members.approvedDomain.button")}
          </Button>
        }
        isEmpty={!domains || !Boolean(domains.length)}
      >
        <Domains
          domains={domains}
          handleRemoveDomainDropdownClick={handleRemoveDomainDropdownClick}
          isAdmin={isAdmin(selectedWorkspace?.role)}
        />

        {domains.length ? (
          <Fragment>
            <Styled.Helper>
              You can send this message to your entire team on Slack, Teams or wherever you are:
            </Styled.Helper>

            <Styled.Input
              label="Click to copy"
              readOnly
              elementType={InputElementType.TEXTAREA}
              value={inputValue}
              onCopyClick={() => {
                navigator.clipboard.writeText(inputValue);
                displayToast({
                  content: "Message copied to clipboard",
                });
              }}
            />
          </Fragment>
        ) : undefined}

        <Styled.Helper>
          {"Why use Approved domains? "}
          <a onClick={() => showHelpCenterArticle(helpCenterArticles.approvedDomains)}>{"Learn more here"}</a>
          {"."}
        </Styled.Helper>
      </AdministrationCard>
      <AddDomainsModalBody
        handleAddApprovedDomain={handleAddApprovedDomain}
        loading={loading}
        Modal={AddDomainsModal}
        internalProps={addDomainsInternal}
      />

      {selectedDomain && (
        <RemoveDomainsModal
          {...removeDomainsInternal}
          heading={`Remove ${selectedDomain.name}?`}
          subtitle={`Removing this approved domain means that people with the @${selectedDomain.name} email address will no longer be able to sign up to this workspace without an invite.`}
          testId={identifiers["members.approvedDomain.dropdownButton.remove.header"]}
          buttonElement={
            <Button
              onClick={() => handleDeleteApprovedDomain(selectedDomain)}
              size="small"
              color="red"
              disabled={loading}
              testId={identifiers["members.approvedDomain.dropdownButton.remove.button"]}
              loadingText={loading && "Removing..."}
            >
              {t("administration.members.removeDomainModal.button")}
            </Button>
          }
        ></RemoveDomainsModal>
      )}
    </>
  );
}

interface DomainsProps {
  domains: ApprovedDomain[];
  handleRemoveDomainDropdownClick: (domain: ApprovedDomain) => void;
  isAdmin: boolean;
}

const Domains = ({ domains, handleRemoveDomainDropdownClick, isAdmin }: DomainsProps) => {
  return (
    <GridTable gridTemplateColumns="repeat(3, auto)">
      {Object.entries(domains).map(([key, domain]) => {
        return (
          <Fragment key={key}>
            <CenteredCell gap={8}>
              <Styled.Globe data-testid={identifiers["member.approvedDomain.icon"]} />

              <Paragraph
                color={vars.colors.grey30}
                data-testid={identifiers["member.approvedDomain.name"]}
              >
                {domain.name}
              </Paragraph>
            </CenteredCell>
            <CenteredCell data-testid={identifiers["member.approvedDomain.status"]}>
              <Paragraph>{domain.verified ? "Verified" : "Not verified"}</Paragraph>
            </CenteredCell>

            <CellEnd>
              {isAdmin && (
                <Dropdown testId={identifiers["member.approvedDomain.dropdown.icon"]}>
                  <DropdownItem
                    onClick={() => handleRemoveDomainDropdownClick(domain)}
                    testId={identifiers["members.approvedDomain.dropdownButton.remove"]}
                    error
                  >
                    Remove
                  </DropdownItem>
                </Dropdown>
              )}
            </CellEnd>
          </Fragment>
        );
      })}
    </GridTable>
  );
};
