import styled, { css } from "styled-components";

import { ReactComponent as DownloadLogsIcon } from "../../../../common/assets/images/shared-icons/download-icon.svg";
import { NavItem } from "../../../../common/components/Navigation/Navigation.styles";
import { vars } from "../../../../common/styles";
import { Flex as BaseFlex } from "../../../../common/styles/reusable/Flex/Flex.styles";

export const Threads = styled.div`
  > div {
    padding-bottom: 1.4rem;
    margin-bottom: 1.4rem;
  }

  > div:not(:last-child) {
    border-bottom: 0.1rem solid ${vars.colors.grey100};
  }
`;

export const Row = styled.div<{
  $isBlamedApp?: boolean;
  $isApp?: boolean;
}>`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 2fr;
  align-items: center;
  color: ${vars.colors.grey70};
  cursor: default;

  ${(props) =>
    props.$isBlamedApp &&
    css`
      color: ${vars.colors.red};
      border: 0.1rem;
      background-color: ${vars.colors.redLighter};
      border-radius: 0.6rem;
    `}

  ${(props) =>
    props.$isApp &&
    css`
      color: ${vars.colors.grey50};
    `}
`;

export const Column = styled.div`
  font-size: 1.4rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  word-break: break-all;
  margin: 0.5rem 1rem;
  height: 100%;
  padding: 0.5rem;
`;

export const Flex = styled(BaseFlex)`
  padding-bottom: 2rem;
`;

export const NavigationTitle = styled(NavItem)<{ $isButton?: boolean }>`
  p {
    color: ${vars.colors.grey10};
    font-weight: normal;
  }

  div {
    display: flex;
    align-items: center;
    height: 100%;

    ${(props) =>
      props.$isButton &&
      css`
        &:hover {
          background-color: ${vars.colors.grey100};
          border-radius: 1rem;
        }

        p {
          max-width: 19rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          user-select: none;

          padding-left: 0.8rem;
          padding-right: 0.8rem;
          font-weight: normal;
        }
      `}
  }
`;

export const Popover = styled.div`
  position: relative;
  width: 24rem;
  border-radius: 1rem;
  border: 1px solid ${vars.colors.grey90};
  box-shadow: 0 0.2rem 1rem 0 #1c1c1e;
  background-color: ${vars.colors.grey100};
  padding: 1rem 0;
  margin: 0;
  cursor: default;
  z-index: ${vars.zIndex.modalContent};
  &:focus-visible {
    border: 1px solid ${vars.colors.grey90};
    outline: transparent;
  }
`;

export const DownloadIcon = styled(DownloadLogsIcon)<{ $disabled?: boolean }>`
  margin-left: 1rem;
  cursor: pointer;
  margin-right: 0.2rem;

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      svg {
        path: ${vars.colors.grey70};
      }
    `}
`;
