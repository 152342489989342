import { useQuery } from "@tanstack/react-query";

import displayToast from "../../../../common/components/Toast/displayToast";
import useChangeLogUnreadNotificationsApiConsumer from "../../../consumers/useChangeLogNotificationsApiConsumer";
import { useFeaturessDeps } from "../views/Features/Features";

export default function useFeaturesConsumer({ latest }: { latest: boolean }) {
  const { featuresService } = useFeaturessDeps();
  const { fetchUnreadChangelogNotificationsAndDispatch } = useChangeLogUnreadNotificationsApiConsumer();

  const onMarkAsReadRequest = async (logId: string) => {
    return featuresService
      .markNotificationAsRead(logId)
      .then(() => {
        fetchUnreadChangelogNotificationsAndDispatch();
      })
      .catch(() => {
        displayToast({ title: "Something went wrong", content: "Please try again.", duration: 2000 });
      });
  };

  async function getLatest() {
    const { data } = await featuresService.getChangeLogs("latest");

    return data;
  }

  async function getPopular() {
    const { data } = await featuresService.getChangeLogs("priority");

    return data;
  }

  const {
    data: latestChangeLogs,
    error: latestError,
    isInitialLoading: latestLoading,
  } = useQuery(["latest change logs"], getLatest, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    enabled: latest,
  });

  const {
    data: popularChangeLogs,
    error: popularError,
    isInitialLoading: popularLoading,
  } = useQuery(["popular change logs"], getPopular, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    enabled: !latest,
  });

  return {
    latestChangeLogs,
    latestError,
    latestLoading,
    popularChangeLogs,
    popularError,
    popularLoading,
    onMarkAsReadRequest,
  };
}
