import React, { Fragment } from "react";

import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import Paragraph from "../../../../../common/components/Paragraph/Paragraph";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import { Analytics, TimeGroup, TimeRange } from "../../../../../common/models/Analytics.model";
import { resolveDateString } from "../../../../../common/models/helpers/time/creationTime";
import { vars } from "../../../../../common/styles";
import { Flex } from "../../../../../common/styles/reusable/Flex/Flex.styles";
import {
  formatLabels,
  formatTooltipDateRangeLabels,
  getMaxValue,
  getStartDate,
  nFormatter,
} from "../../helpers/analyticsHelper";

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend, Filler);

interface BarChartProps {
  data: Analytics[];
  loading: boolean;
  group: TimeGroup;
  time: TimeRange;
  type: "feedbacks" | "crashes";
}

export default function BarChart({ data, loading, group, type, time }: BarChartProps) {
  const max = getMaxValue(data) && getMaxValue(data) !== 0 ? getMaxValue(data) : 10;
  const stepSize = max ? Math.round(max / 2) : 0;

  const labels = formatLabels(
    data.map((rec) => rec.time),
    time,
  );

  const data1: ChartData<"bar"> = {
    labels: labels,
    datasets: [
      {
        label: "Dataset 1",
        data:
          data && data.length
            ? data.map((data: Analytics) => {
                return data.value;
              })
            : [],
        backgroundColor: vars.colors.lavender,
        borderRadius: 6,
        maxBarThickness: 19,
      },
    ],
  };

  if (loading)
    return (
      <div style={{ height: "19.8rem" }}>
        <Spinner />
      </div>
    );

  if (!Boolean(data.length)) {
    return (
      <Flex
        style={{ height: "24.6rem" }}
        $justifyContent="center"
        $alignItems="center"
      >
        <Paragraph>No data available for this period</Paragraph>
      </Flex>
    );
  }

  return (
    <Fragment>
      <Bar
        options={getChartOptions(max, stepSize, group, type, time)}
        data={data1}
      />
      <Flex
        $justifyContent="space-between"
        style={{ marginRight: "2.8rem" }}
      >
        <Paragraph
          fontSize={12}
          lineHeight={12}
        >
          {resolveDateString(getStartDate(time).toString())}
        </Paragraph>
        <Paragraph
          fontSize={12}
          lineHeight={12}
        >
          {resolveDateString(new Date().toString())}
        </Paragraph>
      </Flex>
    </Fragment>
  );
}

const getChartOptions = (
  max: number,
  stepSize: number,
  group: TimeGroup,
  type: "feedbacks" | "crashes",
  time: TimeRange,
) => {
  return {
    aspectRatio: 2.4,
    responsive: true,

    interaction: {
      intersect: false,
      mode: "index", //ovo maknuti ako treba samo na hover tocke
    },
    scales: {
      y: {
        afterDataLimits: (scale) => {
          scale.max = max;
          scale.min = 0;
        },
        display: true,
        ticks: {
          color: vars.colors.grey60,
          stepSize: stepSize,
          font: {
            family: vars.fonts.fontFamily,
            size: 12,
            weight: 500,
          },
          callback: function (label) {
            return nFormatter(Number(label));
          },
        },
        position: "right" as const,
        border: { dash: [4, 4], display: false }, // for the grid lines
        grid: {
          color: vars.colors.grey90, // for the grid lines
          tickColor: "transparent", // for the tick mark
          tickBorderDash: [0, 1],
          tickLength: 10,
          tickWidth: 1,
          drawTicks: true,
          drawOnChartArea: true,
        },
      },
      x: {
        padding: 0,
        ticks: {
          display: false,
        },
        border: { display: true, color: vars.colors.grey80 },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        backgroundColor: "rgba(44, 44, 46, 0.9)",
        borderColor: vars.colors.grey90,
        borderWidth: 1,
        boxPadding: 4,
        boxWidth: 6,
        boxHeight: 6,
        titleColor: vars.colors.white,
        titleMarginBottom: 4,
        titleFont: {
          family: vars.fonts.fontFamily,
          size: 14,
          weight: 500,
        },
        bodyColor: vars.colors.grey40,
        bodyFont: {
          family: vars.fonts.fontFamily,
          size: 14,
          weight: 500,
        },
        callbacks: {
          title: (xDatapoint: TooltipItem<"bar">[]) => {
            return formatTooltipDateRangeLabels(xDatapoint[0].label, group, time);
          },
          label: (yDatapoint: TooltipItem<"bar">) => {
            return `${nFormatter(Number(yDatapoint.raw))} ${
              type === "feedbacks"
                ? nFormatter(Number(yDatapoint.raw)) !== 1
                  ? "tickets received"
                  : "ticket received"
                : nFormatter(Number(yDatapoint.raw)) !== 1
                ? "crash events happened"
                : "crash event happened"
            }`;
          },
        },
      },
    },
  } as ChartOptions<"bar">;
};
