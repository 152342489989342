import React from "react";

import * as Icon from "./icons";
import Android10 from "../../../../common/assets/images/details-pane-icons/android/mini-icon-android-10@2x.png";
import Android11 from "../../../../common/assets/images/details-pane-icons/android/mini-icon-android-11@2x.png";
import Android12 from "../../../../common/assets/images/details-pane-icons/android/mini-icon-android-12@2x.png";
import Android13 from "../../../../common/assets/images/details-pane-icons/android/mini-icon-android-13@2x.png";
import Android14 from "../../../../common/assets/images/details-pane-icons/android/mini-icon-android-14@2x.png";
import Android15 from "../../../../common/assets/images/details-pane-icons/android/mini-icon-android-15@2x.png";
import Browser from "../../../../common/assets/images/details-pane-icons/browser-icon.svg";
import Chrome from "../../../../common/assets/images/details-pane-icons/browsers/browser-chrome.png";
import Chromium from "../../../../common/assets/images/details-pane-icons/browsers/browser-chromium.png";
import Edge from "../../../../common/assets/images/details-pane-icons/browsers/browser-edge.png";
import Firefox from "../../../../common/assets/images/details-pane-icons/browsers/browser-firefox.png";
import InternetExplorer from "../../../../common/assets/images/details-pane-icons/browsers/browser-internet-explorer.png";
import Opera from "../../../../common/assets/images/details-pane-icons/browsers/browser-opera.png";
import Safari from "../../../../common/assets/images/details-pane-icons/browsers/browser-safari.png";
import Samsung from "../../../../common/assets/images/details-pane-icons/browsers/browser-samsung-internet.png";
import Tor from "../../../../common/assets/images/details-pane-icons/browsers/browser-tor.png";
import IOS13 from "../../../../common/assets/images/details-pane-icons/ios/mini-icon-ios-13@2x.png";
import IOS14 from "../../../../common/assets/images/details-pane-icons/ios/mini-icon-ios-14@2x.png";
import IOS15 from "../../../../common/assets/images/details-pane-icons/ios/mini-icon-ios-15@2x.png";
import IOS16 from "../../../../common/assets/images/details-pane-icons/ios/mini-icon-ios-16@2x.png";
import IOS17 from "../../../../common/assets/images/details-pane-icons/ios/mini-icon-ios-17@2x.png";
import IOS18 from "../../../../common/assets/images/details-pane-icons/ios/mini-icon-ios-18@2x.png";
import Android from "../../../../common/assets/images/details-pane-icons/operating-systems/mini-icon-android@2x.png";
import iOS from "../../../../common/assets/images/details-pane-icons/operating-systems/mini-icon-ios@2x.png";
import Linux from "../../../../common/assets/images/details-pane-icons/operating-systems/mini-icon-linux@2x.png";
import Mac from "../../../../common/assets/images/details-pane-icons/operating-systems/mini-icon-os-macos@2x.png";
import Windows from "../../../../common/assets/images/details-pane-icons/operating-systems/mini-icon-os-windows@2x.png";
import Ubuntu from "../../../../common/assets/images/details-pane-icons/operating-systems/mini-icon-ubuntu@2x.png";
import OSIcon from "../../../../common/assets/images/details-pane-icons/os-icon.svg";
import { TicketPriority } from "../../../../common/models/TicketPriority.model";
import { TicketStatus } from "../../../../common/models/TicketStatus.model";

export const getStatusIcon = (status: TicketStatus): JSX.Element => {
  switch (status) {
    case TicketStatus.CLOSED:
    case TicketStatus.LOCKED:
      return <Icon.StatusDoneIcon />;
    case TicketStatus.IN_PROGRESS:
      return <Icon.InProgressIcon />;
    default:
      return <Icon.StatusNewIcon />;
  }
};

export const getPriorityIcon = (status: TicketPriority) => {
  switch (status) {
    case TicketPriority.HIGH:
      return <Icon.PriorityHighIcon />;
    case TicketPriority.MEDIUM:
      return <Icon.PriorityMediumIcon />;
    default:
      return <Icon.PriorityLowIcon />;
  }
};

export const getOSIcon = (os: string) => {
  if (os.includes("Android 10")) return Android10;
  if (os.includes("Android 11")) return Android11;
  if (os.includes("Android 12")) return Android12;
  if (os.includes("Android 13")) return Android13;
  if (os.includes("Android 14")) return Android14;
  if (os.includes("Android 15")) return Android15;

  if (os.includes("iOS 13")) return IOS13;
  if (os.includes("iOS 14")) return IOS14;
  if (os.includes("iOS 15")) return IOS15;
  if (os.includes("iOS 16")) return IOS16;
  if (os.includes("iOS 17")) return IOS17;
  if (os.includes("iOS 18")) return IOS18;

  if (os.includes("Android")) return Android;
  if (os.includes("iOS")) return iOS;
  if (os.includes("Windows")) return Windows;
  if (os.includes("macOS") || os.includes("Mac OS")) return Mac;
  if (os.includes("Linux")) return Linux;
  if (os.includes("Ubuntu")) return Ubuntu;

  return OSIcon;
};

export const getBrowserIcon = (br: string) => {
  const browser = br.toLocaleLowerCase();
  if (browser.includes("chrome")) return Chrome;
  if (browser.includes("chromium")) return Chromium;
  if (browser.includes("edge")) return Edge;
  if (browser.includes("firefox")) return Firefox;

  if (browser.includes("explorer")) return InternetExplorer;
  if (browser.includes("tor")) return Tor;
  if (browser.includes("opera")) return Opera;
  if (browser.includes("safari")) return Safari;
  if (browser.includes("samsung")) return Samsung;

  return Browser;
};
