import styled, { css } from "styled-components";

import { vars } from "../../styles";

export const Grid = styled.div<{ $gridTemplateColumns?: string }>`
  display: grid;
  grid-template-rows: min-content;
  align-items: center;

  ${(props) =>
    props.$gridTemplateColumns &&
    css`
      grid-template-columns: ${props.$gridTemplateColumns};
    `}

  > * {
    height: 6rem;
  }

  row-gap: 0.1rem;
  background-color: ${vars.colors.grey90};
`;

export const CenteredCell = styled.div<{ $color?: string; $minWidth?: string; $gap?: number; $maxWidth?: string }>`
  display: flex;
  align-items: center;
  background-color: ${vars.colors.grey100};
  gap: 1.2rem;

  height: 6rem;

  ${(props) =>
    props.$minWidth &&
    css`
      min-width: ${props.$minWidth}px;
    `}
  ${(props) =>
    props.$maxWidth &&
    css`
      p {
        max-width: ${props.$maxWidth}px;
      }
    `}

  ${(props) =>
    props.$gap &&
    css`
      gap: ${props.$gap}px;
    `} 

  * {
    ${(props) =>
      props.$maxWidth &&
      css`
        max-width: ${props.$maxWidth}px;
      `}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-weight: normal;
    background-color: ${vars.colors.grey100};
    ${(props) =>
      props.$color &&
      css`
        color: ${props.color};
      `}
  }
`;

export const CellEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${vars.colors.grey100};
  height: 6rem;
`;
