import React from "react";

import ShakeIcon from "@common/assets/icons/shake_icon.svg";
import Button from "@common/components/Button/Button";
import { CenteredForm } from "@common/components/CenteredForm/CenteredForm";
import { t } from "@common/util/translator";
import useAppDomainVerificationApiConsumer from "@main/pages/Administration/consumers/useAppDomainVerificationConsumer";
import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath, useNavigate } from "react-router-dom";

import * as Styled from "./AppDomainVerificationView.styles";
import FormTitle from "../../../shared/components/Forms/FormTitle/FormTitle";

export default function AppDomainVerificationView() {
  const navigate = useNavigate();

  const { approvedAppDomain } = useAppDomainVerificationApiConsumer();

  if (!approvedAppDomain) {
    return (
      <CenteredForm>
        <Styled.AppDomainVerificationViewBoxWrap>
          <img
            src={ShakeIcon}
            alt="Shake Logo"
          />
          <h1>{t("administration.members.approvedDomain.activation.error_title")}</h1>
        </Styled.AppDomainVerificationViewBoxWrap>
      </CenteredForm>
    );
  }

  return (
    <CenteredForm>
      <Styled.AppDomainVerificationViewBoxWrap>
        <FormTitle
          title={t("administration.members.approvedDomain.activation.title", {
            domain: approvedAppDomain.domain,
          })}
          subtitle={t("administration.apps.approvedAppDomain.activation.description", {
            domain: approvedAppDomain.domain,
          })}
        />
        <Button
          size="full"
          onClick={() => navigate(generatePath(RoutePaths.ROOT))}
        >
          {t("administration.members.approvedDomain.activation.button")}
        </Button>
      </Styled.AppDomainVerificationViewBoxWrap>
    </CenteredForm>
  );
}
