import React from "react";

import { AdministrationCard } from "../../../../../common/components/AdministrationCard/AdministrationCard";
import { ToggleSection } from "../../../../../common/components/ToggleSection/ToggleSection";
import identifiers from "../../../../../common/util/identifiers.json";

type StatusCardProps = {
  isAppActive: boolean;
  handleActiveChange: () => void;
  loading?: boolean;
  saving?: boolean;
};

export default function StatusCard({ isAppActive, handleActiveChange, loading, saving }: StatusCardProps) {
  return (
    <AdministrationCard
      title="Status"
      testId={identifiers["status.header"]}
    >
      <ToggleSection
        onChange={handleActiveChange}
        disabled={loading || saving}
        title="Active"
        subtitle="If you don't need Shake in this app any more, simply deactivate it here. If you're on a paid plan,
            you won't be billed for it any more. You can then activate it at any time again."
        checked={isAppActive}
        testId={identifiers["status.toggle"]}
      />
    </AdministrationCard>
  );
}
