import { useCallback, useEffect, useState } from "react";

import { useAppSelectionContext } from "@main/context/App/AppSelectionContext";
import { useSearchParams } from "react-router-dom";

import { Attribute } from "./sharedTypes";
import { useLocalStorage } from "./useLocalStorage";

type State = {
  appId: string;
  attributes: Array<Attribute>;
};

type Props = {
  storageKey?: string;
  predefinedAttributes?: Attribute[];
  withoutSearchParams?: boolean;
};

/**
 * Access and modify current attribute set.
 */
export function useAttributes({ storageKey, predefinedAttributes, withoutSearchParams }: Props) {
  const { selectedApp } = useAppSelectionContext();
  const { state, setState } = useLocalStorage<State>(storageKey, { attributes: [], appId: selectedApp?.id ?? "" });
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      Object.fromEntries(searchParams) &&
      Object.fromEntries(searchParams).search &&
      !attributes.length &&
      (!location.pathname.includes("activity-history") ||
        (storageKey === "shakebugs.log_attributes" && location.pathname.includes("activity-history")))
    ) {
      const state = Object.fromEntries(searchParams);
      if (state.search) {
        try {
          const decompressed = decodeURIComponent(window.atob(state.search));

          if (!decompressed) return;
          if (JSON.parse(decompressed) && !JSON.parse(decompressed).length) return;

          setAttributes(JSON.parse(decompressed) ?? []);
          if (!selectedApp) return;
          if (state.appId === selectedApp.id) return;
          setState({ attributes: JSON.parse(decompressed) ?? [], appId: selectedApp.id });
          return;
        } catch (error) {
          return;
        }
      }
    }

    if (predefinedAttributes) {
      setAttributes(predefinedAttributes);
      return;
    }
    if (!selectedApp) return;
    if (state.appId === selectedApp.id) return;

    setState({ attributes: [], appId: selectedApp.id });
    //eslint-disable-next-line
  }, [selectedApp, state, predefinedAttributes, setState, searchParams, setSearchParams]);

  useEffect(() => {
    if (state && state.attributes && !withoutSearchParams) {
      setSearchParams({ search: window.btoa(encodeURIComponent(JSON.stringify(state.attributes))) }, { replace: true });
    }
  }, [state, setSearchParams, withoutSearchParams]);

  const addAttribute = useCallback(
    (attribute: Attribute) => {
      if (!selectedApp) {
        setAttributes((prev) => {
          return prev ? prev.concat(attribute) : [attribute];
        });
        return;
      }

      setState((prev) => {
        return {
          appId: selectedApp.id,
          attributes: prev && prev.attributes ? prev.attributes?.concat(attribute) : [attribute],
        };
      });
    },
    [selectedApp, setState],
  );

  const removeAttribute = useCallback(
    (atIndex: number) => {
      if (!selectedApp) {
        setAttributes((prev) => {
          let newAttributes = [...prev];
          newAttributes.splice(atIndex, 1);
          newAttributes = newAttributes.map((attribute, index) => {
            if (index == 0) return { ...attribute, attributeOperator: undefined };
            return attribute;
          });
          return newAttributes;
        });
        return;
      }

      setState((prev) => {
        let newAttributes = [...prev?.attributes];
        newAttributes.splice(atIndex, 1);
        newAttributes = newAttributes.map((attribute, index) => {
          if (index == 0) return { ...attribute, attributeOperator: undefined };
          return attribute;
        });

        setSearchParams(
          {
            search: window.btoa(encodeURIComponent(JSON.stringify(newAttributes))),
          },
          { replace: true },
        );

        return {
          appId: selectedApp.id,
          attributes: newAttributes,
        };
      });
    },
    [selectedApp, setState, setSearchParams],
  );

  const updateAttribute = useCallback(
    (atIndex: number, updated: Attribute) => {
      if (!selectedApp) {
        setAttributes((prev) => {
          const newAttributes = [...prev];
          newAttributes.splice(atIndex, 1, updated);
          return newAttributes;
        });
        return;
      }

      setState((prev) => {
        const newAttributes = [...prev.attributes];
        newAttributes.splice(atIndex, 1, updated);
        setSearchParams(
          {
            search: window.btoa(encodeURIComponent(JSON.stringify(newAttributes))),
          },
          { replace: true },
        );

        return {
          appId: selectedApp.id,
          attributes: newAttributes,
        };
      });
    },
    [selectedApp, setState, setSearchParams],
  );

  return {
    addAttribute,
    removeAttribute,
    updateAttribute,
    attributes: storageKey ? state.attributes : attributes,
  };
}
