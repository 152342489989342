import env from "../../../../../../../common/environments";
import { Workspace } from "../../../../../../../common/models";
import {
  AsanaActivationChallengeRes,
  AsanaIntegrationInfo,
  AsanaStartIntegrationPayload,
  AzureIntegrationInfo,
  BaseActivationChallengeRes,
  ClickupIntegrationInfo,
  CommonPassthroughState,
  GithubIntegrationInfo,
  GithubStartIntegrationPayload,
  IntegrationType,
  JiraStartIntegrationPayload,
  OAuth2PassthroughState,
  StartIntegrationPayload,
} from "../../../../../../../common/models/integrations";
import { RoutePaths } from "../../../../../../router/config/routePaths";
import { useIntegrationFormConsumer } from "../../../../consumers/useIntegrationFormConsumer";

interface Props {
  selectedAppId: string;
  workspace?: Workspace;
}

type IntegrationInfo = AsanaIntegrationInfo | AzureIntegrationInfo | ClickupIntegrationInfo | GithubIntegrationInfo;

export const useRedirectConsumer = ({ workspace, selectedAppId /* onFinish */ }: Props) => {
  const { startIntegration } = useIntegrationFormConsumer<IntegrationInfo[]>({
    selectedAppId,
  });

  const startRedirection = (integrationType: IntegrationType) => {
    if (!workspace) return;
    switch (integrationType) {
      case IntegrationType.ASANA:
        return redirectToAsana(startIntegration, workspace, selectedAppId);
      case IntegrationType.GITHUB:
        return redirectToGithub(startIntegration, workspace, selectedAppId);
      case IntegrationType.TRELLO:
        return redirectToTrello(workspace, selectedAppId);
      case IntegrationType.CLICKUP:
        return redirectToClickup(workspace, selectedAppId);
      case IntegrationType.JIRA_CLOUD:
        return redirectToJiraCloud(startIntegration, workspace, selectedAppId);
      case IntegrationType.LINEAR:
        return redirectToLinear(startIntegration, workspace, selectedAppId);
      case IntegrationType.SENTRY:
        return redirectToSentry();
    }
  };

  return { startRedirection };
};

const redirectToAsana = async (
  startIntegration: <Res, Payload extends StartIntegrationPayload>(
    payload: Payload,
    integrationType: IntegrationType,
  ) => Promise<Res | undefined>,
  selectedWorkspace: Workspace,
  selectedAppId: string,
) => {
  const redirectURL = new URL(RoutePaths.INTEGRATION_ACTIVATION, window.location.origin);
  redirectURL.searchParams.append("type", IntegrationType.ASANA.toLowerCase());

  const data = await startIntegration<AsanaActivationChallengeRes, AsanaStartIntegrationPayload>(
    {
      client_id: env.asanaClientId,
      redirect_uri: redirectURL.href,
    },
    IntegrationType.ASANA,
  );

  if (!data) throw new Error();

  const stateParam: OAuth2PassthroughState = {
    stateId: data.state,
    workspaceId: selectedWorkspace.id,
    workspaceSlug: selectedWorkspace.slug,
    appId: selectedAppId,
  };

  const asanaAuthURL = new URL("/-/oauth_authorize", "https://app.asana.com");
  asanaAuthURL.searchParams.append("response_type", "code");
  asanaAuthURL.searchParams.append("client_id", env.asanaClientId);
  asanaAuthURL.searchParams.append("redirect_uri", redirectURL.href);
  asanaAuthURL.searchParams.append("code_challenge", data.code_challenge);
  asanaAuthURL.searchParams.append("code_challenge_method", "S256");
  asanaAuthURL.searchParams.append("display_ui", "always");
  asanaAuthURL.searchParams.append("state", JSON.stringify(stateParam));

  window.location.href = asanaAuthURL.href;
};

const redirectToGithub = async (
  startIntegration: <Res, Payload extends StartIntegrationPayload>(
    payload: Payload,
    integrationType: IntegrationType,
  ) => Promise<Res | undefined>,
  selectedWorkspace: Workspace,
  selectedAppId: string,
) => {
  const redirectURL = new URL(RoutePaths.INTEGRATION_ACTIVATION, window.location.origin);
  redirectURL.searchParams.append("type", IntegrationType.GITHUB.toLowerCase());

  const data = await startIntegration<BaseActivationChallengeRes, GithubStartIntegrationPayload>(
    {
      client_id: env.githubClientId,
      redirect_uri: redirectURL.href,
    },
    IntegrationType.GITHUB,
  );

  if (!data) throw new Error();

  const stateParam: OAuth2PassthroughState = {
    stateId: data.state,
    workspaceId: selectedWorkspace.id,
    workspaceSlug: selectedWorkspace.slug,
    appId: selectedAppId,
  };

  redirectURL.searchParams.append("state", JSON.stringify(stateParam));

  const githubAuthURL = new URL("/login/oauth/authorize", "https://github.com");
  githubAuthURL.searchParams.append("client_id", env.githubClientId);
  githubAuthURL.searchParams.append("redirect_uri", redirectURL.href);
  githubAuthURL.searchParams.append("scope", "admin:org read:user repo");
  githubAuthURL.searchParams.set("prompt", "consent");

  window.location.href = githubAuthURL.href;
};

const redirectToTrello = async (selectedWorkspace: Workspace, selectedAppId: string) => {
  const redirectURL = new URL(RoutePaths.INTEGRATION_ACTIVATION, window.location.origin);
  redirectURL.searchParams.append("type", IntegrationType.TRELLO.toLowerCase());

  const stateParam: CommonPassthroughState = {
    workspaceId: selectedWorkspace.id,
    workspaceSlug: selectedWorkspace.slug,
    appId: selectedAppId,
  };

  redirectURL.searchParams.append("state", JSON.stringify(stateParam));
  const trelloAuthURL = new URL("https://trello.com/1/authorize?");
  trelloAuthURL.searchParams.append("key", env.trelloAppKey);
  trelloAuthURL.searchParams.append("name", env.name);
  trelloAuthURL.searchParams.append("response_type", "token");
  trelloAuthURL.searchParams.append("scope", "read,write");
  trelloAuthURL.searchParams.append("expiration", "never");
  trelloAuthURL.searchParams.append("return_url", redirectURL.href);

  window.location.href = trelloAuthURL.href;
};

const redirectToClickup = async (selectedWorkspace: Workspace, selectedAppId: string) => {
  const redirectURL = new URL(RoutePaths.INTEGRATION_ACTIVATION, window.location.origin);
  redirectURL.searchParams.append("type", IntegrationType.CLICKUP.toLowerCase());

  const stateParam: CommonPassthroughState = {
    workspaceId: selectedWorkspace.id,
    workspaceSlug: selectedWorkspace.slug,
    appId: selectedAppId,
  };

  redirectURL.searchParams.append("state", JSON.stringify(stateParam));

  const clickupAuthURL = new URL("https://app.clickup.com/api?");
  clickupAuthURL.searchParams.append("client_id", env.clickupClientId);
  clickupAuthURL.searchParams.append("redirect_uri", redirectURL.href);
  clickupAuthURL.searchParams.set("prompt", "consent");

  window.location.href = clickupAuthURL.href;
};

const redirectToLinear = async (
  startIntegration: <Res, Payload extends StartIntegrationPayload>(
    payload: Payload,
    integrationType: IntegrationType,
  ) => Promise<Res | undefined>,
  selectedWorkspace: Workspace,
  selectedAppId: string,
) => {
  const redirectURL = new URL(RoutePaths.INTEGRATION_ACTIVATION, window.location.origin);
  redirectURL.searchParams.append("type", IntegrationType.LINEAR.toLowerCase());

  const data = await startIntegration<BaseActivationChallengeRes, GithubStartIntegrationPayload>(
    {
      client_id: env.linearClientId,
      redirect_uri: redirectURL.href,
    },
    IntegrationType.LINEAR,
  );

  if (!data) throw new Error();

  const stateParam: OAuth2PassthroughState = {
    stateId: data.state,
    workspaceId: selectedWorkspace.id,
    workspaceSlug: selectedWorkspace.slug,
    appId: selectedAppId,
  };

  const linearAuthURL = new URL("https://linear.app/oauth/authorize");
  linearAuthURL.searchParams.append("client_id", env.linearClientId);
  linearAuthURL.searchParams.append("redirect_uri", redirectURL.href);
  linearAuthURL.searchParams.set("response_type", "code");
  linearAuthURL.searchParams.append("scope", "read,write");
  linearAuthURL.searchParams.append("state", JSON.stringify(stateParam));
  linearAuthURL.searchParams.set("prompt", "consent");

  window.location.href = linearAuthURL.href;
};

const redirectToJiraCloud = async (
  startIntegration: <Res, Payload extends StartIntegrationPayload>(
    payload: Payload,
    integrationType: IntegrationType,
  ) => Promise<Res | undefined>,
  selectedWorkspace: Workspace,
  selectedAppId: string,
) => {
  const redirectURL = new URL(RoutePaths.INTEGRATION_ACTIVATION, window.location.origin);
  redirectURL.searchParams.append("type", IntegrationType.JIRA.toLowerCase());

  const data = await startIntegration<BaseActivationChallengeRes, JiraStartIntegrationPayload>(
    {
      client_id: env.jiraCloudClientId,
      redirect_uri: redirectURL.href,
    },
    IntegrationType.JIRA,
  );

  if (!data) throw new Error();

  const stateParam: OAuth2PassthroughState = {
    stateId: data.state,
    workspaceId: selectedWorkspace.id,
    workspaceSlug: selectedWorkspace.slug,
    appId: selectedAppId,
  };

  const authURL = new URL("https://auth.atlassian.com/authorize");
  authURL.searchParams.set("audience", "api.atlassian.com");
  authURL.searchParams.set("client_id", env.jiraCloudClientId);
  authURL.searchParams.set(
    "scope",
    "read:jira-user read:jira-work write:jira-work manage:jira-webhook manage:jira-project manage:jira-data-provider manage:jira-configuration offline_access",
  );
  authURL.searchParams.set("redirect_uri", redirectURL.href);
  authURL.searchParams.set("response_type", "code");
  authURL.searchParams.set("prompt", "consent");
  authURL.searchParams.append("state", JSON.stringify(stateParam));

  window.location.href = authURL.href;
};

const redirectToSentry = async () => {
  const redirectURL = new URL(RoutePaths.INTEGRATION_ACTIVATION, window.location.origin);
  redirectURL.searchParams.append("type", IntegrationType.SENTRY.toLowerCase());
  const sentryAuthURL = new URL("/sentry-apps/shake/external-install/", "https://sentry.io");
  window.location.href = sentryAuthURL.href;
};
