import React, { useEffect } from "react";

import { generatePath, Link, useSearchParams } from "react-router-dom";

import * as Styled from "./SignInForm.styles";
import GoogleIcon from "../../../../common/assets/icons/google_icon.svg";
import Button from "../../../../common/components/Button/Button";
import Input from "../../../../common/components/Input/Input";
import { Spinner } from "../../../../common/components/Spinner/Spinner.styles";
import identifiers from "../../../../common/util/identifiers.json";
import { t } from "../../../../common/util/translator";
import { RoutePaths } from "../../../router/config/routePaths";
import { EmailPrefillState } from "../../ForgotPassword/consumers/useForgotPasswordFormConsumer";
import FormTitle from "../../shared/components/Forms/FormTitle/FormTitle";
import { useSignInFormConsumer } from "../consumers/useSignInFormConsumer";

export const SignInForm = () => {
  const {
    handleFormChange,
    handleFormSubmit,
    formState,
    formError,
    loading,
    isErrorOnSubmit,
    googleLoginLoading,
    handleDemoLogin,
    handleGoogleLogin,
  } = useSignInFormConsumer();

  const [searchParams] = useSearchParams();
  const user = searchParams.get("user");

  const { email, password } = formState;
  const { email: emailError, password: passwordError } = formError;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isButtonDisabled = !email || !password;

  useEffect(() => {
    if (user === "demo") {
      handleDemoLogin();
    }
  }, [user, handleDemoLogin]);

  if (googleLoginLoading)
    return (
      <Styled.CenteredContainer>
        <Spinner />
      </Styled.CenteredContainer>
    );

  return (
    <Styled.FormContainer $googleLoading={googleLoginLoading}>
      <FormTitle
        title="Welcome back"
        subtitle="Bug and crash reporting tool for mobile apps that tells you everything — so you don’t have to ask the user."
        titleTestId={identifiers["signIn.header.text"]}
        subtitleTestId={identifiers["signIn.header.paragraph"]}
      />

      <Styled.GoogleSignInButton
        disabled={loading}
        size="tallerFull"
        onClick={() => handleGoogleLogin()}
        testId={identifiers["signIn.button.continueWithGoogle"]}
      >
        <img
          src={GoogleIcon}
          alt="Google Icon"
          width={20}
        />
        {t("signIn.form.button.google")}
      </Styled.GoogleSignInButton>

      <Styled.SignInForm
        onSubmit={handleFormSubmit}
        noValidate
      >
        <Input
          disabled={loading}
          name="email"
          type="email"
          label={t("Email")}
          value={email}
          error={!!emailError}
          helper={resolveEmailErrorMessage(emailError, isErrorOnSubmit, formState.email)}
          onChange={handleChange}
          testId={identifiers["signIn.input.email"]}
          autoFocus
        />
        <Input
          disabled={loading}
          name="password"
          type="password"
          label={t("password")}
          value={password}
          error={!!passwordError}
          helper={resolvePasswordErrorMessage(passwordError, isErrorOnSubmit, formState.email)}
          onChange={handleChange}
          testId={identifiers["signIn.input.password"]}
        />
        <Button
          disabled={isButtonDisabled || loading}
          size="tallerFull"
          styling="outline"
          type="submit"
          testId={identifiers["signIn.button.continueWithEmail"]}
          tooltip={{
            position: "top",
            text: "Please enter your email and password first",
            disabled: !isButtonDisabled,
          }}
          loadingText={loading && "Signing you in..."}
        >
          {t("signIn.form.button.email")}
        </Button>
      </Styled.SignInForm>

      <Styled.SignInFormLinks>
        <p>
          Not a member?&nbsp;
          <Link
            to={generatePath(RoutePaths.SIGNUP)}
            data-testid={identifiers["signIn.link.signUpNow"]}
          >
            Sign up now
          </Link>
        </p>

        <p>
          Forgot your password?&nbsp;
          <Link
            to={generatePath(RoutePaths.FORGOT_PASSWORD)}
            state={{ prefillEmail: formState.email } as EmailPrefillState}
            data-testid={identifiers["signIn.link.resetPassword"]}
          >
            Reset it here
          </Link>
        </p>
      </Styled.SignInFormLinks>
    </Styled.FormContainer>
  );
};

const resolveEmailErrorMessage = (emailError?: Error, isErrorOnSubmit?: boolean, prefillEmail?: string) => {
  if (!emailError) return;

  if (isErrorOnSubmit) {
    return (
      <>
        {emailError.message}
        <br />
        <Link
          to={generatePath(RoutePaths.SIGNUP)}
          state={{ prefillEmail } as EmailPrefillState}
        >
          Sign up for a new account.
        </Link>
      </>
    );
  }

  return emailError.message;
};

const resolvePasswordErrorMessage = (passwordError?: Error, isErrorOnSubmit?: boolean, prefillEmail?: string) => {
  if (!passwordError) return;

  if (isErrorOnSubmit) {
    return (
      <>
        {passwordError.message} Please try again or&nbsp;
        <Link
          to={generatePath(RoutePaths.FORGOT_PASSWORD)}
          state={{ prefillEmail } as EmailPrefillState}
        >
          request a new one.
        </Link>
      </>
    );
  }

  return passwordError.message;
};
