import { useCallback } from "react";

import { setUnreadChangeLogNotifications } from "../context/App/App.actions";
import { useAppDispatch } from "../context/App/App.context";
import { useFeaturessDeps } from "../pages/Info/views/Features/Features";

export default function useChangeLogUnreadNotificationsApiConsumer() {
  const appDispatch = useAppDispatch();

  const { featuresService } = useFeaturessDeps();

  const fetchUnreadChangelogNotificationsAndDispatch = useCallback(
    async (signal?: AbortSignal) => {
      const { data } = await featuresService.getChangeLogsNotifications(signal);
      if (signal && signal.aborted) return {};

      appDispatch(setUnreadChangeLogNotifications(data));

      return data;
    },
    [featuresService, appDispatch],
  );

  return {
    fetchUnreadChangelogNotificationsAndDispatch,
  };
}
