import React from "react";

import { UsersService } from "./services/Users.service";
import UserDetailsView from "./views/UserDetailsView";
import UsersPageView from "./views/UsersPageView";
import dependencyInjection from "../../../common/util/dependencyInjection";

interface Services {
  usersService: UsersService;
}

const usersService = new UsersService();

const [UsersDependencyProvider, useUsersDeps] = dependencyInjection<Services>({
  services: {
    usersService,
  },
});

export function UsersPage() {
  return (
    <UsersDependencyProvider>
      <UsersPageView />
    </UsersDependencyProvider>
  );
}

export function UsersDetailPage() {
  return (
    <UsersDependencyProvider>
      <UserDetailsView />
    </UsersDependencyProvider>
  );
}

export { useUsersDeps };
