import React, { useMemo, useState } from "react";

import { CenteredForm } from "../../../../common/components/CenteredForm/CenteredForm";
import identifiers from "../../../../common/util/identifiers.json";
import CheckInbox from "../../shared/components/Forms/CheckInbox/CheckInbox";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import { useForgotPasswordConsumer } from "../consumers/useForgotPasswordConsumer";

export const ForgotPasswordView = () => {
  const { resendResetLink } = useForgotPasswordConsumer();
  const [resetLinkState, setResetLinkState] = useState({ email: "", isLinkSent: false });
  const { email, isLinkSent } = resetLinkState;

  const checkInboxFooterLink = useMemo(() => {
    const resetLinkState = () => setResetLinkState({ email: "", isLinkSent: false });

    return (
      <>
        Wrong email?{" "}
        <a
          onClick={resetLinkState}
          data-testid={identifiers["checkInbox.link.resetPassword"]}
        >
          Reset your password
        </a>{" "}
        with another one
      </>
    );
  }, []);

  return (
    <CenteredForm>
      {isLinkSent ? (
        <CheckInbox
          email={email}
          subtitleText={`We've sent an email to ${email} with instructions on how to reset your password.`}
          resendEmail={() => resendResetLink(email, true)}
          footerLinkContent={checkInboxFooterLink}
        />
      ) : (
        <ForgotPasswordForm setResetLinkState={setResetLinkState} />
      )}
    </CenteredForm>
  );
};
