import React from "react";

import { WorkspaceJoinService } from "./services/WorkspaceJoinService";
import WorkspaceJoinView from "./views/WorkspaceJoinView";
import dependencyInjection from "../../../common/util/dependencyInjection";

interface Services {
  workspaceJoinService: WorkspaceJoinService;
}

const workspaceJoinService = new WorkspaceJoinService();

const [WorkspaceJoinDependencyProvider, userWorkspaceJoinDeps] = dependencyInjection<Services>({
  services: { workspaceJoinService },
});

export const WorkspaceJoinPage = () => {
  return (
    <WorkspaceJoinDependencyProvider>
      <WorkspaceJoinView />
    </WorkspaceJoinDependencyProvider>
  );
};

export { userWorkspaceJoinDeps };
