import React, { Fragment } from "react";

import { generatePath } from "react-router-dom";

import * as Styled from "./UserTicketCards.styles";
import { getTagColor } from "../../../../common/components/Input/InputWithTags/InputWithTags";
import Paragraph from "../../../../common/components/Paragraph/Paragraph";
import { APP_USER_TICKET_TYPE, AppUserTicket } from "../../../../common/models";
import { getTimeSinceLong } from "../../../../common/models/helpers/time/timeSince";
import { AppNotification } from "../../../../common/models/notifications";
import { vars } from "../../../../common/styles";
import { Flex } from "../../../../common/styles/reusable/Flex/Flex.styles";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { RoutePaths } from "../../../router/config/routePaths";
import { getPriorityIcon, getStatusIcon } from "../../shared/icons/getIconsHelper";

interface Props {
  userTickets?: AppUserTicket[];
  unreadNotifications: Record<string, AppNotification[]>;
}

export default function UserTicketCards({ userTickets, unreadNotifications }: Props) {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  if (!userTickets) return <Fragment />;

  return (
    <Styled.UserTicketCards>
      {userTickets.map((ticket) => {
        return (
          <Styled.Link
            key={ticket.id}
            to={getRedirectRoute(ticket, selectedWorkspace?.slug, selectedApp?.key)}
          >
            <Styled.Card>
              <Flex>
                <Flex
                  style={{ width: "45.7rem" }}
                  $column
                  $gap={1.2}
                >
                  <Flex $gap={0.8}>
                    {getStatusIcon(ticket.status)}
                    {getPriorityIcon(ticket.priority)}
                    <Styled.Paragraph
                      fontWeight={500}
                      color={vars.colors.white}
                    >
                      {ticket.title}
                    </Styled.Paragraph>
                    {selectedApp &&
                      unreadNotifications[selectedApp?.id]?.some(
                        (notification) => notification.issue_id === ticket.id,
                      ) && (
                        <Styled.NotificationContainer
                          $justifyContent="flex-end"
                          $alignItems="flex-end"
                        >
                          <Styled.NotificationIndicator />
                        </Styled.NotificationContainer>
                      )}
                  </Flex>
                  <Styled.Tags>
                    {ticket.tags
                      .sort((a, b) => a.localeCompare(b))
                      .map((tag) => {
                        return (
                          <Styled.Tag
                            key={tag}
                            value={tag}
                            color={getTagColor(tag)}
                            border
                            borderColor={vars.colors.grey90}
                          />
                        );
                      })}
                    <Styled.RemoveBackground
                      className="__tagsFade"
                      $removeHover={true}
                      $gradientColor={vars.colors.grey100}
                      $heightPercent={20}
                      $gradientReachPercent={100}
                    />
                  </Styled.Tags>
                  <Flex $gap={2}>
                    <Paragraph fontWeight={500}>{ticket.app_version ? ticket.app_version : "Not available"}</Paragraph>
                    <Paragraph fontWeight={500}>{getTimeSinceLong(ticket.reported_time)}</Paragraph>
                  </Flex>
                </Flex>

                {ticket.screenshot_url && (
                  <div>
                    <img src={ticket.screenshot_url} />
                  </div>
                )}
              </Flex>
            </Styled.Card>
          </Styled.Link>
        );
      })}
    </Styled.UserTicketCards>
  );
}

function getRedirectRoute(ticket: AppUserTicket, workspaceSlug?: string, appKey?: string) {
  if (!workspaceSlug || !appKey) return "/";

  if (ticket.type === APP_USER_TICKET_TYPE.FEEDBACK) {
    return generatePath(RoutePaths.USER_FEEDBACK_DETAILS, {
      workspaceSlug: workspaceSlug,
      appKey: appKey,
      userFeedbackKey: ticket.key.toString(),
    });
  }
  return generatePath(RoutePaths.CRASH_REPORT_EVENT, {
    workspaceSlug: workspaceSlug,
    appKey: appKey,
    crashReportKey: ticket.key.toString(),
    crashReportEventId: ticket.id,
  });
}
