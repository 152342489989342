import { useEffect, useState } from "react";

interface Props {
  group: string;
  size: number;
}

export interface TabController {
  setSelectedTab: (index: number) => void;
  selectedTab: number;
}

export default function useCodeTabsController({ group, size }: Props): TabController {
  const GROUP_KEY = `codeBlock.${group}`;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    try {
      const storedTab = localStorage.getItem(GROUP_KEY) ?? "0";

      // Check if index is valid
      let storedTabInt = parseInt(storedTab);
      if (isNaN(storedTabInt) || storedTabInt < 0 || storedTabInt >= size) {
        storedTabInt = 0;
      }
      setSelectedTab(storedTabInt);

      localStorage.setItem(GROUP_KEY, storedTabInt.toString());
    } catch (e) {}
  }, [GROUP_KEY, group, size]);

  const setGroupTab = (index: number) => {
    localStorage.setItem(GROUP_KEY, index.toString());
    setSelectedTab(index);
  };

  return {
    selectedTab,
    setSelectedTab: setGroupTab,
  };
}

export const useAndroidTabController = () => useCodeTabsController({ group: "android", size: 2 });
export const useIosTabController = () => useCodeTabsController({ group: "ios", size: 2 });
export const useFlutterTabController = () => useCodeTabsController({ group: "flutter", size: 1 });
export const useReactNativeTabController = () => useCodeTabsController({ group: "rn", size: 1 });
export const useGradleTabController = () => useCodeTabsController({ group: "gradle", size: 1 });
export const useCocoapodTabController = () => useCodeTabsController({ group: "cocoapod", size: 2 });
export const useTerminalTabController = () => useCodeTabsController({ group: "terminal", size: 1 });
export const useWebTabController = () => useCodeTabsController({ group: "web", size: 1 });
