import React from "react";

import addAppBackground from "@common/assets/images/empty-state/add-app-visual.png";
import { AddAppButton } from "@common/components/AddAppButton/AddAppButton";
import { useWorkspaceAppParams } from "@main/pages/IntegrationActivation/helpers/useWorkspaceAppParams";
import { RoutePaths } from "@main/router/config/routePaths";
import { generatePath } from "react-router-dom";

import * as Styled from "./AddAppFooter.styles";

export interface AddAppFooter {
  text: string;
}

const AddAppFooter = ({ text }: AddAppFooter) => {
  const { workspaceSlug } = useWorkspaceAppParams();

  return (
    <Styled.Container>
      <Styled.Content style={{ backgroundImage: `url(${addAppBackground})` }}>
        <Styled.Title
          marginTop={0}
          marginBottom={45}
          p1
        >
          {text}
        </Styled.Title>
        <AddAppButton
          navigateTo={generatePath(RoutePaths.ADD_APP, { workspaceSlug: workspaceSlug ?? null })}
          wiggle={true}
          flat={true}
        />
        <Styled.Title
          marginTop={20}
          p2
        >
          {"You can have unlimited apps."}
        </Styled.Title>
      </Styled.Content>
    </Styled.Container>
  );
};

export const FeedbackModuleAddAppFooter = () => {
  return (
    <AddAppFooter
      text={
        "Whether you use the Chrome extension \n or add the SDK, the first step is to create an app.\n" +
        "Think of it as a folder where the tickets will be stored."
      }
    />
  );
};

export const CrashesModuleAddAppFooter = () => {
  return (
    <AddAppFooter
      text={
        "Whether you use the Chrome extension \n or add the SDK, the first step is to create an app.\n" +
        "Think of it as a folder where the tickets will be stored."
      }
    />
  );
};

export const UsersModuleAddAppFooter = () => {
  return (
    <AddAppFooter
      text={
        "Whether you use the Chrome extension \n or add the SDK, the first step is to create an app.\n" +
        "Think of it as a folder where the tickets will be stored."
      }
    />
  );
};

export const AnalyticsModuleAddAppFooter = () => {
  return (
    <AddAppFooter
      text={
        "Whether you use the Chrome extension \n or add the SDK, the first step is to create an app.\n" +
        "Think of it as a folder where the tickets will be stored."
      }
    />
  );
};
