import { useCallback } from "react";

import env from "../../../../common/environments";

/**
 * State that is preserved between OAuth and app between redirects.
 */
export interface PassthroughState {
  originalURL: URL;
  redirectURL: URL;
  clientState?: unknown;
}

interface GoogleSignInProps {
  currentURL: URL;
  clientState?: object;
}

/**
 * Returns a redirect method that initiates google auth in the same tab.
 * Once auth is complete, google will redirect to the currentURL.
 */
export function useGoogleSignIn({ currentURL }: GoogleSignInProps) {
  const redirectToGoogle = useCallback(
    ({ clientState }: { clientState?: object }) => {
      const authURL = constructGoogleAuthURL({ currentURL, clientState });
      window.location.assign(authURL.href);
    },
    [currentURL],
  );

  return { redirectToGoogle };
}

/**
 *
 * Uses the passed in URL as base for appending the authorization data.
 * Data is appended as search parameters.
 */
const constructGoogleAuthURL = ({ currentURL, clientState }: GoogleSignInProps): URL => {
  const authBaseURL = new URL("https://accounts.google.com/o/oauth2/v2/auth");

  const redirectURL = new URL(currentURL.href);
  redirectURL.searchParams.delete("code");

  /// Clear all search params to avoid redirect_uri_mismatch auth error
  /// Deleted search params are passed through "state" return param.
  const leftOverParams: Record<string, unknown> = {};
  redirectURL.searchParams.forEach((value, key, _) => {
    leftOverParams[key] = value;
  });
  redirectURL.search = "";

  redirectURL.searchParams.append("auth_callback", "google");

  authBaseURL.searchParams.append("redirect_uri", redirectURL.href);
  authBaseURL.searchParams.append("scope", "openid profile email");
  authBaseURL.searchParams.append("response_type", "code");
  authBaseURL.searchParams.append("include_granted_scopes", "false");
  authBaseURL.searchParams.append("client_id", env.googleClientId);
  authBaseURL.searchParams.append("ss_domain", currentURL.origin);
  authBaseURL.searchParams.append("access_type", "offline");
  authBaseURL.searchParams.append("prompt", "consent");
  authBaseURL.searchParams.append("gsiwebsdk", "2");
  // authBaseURL.searchParams.append("nonce", "1111"); /// TODO - check with Shake auth, maybe neccessary

  const passthroughState: PassthroughState = {
    originalURL: currentURL,
    redirectURL: redirectURL,
    clientState,
    ...leftOverParams,
  };

  authBaseURL.searchParams.append("state", JSON.stringify(passthroughState));

  return authBaseURL;
};
