import React from "react";

import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { generatePath } from "react-router-dom";

import { useAppSelectionContext } from "../../../../main/context/App/AppSelectionContext";
import { RoutePaths } from "../../../../main/router/config/routePaths";
import { TicketPriority } from "../../../models/TicketPriority.model";
import { TicketStatus } from "../../../models/TicketStatus.model";
import { getTagColor } from "../../Input/InputWithTags/InputWithTags";
import { CrashReportTableRowItem } from "../../MasterTable/models/MasterTableModel";
import TagComponent from "../../Tag/Tag";
import { BaseCell } from "../ui/BaseCell";
import { HeaderCell, Truncate } from "../ui/BaseTableStyles";
import { SelectionHeaderCell } from "../ui/SelectionHeaderCell";
import { SortHeaderCell, TicketSortOption, UserSortOption } from "../ui/SortHeaderCell";
import { TicketTitleCell } from "../ui/TicketTitleCell";
import { useBaseTable } from "../useBaseTable";

type TableProps = {
  selectable?: boolean;
  data: CrashReportTableRowItem[];
  hasMore: boolean;
  isLoading: boolean;
  isFetchingNext: boolean;
  loadMore: () => void;
  onDeleteRequest: (rows: CrashReportTableRowItem[]) => void;
  onChangeSortOption: (sort: TicketSortOption | UserSortOption) => void;
  onChangeStatusRequest: (status: string, ticketID: string) => Promise<void>;
  onChangePriorityRequest: (priority: string, ticketID: string) => Promise<void>;
  onBulkChangePriority: (
    rows: CrashReportTableRowItem[],
    priority: TicketPriority,
    resetSelection?: () => void,
  ) => void;
  onBulkChangeStatus: (rows: CrashReportTableRowItem[], status: TicketStatus, resetSelection?: () => void) => void;
  onBulkChangeAssignee: (
    rows: CrashReportTableRowItem[],
    assigneeId: string | null,
    resetSelection?: () => void,
  ) => void;
  onBulkAddTags: (rows: CrashReportTableRowItem[], tags: string[]) => void;
  selectedSortOption: TicketSortOption | UserSortOption;
  onChangeAssigneeRequest: (assignee_id: string | null, ticketID: string) => Promise<void>;
  total: number;
};

export function useCrashesTable({
  selectable,
  data,
  hasMore,
  isLoading,
  isFetchingNext,
  loadMore,
  onDeleteRequest,
  onBulkChangePriority,
  onBulkChangeStatus,
  onBulkAddTags,
  selectedSortOption,
  onChangeSortOption,
  onChangePriorityRequest,
  onChangeStatusRequest,
  onChangeAssigneeRequest,
  onBulkChangeAssignee,
  total,
}: TableProps) {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();

  const baseTableProps = useBaseTable({
    data,
    columns: defaultCrashColumns({
      onDeleteRequest,
      selectedSortOption,
      onChangeSortOption,
      onChangePriorityRequest,
      onChangeStatusRequest,
      onChangeAssigneeRequest,
      onBulkChangeAssignee,
      onBulkChangePriority,
      onBulkChangeStatus,
      onBulkAddTags,
      total,
    }) as unknown as ColumnDef<CrashReportTableRowItem>[], // TODO FIX TYPING
    selectable,
    hasMore,
    isLoading,
    isFetchingNext,
    loadMore,
    renderRowLinkPath: (item) => {
      return generatePath(RoutePaths.CRASH_REPORT_OVERVIEW, {
        workspaceSlug: selectedWorkspace?.slug ?? null,
        appKey: selectedApp?.key ?? null,
        crashReportKey: item.key?.toString(),
      });
    },
    onPreviewRequest: (data) => {
      // Ignore
    },
    onDeleteRequest,
    total,
    selectedSortOption,
  });

  return { ...baseTableProps };
}

const columnHelper = createColumnHelper<CrashReportTableRowItem>();

const sortOptions = [
  { value: "key" as TicketSortOption, label: "Last created" },
  { value: "status" as TicketSortOption, label: "Status" },
  { value: "priority" as TicketSortOption, label: "Priority" },
];

const defaultCrashColumns = ({
  onDeleteRequest,
  onBulkChangePriority,
  onBulkChangeStatus,
  onBulkAddTags,
  selectedSortOption,
  onChangeSortOption,
  onChangePriorityRequest,
  onChangeStatusRequest,
  onChangeAssigneeRequest,
  onBulkChangeAssignee,
  total,
}: Pick<
  TableProps,
  | "onDeleteRequest"
  | "onBulkChangePriority"
  | "onBulkChangeAssignee"
  | "onBulkChangeStatus"
  | "onBulkAddTags"
  | "selectedSortOption"
  | "onChangeSortOption"
  | "onChangePriorityRequest"
  | "onChangeStatusRequest"
  | "onChangeAssigneeRequest"
  | "total"
>) => [
  columnHelper.accessor("name", {
    cell: (info) => (
      <TicketTitleCell
        title={info.row.original.name}
        showsIndicator={false}
        status={info.row.original.status}
        priority={info.row.original.priority}
        info={info as CellContext<unknown, string>}
        onChangeStatusRequest={(status) => onChangeStatusRequest(status, info.row.original.id)}
        onChangePriorityRequest={(priority) => onChangePriorityRequest(priority, info.row.original.id)}
        linkedTicketsCount={info.row.original.linked_crash_groups_count}
        onChangeAssigneeRequest={onChangeAssigneeRequest}
      />
    ),
    header: (info) => (
      <SelectionHeaderCell
        table={info.table}
        onDeleteRequest={onDeleteRequest}
        onBulkChangePriority={onBulkChangePriority}
        onBulkChangeStatus={onBulkChangeStatus}
        onBulkChangeAssignee={onBulkChangeAssignee}
        onBulkAddTags={onBulkAddTags}
        onMarkAsReadRequest={() => {
          return;
        }}
        total={total}
      />
    ),
    size: 2, // size in fractions of available space - grid fr
  }),
  columnHelper.accessor("method", {
    cell: (info) => (
      <BaseCell>
        <Truncate>{info.getValue()}</Truncate>
      </BaseCell>
    ),
    header: () => <HeaderCell>Method</HeaderCell>,
    size: 3,
  }),
  columnHelper.accessor("line", {
    cell: (info) => <BaseCell>{info.getValue()}</BaseCell>,
    header: () => <HeaderCell>Line</HeaderCell>,
    meta: {
      columnTemplate: "minmax(min-content, 100px)",
    },
    size: 0.5,
  }),
  columnHelper.accessor("tags", {
    cell: (info) => (
      <BaseCell
        fadeGradientReach={100}
        style={{ display: "flex", alignItems: "center", overflow: "hidden", position: "relative" }}
      >
        {info.row.original.tags.map((tag) => {
          return (
            <TagComponent
              key={tag.id}
              value={tag.name}
              color={getTagColor(tag.name)}
              border
            />
          );
        })}
      </BaseCell>
    ),
    header: () => <HeaderCell>Tags</HeaderCell>,
    size: 3,
  }),
  columnHelper.accessor("events", {
    cell: (info) => <BaseCell>{info.getValue()}</BaseCell>,
    header: () => <HeaderCell>Events</HeaderCell>,
    meta: {
      columnTemplate: "minmax(min-content, 100px)",
    },
    size: 0.5,
  }),
  columnHelper.accessor("assignee", {
    cell: () => <BaseCell></BaseCell>,
    header: () => (
      <SortHeaderCell
        onSortRequest={onChangeSortOption}
        selectedSortOption={selectedSortOption}
        options={sortOptions}
        type="crashes"
      />
    ),
    size: 0.5,
  }),
];
