import { Option } from "../../../../../../common/components/SelectDropdown/SelectDropdown";
import { RulesModule } from "../../../../../../common/models/Rule.model";

export const moduleOptions = [{ value: "issue_tracking", label: "User feedback" }] as Option[];

export const eventOptions = [
  {
    value: RulesModule.USER_FEEDBACK,
    label: "A ticket is created",
    subtitle: "Triggers when a new ticket is created",
  },
] as Option[];
