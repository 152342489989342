import styled, { css } from "styled-components";

import Button from "../../../../common/components/Button/Button";
import { Form, FormLinks } from "../../shared/styles/Form.styles";

export const GoogleSignInButton = styled(Button)`
  margin: 3rem 0;

  img {
    margin-right: 0.5rem;
  }
`;

export const CenteredContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SignInForm = styled(Form)``;

export const SignInFormLinks = styled(FormLinks)``;

export const FormContainer = styled.div<{ $googleLoading: boolean }>`
  ${({ $googleLoading }) =>
    $googleLoading &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;
