import React, { Fragment } from "react";

import { AdministrationCard } from "../../../../../common/components/AdministrationCard/AdministrationCard";
import Button from "../../../../../common/components/Button/Button";
import { useModal } from "../../../../../common/components/Modal/Modal";
import identifiers from "../../../../../common/util/identifiers.json";
import { t } from "../../../../../common/util/translator";
import { useAppSelectionContext } from "../../../../context/App/AppSelectionContext";
import DeleteWorkspaceModalBody from "../ModalBody/DeleteWorkspaceModalBody";

interface DangerZoneCardProps {
  deleteWorkspace: () => Promise<void>;
  loading: boolean;
}

export default function DangerZoneCard({ deleteWorkspace, loading }: DangerZoneCardProps): JSX.Element {
  const { selectedWorkspace } = useAppSelectionContext();
  const { Modal: DeleteWorkspaceModal, toggle, modalProps } = useModal({ size: "narrow" });

  const handleWorkspaceDelete = () => {
    deleteWorkspace().then(() => {
      toggle();
    });
  };

  return (
    <Fragment>
      <AdministrationCard
        title={t("administration.general.card.heading2")}
        subtitle={t("administration.general.card.subtitle")}
      >
        <Button
          size="small"
          color="red"
          onClick={toggle}
          testId={identifiers["general.button.deleteWorkspace"]}
        >
          {t("administration.general.card.button")}
        </Button>
      </AdministrationCard>

      <DeleteWorkspaceModalBody
        selectedWorkspaceName={selectedWorkspace?.name}
        deleteWorkspace={handleWorkspaceDelete}
        loading={loading}
        Modal={DeleteWorkspaceModal}
        internalProps={modalProps}
      />
    </Fragment>
  );
}
