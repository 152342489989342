import styled, { css } from "styled-components";

import { ReactComponent as DownloadLogsIcon } from "../../../../common/assets/images/shared-icons/download-icon.svg";
import { NavItem } from "../../../../common/components/Navigation/Navigation.styles";
import { vars } from "../../../../common/styles";

export const NavigationTitle = styled(NavItem)<{ $isButton?: boolean }>`
  p {
    color: ${vars.colors.grey10};
    font-weight: normal;
  }

  div {
    display: flex;
    align-items: center;
    height: 100%;

    ${(props) =>
      props.$isButton &&
      css`
        &:hover {
          background-color: ${vars.colors.grey100};
          border-radius: 1rem;
        }

        p {
          max-width: 19rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          user-select: none;

          padding-left: 0.8rem;
          padding-right: 0.8rem;
          font-weight: normal;
        }
      `}
  }
`;

export const Popover = styled.div`
  position: relative;
  width: 24rem;
  border-radius: 1rem;
  border: 1px solid ${vars.colors.grey90};
  box-shadow: 0 0.2rem 1rem 0 #1c1c1e;
  background-color: ${vars.colors.grey100};
  padding: 1rem 0;
  margin: 0;
  cursor: default;
  z-index: ${vars.zIndex.modalContent};
  &:focus-visible {
    border: 1px solid ${vars.colors.grey90};
    outline: transparent;
  }
`;

export const DownloadIcon = styled(DownloadLogsIcon)`
  margin-left: 1rem;
  cursor: pointer;
  margin-right: 0.2rem;

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      svg {
        path: ${vars.colors.grey70};
      }
    `}
`;
