import { useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";

import { UserToken } from "../../common/models";
import { getAndParseItemFromLocalStorage } from "../../common/util/localStorage";
import { removeAttributesFromLocalStorage } from "../../common/util/removeAttributesFromLocalStorage";
import { unregisterShakeSdkUser } from "../../common/util/shakeSdkUtils";
import { useAuthDeps } from "../App.dependencies";
import { setUserDataAction, setUserTokenAction } from "../context/Auth/Auth.actions";
import { useAuthDispatch } from "../context/Auth/Auth.context";
import { USER_DATA_LOCAL_STORAGE_KEY, USER_TOKEN_LOCAL_STORAGE_KEY } from "../context/Auth/Auth.types";
import { RoutePaths } from "../router/config/routePaths";

export default function useSignOutApiConsumer() {
  const dispatch = useAuthDispatch();
  const { authService } = useAuthDeps();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const signOut = useCallback(async () => {
    const userToken: UserToken | null = getAndParseItemFromLocalStorage(USER_TOKEN_LOCAL_STORAGE_KEY);

    if (userToken && userToken.access_token && userToken.refresh_token) {
      await authService.logout(userToken.access_token);
    }

    dispatch(setUserDataAction(undefined));
    dispatch(setUserTokenAction(undefined));

    // appDispatch(setSelectedAppAction(undefined));
    // appDispatch(setSelectedWorkspaceAction(undefined));

    localStorage.removeItem(USER_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(USER_DATA_LOCAL_STORAGE_KEY);
    removeAttributesFromLocalStorage();

    queryClient.removeQueries();

    unregisterShakeSdkUser();

    navigate(generatePath(RoutePaths.SIGNIN));
  }, [authService, dispatch, navigate, queryClient]);

  return { signOut };
}
