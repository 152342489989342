import React from "react";

import { generatePath, NavigateFunction } from "react-router-dom";

import * as Styled from "./CrashEventPageNavigationContent.styles";
import Icon from "../../../../../../common/components/Icon/Icon";
import Tooltip from "../../../../../../common/components/Tooltip/Tooltip";
import { CrashEvent } from "../../../../../../common/models/crashReporting";
import identifiers from "../../../../../../common/util/identifiers.json";
import { RoutePaths } from "../../../../../router/config/routePaths";

enum EventType {
  PREVIOUS = "previous",
  NEXT = "next",
}

interface Props {
  crashEvent?: CrashEvent;
  crashReportKey?: string;
  selectedWorkspaceSlug?: string;
  selectedAppKey?: string;
  navigate: NavigateFunction;
}

export default function CrashEventPageNavigationContent({
  crashEvent,
  crashReportKey,
  selectedWorkspaceSlug,
  selectedAppKey,
  navigate,
}: Props) {
  if (!selectedWorkspaceSlug || !selectedAppKey || !crashEvent) {
    return <></>;
  }

  const commonParamValues = {
    workspaceSlug: selectedWorkspaceSlug,
    appKey: selectedAppKey,
    crashReportKey: crashReportKey,
  };

  const allEventsPath = generatePath(RoutePaths.CRASH_REPORT_EVENT, {
    workspaceSlug: commonParamValues.workspaceSlug,
    appKey: commonParamValues.appKey,
    crashReportKey: commonParamValues.crashReportKey ?? null,
    crashReportEventId: crashEvent.id,
  });
  const crashOverviewPath = generatePath(RoutePaths.CRASH_REPORT_OVERVIEW, {
    workspaceSlug: commonParamValues.workspaceSlug,
    appKey: commonParamValues.appKey,
    crashReportKey: commonParamValues.crashReportKey ?? null,
  });

  const getCrashReportEventPath = (eventId: string) => {
    return generatePath(RoutePaths.CRASH_REPORT_EVENT, {
      workspaceSlug: selectedWorkspaceSlug,
      appKey: selectedAppKey,
      crashReportKey: crashReportKey ?? null,
      crashReportEventId: eventId,
    });
  };

  const isOverviewClicked = window.location.pathname.includes("overview");

  const CaretLink = (type: EventType, eventId?: string) => {
    const text = type === EventType.PREVIOUS ? "Previous event" : "Next event";
    const icon = type === EventType.PREVIOUS ? "caret-left" : "caret-right";
    const previousEventId = EventType.PREVIOUS ? eventId : undefined;
    const nextEventId = EventType.NEXT ? eventId : undefined;

    return (
      <Styled.CaretLinks
        $previousEventId={previousEventId}
        $nextEventId={nextEventId}
      >
        <Tooltip
          position="bottom"
          text={text}
          disabled={!eventId}
        >
          <a onClick={() => navigate(getCrashReportEventPath(eventId ?? "-1"))}>
            <Icon
              icon={icon}
              size={10}
            />
          </a>
        </Tooltip>
      </Styled.CaretLinks>
    );
  };

  return (
    <Styled.CrashNavigationLinks>
      <Styled.CrashNavigationLink
        $active={isOverviewClicked}
        onClick={() => navigate(crashOverviewPath)}
        data-testid={identifiers["navbar.crashReport.overview"]}
      >
        {"Overview"}
      </Styled.CrashNavigationLink>
      <Styled.CrashNavigationLink
        $active={!isOverviewClicked}
        onClick={() => navigate(allEventsPath)}
        data-testid={identifiers["navbar.crashReport.allEvents"]}
      >
        {"All events"}
      </Styled.CrashNavigationLink>

      {CaretLink(EventType.PREVIOUS, crashEvent.previous_event)}

      {CaretLink(EventType.NEXT, crashEvent.next_event)}
    </Styled.CrashNavigationLinks>
  );
}
