import React, { Fragment, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import Heading from "../../../../../common/components/Heading/Heading";
import PageTitle from "../../../../../common/components/PageTitle/PageTitle";
import PageWrap from "../../../../../common/components/PageWrap/PageWrap";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import displayToast from "../../../../../common/components/Toast/displayToast";
import { isAdmin } from "../../../../../common/models/Member.model";
import identifiers from "../../../../../common/util/identifiers.json";
import { useAppSelectionContext } from "../../../../context/App/AppSelectionContext";
import CreditCardDetailsCard from "../../components/BillingCards/CreditCardDetailsCard";
import ReceiptsCard from "../../components/BillingCards/ReceiptsCard";
import ResumeSubscriptionCard from "../../components/SubscriptionCards/ResumeSubscriptionCard/ResumeSubscriptionCard";
import { YourSubscriptionCard } from "../../components/SubscriptionCards/YourSubscriptionCard/YourSubscriptionCard";
import { useBillingViewConsumer } from "../../consumers/useBillingViewConsumer";

export default function BillingView(): JSX.Element {
  const { selectedWorkspace } = useAppSelectionContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    customer,
    globalLoading,
    creditCard,
    updateCreditCard,
    receipts,
    plans,
    addOns,
    cancelSubscription,
    resumeSubscription,
  } = useBillingViewConsumer();

  useEffect(() => {
    if (searchParams.get("mode") && searchParams.get("success")) {
      if (searchParams.get("mode") === "subscription" && searchParams.get("success") === "true") {
        displayToast({
          title: "Thank you for upgrading",
          content: "You're in — now it's time to make the most out of Shake.",
        });
      } else if (searchParams.get("mode") === "setup" && searchParams.get("success") === "true") {
        displayToast({
          title: "Card updated",
          content: "All the changes have been saved and you're good to go.",
        });
      }
      searchParams.delete("mode");
      searchParams.delete("success");
      setSearchParams(searchParams);
      return;
    }
  }, [searchParams, setSearchParams]);

  return (
    <Fragment>
      <PageTitle>
        <Heading
          as="h1"
          heading1
          marginBottom={8}
          testId={identifiers["billing.header.main"]}
        >
          Billing
        </Heading>
        <p>Manage your subscription and billing details.</p>
      </PageTitle>
      {customer && receipts && !globalLoading ? (
        <PageWrap>
          <YourSubscriptionCard
            customer={customer}
            plans={plans}
            addOns={addOns}
            cancelSubscription={cancelSubscription}
          />
          {isAdmin(selectedWorkspace?.role) && (
            <Fragment>
              {creditCard && (
                <CreditCardDetailsCard
                  customer={customer}
                  creditCard={creditCard}
                  updateCreditCard={updateCreditCard}
                />
              )}
              {customer.subscription?.cancel_at_period_end && (
                <ResumeSubscriptionCard
                  subscription={customer.subscription}
                  resumeSubscription={resumeSubscription}
                />
              )}
              <ReceiptsCard
                receipts={receipts}
                customer={customer}
              />
            </Fragment>
          )}
        </PageWrap>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
}
