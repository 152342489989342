import { useState } from "react";

import displayToast from "../../../../../../../common/components/Toast/displayToast";
import { useForm } from "../../../../../../../common/hooks/useForm";
import { IntegrationType } from "../../../../../../../common/models/integrations";
import { Webhook, WebhookIntegrationPayload } from "../../../../../../../common/models/integrations/Webhook.model";
import { useIntegrationFormConsumer } from "../../../../consumers/useIntegrationFormConsumer";

interface Props {
  integration?: Webhook;
  selectedAppId: string;
  onFinish: () => void;
}

interface FormState {
  webhook_url: string;
  webhook_secret: string | null;
}

const validators = {
  webhook_url: (webhook: string) => {
    const urlRegex = new RegExp("(^https:\\/\\/(?:www\\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}$)");
    if (!webhook.trim().length || !webhook.match(urlRegex)) return new Error("Invalid enpoint URL");
  },
};

export const useWebhookIntegrationFormConsumer = ({ selectedAppId, onFinish, integration }: Props) => {
  const { integrate, updateIntegration } = useIntegrationFormConsumer({
    selectedAppId,
    type: IntegrationType.WEBHOOK,
    noInfo: true,
  });

  const { formState, formError, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: {
      webhook_url: integration ? integration.webhook_url : "",
      webhook_secret: integration ? integration.webhook_secret : null,
    },
    validators,
    onSubmit: handleConnect,
  });

  const [loading, setLoading] = useState(false);

  async function handleConnect() {
    try {
      setLoading(true);
      const payload: WebhookIntegrationPayload = {
        webhook_url: formState.webhook_url,
      };
      const endpoint = !!integration ? updateIntegration : integrate;

      await endpoint<Webhook>(payload);
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      onFinish();
      setLoading(false);
    }
  }

  return { formState, formError, handleFormChange, handleFormSubmit, disabled: loading };
};
