import styled, { css } from "styled-components";

import { vars } from "../../../../../common/styles";
import { pxToRem } from "../../../../../common/styles/abstracts/utils";

export const AdministrationCardWrap = styled.div`
  padding: 3.2rem;
  border-radius: 2rem;
  background-color: ${vars.colors.grey100};
  margin-bottom: 1.2rem;
`;

export const SubtitleWrap = styled.p<{ $isEmpty?: boolean }>`
  color: ${vars.colors.grey60};
  padding-bottom: 2.4rem;
  font-weight: normal;
  ${(props) =>
    props.$isEmpty &&
    css`
      padding-bottom: 0;
    `}
`;

export const HeadingWrap = styled.div`
  color: ${vars.colors.grey30};
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: ${pxToRem(16)};
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
