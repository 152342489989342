import { useCallback, useEffect } from "react";

import { Socket } from "socket.io-client";

import useWorkspaceAppsApiConsumer from "./useWorkspaceAppsApiConsumer";
import ShakeIcon from "../../common/assets/icons/shake_icon.svg";
import {
  IssueAddedEvent,
  IssueChatChangedEvent,
  ServerToClientEvents,
  SocketEvents,
} from "../../common/models/webSocketEvents";
import { resolveCurrentDomainRoute } from "../../common/util/resolveCurrentDomainRoute";
import { useAppContext } from "../context/App/App.context";
import { useAppSelectionContext } from "../context/App/AppSelectionContext";
import { RoutePaths } from "../router/config/routePaths";

export default function useDesktopNotificationsSocketConsumer(socket?: Socket<ServerToClientEvents, never>) {
  const { apps } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();
  const { fetchAllApps } = useWorkspaceAppsApiConsumer();

  const tryToFireDesktopNotification = useCallback(
    (event: IssueAddedEvent | IssueChatChangedEvent, notificationTitle: string) => {
      if (document.hasFocus()) return;

      const app = apps.find((app) => app.key === event.app_key && app.follow_app);
      if (!app) return;

      const currentDomain = resolveCurrentDomainRoute(location.pathname);
      if (currentDomain === RoutePaths.USER_FEEDBACK || currentDomain === RoutePaths.CRASH_REPORTS) {
        selectedWorkspace && fetchAllApps(selectedWorkspace.id);
      }

      createDesktopNotification(
        `${notificationTitle} in ${app.name} ${app.platform.name}`,
        event.issue_title,
        event.issue_url,
      );
    },
    [apps, fetchAllApps, selectedWorkspace],
  );

  // new user feedback notifications
  useEffect(() => {
    if (!selectedWorkspace || !selectedWorkspace?.notify_desktop || !selectedWorkspace?.notify_new_bug) return;

    const eventHandler = (event: IssueAddedEvent) => tryToFireDesktopNotification(event, "New bug reported");
    socket?.on(SocketEvents.ISSUE_ADDED, eventHandler);

    return () => {
      socket?.off(SocketEvents.ISSUE_ADDED, eventHandler);
    };
  }, [selectedWorkspace, socket, tryToFireDesktopNotification]);

  // new chat message notifications
  useEffect(() => {
    if (!selectedWorkspace || !selectedWorkspace?.notify_desktop || !selectedWorkspace?.notify_new_chat_message) return;

    const eventHandler = (event: IssueChatChangedEvent) => tryToFireDesktopNotification(event, "New chat message");
    socket?.on(SocketEvents.ISSUE_CHAT_CHANGED, eventHandler);

    return () => {
      socket?.off(SocketEvents.ISSUE_CHAT_CHANGED, eventHandler);
    };
  }, [selectedWorkspace, socket, tryToFireDesktopNotification]);
}

const createDesktopNotification = (notificationTitle: string, notificationText: string, notificationUrl: string) => {
  const notification = new Notification(notificationTitle, {
    icon: ShakeIcon,
    body: notificationText,
  });

  notification.onclick = () => {
    window.open(notificationUrl, "_blank");
  };
};
