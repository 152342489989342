import { IntegrationType } from "../../../../common/models/integrations";
import ApiService from "../../../../common/services/Api.service";

export class IntegrationActivationService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  activateIntegration<Res, Payload>(workspaceId: string, integrationType: IntegrationType, payload: Payload) {
    return this.apiService.post<Res>({
      resource: `/service_hooks/${workspaceId}/${integrationType.toLocaleLowerCase()}/activate`,
      data: payload,
    });
  }

  activateSentryIntegration<Res, Payload>(workspaceId: string, integrationType: IntegrationType, payload: Payload) {
    return this.apiService.post<Res>({
      resource: `/service_hooks/${workspaceId}/${integrationType.toLocaleLowerCase()}/activate`,
      data: payload,
    });
  }

  activateClickupIntegration<Res, Payload>(
    workspaceId: string,
    integrationType: IntegrationType,
    appId: string,
    payload: Payload,
  ) {
    return this.apiService.post<Res>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/${integrationType.toLocaleLowerCase()}/activate`,
      data: payload,
    });
  }

  activateLinearIntegration<Res>(workspaceId: string, integrationType: IntegrationType) {
    return this.apiService.post<Res>({
      resource: `/service_hooks/${workspaceId}/${integrationType.toLocaleLowerCase()}/activate`,
    });
  }

  activateTrelloIntegration<Res, Payload>(
    workspaceId: string,
    integrationType: IntegrationType,
    appId: string,
    payload: Payload,
  ) {
    return this.apiService.post<Res>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/${integrationType.toLocaleLowerCase()}`,
      data: payload,
    });
  }
}
