import React from "react";

import SecurityCardModalForm from "./SecurityCardModalForm";
import Button from "../../../../../common/components/Button/Button";
import Card from "../../../../../common/components/Card/Card";
import Heading from "../../../../../common/components/Heading/Heading";
import { useModal } from "../../../../../common/components/Modal/Modal";
import identifiers from "../../../../../common/util/identifiers.json";
import { useAuthContext } from "../../../../context/Auth/Auth.context";

export const SecurityCard = () => {
  const { userData } = useAuthContext();
  const { Modal: SecurityCardModal, toggle, modalProps } = useModal({ size: "narrow" });

  return (
    <>
      <Card>
        <Heading
          as="h3"
          heading3
          marginBottom={24}
          testId={identifiers["info.security.header"]}
        >
          Security
        </Heading>

        <Button
          size="small"
          testId={
            userData?.hasPassword
              ? identifiers["info.security.button.changePass"]
              : identifiers["info.security.button.setPass"]
          }
          onClick={toggle}
        >
          {userData?.hasPassword ? "Change password" : "Set password"}
        </Button>
      </Card>

      <SecurityCardModalForm
        toggle={toggle}
        Modal={SecurityCardModal}
        internalProps={modalProps}
      />
    </>
  );
};
