import React from "react";

import * as Styled from "./DetailsPane.styles";
import { DetailsPaneSection } from "./DetailsPaneSection";
import { GroupedInfoElement } from "./helper";
import { AppUserShared } from "../../models";

export interface LinkElement {
  tooltipText: string;
  link: string;
  testId?: string;
  isExternal?: boolean;
}

interface DetailsPaneProps {
  info: GroupedInfoElement[];
  title?: string;
  user?: AppUserShared;
  link?: LinkElement;
  headerTestId?: string;
}

export const DetailsPane: React.FC<DetailsPaneProps> = ({ info, headerTestId }): JSX.Element => {
  return (
    <Styled.DetailsPane>
      {info.map((group, index) => {
        return (
          <DetailsPaneSection
            key={index}
            info={group.infoElements}
            title={group.title}
            headerTestId={headerTestId}
            link={group.link}
          />
        );
      })}
    </Styled.DetailsPane>
  );
};
