import React, { Fragment, useEffect, useRef, useState } from "react";

import InsertCommentsModalBody from "./InsertCommonComment/InsertCommentModalBody";
import { CommonCommentsTooltip } from "../../../../main/pages/shared/components/Messages/MessageContainer.styles";
import { ReactComponent as CommonCommentsIcon } from "../../../assets/images/shared-icons/common-comments-icon.svg";
import { CommonComment } from "../../../models/CommonComment.model";
import { Flex } from "../../../styles/reusable/Flex/Flex.styles";
import { useModal } from "../../Modal/Modal";
import Input, { InputElementType } from "../Input";

interface Props {
  placeholder?: string;
  onMessageSend?: (message: string) => void;
  onChange?: (text: string) => void;
  initialValue?: string;
  autoFocus?: boolean;
  testId?: string;
  icontestid?: string;
  sendToUserButton?: JSX.Element;
  sendButtonText: string;
  withoutSendButton?: boolean;
}

export default function MessageInput(props: Props) {
  const [inputValue, setInputValue] = useState(props.initialValue || "");
  const inputWrapperRef = useRef<HTMLTextAreaElement>(null);
  const [selectedComment, setSelectedComment] = useState<CommonComment>();

  const { Modal: CommonCommentsModal, toggle: toggle, modalProps: internalProps } = useModal({ size: "fuller" });

  const sendMessage = () => {
    props.onMessageSend?.(inputValue);
    setInputValue("");
  };

  const handleKeydownEvent = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.stopPropagation();
      inputWrapperRef?.current?.blur();
      sendMessage();
    }
  };

  const handleChangeEvent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    inputWrapperRef?.current?.focus();
    if (props.onChange) props.onChange(event.target.value);
    setInputValue(event.currentTarget.value);
  };

  useEffect(() => {
    if (selectedComment) {
      inputWrapperRef?.current?.focus();
      setInputValue((inputValue) => inputValue.concat(selectedComment.message));
      setSelectedComment(undefined);
      if (props.onChange) props.onChange(inputValue.concat(selectedComment.message));
      toggle();
    }
  }, [selectedComment, toggle, props, inputValue]);

  return (
    <Fragment>
      <Input
        autoFocus={props.autoFocus}
        elementType={InputElementType.TEXTAREA}
        innerRef={inputWrapperRef}
        placeholder={props.placeholder}
        value={inputValue}
        onChange={handleChangeEvent}
        onKeyDown={handleKeydownEvent}
        withSendButton={!props.withoutSendButton}
        actionIcon={
          <Flex
            $justifyContent="center"
            style={{ cursor: "pointer" }}
          >
            <CommonCommentsTooltip
              position="top"
              text="Insert common comment"
            >
              <CommonCommentsIcon onClick={toggle} />
            </CommonCommentsTooltip>
          </Flex>
        }
        toggleButton={props.sendToUserButton}
        onSendButtonClick={sendMessage}
        testId={props.testId}
        icontestid={props.icontestid}
        rows={5}
        sendButtonText={props.sendButtonText}
      />
      <InsertCommentsModalBody
        Modal={CommonCommentsModal}
        internalProps={internalProps}
        setSelectedComment={setSelectedComment}
      />
    </Fragment>
  );
}
