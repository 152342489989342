import React, { Fragment, useEffect, useMemo, useState } from "react";

import * as Styled from "./ModalBody.styles";
import AppPlaceholderPhoto from "../../../../../common/assets/images/shared-icons/app-icon-placeholder.svg";
import Button from "../../../../../common/components/Button/Button";
import { ModalProps } from "../../../../../common/components/Modal/Modal";
import { ModalHeaderProps } from "../../../../../common/components/Modal/ModalHeader/ModalHeader";
import Option from "../../../../../common/components/Option/Option";
import SelectDropdown from "../../../../../common/components/SelectDropdown/SelectDropdown";
import { App } from "../../../../../common/models";
import { MemberRole } from "../../../../../common/models/Member.model";
import { getActiveApps } from "../../../../../common/util/appsUtil";
import identifiers from "../../../../../common/util/identifiers.json";
import { useAppContext } from "../../../../context/App/App.context";
import { getRoleOptions } from "../../util/getRoleOptions";

interface Props {
  handleCustomizeTeamMember: (role: MemberRole, apps: string[]) => void;
  memberName: string;
  currentMemberRole: MemberRole;
  loading: boolean;
  selectedApps: App[];
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function CustomizeTeamMemberModalBody({
  handleCustomizeTeamMember,
  memberName,
  currentMemberRole,
  loading,
  selectedApps,
  Modal,
  internalProps,
}: Props) {
  const { apps } = useAppContext();
  const activeApps = useMemo(() => getActiveApps(apps), [apps]);
  const [selectedRole, setSelectedRole] = useState<MemberRole>(currentMemberRole);
  const [selectedAppIds, setSelectedAppIds] = useState<string[]>(selectedApps?.map((app) => app.id));

  const roleOptions = getRoleOptions(activeApps.length);

  useEffect(() => {
    if (selectedRole !== MemberRole.GUEST) {
      setSelectedAppIds(activeApps.map((a) => a.id));
    }
  }, [selectedRole, setSelectedAppIds, activeApps]);

  return (
    <Modal
      {...internalProps}
      heading={memberName}
      subtitle={
        "Members have view permissions only. Admins are allowed to edit everything: connect integrations, archive apps, invite and remove teammates, etc. Guests only have permission to view the apps you give them access to in your workspace."
      }
      testId={identifiers["members.myTeam.user.dropdownButton.customize.role.header"]}
      buttonElement={
        <Button
          disabled={loading || (selectedRole === MemberRole.GUEST && !selectedAppIds?.length)}
          onClick={() => {
            handleCustomizeTeamMember(selectedRole, selectedAppIds);
          }}
          size="small"
          testId={identifiers["members.myTeam.user.dropdownButton.customize.button"]}
          loadingText={loading && "Saving..."}
          tooltip={{
            position: "top",
            text: "Please choose at least one app first",
            disabled: loading || !Boolean(selectedRole === MemberRole.GUEST && !selectedAppIds?.length),
          }}
        >
          Save changes
        </Button>
      }
    >
      <Styled.ModalBodyWrapper>
        <SelectDropdown
          options={roleOptions}
          label="Role"
          onChange={(event) => setSelectedRole(event.value as MemberRole)}
          defaultValue={roleOptions.find((option) => option.value === selectedRole)}
          isSearchable={false}
          testId={identifiers["members.myTeam.user.dropdownButton.customize.role.dropdown"]}
        />

        {Boolean(activeApps.length) && (
          <Fragment>
            <Option
              label="All apps"
              checked={Boolean(selectedAppIds?.length === activeApps?.length) || selectedRole !== MemberRole.GUEST}
              handleCheck={() => setSelectedAppIds(handleSelectAllApps(selectedAppIds, activeApps))}
              disabled={loading || selectedRole !== MemberRole.GUEST}
            />

            <Styled.OptionsContainer>
              {activeApps.map((app) => (
                <Option
                  key={app.id}
                  label={app.name}
                  checked={Boolean(selectedAppIds?.find((a) => a === app.id))}
                  handleCheck={() => {
                    setSelectedAppIds(handleCheck(selectedAppIds, app.id));
                  }}
                  disabled={loading || selectedRole !== MemberRole.GUEST}
                  image={app.logo_url ? app.logo_url : AppPlaceholderPhoto}
                />
              ))}
            </Styled.OptionsContainer>
          </Fragment>
        )}
      </Styled.ModalBodyWrapper>
    </Modal>
  );
}

const handleCheck = (selectedApps: string[], appId: string) => {
  if (selectedApps.find((app) => app === appId)) {
    return selectedApps.filter((app) => app !== appId);
  }
  return selectedApps.concat(appId);
};

const handleSelectAllApps = (selectedApps: string[], activeApps: App[]) => {
  if (selectedApps.length === activeApps.length) {
    return [];
  }
  return activeApps.map((app) => app.id);
};
