import { useCallback, useEffect, useRef, useState } from "react";

import displayToast from "../../../../common/components/Toast/displayToast";
import {
  AzureIntegrationPayload,
  getIntegrationTypeName,
  IntegrationPayload,
  IntegrationType,
  JiraIntegrationUpdatePayload,
  StartIntegrationPayload,
} from "../../../../common/models/integrations";
import useWorkspaceAppsApiConsumer from "../../../consumers/useWorkspaceAppsApiConsumer";
import { useAppSelectionContext } from "../../../context/App/AppSelectionContext";
import { useAdministrationDeps } from "../Administration";
import AdministrationService from "../services/AdministrationService";

interface Props {
  selectedAppId: string;
  type?: IntegrationType;
  noInfo?: boolean;
  toggle?: () => void;
}

export const useIntegrationFormConsumer = <T>({ selectedAppId, type, noInfo, toggle }: Props) => {
  const abortControllerRef = useRef(new AbortController());

  const { selectedWorkspace } = useAppSelectionContext();
  const { administrationService } = useAdministrationDeps();
  const { updateAllApps } = useWorkspaceAppsApiConsumer();

  const [integrationInfo, setIntegrationInfo] = useState<T>();

  const fetchIntegrationInfo = useCallback(
    (nextPage?: string) => {
      if (!selectedWorkspace || !type) return Promise.resolve();
      return administrationService
        .integrationInfo<T>(selectedWorkspace.id, type, abortControllerRef.current.signal, selectedAppId, nextPage)
        .then(({ data }) => setIntegrationInfo(data))
        .catch(() => {
          if (abortControllerRef.current.signal && abortControllerRef.current.signal.aborted) return;
          toggle?.();
          displayToast({
            title: "Something went wrong",
            content: `There was an error while fetching ${type} integration info.`,
          });
        });
    },
    [selectedWorkspace, administrationService, type, selectedAppId, toggle],
  );

  const integrate = useCallback(
    async <Res>(payload: IntegrationPayload) => {
      try {
        if (!selectedWorkspace || !type) return Promise.reject();

        const { data } = await integrateRequest<Res>(
          administrationService,
          selectedWorkspace.id,
          selectedAppId,
          type,
          payload,
        );

        await updateAllApps(selectedWorkspace.id);

        displayToast({
          content: `${getIntegrationTypeName(type)} has been connected.`,
        });

        return data;
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: error.response.data.message ? error.response.data.message : "Please try again",
        });
        return Promise.reject();
      }
    },
    [selectedWorkspace, administrationService, selectedAppId, type, updateAllApps],
  );

  const updateIntegration = useCallback(
    async <Res>(payload: IntegrationPayload) => {
      try {
        if (!selectedWorkspace || !type) return;

        const { data } = await updateRequest<Res>(
          administrationService,
          selectedWorkspace.id,
          selectedAppId,
          type,
          payload,
        );

        await updateAllApps(selectedWorkspace.id);

        displayToast({
          title: "Fantastic!",
          content: ` Changes to ${getIntegrationTypeName(type)} integration sucessfully saved.`,
        });

        return data;
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: error.response.data.message ? error.response.data.message : "Please try again",
        });
        return Promise.reject();
      }
    },
    [selectedWorkspace, administrationService, selectedAppId, type, updateAllApps],
  );

  const startIntegration = useCallback(
    async <Res, Payload extends StartIntegrationPayload>(payload: Payload | null, type: IntegrationType) => {
      try {
        if (!selectedWorkspace) return;

        const { data } = await administrationService.startIntegration<Res>(
          selectedWorkspace.id,
          selectedAppId,
          type,
          payload ?? undefined,
        );

        return data;
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: error.response.data.message ? error.response.data.message : "Please try again",
        });
      }
    },
    [selectedWorkspace, administrationService, selectedAppId],
  );

  useEffect(() => {
    if (noInfo) return;
    if (!integrationInfo) {
      fetchIntegrationInfo();

      const controller = abortControllerRef.current;
      return () => {
        controller.abort();
      };
    }
  }, [integrationInfo, fetchIntegrationInfo, noInfo, type]);

  return { integrationInfo, integrate, updateIntegration, startIntegration, fetchIntegrationInfo };
};

const integrateRequest = <Res>(
  service: AdministrationService,
  selectedWorkspaceId: string,
  selectedAppId: string,
  type: IntegrationType,
  payload: IntegrationPayload,
) => {
  switch (type) {
    case IntegrationType.SLACK:
    case IntegrationType.ZAPIER:
    case IntegrationType.WEBHOOK:
    case IntegrationType.TRELLO: {
      return service.integrateLegacy<Res>(selectedWorkspaceId, selectedAppId, type, payload);
    }
    case IntegrationType.AZURE_DEVOPS: {
      return service.integrateAzure<Res>(selectedWorkspaceId, selectedAppId, type, payload as AzureIntegrationPayload);
    }
    case IntegrationType.JIRA: {
      return service.integrateJira<Res>(selectedWorkspaceId, selectedAppId, payload as JiraIntegrationUpdatePayload);
    }
    default: {
      return service.integrate<Res>(selectedWorkspaceId, selectedAppId, type, payload);
    }
  }
};

const updateRequest = <Res>(
  service: AdministrationService,
  selectedWorkspaceId: string,
  selectedAppId: string,
  type: IntegrationType,
  payload: IntegrationPayload,
) => {
  switch (type) {
    case IntegrationType.AZURE_DEVOPS: {
      return service.integrateAzure<Res>(selectedWorkspaceId, selectedAppId, type, payload as AzureIntegrationPayload);
    }
    case IntegrationType.JIRA: {
      return service.updateJiraIntegration<Res>(
        selectedWorkspaceId,
        selectedAppId,
        payload as JiraIntegrationUpdatePayload,
      );
    }
    default: {
      return service.updateIntegration<Res>(selectedWorkspaceId, selectedAppId, type, payload);
    }
  }
};
