import { Socket } from "socket.io-client";

import {
  AppContextActionType,
  SetApps,
  SetJoinableWorkspaces,
  SetLoading,
  SetSocketConnection,
  SetUnreadChangeLogNotifications,
  SetUpdatingWorkspace,
  SetWorkspaces,
  SetWorkspacesLoading,
  SetWSTeamMembers,
} from "./App.types";
import { App, BasicWorkspace, Workspace } from "../../../common/models";
import { ChangelogNotification } from "../../../common/models/Changelog.model";
import { Member } from "../../../common/models/Member.model";
import { ServerToClientEvents } from "../../../common/models/webSocketEvents";

export const setWorkspacesAction = (payload: Workspace[]): SetWorkspaces => ({
  type: AppContextActionType.SET_WORKSPACES,
  payload,
});

export const setWSTeamMembersDataAction = (payload?: Member[]): SetWSTeamMembers => ({
  type: AppContextActionType.SET_WS_TEAM_MEMBERS,
  payload,
});

export const setAppsAction = (payload: App[]): SetApps => ({
  type: AppContextActionType.SET_APPS,
  payload,
});

export const setSocketConnectionAction = (payload?: Socket<ServerToClientEvents, never>): SetSocketConnection => ({
  type: AppContextActionType.SET_SOCKET_CONNECTION,
  payload,
});

export const setLoadingAction = (payload: boolean): SetLoading => ({
  type: AppContextActionType.SET_LOADING,
  payload,
});

export const setUpdatingWorkspaceAction = (payload: boolean): SetUpdatingWorkspace => ({
  type: AppContextActionType.SET_UPDATING_WORKSPACE,
  payload,
});

export const setJoinableWorkspaces = (payload: BasicWorkspace[]): SetJoinableWorkspaces => ({
  type: AppContextActionType.SET_JOINABLE_WORKSPACES,
  payload,
});

export const setWorkspacesLoading = (payload: boolean): SetWorkspacesLoading => ({
  type: AppContextActionType.SET_WORKSPACES_LOADING,
  payload,
});

export const setUnreadChangeLogNotifications = (payload: ChangelogNotification[]): SetUnreadChangeLogNotifications => ({
  type: AppContextActionType.SET_UNREAD_CHANGE_LOG_NOTIFICATIONS,
  payload,
});
