import { useCallback, useEffect, useState } from "react";

import displayToast from "@common/components/Toast/displayToast";
import { ApprovedAppDomain } from "@common/models/organization";
import { useLocation } from "react-router-dom";

import { useAdministrationDeps } from "../Administration";

export default function useAppDomainVerificationApiConsumer() {
  const location = useLocation();

  const code = new URLSearchParams(location.search).get("code");

  const { administrationService } = useAdministrationDeps();

  const [approvedAppDomain, setApprovedAppDomain] = useState<ApprovedAppDomain>();

  const activateApprovedAppDomain = useCallback(async () => {
    if (!code) return;
    try {
      const { data } = await administrationService.activateApprovedAppDomain(code);
      setApprovedAppDomain(data);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
  }, [code, administrationService]);

  useEffect(() => {
    activateApprovedAppDomain();
  }, [activateApprovedAppDomain]);

  return {
    approvedAppDomain,
  };
}
