import styled from "styled-components";

import { vars } from "../../../../../../common/styles";

export const PresentationFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0;
`;

export const HeadersGrid = styled.div`
  display: grid;
  grid-template-columns: fit-content(40%) auto;
  grid-template-rows: min-content;
  gap: 1.2rem;
`;

export const KeyWrap = styled.div`
  color: ${vars.colors.grape};
`;

export const NoOverflow = styled.div`
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  color: ${vars.colors.grey30};
`;

export const Circle = styled.div`
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
`;

export const Preformatted = styled.pre`
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

export const JSONTreeWrap = styled.div`
  span {
    opacity: 1 !important;
  }
`;

export const URL = styled.div`
  display: grid;
  grid-template-columns: fit-content(40%) auto;
  grid-template-rows: min-content;
  gap: 1.2rem;

  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${vars.colors.grey100};
`;
